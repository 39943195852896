import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import axios from 'axios';

const baseRatePercentAdapter = createEntityAdapter();
const apiBaseUrl = window.ENV.api_base_url;

const initialState = baseRatePercentAdapter.getInitialState({
  status: 'idle',
  error: null,
});

export const fetchBaseRatePercent = createAsyncThunk(
  'baseRatePercent/fetchBaseRatePercent',
  async (_, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };

    const response = await axios.get(
      `${apiBaseUrl}/api/BaseRatePercent`,
      config
    );
    return response.data;
  }
);

export const addNewBaseRatePercent = createAsyncThunk(
  'baseRatePercent/addNewBaseRatePercent',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.post(
      `${apiBaseUrl}/api/BaseRatePercent`,
      initialState,
      config
    );
    console.log('adding new baseRatePercent record');
    console.log(response);
    return { ...initialState, id: response.data };
  }
);

export const deleteBaseRatePercent = createAsyncThunk(
  'baseRatePercent/deleteBaseRatePercent',
  async (baseRatePercentId, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.delete(
      `${apiBaseUrl}/api/BaseRatePercent/${baseRatePercentId}`,
      config
    );
    return baseRatePercentId;
  }
);

export const updateBaseRatePercent = createAsyncThunk(
  'baseRatePercent/updateBaseRatePercent',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.put(
      `${apiBaseUrl}/api/BaseRatePercent/${initialState.id}`,
      initialState,
      config
    );
    return { ...initialState, id: response.data };
  }
);

const baseRatePercentSlice = createSlice({
  name: 'baseRatePercent',
  initialState,
  extraReducers: {
    [fetchBaseRatePercent.pending]: state => {
      state.status = 'loading';
    },
    [fetchBaseRatePercent.fulfilled]: (state, action) => {
      // Add any fetched posts to the array
      baseRatePercentAdapter.upsertMany(state, action.payload);
      state.status = 'succeeded';
    },
    [fetchBaseRatePercent.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [addNewBaseRatePercent.fulfilled]: baseRatePercentAdapter.addOne,
    [deleteBaseRatePercent.fulfilled]: baseRatePercentAdapter.removeOne,
    [updateBaseRatePercent.fulfilled]: baseRatePercentAdapter.upsertOne,
  },
});

export default baseRatePercentSlice.reducer;

export const {
  selectAll: selectAllBaseRatePercent,
  selectById: selectBaseRatePercentById,
  selectIds: selectBaseRatePercentIds,
} = baseRatePercentAdapter.getSelectors(state => state.baseRatePercent);
