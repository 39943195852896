import React, { useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Button,
  CloseButton,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Stack,
  StackDivider,
  VStack,
} from '@chakra-ui/react';

import { addNewEstimatedClaimPlan } from './estimatedClaimPlanSlice';
import { FieldGroup } from '../../components/common/FieldGroup';
import DatePicker from '../../components/common/DatePicker';

import { ErrorDisplay } from '../common/ErrorDisplay';
import { overlapCheck } from '../../services/overlapCheck';
import {
  dateToString,
  nullableDateToString,
} from '../../services/dateConverters';

export const AddEstimatedClaimPlanForm = ({ user, estimatedClaimPlanList }) => {
  const [estClaimDesc, setEstClaimDesc] = useState(' ');
  const [employeeFactor, setEmployeeFactor] = useState(0);
  const [spouseFactor, setSpouseFactor] = useState(0);
  const [childFactor, setChildFactor] = useState(0);

  const [startDate, setEffStartDate] = useState(new Date());
  const [endDate, setEffEndDate] = useState('');
  const [addRequestStatus, setAddRequestStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();

  const onEstClaimDescChanged = e => {
    setEstClaimDesc(e.target.value);
    setErrorMessage('');
  };

  const onEmployeeFactorChanged = e =>
    setEmployeeFactor(Number(e.target.value));
  const onSpouseFactorChanged = e => setSpouseFactor(Number(e.target.value));
  const onChildFactorChanged = e => setChildFactor(Number(e.target.value));

  const onEffStartDateChanged = e => {
    setEffStartDate(e);
    setErrorMessage('');
  };
  const onEffEndDateChanged = e => {
    setEffEndDate(e);
    setErrorMessage('');
  };

  const canSave =
    [
      estClaimDesc,
      !isNaN(employeeFactor),
      !isNaN(spouseFactor),
      !isNaN(childFactor),

      startDate,
    ].every(Boolean) && addRequestStatus === 'idle';

  const onSaveEstimatedClaimPlanClicked = async () => {
    if (canSave) {
      try {
        setAddRequestStatus('pending');

        var estimatedClaimPlanOverlap = estimatedClaimPlanList.filter(
          overlapCheck,
          {
            effStartDate: startDate,
            effEndDate: endDate,
            keys: [
              {
                name: 'estClaimDesc',
                value: estClaimDesc,
              },
            ],
          }
        );
        if (estimatedClaimPlanOverlap.length) {
          console.log(estimatedClaimPlanOverlap);
          setErrorMessage(
            'The record being entered overlaps an existing effective date range for this key value.'
          );
          return false;
        }

        const resultAction = await dispatch(
          addNewEstimatedClaimPlan({
            estClaimDesc,
            employeeFactor,
            spouseFactor,
            childFactor,

            modifiedByName: user.profile.name,
            modifiedDate: new Date().toISOString(),
            effStartDate: dateToString(startDate),
            effEndDate: nullableDateToString(endDate),
          })
        );

        unwrapResult(resultAction);

        setEstClaimDesc('');
        setEmployeeFactor(0);
        setSpouseFactor(0);
        setChildFactor(0);

        setEffStartDate(new Date());
        setEffEndDate('');
      } catch (err) {
        console.error(
          'Failed to save the estimatedClaimPlan factor record: ',
          err
        );
      } finally {
        setAddRequestStatus('idle');
      }
    }
  };

  return (
    <Box
      px={{
        base: '4',
        md: '10',
      }}
      pb="16"
      maxWidth="3xl"
      mx="auto"
    >
      <form>
        <Stack spacing="4" divider={<StackDivider />}>
          <FieldGroup title="Add Estimated Claim Plan Record">
            <VStack width="full" spacing="6">
              <FormControl id="estClaimDesc">
                <FormLabel>EstClaimDesc:</FormLabel>
                <Input
                  type="text"
                  value={estClaimDesc}
                  onChange={onEstClaimDescChanged}
                />
              </FormControl>
              <FormControl id="employeeFactor">
                <FormLabel>EmployeeFactor:</FormLabel>
                <Input
                  type="number"
                  value={employeeFactor}
                  onChange={onEmployeeFactorChanged}
                />
              </FormControl>
              <FormControl id="spouseFactor">
                <FormLabel>SpouseFactor:</FormLabel>
                <Input
                  type="number"
                  value={spouseFactor}
                  onChange={onSpouseFactorChanged}
                />
              </FormControl>
              <FormControl id="childFactor">
                <FormLabel>ChildFactor:</FormLabel>
                <Input
                  type="number"
                  value={childFactor}
                  onChange={onChildFactorChanged}
                />
              </FormControl>

              <FormControl>
                <FormLabel htmlFor="effStartDate">
                  Effective Start Date
                </FormLabel>
                <DatePicker
                  id="effStartDate"
                  selectedDate={startDate}
                  onChange={onEffStartDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective Start Date</FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="effEndDate">Effective End Date</FormLabel>
                <DatePicker
                  id="effEndDate"
                  selectedDate={endDate}
                  onChange={onEffEndDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective End Date</FormHelperText>
              </FormControl>
            </VStack>
          </FieldGroup>

          <Button
            onClick={onSaveEstimatedClaimPlanClicked}
            disabled={!canSave}
            variant="brand-primary"
            size="xs"
          >
            Save Estimated Claim Plan Factor Record
          </Button>
          <ErrorDisplay
            title="The record dates overlap other records!"
            errorMessage={errorMessage}
          />
        </Stack>
      </form>
    </Box>
  );
};

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
  };
}

export const AddEstimatedClaimPlanFormUser = connect(mapStateToProps)(
  AddEstimatedClaimPlanForm
);
