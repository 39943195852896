import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
  AccordionIcon,
  Heading,
} from '@chakra-ui/react';
import {
  fetchConstantsAdmin,
  selectConstantsAdminIds,
  selectAllConstantsAdmin,
} from './constantsAdminSlice';
import { AddConstantsAdminFormUser } from './AddConstantsAdminForm';
import { ConstantsAdminList } from './ConstantsAdminList';
import BrandLinkButton from '../../components/common/BrandLinkButton';

export const ConstantsAdminPage = () => {
  const dispatch = useDispatch();

  const orderedConstantsAdminIds = useSelector(selectConstantsAdminIds);
  const constantsAdminList = useSelector(selectAllConstantsAdmin);

  const error = useSelector(state => state.constantsAdmin.error);
  if (error) {
    console.log('ConstantsAdmin List Error:' + error.toString());
  }

  const constantsAdminStatus = useSelector(
    state => state.constantsAdmin.status
  );
  const [index, setIndex] = React.useState([]);

  useEffect(() => {
    if (constantsAdminStatus === 'idle') {
      dispatch(fetchConstantsAdmin());
    }
  }, [constantsAdminStatus, dispatch]);

  function closeAccordion() {
    setIndex([]);
  }

  let content;

  if (constantsAdminStatus === 'loading') {
    content = <div className="loader">Loading...</div>;
  } else if (constantsAdminStatus === 'succeeded') {
    console.log(orderedConstantsAdminIds);
    content = (
      <div>
        <Heading size="xl" mb={8}>
          Constants Admin Factors
        </Heading>
        <Accordion
          allowMultiple
          allowToggle="true"
          mb={8}
          index={index}
          onChange={setIndex}
        >
          <AccordionItem>
            <Box>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Text variant="brand-green-link" fontSize="lg">
                    Add New Factor
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </Box>
            <AccordionPanel pb={4}>
              <AddConstantsAdminFormUser
                constantsAdminList={constantsAdminList}
                closeAccordion={closeAccordion}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <ConstantsAdminList
          orderedConstantsAdminIds={orderedConstantsAdminIds}
        />
      </div>
    );
  } else if (constantsAdminStatus === 'failed') {
    content = <div>{error}</div>;
  }

  return (
    <>
      {content}
      <Box mt={12}>
        <BrandLinkButton path="/settings">Back to Settings</BrandLinkButton>
      </Box>
    </>
  );
};

export default ConstantsAdminPage;
