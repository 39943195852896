import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import axios from 'axios';

const basicGroupInfoAdapter = createEntityAdapter();
const apiBaseUrl = window.ENV.api_base_url;

const initialState = basicGroupInfoAdapter.getInitialState({
  status: 'idle',
  error: null,
});

export const fetchBasicGroupInfo = createAsyncThunk(
  'basicGroupInfo/fetchBasicGroupInfo',
  async (_, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };

    const response = await axios.get(`${apiBaseUrl}/api/BasicGroupInfo`, config);
    return response.data;
  }
);


export const addNewBasicGroupInfo = createAsyncThunk(
  'basicGroupInfo/addNewBasicGroupInfo',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.post(
      `${apiBaseUrl}/api/BasicGroupInfo`,
      initialState,
      config
    );

    return { ...initialState, id: response.data };
  }
);



export const deleteBasicGroupInfo = createAsyncThunk(
  'basicGroupInfo/deleteBasicGroupInfo',
  async (ID, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.delete(
      `${apiBaseUrl}/api/BasicGroupInfo/${ID}`,
      config
    );
    return ID;
  }
);



export const updateBasicGroupInfo = createAsyncThunk(
  'basicGroupInfo/updateBasicGroupInfo',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.put(
      `${apiBaseUrl}/api/BasicGroupInfo/${initialState.ID}`,
      initialState,
      config
    );
    return { ...initialState, ID: response.data };
  }
);


const basicGroupInfoSlice = createSlice({
  name: 'basicGroupInfo',
  initialState,
  extraReducers: {
    [fetchBasicGroupInfo.pending]: state => {
      state.status = 'loading';
    },
    [fetchBasicGroupInfo.fulfilled]: (state, action) => {
      // Add any fetched posts to the array
      basicGroupInfoAdapter.upsertMany(state, action.payload);
      state.status = 'succeeded';
    },
    [fetchBasicGroupInfo.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [addNewBasicGroupInfo.fulfilled]: basicGroupInfoAdapter.addOne, 
    [deleteBasicGroupInfo.fulfilled]: basicGroupInfoAdapter.removeOne, 
    [updateBasicGroupInfo.fulfilled]: basicGroupInfoAdapter.upsertOne, 
  },
});

export default basicGroupInfoSlice.reducer;

export const {
  selectAll: selectAllBasicGroupInfo,
  selectById: selectBasicGroupInfoById,
  selectIds: selectBasicGroupInfoIds,
} = basicGroupInfoAdapter.getSelectors(state => state.basicGroupInfo);
