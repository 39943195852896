import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import axios from 'axios';

const credibilityAdapter = createEntityAdapter();
const apiBaseUrl = window.ENV.api_base_url;

const initialState = credibilityAdapter.getInitialState({
  status: 'idle',
  error: null,
});

export const fetchCredibility = createAsyncThunk(
  'credibility/fetchCredibility',
  async (_, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };

    const response = await axios.get(`${apiBaseUrl}/api/Credibility`, config);
    return response.data;
  }
);

export const addNewCredibility = createAsyncThunk(
  'credibility/addNewCredibility',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.post(
      `${apiBaseUrl}/api/Credibility`,
      initialState,
      config
    );

    return { ...initialState, id: response.data };
  }
);

export const deleteCredibility = createAsyncThunk(
  'credibility/deleteCredibility',
  async (credibilityId, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.delete(
      `${apiBaseUrl}/api/Credibility/${credibilityId}`,
      config
    );
    return credibilityId;
  }
);

export const updateCredibility = createAsyncThunk(
  'credibility/updateCredibility',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.put(
      `${apiBaseUrl}/api/States/${initialState.id}`,
      initialState,
      config
    );
    return { ...initialState, id: response.data };
  }
);

const credibilitySlice = createSlice({
  name: 'states',
  initialState,
  extraReducers: {
    [fetchCredibility.pending]: state => {
      state.status = 'loading';
    },
    [fetchCredibility.fulfilled]: (state, action) => {
      // Add any fetched posts to the array
      credibilityAdapter.upsertMany(state, action.payload);
      state.status = 'succeeded';
    },
    [fetchCredibility.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [addNewCredibility.fulfilled]: credibilityAdapter.addOne,
    [deleteCredibility.fulfilled]: credibilityAdapter.removeOne,
    [updateCredibility.fulfilled]: credibilityAdapter.upsertOne,
  },
});

export default credibilitySlice.reducer;

export const {
  selectAll: selectAllCredibility,
  selectById: selectCredibilityById,
  selectIds: selectCredibilityIds,
} = credibilityAdapter.getSelectors(state => state.credibility);
