import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import axios from 'axios';

const orthodonticBaseRateAdapter = createEntityAdapter();
const apiBaseUrl = window.ENV.api_base_url;

const initialState = orthodonticBaseRateAdapter.getInitialState({
  status: 'idle',
  error: null,
});

export const fetchOrthodonticBaseRate = createAsyncThunk(
  'orthodonticBaseRate/fetchOrthodonticBaseRate',
  async (_, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };

    const response = await axios.get(
      `${apiBaseUrl}/api/OrthodonticBaseRate`,
      config
    );
    return response.data;
  }
);

export const addNewOrthodonticBaseRate = createAsyncThunk(
  'orthodonticBaseRate/addNewOrthodonticBaseRate',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.post(
      `${apiBaseUrl}/api/OrthodonticBaseRate`,
      initialState,
      config
    );
    console.log('adding new orthodonticBaseRate record');
    console.log(response);
    return { ...initialState, id: response.data };
  }
);

export const deleteOrthodonticBaseRate = createAsyncThunk(
  'orthodonticBaseRate/deleteOrthodonticBaseRate',
  async (orthodonticBaseRateId, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.delete(
      `${apiBaseUrl}/api/OrthodonticBaseRate/${orthodonticBaseRateId}`,
      config
    );
    return orthodonticBaseRateId;
  }
);

export const updateOrthodonticBaseRate = createAsyncThunk(
  'orthodonticBaseRate/updateOrthodonticBaseRate',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.put(
      `${apiBaseUrl}/api/OrthodonticBaseRate/${initialState.id}`,
      initialState,
      config
    );
    return { ...initialState, id: response.data };
  }
);

const orthodonticBaseRateSlice = createSlice({
  name: 'orthodonticBaseRate',
  initialState,
  extraReducers: {
    [fetchOrthodonticBaseRate.pending]: state => {
      state.status = 'loading';
    },
    [fetchOrthodonticBaseRate.fulfilled]: (state, action) => {
      // Add any fetched posts to the array
      orthodonticBaseRateAdapter.upsertMany(state, action.payload);
      state.status = 'succeeded';
    },
    [fetchOrthodonticBaseRate.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [addNewOrthodonticBaseRate.fulfilled]: orthodonticBaseRateAdapter.addOne,
    [deleteOrthodonticBaseRate.fulfilled]: orthodonticBaseRateAdapter.removeOne,
    [updateOrthodonticBaseRate.fulfilled]: orthodonticBaseRateAdapter.upsertOne,
  },
});

export default orthodonticBaseRateSlice.reducer;

export const {
  selectAll: selectAllOrthodonticBaseRate,
  selectById: selectOrthodonticBaseRateById,
  selectIds: selectOrthodonticBaseRateIds,
} = orthodonticBaseRateAdapter.getSelectors(state => state.orthodonticBaseRate);
