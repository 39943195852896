import React, { useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Button,
  CloseButton,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Stack,
  StackDivider,
  VStack,
} from '@chakra-ui/react';

import { addNewOrthodonticBaseRate } from './orthodonticBaseRateSlice';
import { FieldGroup } from '../../components/common/FieldGroup';
import DatePicker from '../../components/common/DatePicker';

import { ErrorDisplay } from '../common/ErrorDisplay';
import { overlapCheck } from '../../services/overlapCheck';
import {
  dateToString,
  nullableDateToString,
} from '../../services/dateConverters';

export const AddOrthodonticBaseRateForm = ({
  user,
  orthodonticBaseRateList,
}) => {
  const [copay, setCopay] = useState(0);
  const [maximum, setMaximum] = useState(0);
  const [oneYear, setOneYear] = useState(0);
  const [twoYear, setTwoYear] = useState(0);

  const [startDate, setEffStartDate] = useState(new Date());
  const [endDate, setEffEndDate] = useState('');
  const [addRequestStatus, setAddRequestStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();

  const onCopayChanged = e => {
    setCopay(e.target.value);
    setErrorMessage('');
  };
  const onMaximumChanged = e => setMaximum(Number(e.target.value));
  const onOneYearChanged = e => setOneYear(Number(e.target.value));
  const onTwoYearChanged = e => setTwoYear(Number(e.target.value));

  const onEffStartDateChanged = e => {
    setEffStartDate(e);
    setErrorMessage('');
  };
  const onEffEndDateChanged = e => {
    setEffEndDate(e);
    setErrorMessage('');
  };

  const canSave =
    [
      !isNaN(copay),
      !isNaN(maximum),
      !isNaN(oneYear),
      !isNaN(twoYear),

      startDate,
    ].every(Boolean) && addRequestStatus === 'idle';

  const onSaveOrthodonticBaseRateClicked = async () => {
    if (canSave) {
      try {
        setAddRequestStatus('pending');

        var orthodonticBaseRateOverlap = orthodonticBaseRateList.filter(
          overlapCheck,
          {
            effStartDate: startDate,
            effEndDate: endDate,
            keys: [
              {
                name: 'copay',
                value: copay,
              },
              {
                name: 'maximum',
                value: maximum,
              },
            ],
          }
        );
        if (orthodonticBaseRateOverlap.length) {
          console.log(orthodonticBaseRateOverlap);
          setErrorMessage(
            'The record being entered overlaps an existing effective date range for this key value.'
          );
          return false;
        }

        const resultAction = await dispatch(
          addNewOrthodonticBaseRate({
            copay,
            maximum,
            oneYear,
            twoYear,
            modifiedByName: user.profile.name,
            modifiedDate: new Date().toISOString(),
            effStartDate: dateToString(startDate),
            effEndDate: nullableDateToString(endDate),
          })
        );

        unwrapResult(resultAction);

        setCopay(0);
        setMaximum(0);
        setOneYear(0);
        setTwoYear(0);

        setEffStartDate(new Date());
        setEffEndDate('');
      } catch (err) {
        console.error(
          'Failed to save the orthodonticBaseRate factor record: ',
          err
        );
      } finally {
        setAddRequestStatus('idle');
      }
    }
  };

  return (
    <Box
      px={{
        base: '4',
        md: '10',
      }}
      pb="16"
      maxWidth="3xl"
      mx="auto"
    >
      <form>
        <Stack spacing="4" divider={<StackDivider />}>
          <FieldGroup title="Add OrthodonticBaseRate Record">
            <VStack width="full" spacing="6">
              <FormControl id="copay">
                <FormLabel>Copay:</FormLabel>
                <Input type="number" value={copay} onChange={onCopayChanged} />
              </FormControl>
              <FormControl id="maximum">
                <FormLabel>Maximum:</FormLabel>
                <Input
                  type="number"
                  value={maximum}
                  onChange={onMaximumChanged}
                />
              </FormControl>
              <FormControl id="oneYear">
                <FormLabel>OneYear:</FormLabel>
                <Input
                  type="number"
                  value={oneYear}
                  onChange={onOneYearChanged}
                />
              </FormControl>
              <FormControl id="twoYear">
                <FormLabel>TwoYear:</FormLabel>
                <Input
                  type="number"
                  value={twoYear}
                  onChange={onTwoYearChanged}
                />
              </FormControl>

              <FormControl>
                <FormLabel htmlFor="effStartDate">
                  Effective Start Date
                </FormLabel>
                <DatePicker
                  id="effStartDate"
                  selectedDate={startDate}
                  onChange={onEffStartDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective Start Date</FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="effEndDate">Effective End Date</FormLabel>
                <DatePicker
                  id="effEndDate"
                  selectedDate={endDate}
                  onChange={onEffEndDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective End Date</FormHelperText>
              </FormControl>
            </VStack>
          </FieldGroup>

          <Button
            onClick={onSaveOrthodonticBaseRateClicked}
            disabled={!canSave}
            variant="brand-primary"
            size="xs"
          >
            Save OrthodonticBaseRate Factor Record
          </Button>
          <ErrorDisplay
            title="The record dates overlap other records!"
            errorMessage={errorMessage}
          />
        </Stack>
      </form>
    </Box>
  );
};

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
  };
}

export const AddOrthodonticBaseRateFormUser = connect(mapStateToProps)(
  AddOrthodonticBaseRateForm
);
