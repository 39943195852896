import React, { useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Button,
  CloseButton,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Stack,
  StackDivider,
  VStack,
} from '@chakra-ui/react';

import { addNewAdmins } from './administrationSlice';
import { FieldGroup } from '../../components/common/FieldGroup';
import DatePicker from '../../components/common/DatePicker';
import { ErrorDisplay } from '../common/ErrorDisplay';
import { overlapCheck } from '../../services/overlapCheck';
import {
  dateToString,
  nullableDateToString,
} from '../../services/dateConverters';

export const AddAdminsForm = ({ user, adminsList }) => {
  const [low, setLow] = useState(0);
  const [high, setHigh] = useState(0);
  const [newClaimsExpense, setNewClaimsExpense] = useState(0);
  const [claimsExpense, setClaimsExpense] = useState(0);
  const [riskExpense, setRiskExpense] = useState(0);
  const [premiumTax, setPremiumTax] = useState(0);
  const [acatax, setAcatax] = useState(0);

  const [totalRetention, setTotalRetention] = useState(0);
  const [newRetention, setNewRetention] = useState(0);
  const [surplus, setSurplus] = useState(0);
  const [addAdmin, setAddAdmin] = useState(0);
  const [groupFee, setGroupFee] = useState(0);
  const [flat, setFlat] = useState(0);
  const [minimum, setMinimum] = useState(0);
  const [groupSize, setGroupSize] = useState(0);
  const [step, setStep] = useState(0);
  const [employee, setEmployee] = useState(0);
  const [spouse, setSpouse] = useState(0);
  const [child, setChild] = useState(0);

  const [startDate, setEffStartDate] = useState(new Date());
  const [endDate, setEffEndDate] = useState('');
  const [addRequestStatus, setAddRequestStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();

  const onLowChanged = e => {
    setLow(Number(e.target.value));
    setErrorMessage('');
  };

  const onHighChanged = e => setHigh(Number(e.target.value));
  const onNewClaimsExpenseChanged = e =>
    setNewClaimsExpense(Number(e.target.value));
  const onClaimsExpenseChanged = e => setClaimsExpense(Number(e.target.value));
  const onRiskExpenseChanged = e => setRiskExpense(Number(e.target.value));
  const onPremiumTaxChanged = e => setPremiumTax(Number(e.target.value));
  const onAcataxChanged = e => setAcatax(Number(e.target.value));

  const onTotalRetentionChanged = e =>
    setTotalRetention(Number(e.target.value));
  const onNewRetentionChanged = e => setNewRetention(Number(e.target.value));
  const onSurplusChanged = e => setSurplus(Number(e.target.value));
  const onAddAdminChanged = e => setAddAdmin(Number(e.target.value));
  const onGroupFeeChanged = e => setGroupFee(Number(e.target.value));
  const onFlatChanged = e => setFlat(Number(e.target.value));
  const onMinimumChanged = e => setMinimum(Number(e.target.value));
  const onGroupSizeChanged = e => setGroupSize(Number(e.target.value));
  const onStepChanged = e => setStep(Number(e.target.value));
  const onEmployeeChanged = e => setEmployee(Number(e.target.value));
  const onSpouseChanged = e => setSpouse(Number(e.target.value));
  const onChildChanged = e => setChild(Number(e.target.value));

  const onEffStartDateChanged = e => {
    setEffStartDate(e);
    setErrorMessage('');
  };
  const onEffEndDateChanged = e => {
    setEffEndDate(e);
    setErrorMessage('');
  };

  const canSave =
    [
      !isNaN(low),
      !isNaN(high),
      !isNaN(newClaimsExpense),
      !isNaN(claimsExpense),
      !isNaN(riskExpense),
      !isNaN(premiumTax),
      !isNaN(acatax),
      !isNaN(totalRetention),
      !isNaN(newRetention),
      !isNaN(surplus),
      !isNaN(addAdmin),
      !isNaN(groupFee),
      !isNaN(flat),
      !isNaN(minimum),
      !isNaN(groupSize),
      !isNaN(step),
      !isNaN(employee),
      !isNaN(spouse),
      !isNaN(child),

      startDate,
    ].every(Boolean) && addRequestStatus === 'idle';

  const onSaveAdminsClicked = async () => {
    if (canSave) {
      try {
        setAddRequestStatus('pending');

        var adminsOverlap = adminsList.filter(overlapCheck, {
          effStartDate: startDate,
          effEndDate: endDate,
          keys: [
            {
              name: 'low',
              value: low,
            },
            {
              name: 'high',
              value: high,
            },
          ],
        });
        if (adminsOverlap.length) {
          console.log(adminsOverlap);
          setErrorMessage(
            'The record being entered overlaps an existing effective date range for this key value.'
          );
          return false;
        }

        const resultAction = await dispatch(
          addNewAdmins({
            low,
            high,
            newClaimsExpense,
            claimsExpense,
            riskExpense,
            premiumTax,
            acatax,
            totalRetention,
            newRetention,
            surplus,
            addAdmin,
            groupFee,
            flat,
            minimum,
            groupSize,
            step,
            employee,
            spouse,
            child,
            modifiedByName: user.profile.name,
            modifiedDate: new Date().toISOString(),
            effStartDate: dateToString(startDate),
            effEndDate: nullableDateToString(endDate),
          })
        );

        unwrapResult(resultAction);

        setLow(0);
        setHigh(0);
        setNewClaimsExpense(0);
        setClaimsExpense(0);
        setRiskExpense(0);
        setPremiumTax(0);
        setAcatax(0);
        setTotalRetention(0);
        setNewRetention(0);
        setSurplus(0);
        setAddAdmin(0);
        setGroupFee(0);
        setFlat(0);
        setMinimum(0);
        setGroupSize(0);
        setStep(0);
        setEmployee(0);
        setSpouse(0);
        setChild(0);
        setEffStartDate(new Date());
        setEffEndDate('');
      } catch (err) {
        console.error('Failed to save the adminstration factor record: ', err);
      } finally {
        setAddRequestStatus('idle');
      }
    }
  };

  return (
    <Box
      px={{
        base: '4',
        md: '10',
      }}
      pb="16"
      maxWidth="3xl"
      mx="auto"
    >
      <form>
        <Stack spacing="4" divider={<StackDivider />}>
          <FieldGroup title="Add Administration Factor Record">
            <VStack width="full" spacing="6">
              <FormControl id="low">
                <FormLabel>Low:</FormLabel>
                <Input type="number" value={low} onChange={onLowChanged} />
              </FormControl>
              <FormControl id="high">
                <FormLabel>High:</FormLabel>
                <Input type="number" value={high} onChange={onHighChanged} />
              </FormControl>
              <FormControl id="newClaimsExpense">
                <FormLabel>New Claims Expense:</FormLabel>
                <Input
                  type="number"
                  value={newClaimsExpense}
                  onChange={onNewClaimsExpenseChanged}
                />
              </FormControl>
              <FormControl id="claimsExpense">
                <FormLabel>Claims Expense:</FormLabel>
                <Input
                  type="number"
                  value={claimsExpense}
                  onChange={onClaimsExpenseChanged}
                />
              </FormControl>
              <FormControl id="riskExpense">
                <FormLabel>Risk Expense:</FormLabel>
                <Input
                  type="number"
                  value={riskExpense}
                  onChange={onRiskExpenseChanged}
                />
              </FormControl>
              <FormControl id="premiumTax">
                <FormLabel>Premium Tax:</FormLabel>
                <Input
                  type="number"
                  value={premiumTax}
                  onChange={onPremiumTaxChanged}
                />
              </FormControl>
              <FormControl id="acatax">
                <FormLabel>Acatax:</FormLabel>
                <Input
                  type="number"
                  value={acatax}
                  onChange={onAcataxChanged}
                />
              </FormControl>
              <FormControl id="totalRetention">
                <FormLabel>Total Retention:</FormLabel>
                <Input
                  type="number"
                  value={totalRetention}
                  onChange={onTotalRetentionChanged}
                />
              </FormControl>
              <FormControl id="newRetention">
                <FormLabel>New Retention:</FormLabel>
                <Input
                  type="number"
                  value={newRetention}
                  onChange={onNewRetentionChanged}
                />
              </FormControl>
              <FormControl id="surplus">
                <FormLabel>Surplus:</FormLabel>
                <Input
                  type="number"
                  value={surplus}
                  onChange={onSurplusChanged}
                />
              </FormControl>
              <FormControl id="addAdmin">
                <FormLabel>Add Admin:</FormLabel>
                <Input
                  type="number"
                  value={addAdmin}
                  onChange={onAddAdminChanged}
                />
              </FormControl>
              <FormControl id="groupFee">
                <FormLabel>Group Fee:</FormLabel>
                <Input
                  type="number"
                  value={groupFee}
                  onChange={onGroupFeeChanged}
                />
              </FormControl>
              <FormControl id="flat">
                <FormLabel>Flat:</FormLabel>
                <Input type="number" value={flat} onChange={onFlatChanged} />
              </FormControl>
              <FormControl id="minimum">
                <FormLabel>Minimum:</FormLabel>
                <Input
                  type="number"
                  value={minimum}
                  onChange={onMinimumChanged}
                />
              </FormControl>
              <FormControl id="groupSize">
                <FormLabel>Group Size:</FormLabel>
                <Input
                  type="number"
                  value={groupSize}
                  onChange={onGroupSizeChanged}
                />
              </FormControl>
              <FormControl id="step">
                <FormLabel>Step:</FormLabel>
                <Input type="number" value={step} onChange={onStepChanged} />
              </FormControl>
              <FormControl id="employee">
                <FormLabel>Employee:</FormLabel>
                <Input
                  type="number"
                  value={employee}
                  onChange={onEmployeeChanged}
                />
              </FormControl>
              <FormControl id="spouse">
                <FormLabel>Spouse:</FormLabel>
                <Input
                  type="number"
                  value={spouse}
                  onChange={onSpouseChanged}
                />
              </FormControl>
              <FormControl id="child">
                <FormLabel>Child:</FormLabel>
                <Input type="number" value={child} onChange={onChildChanged} />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="effStartDate">
                  Effective Start Date
                </FormLabel>
                <DatePicker
                  id="effStartDate"
                  selectedDate={startDate}
                  onChange={onEffStartDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective Start Date</FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="effEndDate">Effective End Date</FormLabel>
                <DatePicker
                  id="effEndDate"
                  selectedDate={endDate}
                  onChange={onEffEndDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective End Date</FormHelperText>
              </FormControl>
            </VStack>
          </FieldGroup>

          <Button
            onClick={onSaveAdminsClicked}
            disabled={!canSave}
            variant="brand-primary"
            size="xs"
          >
            Save Administration Factor Record
          </Button>
          <ErrorDisplay
            title="The record dates overlap other records!"
            errorMessage={errorMessage}
          />
        </Stack>
      </form>
    </Box>
  );
};

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
  };
}

export const AddAdminsFormUser = connect(mapStateToProps)(AddAdminsForm);
