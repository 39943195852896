import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
  AccordionIcon,
  Heading,
} from '@chakra-ui/react';
import {
  fetchDeductibleCredit,
  selectDeductibleCreditIds,
  selectAllDeductibleCredit,
} from './deductibleCreditSlice';
import { AddDeductibleCreditFormUser } from './AddDeductibleCreditForm';
import { DeductibleCreditList } from './DeductibleCreditList';
import BrandLinkButton from '../../components/common/BrandLinkButton';

export const DeductibleCreditPage = () => {
  const dispatch = useDispatch();

  const orderedDeductibleCreditIds = useSelector(selectDeductibleCreditIds);
  const deductibleCreditList = useSelector(selectAllDeductibleCredit);

  const error = useSelector(state => state.deductibleCredit.error);
  if (error) {
    console.log('Deductible Credit List Error:' + error.toString());
  }

  const deductibleCreditStatus = useSelector(
    state => state.deductibleCredit.status
  );
  const [index, setIndex] = React.useState([]);

  useEffect(() => {
    if (deductibleCreditStatus === 'idle') {
      dispatch(fetchDeductibleCredit());
    }
  }, [deductibleCreditStatus, dispatch]);

  function closeAccordion() {
    setIndex([]);
  }

  let content;

  if (deductibleCreditStatus === 'loading') {
    content = <div className="loader">Loading...</div>;
  } else if (deductibleCreditStatus === 'succeeded') {
    console.log(orderedDeductibleCreditIds);
    content = (
      <div>
        <Heading size="xl" mb={8}>
          Deductible Credit Factors
        </Heading>
        <Accordion
          allowMultiple
          allowToggle="true"
          mb={8}
          index={index}
          onChange={setIndex}
        >
          <AccordionItem>
            <Box>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Text variant="brand-green-link" fontSize="lg">
                    Add New Factor
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </Box>
            <AccordionPanel pb={4}>
              <AddDeductibleCreditFormUser
                deductibleCreditList={deductibleCreditList}
                closeAccordion={closeAccordion}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <DeductibleCreditList
          orderedDeductibleCreditIds={orderedDeductibleCreditIds}
        />
      </div>
    );
  } else if (deductibleCreditStatus === 'failed') {
    content = <div>{error}</div>;
  }

  return (
    <>
      {content}
      <Box mt={12}>
        <BrandLinkButton path="/settings">Back to Settings</BrandLinkButton>
      </Box>
    </>
  );
};

export default DeductibleCreditPage;
