import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Select,
  Stack,
  StackDivider,
  VStack,
} from '@chakra-ui/react';

import {
  fetchSpecialMix,
  updateSpecialMix,
  selectSpecialMixById,
  selectAllSpecialMix,
} from './specialMixSlice';
import { FieldGroup } from '../../components/common/FieldGroup';
import DatePicker from '../../components/common/DatePicker';
import { ErrorDisplay } from '../common/ErrorDisplay';
import { overlapCheck } from '../../services/overlapCheck';
import {
  dateToString,
  nullableDateToString,
  stringToDateFormat,
} from '../../services/dateConverters';

export const EditSpecialMixForm = ({ match, user }) => {
  const { specialMixId } = match.params;
  const specialMix = useSelector(state =>
    selectSpecialMixById(state, specialMixId)
  );
  const specialMixList = useSelector(selectAllSpecialMix);

  const stdt = stringToDateFormat(specialMix.effStartDate);
  console.log(stdt);
  const endt = stringToDateFormat(specialMix.effEndDate);
  console.log(endt);

  const [description, setDescription] = useState(specialMix.description);
  const [employee, setEmployee] = useState(specialMix.employee);
  const [spouse, setSpouse] = useState(specialMix.spouse);
  const [child, setChild] = useState(specialMix.child);
  const [oneParty, setOneParty] = useState(specialMix.oneParty);
  const [employeeSpouse, setEmployeeSpouse] = useState(
    specialMix.employeeSpouse
  );
  const [employeeChild, setEmployeeChild] = useState(specialMix.employeeChild);
  const [family, setFamily] = useState(specialMix.family);

  const [twoParty, setTwoParty] = useState(specialMix.twoParty);

  const [threeParty, setThreeParty] = useState(specialMix.threeParty);

  const [startDate, setEffStartDate] = useState(stdt);

  const [endDate, setEffEndDate] = useState(endt);
  const [updateRequestStatus, setUpdateRequestStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();
  const history = useHistory();

  const onDescriptionChanged = e => {
    setDescription(e.target.value);
    setErrorMessage('');
  };
  const onEmployeeChanged = e => setEmployee(Number(e.target.value));
  const onSpouseChanged = e => setSpouse(Number(e.target.value));
  const onChildChanged = e => setChild(Number(e.target.value));
  const onOnePartyChanged = e => setOneParty(Number(e.target.value));

  const onEmployeeSpouseChanged = e =>
    setEmployeeSpouse(Number(e.target.value));
  const onEmployeeChildChanged = e => setEmployeeChild(Number(e.target.value));
  const onFamilyChanged = e => setFamily(Number(e.target.value));
  const onTwoPartyChanged = e => setTwoParty(Number(e.target.value));
  const onThreePartyChanged = e => setThreeParty(Number(e.target.value));

  const onEffStartDateChanged = e => {
    setEffStartDate(e);
    setErrorMessage('');
  };
  const onEffEndDateChanged = e => {
    setEffEndDate(e);
    setErrorMessage('');
  };

  const canSave =
    [
      description,
      !isNaN(employee),
      !isNaN(spouse),
      !isNaN(child),
      !isNaN(oneParty),
      !isNaN(employeeSpouse),
      !isNaN(employeeChild),
      !isNaN(family),
      !isNaN(twoParty),
      !isNaN(threeParty),
      startDate,
    ].every(Boolean) && updateRequestStatus === 'idle';

  const onSaveSpecialMixClicked = async () => {
    if (canSave) {
      try {
        setUpdateRequestStatus('pending');

        var specialMixOverlap = specialMixList.filter(overlapCheck, {
          id: specialMix.id,
          effStartDate: startDate,
          effEndDate: endDate,
          keys: [
            {
              name: 'description',
              value: description,
            },
          ],
        });
        if (specialMixOverlap.length) {
          console.log(specialMixOverlap);
          setErrorMessage(
            'The record being entered overlaps an existing effective date range for this key value.'
          );
          return false;
        }

        const resultAction = await dispatch(
          updateSpecialMix({
            id: Number(specialMixId),
            description,
            employee,
            spouse,
            child,
            oneParty,
            employeeSpouse,
            employeeChild,

            family,
            twoParty,
            threeParty,
            modifiedByName: user.profile.name,
            modifiedDate: new Date().toISOString(),
            effStartDate: dateToString(startDate),
            effEndDate: nullableDateToString(endDate),
          })
        );
        console.log(resultAction);
        setUpdateRequestStatus('idle');
        history.push(`/specialMix`);
      } catch (err) {
        setUpdateRequestStatus('idle');
        console.error('Failed to save the specialMix factor: ', err);
      }
    }
  };
  return (
    <Box
      px={{
        base: '4',
        md: '10',
      }}
      pb="16"
      maxWidth="3xl"
      mx="auto"
    >
      <form>
        <Stack spacing="4" divider={<StackDivider />}>
          <FieldGroup title="Edit Special Mix Factors">
            <VStack width="full" spacing="6">
              <FormControl id="description">
                <FormLabel>Description:</FormLabel>
                <Input
                  type="text"
                  value={description}
                  onChange={onDescriptionChanged}
                />
              </FormControl>
              <FormControl id="employee">
                <FormLabel>Employee:</FormLabel>
                <Input
                  type="number"
                  value={employee}
                  onChange={onEmployeeChanged}
                />
              </FormControl>
              <FormControl id="spouse">
                <FormLabel>Spouse:</FormLabel>
                <Input
                  type="number"
                  value={spouse}
                  onChange={onSpouseChanged}
                />
              </FormControl>
              <FormControl id="child">
                <FormLabel>Child:</FormLabel>
                <Input type="number" value={child} onChange={onChildChanged} />
              </FormControl>

              <FormControl id="oneParty">
                <FormLabel>One Party:</FormLabel>
                <Input
                  type="number"
                  value={oneParty}
                  onChange={onOnePartyChanged}
                />
              </FormControl>

              <FormControl id="employeeSpouse">
                <FormLabel>Employee Spouse:</FormLabel>
                <Input
                  type="number"
                  value={employeeSpouse}
                  onChange={onEmployeeSpouseChanged}
                />
              </FormControl>
              <FormControl id="employeeChild">
                <FormLabel>Employee Child:</FormLabel>
                <Input
                  type="number"
                  value={employeeChild}
                  onChange={onEmployeeChildChanged}
                />
              </FormControl>
              <FormControl id="family">
                <FormLabel>Family:</FormLabel>
                <Input
                  type="number"
                  value={family}
                  onChange={onFamilyChanged}
                />
              </FormControl>
              <FormControl id="twoParty">
                <FormLabel>Two Party:</FormLabel>
                <Input
                  type="number"
                  value={twoParty}
                  onChange={onTwoPartyChanged}
                />
              </FormControl>

              <FormControl id="threeParty">
                <FormLabel>Three Party:</FormLabel>
                <Input
                  type="number"
                  value={threeParty}
                  onChange={onThreePartyChanged}
                />
              </FormControl>

              <FormControl id="effStartDate">
                <FormLabel>Effective Start Date:</FormLabel>
                <DatePicker
                  id="effStartDate"
                  selectedDate={startDate}
                  onChange={onEffStartDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective Start Date</FormHelperText>
              </FormControl>
              <FormControl id="effEndDate">
                <FormLabel>Effective End Date:</FormLabel>
                <DatePicker
                  id="effEndDate"
                  selectedDate={endDate}
                  onChange={onEffEndDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective End Date</FormHelperText>
              </FormControl>
            </VStack>
          </FieldGroup>

          <Button
            onClick={onSaveSpecialMixClicked}
            disabled={!canSave}
            variant="brand-primary"
            size="xs"
          >
            Save SpecialMix Factor
          </Button>
          <ErrorDisplay
            title="The record dates overlap other records!"
            errorMessage={errorMessage}
          />
        </Stack>
      </form>
    </Box>
  );
};

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
  };
}

export const EditSpecialMixFormUser =
  connect(mapStateToProps)(EditSpecialMixForm);
