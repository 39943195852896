import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Select,
  Stack,
  StackDivider,
  VStack,
} from '@chakra-ui/react';

import {
  fetchWaitingPeriod,
  updateWaitingPeriod,
  selectWaitingPeriodById,
  selectAllWaitingPeriod,
} from './waitingPeriodSlice';
import { FieldGroup } from '../../components/common/FieldGroup';
import DatePicker from '../../components/common/DatePicker';
import { ErrorDisplay } from '../common/ErrorDisplay';
import { overlapCheck } from '../../services/overlapCheck';
import {
  dateToString,
  nullableDateToString,
  stringToDateFormat,
} from '../../services/dateConverters';

export const EditWaitingPeriodForm = ({ match, user }) => {
  const { waitingPeriodId } = match.params;
  const waitingPeriod = useSelector(state =>
    selectWaitingPeriodById(state, waitingPeriodId)
  );
  const waitingPeriodList = useSelector(selectAllWaitingPeriod);

  const stdt = stringToDateFormat(waitingPeriod.effStartDate);
  console.log(stdt);
  const endt = stringToDateFormat(waitingPeriod.effEndDate);
  console.log(endt);

  const [waitingPeriodDesc, setWaitingPeriodDesc] = useState(
    waitingPeriod.waitingPeriodDesc
  );
  const [prev, setPrev] = useState(waitingPeriod.prev);
  const [xray, setXray] = useState(waitingPeriod.xray);
  const [mres, setMres] = useState(waitingPeriod.mres);
  const [oth2, setOth2] = useState(waitingPeriod.oth2);
  const [pros, setPros] = useState(waitingPeriod.pros);
  const [ortho, setOrtho] = useState(waitingPeriod.ortho);

  const [startDate, setEffStartDate] = useState(stdt);

  const [endDate, setEffEndDate] = useState(endt);
  const [updateRequestStatus, setUpdateRequestStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();
  const history = useHistory();

  const onWaitingPeriodDescChanged = e => {
    setWaitingPeriodDesc(Text(e.target.value));
    setErrorMessage('');
  };

  const onPrevChanged = e => setPrev(Number(e.target.value));
  const onXrayChanged = e => setXray(Number(e.target.value));
  const onMresChanged = e => setMres(Number(e.target.value));
  const onOth2Changed = e => setOth2(Number(e.target.value));
  const onProsChanged = e => setPros(Number(e.target.value));
  const onOrthoChanged = e => setOrtho(Number(e.target.value));

  const onEffStartDateChanged = e => {
    setEffStartDate(e);
    setErrorMessage('');
  };
  const onEffEndDateChanged = e => {
    setEffEndDate(e);
    setErrorMessage('');
  };

  const canSave =
    [
      waitingPeriodDesc,
      !isNaN(prev),
      !isNaN(xray),
      !isNaN(mres),
      !isNaN(oth2),
      !isNaN(pros),
      !isNaN(ortho),
      startDate,
    ].every(Boolean) && updateRequestStatus === 'idle';

  const onSaveWaitingPeriodClicked = async () => {
    if (canSave) {
      try {
        setUpdateRequestStatus('pending');

        var waitingPeriodOverlap = waitingPeriodList.filter(overlapCheck, {
          id: waitingPeriod.id,
          effStartDate: startDate,
          effEndDate: endDate,
          keys: [
            {
              name: 'waitingPeriodDesc',
              value: waitingPeriodDesc,
            },
          ],
        });
        if (waitingPeriodOverlap.length) {
          console.log(waitingPeriodOverlap);
          setErrorMessage(
            'The record being entered overlaps an existing effective date range for this key value.'
          );
          return false;
        }

        const resultAction = await dispatch(
          updateWaitingPeriod({
            id: Number(waitingPeriodId),
            waitingPeriodDesc,
            prev,
            xray,
            mres,
            oth2,
            pros,
            ortho,
            modifiedByName: user.profile.name,
            modifiedDate: new Date().toISOString(),
            effStartDate: dateToString(startDate),
            effEndDate: nullableDateToString(endDate),
          })
        );
        console.log(resultAction);
        setUpdateRequestStatus('idle');
        history.push(`/waitingPeriod`);
      } catch (err) {
        setUpdateRequestStatus('idle');
        console.error('Failed to save the administration factor: ', err);
      }
    }
  };
  return (
    <Box
      px={{
        base: '4',
        md: '10',
      }}
      pb="16"
      maxWidth="3xl"
      mx="auto"
    >
      <form>
        <Stack spacing="4" divider={<StackDivider />}>
          <FieldGroup title="Edit Administration Factors">
            <VStack width="full" spacing="6">
              <FormControl id="waitingPeriodDesc">
                <FormLabel>Waiting Period Desc:</FormLabel>
                <Input
                  type="text"
                  value={waitingPeriodDesc}
                  onChange={onWaitingPeriodDescChanged}
                />
              </FormControl>
              <FormControl id="prev">
                <FormLabel>Prev:</FormLabel>
                <Input type="number" value={prev} onChange={onPrevChanged} />
              </FormControl>
              <FormControl id="xray">
                <FormLabel>Xray:</FormLabel>
                <Input type="number" value={xray} onChange={onXrayChanged} />
              </FormControl>
              <FormControl id="mres">
                <FormLabel>Mres:</FormLabel>
                <Input type="number" value={mres} onChange={onMresChanged} />
              </FormControl>
              <FormControl id="oth2">
                <FormLabel>Oth2:</FormLabel>
                <Input type="number" value={oth2} onChange={onOth2Changed} />
              </FormControl>
              <FormControl id="pros">
                <FormLabel>Pros:</FormLabel>
                <Input type="number" value={pros} onChange={onProsChanged} />
              </FormControl>
              <FormControl id="ortho">
                <FormLabel>Ortho:</FormLabel>
                <Input type="number" value={ortho} onChange={onOrthoChanged} />
              </FormControl>

              <FormControl id="effStartDate">
                <FormLabel>Effective Start Date:</FormLabel>
                <DatePicker
                  id="effStartDate"
                  selectedDate={startDate}
                  onChange={onEffStartDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective Start Date</FormHelperText>
              </FormControl>
              <FormControl id="effEndDate">
                <FormLabel>Effective End Date:</FormLabel>
                <DatePicker
                  id="effEndDate"
                  selectedDate={endDate}
                  onChange={onEffEndDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective End Date</FormHelperText>
              </FormControl>
            </VStack>
          </FieldGroup>

          <Button
            onClick={onSaveWaitingPeriodClicked}
            disabled={!canSave}
            variant="brand-primary"
            size="xs"
          >
            Save WaitingPeriod Factor
          </Button>
          <ErrorDisplay
            title="The record dates overlap other records!"
            errorMessage={errorMessage}
          />
        </Stack>
      </form>
    </Box>
  );
};

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
  };
}

export const EditWaitingPeriodFormUser = connect(mapStateToProps)(
  EditWaitingPeriodForm
);
