import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import axios from 'axios';

const deductibleCreditAdapter = createEntityAdapter();
const apiBaseUrl = window.ENV.api_base_url;

const initialState = deductibleCreditAdapter.getInitialState({
  status: 'idle',
  error: null,
});

export const fetchDeductibleCredit = createAsyncThunk(
  'deductibleCredit/fetchDeductibleCredit',
  async (_, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };

    const response = await axios.get(
      `${apiBaseUrl}/api/DeductibleCredit`,
      config
    );
    return response.data;
  }
);

export const addNewDeductibleCredit = createAsyncThunk(
  'deductibleCredit/addNewDeductibleCredit',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.post(
      `${apiBaseUrl}/api/DeductibleCredit`,
      initialState,
      config
    );
    console.log('adding new deductibleCredit record');
    console.log(response);
    return { ...initialState, id: response.data };
  }
);

export const deleteDeductibleCredit = createAsyncThunk(
  'deductibleCredit/deleteDeductibleCredit',
  async (deductibleCreditId, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.delete(
      `${apiBaseUrl}/api/DeductibleCredit/${deductibleCreditId}`,
      config
    );
    return deductibleCreditId;
  }
);

export const updateDeductibleCredit = createAsyncThunk(
  'deductibleCredit/updateDeductibleCredit',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.put(
      `${apiBaseUrl}/api/DeductibleCredit/${initialState.id}`,
      initialState,
      config
    );
    return { ...initialState, id: response.data };
  }
);

const deductibleCreditSlice = createSlice({
  name: 'deductibleCredit',
  initialState,
  extraReducers: {
    [fetchDeductibleCredit.pending]: state => {
      state.status = 'loading';
    },
    [fetchDeductibleCredit.fulfilled]: (state, action) => {
      // Add any fetched posts to the array
      deductibleCreditAdapter.upsertMany(state, action.payload);
      state.status = 'succeeded';
    },
    [fetchDeductibleCredit.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [addNewDeductibleCredit.fulfilled]: deductibleCreditAdapter.addOne,
    [deleteDeductibleCredit.fulfilled]: deductibleCreditAdapter.removeOne,
    [updateDeductibleCredit.fulfilled]: deductibleCreditAdapter.upsertOne,
  },
});

export default deductibleCreditSlice.reducer;

export const {
  selectAll: selectAllDeductibleCredit,
  selectById: selectDeductibleCreditById,
  selectIds: selectDeductibleCreditIds,
} = deductibleCreditAdapter.getSelectors(state => state.deductibleCredit);
