import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import axios from 'axios';

const groupAdapter = createEntityAdapter();
const apiBaseUrl = window.ENV.api_base_url;

const initialState = groupAdapter.getInitialState({
  status: 'idle',
  error: null,
});

export const fetchGroups = createAsyncThunk(
  'ratingGroups/fetchGroups',
  async (_, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };

    const response = await axios.get(`${apiBaseUrl}/api/RatingGroup`, config);
    return response.data;
  }
);

export const addNewGroup = createAsyncThunk(
  'ratingGroups/addNewGroup',
  async (initialGroup, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    const response = await axios.post(
      `${apiBaseUrl}/api/RatingGroup`,
      initialGroup,
      config
    );
    return { ...initialGroup, id: response.data };
  }
);

export const deleteGroup = createAsyncThunk(
  'ratingGroups/deleteGroup',
  async (initialGroup, { getState }) => {
      const user = getState().oidc.user;      
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    const response = await axios.delete(
      `${apiBaseUrl}/api/RatingGroup/${initialGroup.id}`,
      config
    );
    return { ...initialGroup, id: response.data };
  }
);

const ratingGroupSlice = createSlice({
  name: 'ratingGroups',
  initialState,
  extraReducers: {
    [fetchGroups.pending]: state => {
      state.status = 'loading';
    },
    [fetchGroups.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      groupAdapter.upsertMany(state, action.payload);
    },
    [fetchGroups.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [addNewGroup.fulfilled]: groupAdapter.addOne,
    [deleteGroup.fulfilled]: groupAdapter.removeOne,
  },
});

export default ratingGroupSlice.reducer;

export const {
  selectAll: selectAllGroups,
  selectById: selectGroupById,
  selectIds: selectGroupIds,
} = groupAdapter.getSelectors(state => state.ratingGroups);
