import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
  AccordionIcon,
  Heading,
} from '@chakra-ui/react';
import {
  fetchCredibility,
  selectCredibilityIds,
  selectAllCredibility,
} from './credibilitySlice';
import { AddCredibilityFormUser } from './AddCredibilityForm';
import { CredibilityList } from './CredibilityList';
import BrandLinkButton from '../../components/common/BrandLinkButton';

export const CredibilityPage = () => {
  const dispatch = useDispatch();

  const orderedCredibilityIds = useSelector(selectCredibilityIds);
  const credibilityList = useSelector(selectAllCredibility);

  const error = useSelector(state => state.credibility.error);
  if (error) {
    console.log('Credibility List Error:' + error.toString());
  }

  const credibilityStatus = useSelector(state => state.credibility.status);
  const [index, setIndex] = React.useState([]);

  useEffect(() => {
    if (credibilityStatus === 'idle') {
      dispatch(fetchCredibility());
    }
  }, [credibilityStatus, dispatch]);

  function closeAccordion() {
    setIndex([]);
  }

  let content;

  if (credibilityStatus === 'loading') {
    content = <div className="loader">Loading...</div>;
  } else if (credibilityStatus === 'succeeded') {
    console.log(orderedCredibilityIds);
    content = (
      <div>
        <Heading size="xl" mb={8}>
          Credibility Factors
        </Heading>
        <Accordion
          allowMultiple
          allowToggle="true"
          mb={8}
          index={index}
          onChange={setIndex}
        >
          <AccordionItem>
            <Box>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Text variant="brand-green-link" fontSize="lg">
                    Add New Factor
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </Box>
            <AccordionPanel pb={4}>
              <AddCredibilityFormUser
                credibilityList={credibilityList}
                closeAccordion={closeAccordion}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <CredibilityList orderedCredibilityIds={orderedCredibilityIds} />
      </div>
    );
  } else if (credibilityStatus === 'failed') {
    content = <div>{error}</div>;
  }

  return (
    <>
      {content}
      <Box mt={12}>
        <BrandLinkButton path="/settings">Back to Settings</BrandLinkButton>
      </Box>
    </>
  );
};

export default CredibilityPage;
