import React, { useEffect } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, Button, ButtonGroup } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchCategories,
  selectCategoryIds,
  selectCategoryById,
  deleteCategory,
} from './ratingCategorySlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { NavLink } from 'react-router-dom';

let CategoryTable = ({ categoryIdList }) => {
  let tableRows = categoryIdList.map(categoryId => (
    <Category categoryId={categoryId} key={categoryId} />
  ));

  return (
   <Table>
     <Thead>
       <Tr>
         <Th>Name</Th>
         <Th>UCM</Th>
         <Th>Target Code</Th>
         <Th></Th>
       </Tr>
     </Thead>
     <Tbody>
       {tableRows}
     </Tbody>
   </Table>
   )
}

let Category = ({ categoryId }) => {
  const category = useSelector(state => selectCategoryById(state, categoryId));
  const dispatch = useDispatch();

  const onDeleteCategoryClicked = async () => {
    try {
      const resultAction = await dispatch(deleteCategory(category.id));
      unwrapResult(resultAction);
    } catch (err) {
      console.error('Failed to save the category: ', err);
    }
  }

  return (
    <Tr>
      <Td>{category.categoryName}</Td>
      <Td>{category.ucmCategory}</Td>
      <Td>{category.targetProcedureCode}</Td>
      <Td>
        <ButtonGroup variant="outline">
          <Button colorScheme="gray" size="sm" as={NavLink} to={`/editCategory/${category.id}`}>Edit</Button>
          <Button colorScheme="red" size="sm" onClick={onDeleteCategoryClicked}>Delete</Button>
        </ButtonGroup>
      </Td>
    </Tr>
  )
}

export const CategoryList = () => {
  const dispatch = useDispatch();
  const orderedCategoryIds = useSelector(selectCategoryIds);
  const error = useSelector(state => state.categories.error)

  const categoryStatus = useSelector(state => state.categories.status)
  console.log(categoryStatus);
  useEffect(() => {
    if (categoryStatus === 'idle') {
      dispatch(fetchCategories())
    }
  }, [categoryStatus, dispatch])

  let content

  if (categoryStatus === 'loading') {
    content = <div className="loader">Loading...</div>
  } else if (categoryStatus === 'succeeded') {
    content = <CategoryTable categoryIdList={orderedCategoryIds} />
  } else if (categoryStatus === 'failed') {
    content = <div>{error}</div>
  }

  return (
    <section>
      <h2>Categories</h2>
      {content}
    </section>
  )
}
