import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Select,
  Stack,
  StackDivider,
  VStack,
} from '@chakra-ui/react';

import {
  fetchOccupation,
  updateOccupation,
  selectOccupationById,
  selectAllOccupation,
} from './occupationSlice';
import { FieldGroup } from '../../components/common/FieldGroup';
import DatePicker from '../../components/common/DatePicker';
import { ErrorDisplay } from '../common/ErrorDisplay';
import { overlapCheck } from '../../services/overlapCheck';
import {
  dateToString,
  nullableDateToString,
  stringToDateFormat,
} from '../../services/dateConverters';

export const EditOccupationForm = ({ match, user }) => {
  const { occupationId } = match.params;
  const occupation = useSelector(state =>
    selectOccupationById(state, occupationId)
  );
  const occupationList = useSelector(selectAllOccupation);

  const stdt = stringToDateFormat(occupation.effStartDate);
  console.log(stdt);
  const endt = stringToDateFormat(occupation.effEndDate);
  console.log(endt);

  const [occupationDesc, setOccupationDesc] = useState(
    occupation.occupationDesc
  );
  const [factor, setFactor] = useState(occupation.factor);
  const [age0To24, setAge0To24] = useState(occupation.age0To24);
  const [age25To34, setAge25To34] = useState(occupation.age25To34);
  const [age35To44, setAge35To44] = useState(occupation.age35To44);
  const [age45To54, setAge45To54] = useState(occupation.age45To54);
  const [age55To64, setAge55To64] = useState(occupation.age55To64);

  const [age65Plus, setAge65Plus] = useState(occupation.age65Plus);
  const [age0to5, setAge0to5] = useState(occupation.age0to5);
  const [f11, setF11] = useState(occupation.f11);
  const [f12, setF12] = useState(occupation.f12);
  const [age19Plus, setAge19Plus] = useState(occupation.age19Plus);
  const [employee, setEmployee] = useState(occupation.employee);
  const [spouse, setSpouse] = useState(occupation.spouse);
  const [child, setChild] = useState(occupation.child);

  const [employeePercent, setEmployeePercent] = useState(
    occupation.employeePercent
  );
  const [spousePercent, setSpousePercent] = useState(occupation.spousePercent);
  const [childPercent, setChildPercent] = useState(occupation.childPercent);

  const [startDate, setEffStartDate] = useState(stdt);

  const [endDate, setEffEndDate] = useState(endt);
  const [updateRequestStatus, setUpdateRequestStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();
  const history = useHistory();

  const onOccupationDescChanged = e => {
    setOccupationDesc(e.target.value);
    setErrorMessage('');
  };

  const onFactorChanged = e => setFactor(Number(e.target.value));
  const onAge0To24Changed = e => setAge0To24(Number(e.target.value));
  const onAge25To34Changed = e => setAge25To34(Number(e.target.value));
  const onAge35To44Changed = e => setAge35To44(Number(e.target.value));
  const onAge45To54Changed = e => setAge45To54(Number(e.target.value));
  const onAge55To64Changed = e => setAge55To64(Number(e.target.value));

  const onAge65PlusChanged = e => setAge65Plus(Number(e.target.value));
  const onAge0to5Changed = e => setAge0to5(Number(e.target.value));
  const onF11Changed = e => setF11(Number(e.target.value));
  const onF12Changed = e => setF12(Number(e.target.value));
  const onAge19PlusChanged = e => setAge19Plus(Number(e.target.value));
  const onEmployeeChanged = e => setEmployee(Number(e.target.value));
  const onSpouseChanged = e => setSpouse(Number(e.target.value));

  const onChildChanged = e => setChild(Number(e.target.value));
  const onEmployeePercentChanged = e =>
    setEmployeePercent(Number(e.target.value));
  const onSpousePercentChanged = e => setSpousePercent(Number(e.target.value));
  const onChildPercentChanged = e => setChildPercent(Number(e.target.value));

  const onEffStartDateChanged = e => {
    setEffStartDate(e);
    setErrorMessage('');
  };
  const onEffEndDateChanged = e => {
    setEffEndDate(e);
    setErrorMessage('');
  };

  const canSave =
    [
      occupationDesc,
      !isNaN(factor),
      !isNaN(age0To24),
      !isNaN(age25To34),
      !isNaN(age35To44),
      !isNaN(age45To54),
      !isNaN(age55To64),
      !isNaN(age65Plus),
      !isNaN(age0to5),
      !isNaN(f11),
      !isNaN(f12),
      !isNaN(age19Plus),
      !isNaN(employee),
      !isNaN(spouse),
      !isNaN(child),
      !isNaN(employeePercent),
      !isNaN(spousePercent),
      !isNaN(childPercent),
      startDate,
    ].every(Boolean) && updateRequestStatus === 'idle';

  const onSaveOccupationClicked = async () => {
    if (canSave) {
      try {
        setUpdateRequestStatus('pending');

        var occupationOverlap = occupationList.filter(overlapCheck, {
          id: occupation.id,
          effStartDate: startDate,
          effEndDate: endDate,
          keys: [
            {
              name: 'occupationDesc',
              value: occupationDesc,
            },
          ],
        });
        if (occupationOverlap.length) {
          console.log(occupationOverlap);
          setErrorMessage(
            'The record being entered overlaps an existing effective date range for this key value.'
          );
          return false;
        }

        const resultAction = await dispatch(
          updateOccupation({
            id: Number(occupationId),
            occupationDesc,
            factor,
            age0To24,
            age25To34,
            age35To44,
            age45To54,
            age55To64,
            age65Plus,
            age0to5,
            f11,
            f12,
            age19Plus,
            employee,
            spouse,
            child,
            employeePercent,
            spousePercent,
            childPercent,
            modifiedByName: user.profile.name,
            modifiedDate: new Date().toISOString(),
            effStartDate: dateToString(startDate),
            effEndDate: nullableDateToString(endDate),
          })
        );
        console.log(resultAction);
        setUpdateRequestStatus('idle');
        history.push(`/occupation`);
      } catch (err) {
        setUpdateRequestStatus('idle');
        console.error('Failed to save the occupation factor: ', err);
      }
    }
  };
  return (
    <Box
      px={{
        base: '4',
        md: '10',
      }}
      pb="16"
      maxWidth="3xl"
      mx="auto"
    >
      <form>
        <Stack spacing="4" divider={<StackDivider />}>
          <FieldGroup title="Edit Occupation Factors">
            <VStack width="full" spacing="6">
              <FormControl id="occupationDesc">
                <FormLabel>OccupationDesc:</FormLabel>
                <Input
                  type="text"
                  value={occupationDesc}
                  onChange={onOccupationDescChanged}
                />
              </FormControl>
              <FormControl id="factor">
                <FormLabel>Factor:</FormLabel>
                <Input
                  type="number"
                  value={factor}
                  onChange={onFactorChanged}
                />
              </FormControl>
              <FormControl id="age0To24">
                <FormLabel>Age0To24:</FormLabel>
                <Input
                  type="number"
                  value={age0To24}
                  onChange={onAge0To24Changed}
                />
              </FormControl>
              <FormControl id="age25To34">
                <FormLabel>Age25To34:</FormLabel>
                <Input
                  type="number"
                  value={age25To34}
                  onChange={onAge25To34Changed}
                />
              </FormControl>

              <FormControl id="age35To44">
                <FormLabel>Age35To44:</FormLabel>
                <Input
                  type="number"
                  value={age35To44}
                  onChange={onAge35To44Changed}
                />
              </FormControl>
              <FormControl id="age45To54">
                <FormLabel>Age45To54:</FormLabel>
                <Input
                  type="text"
                  value={age45To54}
                  onChange={onAge45To54Changed}
                />
              </FormControl>
              <FormControl id="age55To64">
                <FormLabel>Age55To64:</FormLabel>
                <Input
                  type="number"
                  value={age55To64}
                  onChange={onAge55To64Changed}
                />
              </FormControl>

              <FormControl id="age65Plus">
                <FormLabel>Age65Plus:</FormLabel>
                <Input
                  type="number"
                  value={age65Plus}
                  onChange={onAge65PlusChanged}
                />
              </FormControl>
              <FormControl id="age0to5">
                <FormLabel>Age0to5:</FormLabel>
                <Input
                  type="number"
                  value={age0to5}
                  onChange={onAge0to5Changed}
                />
              </FormControl>
              <FormControl id="f11">
                <FormLabel>F11:</FormLabel>
                <Input type="number" value={f11} onChange={onF11Changed} />
              </FormControl>
              <FormControl id="f12">
                <FormLabel>f12:</FormLabel>
                <Input type="number" value={f12} onChange={onF12Changed} />
              </FormControl>

              <FormControl id="age19Plus">
                <FormLabel>Age19Plus:</FormLabel>
                <Input
                  type="number"
                  value={age19Plus}
                  onChange={onAge19PlusChanged}
                />
              </FormControl>
              <FormControl id="employee">
                <FormLabel>Employee:</FormLabel>
                <Input
                  type="text"
                  value={employee}
                  onChange={onEmployeeChanged}
                />
              </FormControl>
              <FormControl id="Spouse">
                <FormLabel>Spouse:</FormLabel>
                <Input
                  type="number"
                  value={spouse}
                  onChange={onSpouseChanged}
                />
              </FormControl>

              <FormControl id="child">
                <FormLabel>Child:</FormLabel>
                <Input type="number" value={child} onChange={onChildChanged} />
              </FormControl>
              <FormControl id="employeePercent">
                <FormLabel>EmployeePercent:</FormLabel>
                <Input
                  type="number"
                  value={employeePercent}
                  onChange={onEmployeePercentChanged}
                />
              </FormControl>
              <FormControl id="spousePercent">
                <FormLabel>SpousePercent:</FormLabel>
                <Input
                  type="number"
                  value={spousePercent}
                  onChange={onSpousePercentChanged}
                />
              </FormControl>
              <FormControl id="childPercent">
                <FormLabel>ChildPercent:</FormLabel>
                <Input
                  type="number"
                  value={childPercent}
                  onChange={onChildPercentChanged}
                />
              </FormControl>

              <FormControl id="effStartDate">
                <FormLabel>Effective Start Date:</FormLabel>
                <DatePicker
                  id="effStartDate"
                  selectedDate={startDate}
                  onChange={onEffStartDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective Start Date</FormHelperText>
              </FormControl>
              <FormControl id="effEndDate">
                <FormLabel>Effective End Date:</FormLabel>
                <DatePicker
                  id="effEndDate"
                  selectedDate={endDate}
                  onChange={onEffEndDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective End Date</FormHelperText>
              </FormControl>
            </VStack>
          </FieldGroup>

          <Button
            onClick={onSaveOccupationClicked}
            disabled={!canSave}
            variant="brand-primary"
            size="xs"
          >
            Save Occupation Factor
          </Button>
          <ErrorDisplay
            title="The record dates overlap other records!"
            errorMessage={errorMessage}
          />
        </Stack>
      </form>
    </Box>
  );
};

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
  };
}

export const EditOccupationFormUser =
  connect(mapStateToProps)(EditOccupationForm);
