import React, { useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Button,
  CloseButton,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Stack,
  StackDivider,
  VStack,
} from '@chakra-ui/react';

import { addNewDeductibleCredit } from './deductibleCreditSlice';
import { FieldGroup } from '../../components/common/FieldGroup';
import DatePicker from '../../components/common/DatePicker';
import { ErrorDisplay } from '../common/ErrorDisplay';
import { overlapCheck } from '../../services/overlapCheck';
import {
  dateToString,
  nullableDateToString,
} from '../../services/dateConverters';

export const AddDeductibleCreditForm = ({ user, deductibleCreditList }) => {
  const [amount, setAmount] = useState(0);
  const [dedAppliesDesc, setDedAppliesDesc] = useState(' ');
  const [preventive, setPreventive] = useState(0);
  const [radiographs, setRadiographs] = useState(0);
  const [majorRestorative, setMajorRestorative] = useState(0);
  const [otherClass2, setOtherClass2] = useState(0);
  const [prosthodontics, setProsthodontics] = useState(0);

  const [preventive1, setPreventive1] = useState(0);
  const [radiographs1, setRadiographs1] = useState(0);
  const [majorRestorative1, setMajorRestorative1] = useState(0);
  const [otherClass21, setOtherClass21] = useState(0);
  const [prosthodontics1, setProsthodontics1] = useState(0);

  const [preventive2, setPreventive2] = useState(0);
  const [radiographs2, setRadiographs2] = useState(0);
  const [majorRestorative2, setMajorRestorative2] = useState(0);
  const [otherClass22, setOtherClass22] = useState(0);
  const [prosthodontics2, setProsthodontics2] = useState(0);

  const [startDate, setEffStartDate] = useState(new Date());
  const [endDate, setEffEndDate] = useState('');
  const [addRequestStatus, setAddRequestStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();

  const onAmountChanged = e => {
    setAmount(e.target.value);
    setErrorMessage('');
  };

  const onDedAppliesDescChanged = e => setDedAppliesDesc(e.target.value);
  const onPreventiveChanged = e => setPreventive(Number(e.target.value));
  const onRadiographsChanged = e => setRadiographs(Number(e.target.value));
  const onMajorRestorativeChanged = e =>
    setMajorRestorative(Number(e.target.value));
  const onOtherClass2Changed = e => setOtherClass2(Number(e.target.value));
  const onProsthodonticsChanged = e =>
    setProsthodontics(Number(e.target.value));

  const onPreventive1Changed = e => setPreventive1(Number(e.target.value));
  const onRadiographs1Changed = e => setRadiographs1(Number(e.target.value));
  const onMajorRestorative1Changed = e =>
    setMajorRestorative1(Number(e.target.value));
  const onOtherClass21Changed = e => setOtherClass21(Number(e.target.value));
  const onProsthodontics1Changed = e =>
    setProsthodontics1(Number(e.target.value));

  const onPreventive2Changed = e => setPreventive2(Number(e.target.value));
  const onRadiographs2Changed = e => setRadiographs2(Number(e.target.value));
  const onMajorRestorative2Changed = e =>
    setMajorRestorative2(Number(e.target.value));
  const onOtherClass22Changed = e => setOtherClass22(Number(e.target.value));
  const onProsthodontics2Changed = e =>
    setProsthodontics2(Number(e.target.value));

  const onEffStartDateChanged = e => {
    setEffStartDate(e);
    setErrorMessage('');
  };
  const onEffEndDateChanged = e => {
    setEffEndDate(e);
    setErrorMessage('');
  };

  const canSave =
    [
      !isNaN(amount),
      dedAppliesDesc,
      !isNaN(preventive),
      !isNaN(radiographs),
      !isNaN(majorRestorative),
      !isNaN(otherClass2),
      !isNaN(prosthodontics),

      !isNaN(preventive1),
      !isNaN(radiographs1),
      !isNaN(majorRestorative1),
      !isNaN(otherClass21),
      !isNaN(prosthodontics1),

      !isNaN(preventive2),
      !isNaN(radiographs2),
      !isNaN(majorRestorative2),
      !isNaN(otherClass22),
      !isNaN(prosthodontics2),

      startDate,
    ].every(Boolean) && addRequestStatus === 'idle';

  const onSaveDeductibleCreditClicked = async () => {
    if (canSave) {
      try {
        setAddRequestStatus('pending');

        var deductibleCreditOverlap = deductibleCreditList.filter(
          overlapCheck,
          {
            effStartDate: startDate,
            effEndDate: endDate,
            keys: [
              {
                name: 'amount',
                value: amount,
              },
              {
                name: 'dedAppliesDesc',
                value: dedAppliesDesc,
              },
            ],
          }
        );
        if (deductibleCreditOverlap.length) {
          console.log(deductibleCreditOverlap);
          setErrorMessage(
            'The record being entered overlaps an existing effective date range for this key value.'
          );
          return false;
        }

        const resultAction = await dispatch(
          addNewDeductibleCredit({
            amount,
            dedAppliesDesc,
            preventive,
            radiographs,
            majorRestorative,
            otherClass2,
            prosthodontics,

            preventive1,
            radiographs1,
            majorRestorative1,
            otherClass21,
            prosthodontics1,

            preventive2,
            radiographs2,
            majorRestorative2,
            otherClass22,
            prosthodontics2,
            modifiedByName: user.profile.name,
            modifiedDate: new Date().toISOString(),
            effStartDate: dateToString(startDate),
            effEndDate: nullableDateToString(endDate),
          })
        );

        unwrapResult(resultAction);

        setAmount(0);
        setDedAppliesDesc('');
        setPreventive(0);
        setRadiographs(0);
        setMajorRestorative(0);
        setOtherClass2(0);
        setProsthodontics(0);

        setPreventive1(0);
        setRadiographs1(0);
        setMajorRestorative1(0);
        setOtherClass21(0);
        setProsthodontics1(0);

        setPreventive2(0);
        setRadiographs2(0);
        setMajorRestorative2(0);
        setOtherClass22(0);
        setProsthodontics2(0);

        setEffStartDate(new Date());
        setEffEndDate('');
      } catch (err) {
        console.error(
          'Failed to save the deductibleCredit factor record: ',
          err
        );
      } finally {
        setAddRequestStatus('idle');
      }
    }
  };

  return (
    <Box
      px={{
        base: '4',
        md: '10',
      }}
      pb="16"
      maxWidth="3xl"
      mx="auto"
    >
      <form>
        <Stack spacing="4" divider={<StackDivider />}>
          <FieldGroup title="Add DeductibleCredit Record">
            <VStack width="full" spacing="6">
              <FormControl id="amount">
                <FormLabel>Amount:</FormLabel>
                <Input
                  type="number"
                  value={amount}
                  onChange={onAmountChanged}
                />
              </FormControl>
              <FormControl id="dedAppliesDesc">
                <FormLabel>DedAppliesDesc:</FormLabel>
                <Input
                  type="text"
                  value={dedAppliesDesc}
                  onChange={onDedAppliesDescChanged}
                />
              </FormControl>
              <FormControl id="preventive">
                <FormLabel>Preventive:</FormLabel>
                <Input
                  type="number"
                  value={preventive}
                  onChange={onPreventiveChanged}
                />
              </FormControl>
              <FormControl id="radiographs">
                <FormLabel>Radiographs:</FormLabel>
                <Input
                  type="number"
                  value={radiographs}
                  onChange={onRadiographsChanged}
                />
              </FormControl>

              <FormControl id="majorRestorative">
                <FormLabel>MajorRestorative:</FormLabel>
                <Input
                  type="number"
                  value={majorRestorative}
                  onChange={onMajorRestorativeChanged}
                />
              </FormControl>
              <FormControl id="otherClass2">
                <FormLabel>OtherClass2:</FormLabel>
                <Input
                  type="number"
                  value={otherClass2}
                  onChange={onOtherClass2Changed}
                />
              </FormControl>
              <FormControl id="prosthodontics">
                <FormLabel>Prosthodontics:</FormLabel>
                <Input
                  type="number"
                  value={prosthodontics}
                  onChange={onProsthodonticsChanged}
                />
              </FormControl>

              <FormControl id="preventive1">
                <FormLabel>Preventive1:</FormLabel>
                <Input
                  type="number"
                  value={preventive1}
                  onChange={onPreventive1Changed}
                />
              </FormControl>
              <FormControl id="radiographs1">
                <FormLabel>Radiographs1:</FormLabel>
                <Input
                  type="number"
                  value={radiographs1}
                  onChange={onRadiographs1Changed}
                />
              </FormControl>

              <FormControl id="majorRestorative1">
                <FormLabel>MajorRestorative1:</FormLabel>
                <Input
                  type="number"
                  value={majorRestorative1}
                  onChange={onMajorRestorative1Changed}
                />
              </FormControl>
              <FormControl id="otherClass21">
                <FormLabel>OtherClass21:</FormLabel>
                <Input
                  type="number"
                  value={otherClass21}
                  onChange={onOtherClass21Changed}
                />
              </FormControl>
              <FormControl id="prosthodontics1">
                <FormLabel>Prosthodontics1:</FormLabel>
                <Input
                  type="number"
                  value={prosthodontics1}
                  onChange={onProsthodontics1Changed}
                />
              </FormControl>

              <FormControl id="preventive2">
                <FormLabel>Preventive2:</FormLabel>
                <Input
                  type="number"
                  value={preventive2}
                  onChange={onPreventive2Changed}
                />
              </FormControl>
              <FormControl id="radiographs2">
                <FormLabel>Radiographs2:</FormLabel>
                <Input
                  type="number"
                  value={radiographs2}
                  onChange={onRadiographs2Changed}
                />
              </FormControl>

              <FormControl id="majorRestorative2">
                <FormLabel>MajorRestorative2:</FormLabel>
                <Input
                  type="number"
                  value={majorRestorative2}
                  onChange={onMajorRestorative2Changed}
                />
              </FormControl>
              <FormControl id="otherClass22">
                <FormLabel>OtherClass22:</FormLabel>
                <Input
                  type="number"
                  value={otherClass22}
                  onChange={onOtherClass22Changed}
                />
              </FormControl>
              <FormControl id="prosthodontics2">
                <FormLabel>Prosthodontics2:</FormLabel>
                <Input
                  type="number"
                  value={prosthodontics2}
                  onChange={onProsthodontics2Changed}
                />
              </FormControl>

              <FormControl>
                <FormLabel htmlFor="effStartDate">
                  Effective Start Date
                </FormLabel>
                <DatePicker
                  id="effStartDate"
                  selectedDate={startDate}
                  onChange={onEffStartDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective Start Date</FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="effEndDate">Effective End Date</FormLabel>
                <DatePicker
                  id="effEndDate"
                  selectedDate={endDate}
                  onChange={onEffEndDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective End Date</FormHelperText>
              </FormControl>
            </VStack>
          </FieldGroup>

          <Button
            onClick={onSaveDeductibleCreditClicked}
            disabled={!canSave}
            variant="brand-primary"
            size="xs"
          >
            Save Deductible Credit Factor Record
          </Button>
          <ErrorDisplay
            title="The record dates overlap other records!"
            errorMessage={errorMessage}
          />
        </Stack>
      </form>
    </Box>
  );
};

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
  };
}

export const AddDeductibleCreditFormUser = connect(mapStateToProps)(
  AddDeductibleCreditForm
);
