import React from 'react'
import { AddCategoryForm } from './AddCategoryForm';
import { CategoryList } from './CategoryList';

export const CategoryPage = () => {
  return (
    <>
      <AddCategoryForm />
      <CategoryList />
    </>
  )
}