import React from 'react';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';

export const ErrorDisplay = ({ title, errorMessage }) => {
  return (
    errorMessage && (
      <>
        <Alert status="error">
          <AlertIcon />
          <AlertTitle mr={2}>{title}</AlertTitle>
          <AlertDescription>{errorMessage}</AlertDescription>
        </Alert>
      </>
    )
  );
};
