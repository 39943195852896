import { createUserManager } from 'redux-oidc';

const userManagerConfig = {
  client_id: window.ENV.client_id,
  redirect_uri: window.ENV.redirect_uri,
  post_logout_redirect_uri: window.ENV.post_logout_redirect_uri,
  response_type: 'code',
  scope: 'openid profile email roles rating_api',
  authority: window.ENV.authority,
  silent_redirect_uri: window.ENV.silent_redirect_uri,
  automaticSilentRenew: true,
  loadUserInfo: true,
};

const userManager = createUserManager(userManagerConfig);

export default userManager;
