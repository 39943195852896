import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import axios from 'axios';

const ageAdultAdapter = createEntityAdapter();
const apiBaseUrl = window.ENV.api_base_url;

const initialState = ageAdultAdapter.getInitialState({
  status: 'idle',
  error: null,
});

export const fetchAgeAdult = createAsyncThunk(
  'ageAdult/fetchAgeAdult',
  async (_, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };

    const response = await axios.get(`${apiBaseUrl}/api/AgeAdult`, config);
    return response.data;
  }
);

export const addNewAgeAdult = createAsyncThunk(
  'ageAdult/addNewAgeAdult',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.post(
      `${apiBaseUrl}/api/AgeAdult`,
      initialState,
      config
    );
    console.log('adding new ageAdult record');
    console.log(response);
    return { ...initialState, id: response.data };
  }
);

export const deleteAgeAdult = createAsyncThunk(
  'ageAdult/deleteAgeAdult',
  async (ageAdultId, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.delete(
      `${apiBaseUrl}/api/AgeAdult/${ageAdultId}`,
      config
    );
    return ageAdultId;
  }
);

export const updateAgeAdult = createAsyncThunk(
  'ageAdult/updateAgeAdult',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.put(
      `${apiBaseUrl}/api/AgeAdult/${initialState.id}`,
      initialState,
      config
    );
    return { ...initialState, id: response.data };
  }
);

const ageAdultSlice = createSlice({
  name: 'ageAdult',
  initialState,
  extraReducers: {
    [fetchAgeAdult.pending]: state => {
      state.status = 'loading';
    },
    [fetchAgeAdult.fulfilled]: (state, action) => {
      // Add any fetched posts to the array
      ageAdultAdapter.upsertMany(state, action.payload);
      state.status = 'succeeded';
    },
    [fetchAgeAdult.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [addNewAgeAdult.fulfilled]: ageAdultAdapter.addOne,
    [deleteAgeAdult.fulfilled]: ageAdultAdapter.removeOne,
    [updateAgeAdult.fulfilled]: ageAdultAdapter.upsertOne,
  },
});

export default ageAdultSlice.reducer;

export const {
  selectAll: selectAllAgeAdult,
  selectById: selectAgeAdultById,
  selectIds: selectAgeAdultIds,
} = ageAdultAdapter.getSelectors(state => state.ageAdult);
