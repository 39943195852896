import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Select,
  Stack,
  StackDivider,
  VStack,
} from '@chakra-ui/react';

import {
  fetchCountyArea,
  updateCountyArea,
  selectCountyAreaById,
  selectAllCountyArea,
} from './countyAreaSlice';
import { FieldGroup } from '../../components/common/FieldGroup';
import DatePicker from '../../components/common/DatePicker';
import { ErrorDisplay } from '../common/ErrorDisplay';
import { overlapCheck } from '../../services/overlapCheck';
import {
  dateToString,
  nullableDateToString,
  stringToDateFormat,
} from '../../services/dateConverters';

export const EditCountyAreaForm = ({ match, user }) => {
  const { countyAreaId } = match.params;
  const carea = useSelector(state => selectCountyAreaById(state, countyAreaId));
  const countyAreaList = useSelector(selectAllCountyArea);

  const stdt = stringToDateFormat(carea.effStartDate);
  console.log(stdt);
  const endt = stringToDateFormat(carea.effEndDate);
  console.log(endt);

  const [state, setState] = useState(carea.state);
  const [county, setCounty] = useState(carea.county);
  const [trd, setTrd] = useState(carea.trd);
  const [ppo, setPpo] = useState(carea.ppo);
  const [standardOon, setStandardOon] = useState(carea.standardOon);
  const [nationalOon, setNationalOon] = useState(carea.nationalOon);
  const [ehb, setEhb] = useState(carea.ehb);
  const [ppo1, setPpo1] = useState(carea.ppo1);
  const [non, setNon] = useState(carea.non);

  const [startDate, setEffStartDate] = useState(stdt);
  const [endDate, setEffEndDate] = useState(endt);
  const [updateRequestStatus, setUpdateRequestStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();
  const history = useHistory();

  const onStateChanged = e => setState(e.target.value);
  const onCountyChanged = e => {
    setCounty(e.target.value);
    setErrorMessage('');
  };
  const onTrdChanged = e => setTrd(Number(e.target.value));
  const onPpoChanged = e => setPpo(Number(e.target.value));

  const onStandardOonChanged = e => setStandardOon(Number(e.target.value));
  const onNationalOonChanged = e => setNationalOon(Number(e.target.value));
  const onEhbChanged = e => setEhb(Number(e.target.value));
  const onPpo1Changed = e => setPpo1(Number(e.target.value));
  const onNonChanged = e => setNon(Number(e.target.value));

  const onEffStartDateChanged = e => {
    setEffStartDate(e);
    setErrorMessage('');
  };
  const onEffEndDateChanged = e => {
    setEffEndDate(e);
    setErrorMessage('');
  };

  const canSave =
    [
      state,
      county,
      !isNaN(trd),
      !isNaN(ppo),
      !isNaN(standardOon),
      !isNaN(nationalOon),
      !isNaN(ehb),
      !isNaN(ppo1),
      !isNaN(non),
      startDate,
    ].every(Boolean) && updateRequestStatus === 'idle';

  const onSaveCountyAreaClicked = async () => {
    if (canSave) {
      try {
        setUpdateRequestStatus('pending');

        var countyAreaOverlap = countyAreaList.filter(overlapCheck, {
          id: carea.id,
          checkType: '',
          effStartDate: startDate,
          effEndDate: endDate,
          keys: [
            {
              name: 'state',
              value: state,
            },
            {
              name: 'county',
              value: county,
            },
          ],
        });
        if (countyAreaOverlap.length) {
          console.log(countyAreaOverlap);
          setErrorMessage(
            'The record being entered overlaps an existing effective date range for this key value.'
          );
          return false;
        }

        const resultAction = await dispatch(
          updateCountyArea({
            id: Number(countyAreaId),
            state,
            county,
            trd,
            ppo,
            standardOon,
            nationalOon,
            ehb,
            ppo1,
            non,
            modifiedByName: user.profile.name,
            modifiedDate: new Date().toISOString(),
            effStartDate: dateToString(startDate),
            effEndDate: nullableDateToString(endDate),
          })
        );
        console.log(resultAction);
        setUpdateRequestStatus('idle');
        history.push(`/countyArea`);
      } catch (err) {
        setUpdateRequestStatus('idle');
        console.error('Failed to save the countyArea: ', err);
      }
    }
  };

  return (
    <Box
      px={{
        base: '4',
        md: '10',
      }}
      pb="16"
      maxWidth="3xl"
      mx="auto"
    >
      <form>
        <Stack spacing="4" divider={<StackDivider />}>
          <FieldGroup title="Edit CountyArea">
            <VStack width="full" spacing="6">
              <FormControl id="state">
                <FormLabel>State:</FormLabel>
                <Input type="text" value={state} onChange={onStateChanged} />
              </FormControl>
              <FormControl id="county">
                <FormLabel>County:</FormLabel>
                <Input type="text" value={county} onChange={onCountyChanged} />
              </FormControl>
              <FormControl id="trd">
                <FormLabel>TRD:</FormLabel>
                <Input type="number" value={trd} onChange={onTrdChanged} />
              </FormControl>
              <FormControl id="ppo">
                <FormLabel>PPO:</FormLabel>
                <Input type="number" value={ppo} onChange={onPpoChanged} />
              </FormControl>

              <FormControl id="standardOon">
                <FormLabel>Standard Oon:</FormLabel>
                <Input
                  type="number"
                  value={standardOon}
                  onChange={onStandardOonChanged}
                />
              </FormControl>
              <FormControl id="nationalOon">
                <FormLabel>National Oon:</FormLabel>
                <Input
                  type="number"
                  value={nationalOon}
                  onChange={onNationalOonChanged}
                />
              </FormControl>
              <FormControl id="ehb">
                <FormLabel>EHB:</FormLabel>
                <Input type="text" value={ehb} onChange={onEhbChanged} />
              </FormControl>
              <FormControl id="ppo1">
                <FormLabel>PPO 1:</FormLabel>
                <Input type="number" value={ppo1} onChange={onPpo1Changed} />
              </FormControl>

              <FormControl id="non">
                <FormLabel>Non:</FormLabel>
                <Input type="text" value={non} onChange={onNonChanged} />
              </FormControl>

              <FormControl id="effStartDate">
                <FormLabel>Effective Start Date:</FormLabel>
                <DatePicker
                  id="effStartDate"
                  selectedDate={startDate}
                  onChange={onEffStartDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective Start Date</FormHelperText>
              </FormControl>
              <FormControl id="effEndDate">
                <FormLabel>Effective End Date:</FormLabel>
                <DatePicker
                  id="effEndDate"
                  selectedDate={endDate}
                  onChange={onEffEndDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective End Date</FormHelperText>
              </FormControl>
            </VStack>
          </FieldGroup>

          <Button
            onClick={onSaveCountyAreaClicked}
            disabled={!canSave}
            variant="brand-primary"
            size="xs"
          >
            Save County Area
          </Button>
          <ErrorDisplay
            title="The record dates overlap other records!"
            errorMessage={errorMessage}
          />
        </Stack>
      </form>
    </Box>
  );
};

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
  };
}

export const EditCountyAreaFormUser =
  connect(mapStateToProps)(EditCountyAreaForm);
