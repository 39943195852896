import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
  AccordionIcon,
  Heading,
} from '@chakra-ui/react';
import {
  fetchAreaEhb,
  selectAreaEhbIds,
  selectAllAreaEhb,
} from './areaEhbSlice';
import { AddAreaEhbFormUser } from './AddAreaEhbForm';
import { AreaEhbList } from './AreaEhbList';
import BrandLinkButton from '../../components/common/BrandLinkButton';

export const AreaEhbPage = () => {
  const dispatch = useDispatch();

  const orderedAreaEhbIds = useSelector(selectAreaEhbIds);
  const areaEhbList = useSelector(selectAllAreaEhb);

  const error = useSelector(state => state.areaEhb.error);
  if (error) {
    console.log('AreaEhb List Error:' + error.toString());
  }

  const areaEhbStatus = useSelector(state => state.areaEhb.status);
  const [index, setIndex] = React.useState([]);

  useEffect(() => {
    if (areaEhbStatus === 'idle') {
      dispatch(fetchAreaEhb());
    }
  }, [areaEhbStatus, dispatch]);

  function closeAccordion() {
    setIndex([]);
  }

  let content;

  if (areaEhbStatus === 'loading') {
    content = <div className="loader">Loading...</div>;
  } else if (areaEhbStatus === 'succeeded') {
    console.log(orderedAreaEhbIds);
    content = (
      <div>
        <Heading size="xl" mb={8}>
          Area Ehb Factors
        </Heading>
        <Accordion
          allowMultiple
          allowToggle="true"
          mb={8}
          index={index}
          onChange={setIndex}
        >
          <AccordionItem>
            <Box>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Text variant="brand-green-link" fontSize="lg">
                    Add New Factor
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </Box>
            <AccordionPanel pb={4}>
              <AddAreaEhbFormUser
                areaEhbList={areaEhbList}
                closeAccordion={closeAccordion}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <AreaEhbList orderedAreaEhbIds={orderedAreaEhbIds} />
      </div>
    );
  } else if (areaEhbStatus === 'failed') {
    content = <div>{error}</div>;
  }

  return (
    <>
      {content}
      <Box mt={12}>
        <BrandLinkButton path="/settings">Back to Settings</BrandLinkButton>
      </Box>
    </>
  );
};

export default AreaEhbPage;
