import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import axios from 'axios';

const incurredClaimsAdapter = createEntityAdapter();
const apiBaseUrl = window.ENV.api_base_url;

const initialState = incurredClaimsAdapter.getInitialState({
  status: 'idle',
  error: null,
});

export const fetchIncurredClaims = createAsyncThunk(
  'incurredClaims/fetchIncurredClaims',
  async (_, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };

    const response = await axios.get(`${apiBaseUrl}/api/IncurredClaims`, config);
    return response.data;
  }
);


export const addNewIncurredClaims = createAsyncThunk(
  'incurredClaims/addNewIncurredClaims',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.post(
      `${apiBaseUrl}/api/IncurredClaims`,
      initialState,
      config
    );

    return { ...initialState, id: response.data };
  }
);


/*
export const deleteIncurredClaims = createAsyncThunk(
  'incurredClaims/deleteIncurredClaims',
  async (copayId, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.delete(
      `${apiBaseUrl}/api/Copay/${copayId}`,
      config
    );
    return copayId;
  }
);
*/

/*
export const updateIncurredClaims = createAsyncThunk(
  'incurredClaims/updateIncurredClaims',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.put(
      `${apiBaseUrl}/api/IncurredClaims/${initialState.id}`,
      initialState,
      config
    );
    return { ...initialState, id: response.data };
  }
);
*/

const incurredClaimsSlice = createSlice({
  name: 'incurredClaims',
  initialState,
  extraReducers: {
    [fetchIncurredClaims.pending]: state => {
      state.status = 'loading';
    },
    [fetchIncurredClaims.fulfilled]: (state, action) => {
      // Add any fetched posts to the array
      incurredClaimsAdapter.upsertMany(state, action.payload);
      state.status = 'succeeded';
    },
    [fetchIncurredClaims.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
   /* [addNewIncurredClaims.fulfilled]: incurredClaimsAdapter.addOne, */
   /* [deleteCopay.fulfilled]: copayAdapter.removeOne, */
   /* [updateIncurredClaims.fulfilled]: IncurredClaimsAdapter.upsertOne, */
  },
});

export default incurredClaimsSlice.reducer;

export const {
  selectAll: selectAllIncurredClaims, 
  selectById: selectIncurredClaimsById,
  selectIds: selectIncurredClaimsIds,
} = incurredClaimsAdapter.getSelectors(state => state.incurredClaims);
