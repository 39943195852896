import { Box, Image, Flex, Stack } from '@chakra-ui/react';
import * as React from 'react';
import {
  BiBuoy,
  BiCog,
  BiCommentAdd,
  BiCheckCircle,
  BiDollar,
  BiHome,
  BiRepost,
  BiIdCard,
  BiGroup,
  BiUserCircle,
  BiFolderOpen,
  BiLogOut,
  BiCoinStack,
} from 'react-icons/bi';
import { NavGroup } from './NavGroup';
import { NavItem } from './NavItem';

import userManager from '../../services/userManager';
import { connect } from 'react-redux';

function LeftNav(props) {
  const { user, isAuthenticated } = props;
  const isAdmin = isAuthenticated && user.profile['role'] === 'Administrator';

  const onLoginButtonClick = event => {
    console.log('Login Click');
    event.preventDefault();
    userManager.signinRedirect();
  };

  const onLogoutButtonClick = event => {
    event.preventDefault();
    userManager.signoutRedirect({ id_token_hint: user.id_token });
    userManager.removeUser(); // removes the user data from sessionStorage
  };

  return (
    <Box w="64" minW="64" bg="brand_green.600" color="white" fontSize="sm">
      <Flex h="full" direction="column" px="4" py="4">
        <Image src="./images/logo.png" />
        <Stack spacing="8" flex="1" overflow="auto" pt="8">
          <Stack spacing="1">
            <NavItem icon={<BiHome />} label="Group Rating Home" url="/" />
            {isAuthenticated ? (
              <NavItem icon={<BiCommentAdd />} label="Inbox" url="/profile" />
            ) : (
              <NavItem
                icon={<BiIdCard />}
                label="Login"
                onClick={onLoginButtonClick}
                url="#"
              />
            )}
          </Stack>
          {isAuthenticated ? (
            <>
              <NavGroup label="Quotes">
                <NavItem icon={<BiUserCircle />} label="New Groups" url="/" />
                <NavItem icon={<BiFolderOpen />} label="Documents" url="/" />
                <NavItem icon={<BiCheckCircle />} label="Completed" url="/" />
                <NavItem icon={<BiIdCard />} label="Plans" url="/" />
                <NavItem icon={<BiCoinStack />} label="Logs" url="/" />
              </NavGroup>

              <NavGroup label="Renewals">
                <NavItem
                  icon={<BiRepost />}
                  label="Run Batch Renewals"
                  url="/renewals"
                />
                <NavItem icon={<BiGroup />} label="Large Group" url="/" />
                <NavItem icon={<BiDollar />} label="Rate Tables" url="/" />
                <NavItem icon={<BiCoinStack />} label="Logs" url="/" />
              </NavGroup>
            </>
          ) : (
            <React.Fragment />
          )}
        </Stack>
        <Box>
          <Stack spacing="1">
            {isAuthenticated ? (
              <>
                <NavItem
                  icon={<BiLogOut />}
                  label="Log Out"
                  onClick={onLogoutButtonClick}
                  url="#"
                />
                <NavItem icon={<BiCog />} label="Settings" url="/settings" />
              </>
            ) : (
              <React.Fragment />
            )}
            <NavItem icon={<BiBuoy />} label="Help & Support" url="/" />
          </Stack>
        </Box>
      </Flex>
    </Box>
  );
}

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
    isAuthenticated: state.oidc.user && !state.oidc.user.expired,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LeftNav);
