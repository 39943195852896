import React, { useEffect } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalHeader,
  ModalFooter,
  ModalContent,
  ModalCloseButton,
  Heading,
  useDisclosure,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchPandemic,
  selectPandemicIds,
  selectPandemicById,
  deletePandemic,
} from './pandemicSlice';
import { unwrapResult } from '@reduxjs/toolkit';

import { NavLink } from 'react-router-dom';
import { FormattedDate } from '../common/FormattedDate';
import { FormattedTimestamp } from '../common/FormattedTimestamp';

const ItemDetails = ({ currentItem }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button onClick={onOpen} size="xs">
        Details
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Pandemic: {currentItem.pandemic1} {currentItem.endDate}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Table size="sm">
              <Tbody>
                <Tr>
                  <Th>Pandemic1</Th>
                  <Th>EndDate</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.pandemic1}</Td>
                  <Td>{currentItem.endDate}</Td>
                </Tr>

                <Tr>
                  <Th>Factor</Th>
                  <Th> </Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.factor}</Td>
                  <Td></Td>
                </Tr>
                <Tr>
                  <Th>Modified By Name</Th>
                  <Th>Modified Date</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.modifiedByName}</Td>
                  <Td>
                    <FormattedTimestamp
                      dateToFormat={currentItem.modifiedDate}
                    />
                  </Td>
                </Tr>
                <Tr>
                  <Th>Eff Start Date</Th>
                  <Th>Eff End Date</Th>
                </Tr>
                <Tr>
                  <Td>
                    <FormattedDate dateToFormat={currentItem.effStartDate} />
                  </Td>
                  <Td>
                    <FormattedDate dateToFormat={currentItem.effEndDate} />
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="brand-primary-sm"
              mr={3}
              onClick={onClose}
              size="sm"
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

let PandemicTable = ({ pandemicIdList }) => {
  let tableRows = pandemicIdList.map(pandemicId => (
    <Pandemic pandemicId={pandemicId} key={pandemicId} />
  ));

  return (
    <Box overflowX="auto">
      <Table size="sm">
        <Thead>
          <Tr>
            <Th></Th>
            <Th>Pandemic1</Th>
            <Th>EndDate</Th>
            <Th>Factor</Th>

            <Th>Modified By Name</Th>
            <Th>Modified Date</Th>
            <Th>Eff Start Date</Th>
            <Th>Eff End Date</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>{tableRows}</Tbody>
      </Table>
    </Box>
  );
};

let Pandemic = ({ pandemicId }) => {
  const pandemic = useSelector(state => selectPandemicById(state, pandemicId));
  //console.log('In the pandemic list');

  const dispatch = useDispatch();

  const onDeletePandemicClicked = async () => {
    try {
      const resultAction = await dispatch(deletePandemic(pandemic.id));
      unwrapResult(resultAction);
    } catch (err) {
      console.error('Failed to save the pandemic record', err);
    }
  };

  return (
    <Tr>
      <Td>
        <ItemDetails currentItem={pandemic} />
      </Td>
      <Td>{pandemic.pandemic1}</Td>
      <Td>{pandemic.endDate}</Td>
      <Td>{pandemic.factor}</Td>
      <Td>{pandemic.modifiedByName}</Td>
      <Td>
        <FormattedTimestamp dateToFormat={pandemic.modifiedDate} />
      </Td>
      <Td>
        <FormattedDate dateToFormat={pandemic.effStartDate} />
      </Td>
      <Td>
        <FormattedDate dateToFormat={pandemic.effEndDate} />
      </Td>
      <Td>
        <ButtonGroup variant="outline">
          <Button
            colorScheme="gray"
            size="sm"
            as={NavLink}
            to={`/editPandemic/${pandemic.id}`}
          >
            Edit
          </Button>
          <Button colorScheme="red" size="sm" onClick={onDeletePandemicClicked}>
            Delete
          </Button>
        </ButtonGroup>
      </Td>
    </Tr>
  );
};

export const PandemicList = () => {
  const dispatch = useDispatch();
  const orderedPandemicIds = useSelector(selectPandemicIds);
  console.log('in the roller list');
  const error = useSelector(state => state.pandemic.error);

  const pandemicStatus = useSelector(state => state.pandemic.status);
  console.log(pandemicStatus);
  useEffect(() => {
    if (pandemicStatus === 'idle') {
      dispatch(fetchPandemic());
    }
  }, [pandemicStatus, dispatch]);

  let content;

  if (pandemicStatus === 'loading') {
    content = <div className="loader">Loading...</div>;
  } else if (pandemicStatus === 'succeeded') {
    content = <PandemicTable pandemicIdList={orderedPandemicIds} />;
  } else if (pandemicStatus === 'failed') {
    content = <div>{error}</div>;
  }

  return (
    <section>
      <h2>
        <b>Pandemic Listing</b>
      </h2>
      {content}
    </section>
  );
};
