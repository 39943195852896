import React, { useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Button,
  CloseButton,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Stack,
  StackDivider,
  VStack,
} from '@chakra-ui/react';

import { addNewAPRC } from './aPRCSlice';
import { FieldGroup } from '../../components/common/FieldGroup';
import DatePicker from '../../components/common/DatePicker';
import { ErrorDisplay } from '../common/ErrorDisplay';
import { overlapCheck } from '../../services/overlapCheck';
import {
  dateToString,
  nullableDateToString,
} from '../../services/dateConverters';

export const AddAPRCForm = ({ user, aPRCList }) => {
  const [description, setDescription] = useState(' ');
  const [admin, setAdmin] = useState(0);
  const [rateChange, setRateChange] = useState(0);
  const [roundBy, setRoundBy] = useState(0);

  const [startDate, setEffStartDate] = useState(new Date());
  const [endDate, setEffEndDate] = useState('');
  const [addRequestStatus, setAddRequestStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();

  const onDescriptionChanged = e => {
    setDescription(e.target.value);
    setErrorMessage('');
  };

  const onAdminChanged = e => setAdmin(Number(e.target.value));
  const onRateChangeChanged = e => setRateChange(Number(e.target.value));
  const onRoundByChanged = e => setRoundBy(Number(e.target.value));

  const onEffStartDateChanged = e => {
    setEffStartDate(e);
    setErrorMessage('');
  };
  const onEffEndDateChanged = e => {
    setEffEndDate(e);
    setErrorMessage('');
  };

  const canSave =
    [
      description,
      !isNaN(admin),
      !isNaN(rateChange),
      !isNaN(roundBy),

      startDate,
    ].every(Boolean) && addRequestStatus === 'idle';

  const onSaveAPRCClicked = async () => {
    if (canSave) {
      try {
        setAddRequestStatus('pending');

        var aPRCOverlap = aPRCList.filter(overlapCheck, {
          effStartDate: startDate,
          effEndDate: endDate,
          keys: [
            {
              name: 'description',
              value: description,
            },
          ],
        });
        if (aPRCOverlap.length) {
          console.log(aPRCOverlap);
          setErrorMessage(
            'The record being entered overlaps an existing effective date range for this key value.'
          );
          return false;
        }

        const resultAction = await dispatch(
          addNewAPRC({
            description,
            admin,
            rateChange,
            roundBy,
            modifiedByName: user.profile.name,
            modifiedDate: new Date().toISOString(),
            effStartDate: dateToString(startDate),
            effEndDate: nullableDateToString(endDate),
          })
        );

        unwrapResult(resultAction);

        setDescription('');
        setAdmin(0);
        setRateChange(0);
        setRoundBy(0);

        setEffStartDate(new Date());
        setEffEndDate('');
      } catch (err) {
        console.error('Failed to save the aPRC factor record: ', err);
      } finally {
        setAddRequestStatus('idle');
      }
    }
  };

  return (
    <Box
      px={{
        base: '4',
        md: '10',
      }}
      pb="16"
      maxWidth="3xl"
      mx="auto"
    >
      <form>
        <Stack spacing="4" divider={<StackDivider />}>
          <FieldGroup title="Add APRC Record">
            <VStack width="full" spacing="6">
              <FormControl id="description">
                <FormLabel>Description:</FormLabel>
                <Input
                  type="text"
                  value={description}
                  onChange={onDescriptionChanged}
                />
              </FormControl>
              <FormControl id="admin">
                <FormLabel>Admin:</FormLabel>
                <Input type="number" value={admin} onChange={onAdminChanged} />
              </FormControl>
              <FormControl id="rateChange">
                <FormLabel>RateChange:</FormLabel>
                <Input
                  type="number"
                  value={rateChange}
                  onChange={onRateChangeChanged}
                />
              </FormControl>
              <FormControl id="roundBy">
                <FormLabel>RoundBy:</FormLabel>
                <Input
                  type="number"
                  value={roundBy}
                  onChange={onRoundByChanged}
                />
              </FormControl>

              <FormControl>
                <FormLabel htmlFor="effStartDate">
                  Effective Start Date
                </FormLabel>
                <DatePicker
                  id="effStartDate"
                  selectedDate={startDate}
                  onChange={onEffStartDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective Start Date</FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="effEndDate">Effective End Date</FormLabel>
                <DatePicker
                  id="effEndDate"
                  selectedDate={endDate}
                  onChange={onEffEndDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective End Date</FormHelperText>
              </FormControl>
            </VStack>
          </FieldGroup>

          <Button
            onClick={onSaveAPRCClicked}
            disabled={!canSave}
            variant="brand-primary"
            size="xs"
          >
            Save APRC Factor Record
          </Button>
          <ErrorDisplay
            title="The record dates overlap other records!"
            errorMessage={errorMessage}
          />
        </Stack>
      </form>
    </Box>
  );
};

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
  };
}

export const AddAPRCFormUser = connect(mapStateToProps)(AddAPRCForm);
