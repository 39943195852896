import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Select,
  Stack,
  StackDivider,
  VStack,
} from '@chakra-ui/react';

import {
  fetchAPCAso,
  updateAPCAso,
  selectAPCAsoById,
  selectAllAPCAso,
} from './aPCAsoSlice';
import { FieldGroup } from '../../components/common/FieldGroup';
import DatePicker from '../../components/common/DatePicker';
import { ErrorDisplay } from '../common/ErrorDisplay';
import { overlapCheck } from '../../services/overlapCheck';
import {
  dateToString,
  nullableDateToString,
  stringToDateFormat,
} from '../../services/dateConverters';

export const EditAPCAsoForm = ({ match, user }) => {
  const { aPCAsoId } = match.params;
  const aPCAso = useSelector(state => selectAPCAsoById(state, aPCAsoId));
  const aPCAsoList = useSelector(selectAllAPCAso);

  const stdt = stringToDateFormat(aPCAso.effStartDate);
  console.log(stdt);
  const endt = stringToDateFormat(aPCAso.effEndDate);
  console.log(endt);

  const [groupLow, setGroupLow] = useState(aPCAso.groupLow);
  const [groupHigh, setGroupHigh] = useState(aPCAso.groupHigh);
  const [amount, setAmount] = useState(aPCAso.amount);

  const [startDate, setEffStartDate] = useState(stdt);

  const [endDate, setEffEndDate] = useState(endt);
  const [updateRequestStatus, setUpdateRequestStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();
  const history = useHistory();

  const onGroupLowChanged = e => {
    setGroupLow(Number(e.target.value));
    setErrorMessage('');
  };
  const onGroupHighChanged = e => setGroupHigh(Number(e.target.value));
  const onAmountChanged = e => setAmount(Number(e.target.value));

  const onEffStartDateChanged = e => {
    setEffStartDate(e);
    setErrorMessage('');
  };
  const onEffEndDateChanged = e => {
    setEffEndDate(e);
    setErrorMessage('');
  };

  const canSave =
    [!isNaN(groupLow), !isNaN(groupHigh), !isNaN(amount), startDate].every(
      Boolean
    ) && updateRequestStatus === 'idle';

  const onSaveAPCAsoClicked = async () => {
    if (canSave) {
      try {
        setUpdateRequestStatus('pending');

        var aPCAsoOverlap = aPCAsoList.filter(overlapCheck, {
          id: aPCAso.id,
          checkType: 'range',
          effStartDate: startDate,
          effEndDate: endDate,
          keys: [
            {
              name: 'groupLow',
              value: groupLow,
            },
            {
              name: 'groupHigh',
              value: groupHigh,
            },
          ],
        });
        if (aPCAsoOverlap.length) {
          console.log('aPCAsoOverlap:');
          console.log(aPCAsoOverlap);
          setErrorMessage(
            'The record being entered overlaps an existing effective date range for this key value.'
          );
          return false;
        }

        const resultAction = await dispatch(
          updateAPCAso({
            id: Number(aPCAsoId),
            groupLow,
            groupHigh,
            amount,
            modifiedByName: user.profile.name,
            modifiedDate: new Date().toISOString(),
            effStartDate: dateToString(startDate),
            effEndDate: nullableDateToString(endDate),
          })
        );
        console.log(resultAction);
        setUpdateRequestStatus('idle');
        history.push(`/aPCAso`);
      } catch (err) {
        setUpdateRequestStatus('idle');
        console.error('Failed to save the aPCAso factor: ', err);
      }
    }
  };
  return (
    <Box
      px={{
        base: '4',
        md: '10',
      }}
      pb="16"
      maxWidth="3xl"
      mx="auto"
    >
      <form>
        <Stack spacing="4" divider={<StackDivider />}>
          <FieldGroup title="Edit APC ASO Factors">
            <VStack width="full" spacing="6">
              <FormControl id="groupLow">
                <FormLabel>GroupLow:</FormLabel>
                <Input
                  type="number"
                  value={groupLow}
                  onChange={onGroupLowChanged}
                />
              </FormControl>
              <FormControl id="groupHigh">
                <FormLabel>GroupHigh:</FormLabel>
                <Input
                  type="number"
                  value={groupHigh}
                  onChange={onGroupHighChanged}
                />
              </FormControl>
              <FormControl id="amount">
                <FormLabel>Amount:</FormLabel>
                <Input
                  type="number"
                  value={amount}
                  onChange={onAmountChanged}
                />
              </FormControl>

              <FormControl id="effStartDate">
                <FormLabel>Effective Start Date:</FormLabel>
                <DatePicker
                  id="effStartDate"
                  selectedDate={startDate}
                  onChange={onEffStartDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective Start Date</FormHelperText>
              </FormControl>
              <FormControl id="effEndDate">
                <FormLabel>Effective End Date:</FormLabel>
                <DatePicker
                  id="effEndDate"
                  selectedDate={endDate}
                  onChange={onEffEndDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective End Date</FormHelperText>
              </FormControl>
            </VStack>
          </FieldGroup>

          <Button
            onClick={onSaveAPCAsoClicked}
            disabled={!canSave}
            variant="brand-primary"
            size="xs"
          >
            Save APCAso Factor
          </Button>
          <ErrorDisplay
            title="The record dates overlap other records!"
            errorMessage={errorMessage}
          />
        </Stack>
      </form>
    </Box>
  );
};

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
  };
}

export const EditAPCAsoFormUser = connect(mapStateToProps)(EditAPCAsoForm);
