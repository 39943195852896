import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Select,
  Stack,
  StackDivider,
  VStack,
} from '@chakra-ui/react';

import {
  fetchEhbTierDist,
  updateEhbTierDist,
  selectEhbTierDistById,
  selectAllEhbTierDist,
} from './ehbTierDistSlice';
import { FieldGroup } from '../../components/common/FieldGroup';
import DatePicker from '../../components/common/DatePicker';
import { ErrorDisplay } from '../common/ErrorDisplay';
import { overlapCheck } from '../../services/overlapCheck';
import {
  dateToString,
  nullableDateToString,
  stringToDateFormat,
} from '../../services/dateConverters';

export const EditEhbTierDistForm = ({ match, user }) => {
  const { ehbTierDistId } = match.params;
  const ehbTierDist = useSelector(state =>
    selectEhbTierDistById(state, ehbTierDistId)
  );
  const ehbTierDistList = useSelector(selectAllEhbTierDist);

  const stdt = stringToDateFormat(ehbTierDist.effStartDate);
  console.log(stdt);
  const endt = stringToDateFormat(ehbTierDist.effEndDate);
  console.log(endt);

  const [ehbRateType, setEhbRateType] = useState(ehbTierDist.ehbRateType);
  const [tierPercent, setTierPercent] = useState(ehbTierDist.tierPercent);

  const [startDate, setEffStartDate] = useState(stdt);

  const [endDate, setEffEndDate] = useState(endt);
  const [updateRequestStatus, setUpdateRequestStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();
  const history = useHistory();

  const onEhbRateTypeChanged = e => {
    setEhbRateType(e.target.value);
    setErrorMessage('');
  };

  const onTierPercentChanged = e => setTierPercent(Number(e.target.value));

  const onEffStartDateChanged = e => {
    setEffStartDate(e);
    setErrorMessage('');
  };
  const onEffEndDateChanged = e => {
    setEffEndDate(e);
    setErrorMessage('');
  };

  const canSave =
    [ehbRateType, !isNaN(tierPercent), startDate].every(Boolean) &&
    updateRequestStatus === 'idle';

  const onSaveEhbTierDistClicked = async () => {
    if (canSave) {
      try {
        setUpdateRequestStatus('pending');

        var ehbTierDistOverlap = ehbTierDistList.filter(overlapCheck, {
          id: ehbTierDist.id,
          effStartDate: startDate,
          effEndDate: endDate,
          keys: [
            {
              name: 'ehbRateType',
              value: ehbRateType,
            },
          ],
        });
        if (ehbTierDistOverlap.length) {
          console.log(ehbTierDistOverlap);
          setErrorMessage(
            'The record being entered overlaps an existing effective date range for this key value.'
          );
          return false;
        }

        const resultAction = await dispatch(
          updateEhbTierDist({
            id: Number(ehbTierDistId),
            ehbRateType,
            tierPercent,
            modifiedByName: user.profile.name,
            modifiedDate: new Date().toISOString(),
            effStartDate: dateToString(startDate),
            effEndDate: nullableDateToString(endDate),
          })
        );
        console.log(resultAction);
        setUpdateRequestStatus('idle');
        history.push(`/ehbTierDist`);
      } catch (err) {
        setUpdateRequestStatus('idle');
        console.error('Failed to save the ehbTierDist factor: ', err);
      }
    }
  };
  return (
    <Box
      px={{
        base: '4',
        md: '10',
      }}
      pb="16"
      maxWidth="3xl"
      mx="auto"
    >
      <form>
        <Stack spacing="4" divider={<StackDivider />}>
          <FieldGroup title="Edit EhbTierDist Factors">
            <VStack width="full" spacing="6">
              <FormControl id="ehbRateType">
                <FormLabel>EhbRateType:</FormLabel>
                <Input
                  type="text"
                  value={ehbRateType}
                  onChange={onEhbRateTypeChanged}
                />
              </FormControl>
              <FormControl id="tierPercent">
                <FormLabel>TierPercent:</FormLabel>
                <Input
                  type="number"
                  value={tierPercent}
                  onChange={onTierPercentChanged}
                />
              </FormControl>

              <FormControl id="effStartDate">
                <FormLabel>Effective Start Date:</FormLabel>
                <DatePicker
                  id="effStartDate"
                  selectedDate={startDate}
                  onChange={onEffStartDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective Start Date</FormHelperText>
              </FormControl>
              <FormControl id="effEndDate">
                <FormLabel>Effective End Date:</FormLabel>
                <DatePicker
                  id="effEndDate"
                  selectedDate={endDate}
                  onChange={onEffEndDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective End Date</FormHelperText>
              </FormControl>
            </VStack>
          </FieldGroup>

          <Button
            onClick={onSaveEhbTierDistClicked}
            disabled={!canSave}
            variant="brand-primary"
            size="xs"
          >
            Save EhbTierDist Factor
          </Button>
          <ErrorDisplay
            title="The record dates overlap other records!"
            errorMessage={errorMessage}
          />
        </Stack>
      </form>
    </Box>
  );
};

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
  };
}

export const EditEhbTierDistFormUser =
  connect(mapStateToProps)(EditEhbTierDistForm);
