import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import axios from 'axios';

const voluntaryAdapter = createEntityAdapter();
const apiBaseUrl = window.ENV.api_base_url;

const initialState = voluntaryAdapter.getInitialState({
  status: 'idle',
  error: null,
});

export const fetchVoluntary = createAsyncThunk(
  'voluntary/fetchVoluntary',
  async (_, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };

    const response = await axios.get(`${apiBaseUrl}/api/Voluntary`, config);
    return response.data;
  }
);

export const addNewVoluntary = createAsyncThunk(
  'voluntary/addNewVoluntary',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.post(
      `${apiBaseUrl}/api/Voluntary`,
      initialState,
      config
    );
    console.log('adding new voluntary record');
    console.log(response);
    return { ...initialState, id: response.data };
  }
);

export const deleteVoluntary = createAsyncThunk(
  'voluntary/deleteVoluntary',
  async (voluntaryId, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.delete(
      `${apiBaseUrl}/api/Voluntary/${voluntaryId}`,
      config
    );
    return voluntaryId;
  }
);

export const updateVoluntary = createAsyncThunk(
  'voluntary/updateVoluntary',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.put(
      `${apiBaseUrl}/api/Voluntary/${initialState.id}`,
      initialState,
      config
    );
    return { ...initialState, id: response.data };
  }
);

const voluntarySlice = createSlice({
  name: 'voluntary',
  initialState,
  extraReducers: {
    [fetchVoluntary.pending]: state => {
      state.status = 'loading';
    },
    [fetchVoluntary.fulfilled]: (state, action) => {
      // Add any fetched posts to the array
      voluntaryAdapter.upsertMany(state, action.payload);
      state.status = 'succeeded';
    },
    [fetchVoluntary.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [addNewVoluntary.fulfilled]: voluntaryAdapter.addOne,
    [deleteVoluntary.fulfilled]: voluntaryAdapter.removeOne,
    [updateVoluntary.fulfilled]: voluntaryAdapter.upsertOne,
  },
});

export default voluntarySlice.reducer;

export const {
  selectAll: selectAllVoluntary,
  selectById: selectVoluntaryById,
  selectIds: selectVoluntaryIds,
} = voluntaryAdapter.getSelectors(state => state.voluntary);
