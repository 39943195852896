import React, { useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Button,
  CloseButton,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Stack,
  StackDivider,
  VStack,
} from '@chakra-ui/react';

import { addNewCredibility } from './credibilitySlice';
import { FieldGroup } from '../../components/common/FieldGroup';
import DatePicker from '../../components/common/DatePicker';

import { ErrorDisplay } from '../common/ErrorDisplay';
import { overlapCheck } from '../../services/overlapCheck';
import {
  dateToString,
  nullableDateToString,
} from '../../services/dateConverters';

export const AddCredibilityForm = ({ user, credibilityList }) => {
  const [groupLow, setGroupLow] = useState(0);
  const [groupHigh, setGroupHigh] = useState(0);
  const [factor, setFactor] = useState(0);

  const [startDate, setEffStartDate] = useState(new Date());
  const [endDate, setEffEndDate] = useState('');
  const [addRequestStatus, setAddRequestStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();

  const onGroupLowChanged = e => {
    setGroupLow(Number(e.target.value));
    setErrorMessage('');
  };
  const onGroupHighChanged = e => setGroupHigh(Number(e.target.value));
  const onFactorChanged = e => setFactor(Number(e.target.value));

  const onEffStartDateChanged = e => {
    setEffStartDate(e);
    setErrorMessage('');
  };
  const onEffEndDateChanged = e => {
    setEffEndDate(e);
    setErrorMessage('');
  };

  const canSave =
    [!isNaN(groupLow), !isNaN(groupHigh), !isNaN(factor), startDate].every(
      Boolean
    ) && addRequestStatus === 'idle';

  const onSaveCredibilityClicked = async () => {
    if (canSave) {
      try {
        setAddRequestStatus('pending');

        var credibilityOverlap = credibilityList.filter(overlapCheck, {
          effStartDate: startDate,
          effEndDate: endDate,
          keys: [
            {
              name: 'groupLow',
              value: groupLow,
            },
            {
              name: 'groupHigh',
              value: groupHigh,
            },
          ],
        });
        if (credibilityOverlap.length) {
          console.log(credibilityOverlap);
          setErrorMessage(
            'The record being entered overlaps an existing effective date range for this key value.'
          );
          return false;
        }

        const resultAction = await dispatch(
          addNewCredibility({
            groupLow,
            groupHigh,
            factor,
            modifiedByName: user.profile.name,
            modifiedDate: new Date().toISOString(),
            effStartDate: dateToString(startDate),
            effEndDate: nullableDateToString(endDate),
          })
        );

        unwrapResult(resultAction);

        setGroupLow(0);
        setGroupHigh(0);
        setFactor(0);

        setEffStartDate(new Date());
        setEffEndDate('');
      } catch (err) {
        console.error('Failed to save the credibility factor record: ', err);
      } finally {
        setAddRequestStatus('idle');
      }
    }
  };

  return (
    <Box
      px={{
        base: '4',
        md: '10',
      }}
      pb="16"
      maxWidth="3xl"
      mx="auto"
    >
      <form>
        <Stack spacing="4" divider={<StackDivider />}>
          <FieldGroup title="Add Credibility Record">
            <VStack width="full" spacing="6">
              <FormControl id="groupLow">
                <FormLabel>Group Low:</FormLabel>
                <Input
                  type="number"
                  value={groupLow}
                  onChange={onGroupLowChanged}
                />
              </FormControl>
              <FormControl id="groupHigh">
                <FormLabel>Group High:</FormLabel>
                <Input
                  type="number"
                  value={groupHigh}
                  onChange={onGroupHighChanged}
                />
              </FormControl>
              <FormControl id="factor">
                <FormLabel>Factor:</FormLabel>
                <Input
                  type="number"
                  value={factor}
                  onChange={onFactorChanged}
                />
              </FormControl>

              <FormControl>
                <FormLabel htmlFor="effStartDate">
                  Effective Start Date
                </FormLabel>
                <DatePicker
                  id="effStartDate"
                  selectedDate={startDate}
                  onChange={onEffStartDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective Start Date</FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="effEndDate">Effective End Date</FormLabel>
                <DatePicker
                  id="effEndDate"
                  selectedDate={endDate}
                  onChange={onEffEndDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective End Date</FormHelperText>
              </FormControl>
            </VStack>
          </FieldGroup>

          <Button
            onClick={onSaveCredibilityClicked}
            disabled={!canSave}
            variant="brand-primary"
            size="xs"
          >
            Save Credibility Factor Record
          </Button>
          <ErrorDisplay
            title="The record dates overlap other records!"
            errorMessage={errorMessage}
          />
        </Stack>
      </form>
    </Box>
  );
};

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
  };
}

export const AddCredibilityFormUser =
  connect(mapStateToProps)(AddCredibilityForm);
