import React from 'react';
import {
  Box,
  Button,
  Text,
  Grid,
  Heading,
  SimpleGrid,
  Stack,
} from '@chakra-ui/react';
import BrandLinkButton from '../../components/common/BrandLinkButton';

function OtherFactorsPage() {
  return (
    <Box textAlign="left" fontSize="xl" p={8}>
      <Grid p={3}>
        <Stack>
          <Heading>Other Factors</Heading>
          <Text>
            Here the non-specific group ratings settings can be applied and
            modified.
          </Text>
          <SimpleGrid columns={4} spacing={10}>
            <Button variant="brand-primary">Credibility</Button>
            <Button colorScheme="green">Special Mix</Button>
            <Button colorScheme="green">Pandemic</Button>
            <Button colorScheme="green">Voluntary</Button>
          </SimpleGrid>
        </Stack>
      </Grid>
      <Box mt={12}>
        <BrandLinkButton path="/settings">Back to Settings</BrandLinkButton>
      </Box>
    </Box>
  );
}

export default OtherFactorsPage;
