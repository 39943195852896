import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import axios from 'axios';

const estimatedClaimPlanAdapter = createEntityAdapter();
const apiBaseUrl = window.ENV.api_base_url;

const initialState = estimatedClaimPlanAdapter.getInitialState({
  status: 'idle',
  error: null,
});

export const fetchEstimatedClaimPlan = createAsyncThunk(
  'estimatedClaimPlan/fetchEstimatedClaimPlan',
  async (_, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };

    const response = await axios.get(
      `${apiBaseUrl}/api/EstimatedClaimPlan`,
      config
    );
    return response.data;
  }
);

export const addNewEstimatedClaimPlan = createAsyncThunk(
  'estimatedClaimPlan/addNewEstimatedClaimPlan',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.post(
      `${apiBaseUrl}/api/EstimatedClaimPlan`,
      initialState,
      config
    );
    console.log('adding new estimatedClaimPlan record');
    console.log(response);
    return { ...initialState, id: response.data };
  }
);

export const deleteEstimatedClaimPlan = createAsyncThunk(
  'estimatedClaimPlan/deleteEstimatedClaimPlan',
  async (estimatedClaimPlanId, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.delete(
      `${apiBaseUrl}/api/EstimatedClaimPlan/${estimatedClaimPlanId}`,
      config
    );
    return estimatedClaimPlanId;
  }
);

export const updateEstimatedClaimPlan = createAsyncThunk(
  'estimatedClaimPlan/updateEstimatedClaimPlan',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.put(
      `${apiBaseUrl}/api/EstimatedClaimPlan/${initialState.id}`,
      initialState,
      config
    );
    return { ...initialState, id: response.data };
  }
);

const estimatedClaimPlanSlice = createSlice({
  name: 'estimatedClaimPlan',
  initialState,
  extraReducers: {
    [fetchEstimatedClaimPlan.pending]: state => {
      state.status = 'loading';
    },
    [fetchEstimatedClaimPlan.fulfilled]: (state, action) => {
      // Add any fetched posts to the array
      estimatedClaimPlanAdapter.upsertMany(state, action.payload);
      state.status = 'succeeded';
    },
    [fetchEstimatedClaimPlan.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [addNewEstimatedClaimPlan.fulfilled]: estimatedClaimPlanAdapter.addOne,
    [deleteEstimatedClaimPlan.fulfilled]: estimatedClaimPlanAdapter.removeOne,
    [updateEstimatedClaimPlan.fulfilled]: estimatedClaimPlanAdapter.upsertOne,
  },
});

export default estimatedClaimPlanSlice.reducer;

export const {
  selectAll: selectAllEstimatedClaimPlan,
  selectById: selectEstimatedClaimPlanById,
  selectIds: selectEstimatedClaimPlanIds,
} = estimatedClaimPlanAdapter.getSelectors(state => state.estimatedClaimPlan);
