import React, { useEffect } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalHeader,
  ModalFooter,
  ModalContent,
  ModalCloseButton,
  Heading,
  useDisclosure,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchRollover,
  selectRolloverIds,
  selectRolloverById,
  deleteRollover,
} from './rolloverSlice';
import { unwrapResult } from '@reduxjs/toolkit';

import { NavLink } from 'react-router-dom';
import { FormattedDate } from '../common/FormattedDate';
import { FormattedTimestamp } from '../common/FormattedTimestamp';

const ItemDetails = ({ currentItem }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button onClick={onOpen} size="xs">
        Details
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Rollover: GenMax/Thres/AnnCO - {currentItem.generalMax}/
            {currentItem.threshold}/{currentItem.annualCarryover}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Table size="sm">
              <Tbody>
                <Tr>
                  <Th>General Max</Th>
                  <Th>Threshold</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.generalMax}</Td>
                  <Td>{currentItem.threshold}</Td>
                </Tr>
                <Tr>
                  <Th>Annual Carryover</Th>
                  <Th>Max Carryover</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.annualCarryover}</Td>
                  <Td>{currentItem.maxCarryover}</Td>
                </Tr>
                <Tr>
                  <Th>Max</Th>
                  <Th>Req Services</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.max}</Td>
                  <Td>{currentItem.reqServices}</Td>
                </Tr>
                <Tr>
                  <Th>Factor Amt</Th>
                  <Th> </Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.factorAmt}</Td>
                  <Td></Td>
                </Tr>
                <Tr>
                  <Th>Modified By Name</Th>
                  <Th>Modified Date</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.modifiedByName}</Td>
                  <Td>
                    <FormattedTimestamp
                      dateToFormat={currentItem.modifiedDate}
                    />
                  </Td>
                </Tr>
                <Tr>
                  <Th>Eff Start Date</Th>
                  <Th>Eff End Date</Th>
                </Tr>
                <Tr>
                  <Td>
                    <FormattedDate dateToFormat={currentItem.effStartDate} />
                  </Td>
                  <Td>
                    <FormattedDate dateToFormat={currentItem.effEndDate} />
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="brand-primary-sm"
              mr={3}
              onClick={onClose}
              size="sm"
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

let RolloverTable = ({ rolloverIdList }) => {
  let tableRows = rolloverIdList.map(rolloverId => (
    <Rollover rolloverId={rolloverId} key={rolloverId} />
  ));

  return (
    <Box overflowX="auto">
      <Table size="sm">
        <Thead>
          <Tr>
            <Th></Th>
            <Th>General Max</Th>
            <Th>Threshold</Th>
            <Th>Annual Carryover</Th>
            <Th>Max Carryover</Th>
            <Th>Max</Th>
            <Th>Req Services</Th>
            <Th>Factor Amt</Th>

            <Th>Modified By Name</Th>
            <Th>Modified Date</Th>
            <Th>Eff Start Date</Th>
            <Th>Eff End Date</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>{tableRows}</Tbody>
      </Table>
    </Box>
  );
};

let Rollover = ({ rolloverId }) => {
  const rollover = useSelector(state => selectRolloverById(state, rolloverId));
  //console.log('In the rollover list');

  const dispatch = useDispatch();

  const onDeleteRolloverClicked = async () => {
    try {
      const resultAction = await dispatch(deleteRollover(rollover.id));
      unwrapResult(resultAction);
    } catch (err) {
      console.error('Failed to save the rollover record', err);
    }
  };

  return (
    <Tr>
      <Td>
        <ItemDetails currentItem={rollover} />
      </Td>
      <Td>{rollover.generalMax}</Td>
      <Td>{rollover.threshold}</Td>
      <Td>{rollover.annualCarryover}</Td>
      <Td>{rollover.maxCarryover}</Td>
      <Td>{rollover.max}</Td>
      <Td>{rollover.reqServices}</Td>
      <Td>{rollover.factorAmt}</Td>
      <Td>{rollover.modifiedByName}</Td>
      <Td>
        <FormattedTimestamp dateToFormat={rollover.modifiedDate} />
      </Td>
      <Td>
        <FormattedDate dateToFormat={rollover.effStartDate} />
      </Td>
      <Td>
        <FormattedDate dateToFormat={rollover.effEndDate} />
      </Td>
      <Td>
        <ButtonGroup variant="outline">
          <Button
            colorScheme="gray"
            size="sm"
            as={NavLink}
            to={`/editRollover/${rollover.id}`}
          >
            Edit
          </Button>
          <Button colorScheme="red" size="sm" onClick={onDeleteRolloverClicked}>
            Delete
          </Button>
        </ButtonGroup>
      </Td>
    </Tr>
  );
};

export const RolloverList = () => {
  const dispatch = useDispatch();
  const orderedRolloverIds = useSelector(selectRolloverIds);
  console.log('in the roller list');
  const error = useSelector(state => state.rollover.error);

  const rolloverStatus = useSelector(state => state.rollover.status);
  console.log(rolloverStatus);
  useEffect(() => {
    if (rolloverStatus === 'idle') {
      dispatch(fetchRollover());
    }
  }, [rolloverStatus, dispatch]);

  let content;

  if (rolloverStatus === 'loading') {
    content = <div className="loader">Loading...</div>;
  } else if (rolloverStatus === 'succeeded') {
    content = <RolloverTable rolloverIdList={orderedRolloverIds} />;
  } else if (rolloverStatus === 'failed') {
    content = <div>{error}</div>;
  }

  return (
    <section>
      <h2>
        <b>Rollover Listing</b>
      </h2>
      {content}
    </section>
  );
};
