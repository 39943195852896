import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import axios from 'axios';

const occupationAdapter = createEntityAdapter();
const apiBaseUrl = window.ENV.api_base_url;

const initialState = occupationAdapter.getInitialState({
  status: 'idle',
  error: null,
});

export const fetchOccupation = createAsyncThunk(
  'occupation/fetchOccupation',
  async (_, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };

    const response = await axios.get(`${apiBaseUrl}/api/Occupation`, config);
    return response.data;
  }
);

export const addNewOccupation = createAsyncThunk(
  'occupation/addNewOccupation',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.post(
      `${apiBaseUrl}/api/Occupation`,
      initialState,
      config
    );
    console.log('adding new occupation record');
    console.log(response);
    return { ...initialState, id: response.data };
  }
);

export const deleteOccupation = createAsyncThunk(
  'occupation/deleteOccupation',
  async (occupationId, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.delete(
      `${apiBaseUrl}/api/Occupation/${occupationId}`,
      config
    );
    return occupationId;
  }
);

export const updateOccupation = createAsyncThunk(
  'occupation/updateOccupation',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.put(
      `${apiBaseUrl}/api/Occupation/${initialState.id}`,
      initialState,
      config
    );
    return { ...initialState, id: response.data };
  }
);

const occupationSlice = createSlice({
  name: 'occupation',
  initialState,
  extraReducers: {
    [fetchOccupation.pending]: state => {
      state.status = 'loading';
    },
    [fetchOccupation.fulfilled]: (state, action) => {
      // Add any fetched posts to the array
      occupationAdapter.upsertMany(state, action.payload);
      state.status = 'succeeded';
    },
    [fetchOccupation.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [addNewOccupation.fulfilled]: occupationAdapter.addOne,
    [deleteOccupation.fulfilled]: occupationAdapter.removeOne,
    [updateOccupation.fulfilled]: occupationAdapter.upsertOne,
  },
});

export default occupationSlice.reducer;

export const {
  selectAll: selectAllOccupation,
  selectById: selectOccupationById,
  selectIds: selectOccupationIds,
} = occupationAdapter.getSelectors(state => state.occupation);
