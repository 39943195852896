import React, { useEffect } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalHeader,
  ModalFooter,
  ModalContent,
  ModalCloseButton,
  Heading,
  useDisclosure,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchCredibility,
  selectCredibilityIds,
  selectCredibilityById,
  deleteCredibility,
} from './credibilitySlice';
import { unwrapResult } from '@reduxjs/toolkit';

import { NavLink } from 'react-router-dom';
import { FormattedDate } from '../common/FormattedDate';
import { FormattedTimestamp } from '../common/FormattedTimestamp';

const ItemDetails = ({ currentItem }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button onClick={onOpen} size="xs">
        Details
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Credibility Factors: {currentItem.groupLow} -{' '}
            {currentItem.groupHigh}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Table size="sm">
              <Tbody>
                <Tr>
                  <Th>GroupLow</Th>
                  <Th>GroupHigh</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.groupLow}</Td>
                  <Td>{currentItem.groupHigh}</Td>
                </Tr>
                <Tr>
                  <Th>Factor</Th>
                  <Th> </Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.factor}</Td>
                  <Td> </Td>
                </Tr>

                <Tr>
                  <Th>Modified By Name</Th>
                  <Th>Modified Date</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.modifiedByName}</Td>
                  <Td>
                    <FormattedTimestamp
                      dateToFormat={currentItem.modifiedDate}
                    />
                  </Td>
                </Tr>
                <Tr>
                  <Th>Eff Start Date</Th>
                  <Th>Eff End Date</Th>
                </Tr>
                <Tr>
                  <Td>
                    <FormattedDate dateToFormat={currentItem.effStartDate} />
                  </Td>
                  <Td>
                    <FormattedDate dateToFormat={currentItem.effEndDate} />
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="brand-primary-sm"
              mr={3}
              onClick={onClose}
              size="sm"
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

let CredibilityTable = ({ credibilityIdList }) => {
  let tableRows = credibilityIdList.map(credibilityId => (
    <Credibility credibilityId={credibilityId} key={credibilityId} />
  ));

  return (
    <Box overflowX="auto">
      <Table size="sm">
        <Thead>
          <Tr>
            <Th></Th>
            <Th>GroupLow</Th>
            <Th>GroupHigh</Th>
            <Th>Factor</Th>

            <Th>Modified Date</Th>
            <Th>Eff Start Date</Th>
            <Th>Eff End Date</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>{tableRows}</Tbody>
      </Table>
    </Box>
  );
};

let Credibility = ({ credibilityId }) => {
  const credibility = useSelector(state =>
    selectCredibilityById(state, credibilityId)
  );
  //console.log('In the credibility list');

  const dispatch = useDispatch();

  const onDeleteCredibilityClicked = async () => {
    try {
      const resultAction = await dispatch(deleteCredibility(credibility.id));
      unwrapResult(resultAction);
    } catch (err) {
      console.error('Failed to save the credibility record', err);
    }
  };

  return (
    <Tr>
      <Td>
        <ItemDetails currentItem={credibility} />
      </Td>
      <Td>{credibility.groupLow}</Td>
      <Td>{credibility.groupHigh}</Td>
      <Td>{credibility.factor}</Td>

      <Td>
        <FormattedTimestamp dateToFormat={credibility.modifiedDate} />
      </Td>
      <Td>
        <FormattedDate dateToFormat={credibility.effStartDate} />
      </Td>
      <Td>
        <FormattedDate dateToFormat={credibility.effEndDate} />
      </Td>
      <Td>
        <ButtonGroup variant="outline">
          <Button
            colorScheme="gray"
            size="sm"
            as={NavLink}
            to={`/editCredibility/${credibility.id}`}
          >
            Edit
          </Button>
          <Button
            colorScheme="red"
            size="sm"
            onClick={onDeleteCredibilityClicked}
          >
            Delete
          </Button>
        </ButtonGroup>
      </Td>
    </Tr>
  );
};

export const CredibilityList = () => {
  const dispatch = useDispatch();
  const orderedCredibilityIds = useSelector(selectCredibilityIds);

  const error = useSelector(state => state.credibility.error);

  const credibilityStatus = useSelector(state => state.credibility.status);
  console.log(credibilityStatus);
  useEffect(() => {
    if (credibilityStatus === 'idle') {
      dispatch(fetchCredibility());
    }
  }, [credibilityStatus, dispatch]);

  let content;

  if (credibilityStatus === 'loading') {
    content = <div className="loader">Loading...</div>;
  } else if (credibilityStatus === 'succeeded') {
    content = <CredibilityTable credibilityIdList={orderedCredibilityIds} />;
  } else if (credibilityStatus === 'failed') {
    content = <div>{error}</div>;
  }

  return (
    <section>
      <h2>
        <b>Credibility Listing</b>
      </h2>
      {content}
    </section>
  );
};
