import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
  AccordionIcon,
  Heading,
} from '@chakra-ui/react';
import {
  fetchIncurredClaims,
  selectAllIncurredClaims,
} from './incurredClaimsSlice';
import { AddIncurredClaimsFormUser } from './AddIncurredClaimsForm';
import { IncurredClaimsList } from './IncurredClaimsList';
import BrandLinkButton from '../common/BrandLinkButton';

export const IncurredClaimsPage = () => {
  const dispatch = useDispatch();

  //const orderedIncurredClaimsIds = useSelector(selectIncurredClaimsIds);
  const incurredClaimsList = useSelector(selectAllIncurredClaims);

  const error = useSelector(state => state.incurredClaims.error);
  if (error) {
    console.log('Incurred Claims List Error:' + error.toString());
  }

  const incurredClaimsStatus = useSelector(
    state => state.incurredClaims.status
  );
  const [index, setIndex] = React.useState([]);
  const [clientId, setClientId] = useState(0);
  const [groupId, setGroupId] = useState(0);

  const onClientIdChanged = e => setClientId(Number(e.target.value));
  const onGroupIdChanged = e => setGroupId(e.target.value);

  useEffect(() => {
    if (incurredClaimsStatus === 'idle') {
      dispatch(fetchIncurredClaims());
    }
  }, [incurredClaimsStatus, dispatch]);

  let content;

  if (incurredClaimsStatus === 'loading') {
    content = <div className="loader">Loading...</div>;
  } else if (incurredClaimsStatus === 'succeeded') {
    // console.log(orderedIncurredClaimsIds);
    content = (
      <div>
        <Heading size="xl" mb={8}>
          Initial Renewal Data
        </Heading>
        <p>
          Client Id:{' '}
          <input type="number" value={clientId} onChange={onClientIdChanged} />
          Group Id:{' '}
          <input type="text" value={groupId} onChange={onGroupIdChanged} />
        </p>
        <IncurredClaimsList
          // orderedIncurredClaimsIds={orderedIncurredClaimsIds}
          incurredClaimsList={incurredClaimsList}
        />
      </div>
    );
  } else if (incurredClaimsStatus === 'failed') {
    content = <div>{error}</div>;
  }

  return (
    <>
      {content}

      <Box mt={12}>
        <BrandLinkButton path="/renewals">Back to Renewals</BrandLinkButton>
      </Box>
    </>
  );
};

export default IncurredClaimsPage;
