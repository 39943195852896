import React, { useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  AccordionIcon,
  Box,
  Button,
  CloseButton,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Stack,
  StackDivider,
  VStack,
  Accordion,
} from '@chakra-ui/react';

import { addNewCalendarYearLoad } from './calendarYearLoadSlice';
import { FieldGroup } from '../../components/common/FieldGroup';
import DatePicker from '../../components/common/DatePicker';
import { ErrorDisplay } from '../common/ErrorDisplay';
import { overlapCheck } from '../../services/overlapCheck';
import {
  dateToString,
  nullableDateToString,
} from '../../services/dateConverters';
import { useHistory } from 'react-router-dom';

import * as moment from 'moment';

function formatDate(dt) {
  const m = dt.getMonth() + 1;
  const y = dt.getFullYear();
  const d = dt.getDate();
  return `${y}-${m < 10 ? '0' : ''}${m}-${d < 10 ? '0' : ''}${d}`;
}

export const AddCalendarYearLoadForm = ({ user, calendarYearLoadList }) => {
  const [month, setMonth] = useState(0);
  const [factor, setFactor] = useState(0);

  const [startDate, setEffStartDate] = useState(new Date());
  const [endDate, setEffEndDate] = useState('');
  const [addRequestStatus, setAddRequestStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();

  const onMonthChanged = e => {
    setMonth(e.target.value);
    setErrorMessage('');
  };
  const onFactorChanged = e => setFactor(Number(e.target.value));

  const onEffStartDateChanged = e => {
    setEffStartDate(e);
    setErrorMessage('');
  };
  const onEffEndDateChanged = e => {
    setEffEndDate(e);
    setErrorMessage('');
  };

  const canSave =
    [!isNaN(month), !isNaN(factor), startDate].every(Boolean) &&
    addRequestStatus === 'idle';

  const onSaveCalendarYearLoadClicked = async () => {
    if (canSave) {
      try {
        setAddRequestStatus('pending');

        var calendarYearLoadOverlap = calendarYearLoadList.filter(
          overlapCheck,
          {
            effStartDate: startDate,
            effEndDate: endDate,
            keys: [
              {
                name: 'month',
                value: month,
              },
            ],
          }
        );
        if (calendarYearLoadOverlap.length) {
          console.log('calendarYearLoadOverlap:');
          console.log(calendarYearLoadOverlap);
          setErrorMessage(
            'The record being entered overlaps an existing effective date range for this key value.'
          );
          return false;
        }

        const resultAction = await dispatch(
          addNewCalendarYearLoad({
            month,
            factor,

            modifiedByName: user.profile.name,
            modifiedDate: new Date().toISOString(),
            effStartDate: dateToString(startDate),
            effEndDate: nullableDateToString(endDate),
          })
        );

        unwrapResult(resultAction);

        setMonth(0);
        setFactor(0);
        setEffStartDate(new Date());
        setEffEndDate('');
      } catch (err) {
        console.error(
          'Failed to save the calendar Year Load factor record: ',
          err
        );
      } finally {
        setAddRequestStatus('idle');
      }
    }
  };

  return (
    <Box
      px={{
        base: '4',
        md: '10',
      }}
      pb="16"
      maxWidth="3xl"
      mx="auto"
    >
      <form>
        <Stack spacing="4" divider={<StackDivider />}>
          <FieldGroup title="Add Calendar Year Load Record">
            <VStack width="full" spacing="6">
              <FormControl id="month">
                <FormLabel>Month:</FormLabel>
                <Input type="number" value={month} onChange={onMonthChanged} />
              </FormControl>
              <FormControl id="factor">
                <FormLabel>Factor:</FormLabel>
                <Input
                  type="number"
                  value={factor}
                  onChange={onFactorChanged}
                />
              </FormControl>

              <FormControl>
                <FormLabel htmlFor="effStartDate">
                  Effective Start Date
                </FormLabel>
                <DatePicker
                  id="effStartDate"
                  selectedDate={startDate}
                  onChange={onEffStartDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective Start Date</FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="effEndDate">Effective End Date</FormLabel>
                <DatePicker
                  id="effEndDate"
                  selectedDate={endDate}
                  onChange={onEffEndDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective End Date</FormHelperText>
              </FormControl>
            </VStack>
          </FieldGroup>

          <Button
            onClick={onSaveCalendarYearLoadClicked}
            disabled={!canSave}
            variant="brand-primary"
            size="xs"
          >
            Save Calendar Year Load Factor Record
          </Button>
          <ErrorDisplay
            title="The record dates overlap other records!"
            errorMessage={errorMessage}
          />
        </Stack>
      </form>
    </Box>
  );
};

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
  };
}

export const AddCalendarYearLoadFormUser = connect(mapStateToProps)(
  AddCalendarYearLoadForm
);
