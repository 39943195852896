import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
  AccordionIcon,
  Heading,
} from '@chakra-ui/react';
import { fetchStates, selectStatesIds, selectAllStates } from './statesSlice';
import { AddStatesFormUser } from './AddStatesForm';
import { StatList } from './StatesList';
import BrandLinkButton from '../../components/common/BrandLinkButton';

export const StatesPage = () => {
  const dispatch = useDispatch();

  const orderedStatesIds = useSelector(selectStatesIds);
  const statesList = useSelector(selectAllStates);

  const error = useSelector(state => state.states.error);
  if (error) {
    console.log('State List Error:' + error.toString());
  }

  const statesStatus = useSelector(state => state.states.status);
  const [index, setIndex] = React.useState([]);

  useEffect(() => {
    if (statesStatus === 'idle') {
      dispatch(fetchStates());
    }
  }, [statesStatus, dispatch]);

  function closeAccordion() {
    setIndex([]);
  }

  let content;

  if (statesStatus === 'loading') {
    content = <div className="loader">Loading...</div>;
  } else if (statesStatus === 'succeeded') {
    console.log(orderedStatesIds);
    content = (
      <div>
        <Heading size="xl" mb={8}>
          State Factors
        </Heading>
        <Accordion
          allowMultiple
          allowToggle="true"
          mb={8}
          index={index}
          onChange={setIndex}
        >
          <AccordionItem>
            <Box>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Text variant="brand-green-link" fontSize="lg">
                    Add New Factor
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </Box>
            <AccordionPanel pb={4}>
              <AddStatesFormUser
                statesList={statesList}
                closeAccordion={closeAccordion}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <StatList orderedStatesIds={orderedStatesIds} />
      </div>
    );
  } else if (statesStatus === 'failed') {
    content = <div>{error}</div>;
  }

  return (
    <>
      {content}
      <Box mt={12}>
        <BrandLinkButton path="/settings">Back to Settings</BrandLinkButton>
      </Box>
    </>
  );
};

export default StatesPage;
