import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
  AccordionIcon,
  Heading,
} from '@chakra-ui/react';
import {
  fetchBaseRateAmount,
  selectBaseRateAmountIds,
  selectAllBaseRateAmount,
} from './baseRateAmountSlice';
import { AddBaseRateAmountFormUser } from './AddBaseRateAmountForm';
import { BaseRateAmountList } from './BaseRateAmountList';
import BrandLinkButton from '../../components/common/BrandLinkButton';

export const BaseRateAmountPage = () => {
  const dispatch = useDispatch();

  const orderedBaseRateAmountIds = useSelector(selectBaseRateAmountIds);
  const baseRateAmountList = useSelector(selectAllBaseRateAmount);

  const error = useSelector(state => state.baseRateAmount.error);
  if (error) {
    console.log('BaseRateAmount List Error:' + error.toString());
  }

  const baseRateAmountStatus = useSelector(
    state => state.baseRateAmount.status
  );
  const [index, setIndex] = React.useState([]);

  useEffect(() => {
    if (baseRateAmountStatus === 'idle') {
      dispatch(fetchBaseRateAmount());
    }
  }, [baseRateAmountStatus, dispatch]);

  function closeAccordion() {
    setIndex([]);
  }

  let content;

  if (baseRateAmountStatus === 'loading') {
    content = <div className="loader">Loading...</div>;
  } else if (baseRateAmountStatus === 'succeeded') {
    console.log(orderedBaseRateAmountIds);
    content = (
      <div>
        <Heading size="xl" mb={8}>
          Base Rate Amount Factors
        </Heading>
        <Accordion
          allowMultiple
          allowToggle="true"
          mb={8}
          index={index}
          onChange={setIndex}
        >
          <AccordionItem>
            <Box>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Text variant="brand-green-link" fontSize="lg">
                    Add New Factor
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </Box>
            <AccordionPanel pb={4}>
              <AddBaseRateAmountFormUser
                baseRateAmountList={baseRateAmountList}
                closeAccordion={closeAccordion}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <BaseRateAmountList
          orderedBaseRateAmountIds={orderedBaseRateAmountIds}
        />
      </div>
    );
  } else if (baseRateAmountStatus === 'failed') {
    content = <div>{error}</div>;
  }

  return (
    <>
      {content}
      <Box mt={12}>
        <BrandLinkButton path="/settings">Back to Settings</BrandLinkButton>
      </Box>
    </>
  );
};

export default BaseRateAmountPage;
