import React, { useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Button,
  CloseButton,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Stack,
  StackDivider,
  VStack,
} from '@chakra-ui/react';

//import { addNewIncurredClaims } from './incurredClaimsSlice';
import {
  fetchIncurredClaims,
  selectAllIncurredClaims,
} from './incurredClaimsSlice';
import { FieldGroup } from '../../components/common/FieldGroup';
import DatePicker from '../../components/common/DatePicker';
import { ErrorDisplay } from '../common/ErrorDisplay';
import { overlapCheck } from '../../services/overlapCheck';
import {
  dateToString,
  nullableDateToString,
} from '../../services/dateConverters';

export const AddGroupKeysForm = ({ user, incurredClaimsList }) => {
  const [clientId, setClientId] = useState(0);
  const [groupId, setGroupId] = useState(0);

  const [addRequestStatus, setAddRequestStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();

  const onClientIdChanged = e => setClientId(Number(e.target.value));
  const onGroupIdChanged = e => {
    setGroupId(e.target.value);
    setErrorMessage('');
  };

  const canSave =
    [!isNaN(clientId), !isNaN(groupId)].every(Boolean) &&
    addRequestStatus === 'idle';

  const onSaveGroupKeysClicked = async () => {
    if (canSave) {
      try {
        setAddRequestStatus('pending');

        const resultAction = await dispatch(
          fetchIncurredClaims({
            clientId,
            groupId,
          })
        );

        unwrapResult(resultAction);

        setClientId(0);
        setGroupId(0);
      } catch (err) {
        console.error(
          'Failed to save the baseRatePercent factor record: ',
          err
        );
      } finally {
        setAddRequestStatus('idle');
      }
    }
  };

  return (
    <Box
      px={{
        base: '4',
        md: '10',
      }}
      pb="16"
      maxWidth="3xl"
      mx="auto"
    >
      <form>
        <Stack spacing="4" divider={<StackDivider />}>
          <FieldGroup title="Add group Record">
            <VStack width="full" spacing="6">
              <FormControl id="clientId">
                <FormLabel>Client Id:</FormLabel>
                <Input
                  type="number"
                  value={clientId}
                  onChange={onClientIdChanged}
                />
              </FormControl>
              <FormControl id="groupId">
                <FormLabel>Group Id:</FormLabel>
                <Input
                  type="text"
                  value={groupId}
                  onChange={onGroupIdChanged}
                />
              </FormControl>
            </VStack>
          </FieldGroup>

          <Button
            onClick={onSaveGroupKeysClicked}
            disabled={!canSave}
            variant="brand-primary"
            size="xs"
          >
            Save Group Keys Data
          </Button>
          <ErrorDisplay
            title="The record dates overlap other records!"
            errorMessage={errorMessage}
          />
        </Stack>
      </form>
    </Box>
  );
};

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
  };
}

export const AddGroupKeysFormUser = connect(mapStateToProps)(AddGroupKeysForm);
