import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
  AccordionIcon,
  Heading,
} from '@chakra-ui/react';
import {
  fetchBasicGroupInfo,
  selectAllBasicGroupInfo,
  selectBasicGroupInfoById,
  selectBasicGroupInfoIds,
} from './basicGroupInfoSlice';
import { AddBasicGroupInfoFormUser } from './AddBasicGroupInfoForm';
import { BasicGroupInfoList } from './BasicGroupInfoList';
import BrandLinkButton from '../common/BrandLinkButton';

export const BasicGroupInfoPage = () => {
  const dispatch = useDispatch();

  const orderedBasicGroupInfoIds = useSelector(selectBasicGroupInfoIds);
  const basicGroupInfoList = useSelector(selectAllBasicGroupInfo);

  const error = useSelector(state => state.basicGroupInfo.error);
  if (error) {
    console.log('basicGroupInfo List Error:' + error.toString());
  }

  const basicGroupInfoStatus = useSelector(
    state => state.basicGroupInfo.status
  );
  const [index, setIndex] = React.useState([]);

  useEffect(() => {
    if (basicGroupInfoStatus === 'idle') {
      dispatch(fetchBasicGroupInfo());
    }
  }, [basicGroupInfoStatus, dispatch]);

  function closeAccordion() {
    setIndex([]);
  }

  let content;

  if (basicGroupInfoStatus === 'loading') {
    content = <div className="loader">Loading...</div>;
  } else if (basicGroupInfoStatus === 'succeeded') {
    console.log(orderedBasicGroupInfoIds);
    content = (
      <div>
        <Heading size="xl" mb={8}>
          Basic Group Info Data for Renewals
        </Heading>

        <BasicGroupInfoList
          // orderedBasicGroupInfoIds={orderedBasicGroupInfoIds}
          basicGroupInfoList={basicGroupInfoList}
        />
      </div>
    );
  } else if (basicGroupInfoStatus === 'failed') {
    content = <div>{error}</div>;
  }

  return (
    <>
      {content}
      <Box mt={12}>
        <BrandLinkButton path="/renewals">Back to Renewals</BrandLinkButton>
      </Box>
    </>
  );
};

export default BasicGroupInfoPage;
