import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
  AccordionIcon,
  Heading,
} from '@chakra-ui/react';
import {
  fetchUsaTrend,
  selectUsaTrendIds,
  selectAllUsaTrend,
} from './usaTrendSlice';
import { AddUsaTrendFormUser } from './AddUsaTrendForm';
import { UsaTrendList } from './UsaTrendList';
import BrandLinkButton from '../../components/common/BrandLinkButton';

export const UsaTrendPage = () => {
  const dispatch = useDispatch();

  const orderedUsaTrendIds = useSelector(selectUsaTrendIds);
  const usaTrendList = useSelector(selectAllUsaTrend);

  const error = useSelector(state => state.usaTrend.error);
  if (error) {
    console.log('UsaTrend List Error:' + error.toString());
  }

  const usaTrendStatus = useSelector(state => state.usaTrend.status);
  const [index, setIndex] = React.useState([]);

  useEffect(() => {
    if (usaTrendStatus === 'idle') {
      dispatch(fetchUsaTrend());
    }
  }, [usaTrendStatus, dispatch]);

  function closeAccordion() {
    setIndex([]);
  }

  let content;

  if (usaTrendStatus === 'loading') {
    content = <div className="loader">Loading...</div>;
  } else if (usaTrendStatus === 'succeeded') {
    console.log(orderedUsaTrendIds);
    content = (
      <div>
        <Heading size="xl" mb={8}>
          UsaTrend Factors
        </Heading>
        <Accordion
          allowMultiple
          allowToggle="true"
          mb={8}
          index={index}
          onChange={setIndex}
        >
          <AccordionItem>
            <Box>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Text variant="brand-green-link" fontSize="lg">
                    Add New Factor
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </Box>
            <AccordionPanel pb={4}>
              <AddUsaTrendFormUser
                usaTrendList={usaTrendList}
                closeAccordion={closeAccordion}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <UsaTrendList orderedUsaTrendIds={orderedUsaTrendIds} />
      </div>
    );
  } else if (usaTrendStatus === 'failed') {
    content = <div>{error}</div>;
  }

  return (
    <>
      {content}
      <Box mt={12}>
        <BrandLinkButton path="/settings">Back to Settings</BrandLinkButton>
      </Box>
    </>
  );
};

export default UsaTrendPage;
