import React, { useState } from 'react';
import { useDispatch, connect, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Button,
  CloseButton,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Stack,
  StackDivider,
  VStack,
} from '@chakra-ui/react';

import {
  addNewIncurredClaims,
  selectIncurredClaimsById,
} from './incurredClaimsSlice';
import { FieldGroup } from '../../components/common/FieldGroup';
import DatePicker from '../../components/common/DatePicker';
import { ErrorDisplay } from '../common/ErrorDisplay';
import { overlapCheck } from '../../services/overlapCheck';
import {
  dateToString,
  nullableDateToString,
} from '../../services/dateConverters';

function IncurredClaimsList({ groupId }) {
  const incurredClaims = useSelector(state =>
    selectIncurredClaimsById(state, groupId)
  );
}

export const AddIncurredClaimsForm = ({ user, incurredClaimsList }) => {
  const [allowAsRider, setAllowAsRider] = useState(0);
  const [benefit, setBenefit] = useState(' ');
  const [proceduresTimeLimit, setProceduresTimeLimit] = useState(' ');
  const [employee, setEmployee] = useState(0);
  const [spouse, setSpouse] = useState(0);
  const [child, setChild] = useState(0);

  const [startDate, setEffStartDate] = useState(new Date());
  const [endDate, setEffEndDate] = useState('');
  const [addRequestStatus, setAddRequestStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();

  const onAllowAsRiderChanged = e => setAllowAsRider(Number(e.target.value));
  const onBenefitChanged = e => {
    setBenefit(e.target.value);
    setErrorMessage('');
  };
  const onProceduresTimeLimitChanged = e =>
    setProceduresTimeLimit(e.target.value);
  const onEmployeeChanged = e => setEmployee(Number(e.target.value));
  const onSpouseChanged = e => setSpouse(Number(e.target.value));
  const onChildChanged = e => setChild(Number(e.target.value));

  const onEffStartDateChanged = e => {
    setEffStartDate(e);
    setErrorMessage('');
  };
  const onEffEndDateChanged = e => {
    setEffEndDate(e);
    setErrorMessage('');
  };

  const canSave =
    [
      !isNaN(allowAsRider),
      benefit,
      proceduresTimeLimit,
      !isNaN(employee),
      !isNaN(spouse),
      !isNaN(child),

      startDate,
    ].every(Boolean) && addRequestStatus === 'idle';

  const onSaveIncurredClaimsClicked = async () => {
    if (canSave) {
      try {
        setAddRequestStatus('pending');

        const resultAction = await dispatch(
          addNewIncurredClaims({
            allowAsRider,
            benefit,
            proceduresTimeLimit,
            employee,
            spouse,
            child,
            modifiedByName: user.profile.name,
            modifiedDate: new Date().toISOString(),
            effStartDate: dateToString(startDate),
            effEndDate: nullableDateToString(endDate),
          })
        );

        unwrapResult(resultAction);

        setAllowAsRider(0);
        setBenefit('');
        setProceduresTimeLimit('');
        setEmployee(0);
        setSpouse(0);
        setChild(0);
        setEffStartDate(new Date());
        setEffEndDate('');
      } catch (err) {
        console.error(
          'Failed to save the baseRatePercent factor record: ',
          err
        );
      } finally {
        setAddRequestStatus('idle');
      }
    }
  };

  return (
    <Box
      px={{
        base: '4',
        md: '10',
      }}
      pb="16"
      maxWidth="3xl"
      mx="auto"
    >
      <form>
        <Stack spacing="4" divider={<StackDivider />}>
          <FieldGroup title="Add BaseRatePercent Record">
            <VStack width="full" spacing="6">
              <FormControl id="allowAsRider">
                <FormLabel>AllowAsRider:</FormLabel>
                <Input
                  type="number"
                  value={allowAsRider}
                  onChange={onAllowAsRiderChanged}
                />
              </FormControl>
              <FormControl id="benefit">
                <FormLabel>Benefit:</FormLabel>
                <Input
                  type="text"
                  value={benefit}
                  onChange={onBenefitChanged}
                />
              </FormControl>
              <FormControl id="proceduresTimeLimit">
                <FormLabel>ProceduresTimeLimit:</FormLabel>
                <Input
                  type="text"
                  value={proceduresTimeLimit}
                  onChange={onProceduresTimeLimitChanged}
                />
              </FormControl>
              <FormControl id="employee">
                <FormLabel>Employee:</FormLabel>
                <Input
                  type="number"
                  value={employee}
                  onChange={onEmployeeChanged}
                />
              </FormControl>
              <FormControl id="spouse">
                <FormLabel>Spouse:</FormLabel>
                <Input
                  type="number"
                  value={spouse}
                  onChange={onSpouseChanged}
                />
              </FormControl>

              <FormControl id="child">
                <FormLabel>Child:</FormLabel>
                <Input type="number" value={child} onChange={onChildChanged} />
              </FormControl>

              <FormControl>
                <FormLabel htmlFor="effStartDate">
                  Effective Start Date
                </FormLabel>
                <DatePicker
                  id="effStartDate"
                  selectedDate={startDate}
                  onChange={onEffStartDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective Start Date</FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="effEndDate">Effective End Date</FormLabel>
                <DatePicker
                  id="effEndDate"
                  selectedDate={endDate}
                  onChange={onEffEndDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective End Date</FormHelperText>
              </FormControl>
            </VStack>
          </FieldGroup>

          <Button
            onClick={onSaveIncurredClaimsClicked}
            disabled={!canSave}
            variant="brand-primary"
            size="xs"
          >
            Save basicGroupInfo Record
          </Button>
          <ErrorDisplay
            title="The record dates overlap other records!"
            errorMessage={errorMessage}
          />
        </Stack>
      </form>
    </Box>
  );
};

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
  };
}

export const AddIncurredClaimsFormUser = connect(mapStateToProps)(
  AddIncurredClaimsForm
);
