import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import axios from 'axios';

const copayAdapter = createEntityAdapter();
const apiBaseUrl = window.ENV.api_base_url;

const initialState = copayAdapter.getInitialState({
  status: 'idle',
  error: null,
});

export const fetchCopay = createAsyncThunk(
  'copay/fetchCopay',
  async (_, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };

    const response = await axios.get(`${apiBaseUrl}/api/Copay`, config);
    return response.data;
  }
);

export const addNewCopay = createAsyncThunk(
  'copay/addNewCopay',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.post(
      `${apiBaseUrl}/api/Copay`,
      initialState,
      config
    );

    return { ...initialState, id: response.data };
  }
);

export const deleteCopay = createAsyncThunk(
  'copay/deleteCopay',
  async (copayId, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.delete(
      `${apiBaseUrl}/api/Copay/${copayId}`,
      config
    );
    return copayId;
  }
);

export const updateCopay = createAsyncThunk(
  'copay/updateCopay',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.put(
      `${apiBaseUrl}/api/Copay/${initialState.id}`,
      initialState,
      config
    );
    return { ...initialState, id: response.data };
  }
);

const copaySlice = createSlice({
  name: 'copay',
  initialState,
  extraReducers: {
    [fetchCopay.pending]: state => {
      state.status = 'loading';
    },
    [fetchCopay.fulfilled]: (state, action) => {
      // Add any fetched posts to the array
      copayAdapter.upsertMany(state, action.payload);
      state.status = 'succeeded';
    },
    [fetchCopay.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [addNewCopay.fulfilled]: copayAdapter.addOne,
    [deleteCopay.fulfilled]: copayAdapter.removeOne,
    [updateCopay.fulfilled]: copayAdapter.upsertOne,
  },
});

export default copaySlice.reducer;

export const {
  selectAll: selectAllCopay,
  selectById: selectCopayById,
  selectIds: selectCopayIds,
} = copayAdapter.getSelectors(state => state.copay);
