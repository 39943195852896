import React, { useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Button,
  CloseButton,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Stack,
  StackDivider,
  VStack,
} from '@chakra-ui/react';

import { addNewArea } from './areaSlice';
import { FieldGroup } from '../../components/common/FieldGroup';
import DatePicker from '../../components/common/DatePicker';
import { ErrorDisplay } from '../common/ErrorDisplay';
import { overlapCheck } from '../../services/overlapCheck';
import {
  dateToString,
  nullableDateToString,
} from '../../services/dateConverters';

export const AddAreaForm = ({ user, areaList }) => {
  const [areaNumber, setAreaNumber] = useState(0);
  const [trd, setTrd] = useState(0);
  const [ppo, setPpo] = useState(0);
  const [nationalOon, setNationalOon] = useState(0);
  const [standardOon, setStandardOon] = useState(0);

  const [startDate, setEffStartDate] = useState(new Date());
  const [endDate, setEffEndDate] = useState('');
  const [addRequestStatus, setAddRequestStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();

  const onAreaNumberChanged = e => {
    setAreaNumber(Number(e.target.value));
    setErrorMessage('');
  };
  const onTrdChanged = e => setTrd(Number(e.target.value));
  const onPpoChanged = e => setPpo(Number(e.target.value));
  const onNationalOonChanged = e => setNationalOon(Number(e.target.value));
  const onStandardOonChanged = e => setStandardOon(Number(e.target.value));

  const onEffStartDateChanged = e => {
    setEffStartDate(e);
    setErrorMessage('');
  };
  const onEffEndDateChanged = e => {
    setEffEndDate(e);
    setErrorMessage('');
  };

  const canSave =
    [
      !isNaN(areaNumber),
      !isNaN(trd),
      !isNaN(ppo),
      !isNaN(nationalOon),
      !isNaN(standardOon),

      startDate,
    ].every(Boolean) && addRequestStatus === 'idle';

  const onSaveAreaClicked = async () => {
    if (canSave) {
      try {
        setAddRequestStatus('pending');

        var areaOverlap = areaList.filter(overlapCheck, {
          effStartDate: startDate,
          effEndDate: endDate,
          keys: [
            {
              name: 'areaNumber',
              value: areaNumber,
            },
          ],
        });
        if (areaOverlap.length) {
          console.log('areaOverlap:');
          console.log(areaOverlap);
          setErrorMessage(
            'The record being entered overlaps an existing effective date range for this key value.'
          );
          return false;
        }

        const resultAction = await dispatch(
          addNewArea({
            areaNumber,
            trd,
            ppo,
            nationalOon,
            standardOon,
            modifiedByName: user.profile.name,
            modifiedDate: new Date().toISOString(),
            effStartDate: dateToString(startDate),
            effEndDate: nullableDateToString(endDate),
          })
        );

        unwrapResult(resultAction);

        setAreaNumber(0);
        setTrd(0);
        setPpo(0);
        setNationalOon(0);
        setStandardOon(0);

        setEffStartDate(new Date());
        setEffEndDate('');
      } catch (err) {
        console.error('Failed to save the area factor record: ', err);
      } finally {
        setAddRequestStatus('idle');
      }
    }
  };

  return (
    <Box
      px={{
        base: '4',
        md: '10',
      }}
      pb="16"
      maxWidth="3xl"
      mx="auto"
    >
      <form>
        <Stack spacing="4" divider={<StackDivider />}>
          <FieldGroup title="Add Area Record">
            <VStack width="full" spacing="6">
              <FormControl id="areaNumber">
                <FormLabel>AreaNumber:</FormLabel>
                <Input
                  type="number"
                  value={areaNumber}
                  onChange={onAreaNumberChanged}
                />
              </FormControl>
              <FormControl id="trd">
                <FormLabel>Trd:</FormLabel>
                <Input type="number" value={trd} onChange={onTrdChanged} />
              </FormControl>
              <FormControl id="ppo">
                <FormLabel>Ppo:</FormLabel>
                <Input type="number" value={ppo} onChange={onPpoChanged} />
              </FormControl>
              <FormControl id="nationalOon">
                <FormLabel>NationalOon:</FormLabel>
                <Input
                  type="number"
                  value={nationalOon}
                  onChange={onNationalOonChanged}
                />
              </FormControl>

              <FormControl id="standardOon">
                <FormLabel>StandardOon:</FormLabel>
                <Input
                  type="number"
                  value={standardOon}
                  onChange={onStandardOonChanged}
                />
              </FormControl>

              <FormControl>
                <FormLabel htmlFor="effStartDate">
                  Effective Start Date
                </FormLabel>
                <DatePicker
                  id="effStartDate"
                  selectedDate={startDate}
                  onChange={onEffStartDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective Start Date</FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="effEndDate">Effective End Date</FormLabel>
                <DatePicker
                  id="effEndDate"
                  selectedDate={endDate}
                  onChange={onEffEndDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective End Date</FormHelperText>
              </FormControl>
            </VStack>
          </FieldGroup>

          <Button
            onClick={onSaveAreaClicked}
            disabled={!canSave}
            variant="brand-primary"
            size="xs"
          >
            Save Area Factor Record
          </Button>
          <ErrorDisplay
            title="The record dates overlap other records!"
            errorMessage={errorMessage}
          />
        </Stack>
      </form>
    </Box>
  );
};

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
  };
}

export const AddAreaFormUser = connect(mapStateToProps)(AddAreaForm);
