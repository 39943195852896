import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Select,
  Stack,
  StackDivider,
  VStack,
} from '@chakra-ui/react';

import {
  fetchBaseRatePercent,
  updateBaseRatePercent,
  selectBaseRatePercentById,
  selectAllBaseRatePercent,
} from './baseRatePercentSlice';
import { FieldGroup } from '../../components/common/FieldGroup';
import DatePicker from '../../components/common/DatePicker';
import { ErrorDisplay } from '../common/ErrorDisplay';
import { overlapCheck } from '../../services/overlapCheck';
import {
  dateToString,
  nullableDateToString,
  stringToDateFormat,
} from '../../services/dateConverters';

export const EditBaseRatePercentForm = ({ match, user }) => {
  const { baseRatePercentId } = match.params;
  const baseRatePercent = useSelector(state =>
    selectBaseRatePercentById(state, baseRatePercentId)
  );
  const baseRatePercentList = useSelector(selectAllBaseRatePercent);

  const stdt = stringToDateFormat(baseRatePercent.effStartDate);
  console.log(stdt);
  const endt = stringToDateFormat(baseRatePercent.effEndDate);
  console.log(endt);

  const [allowAsRider, setAllowAsRider] = useState(
    baseRatePercent.allowAsRider
  );
  const [benefit, setBenefit] = useState(baseRatePercent.benefit);
  const [proceduresTimeLimit, setProceduresTimeLimit] = useState(
    baseRatePercent.proceduresTimeLimit
  );

  const [employee, setEmployee] = useState(baseRatePercent.employee);
  const [spouse, setSpouse] = useState(baseRatePercent.spouse);
  const [child, setChild] = useState(baseRatePercent.child);

  const [startDate, setEffStartDate] = useState(stdt);

  const [endDate, setEffEndDate] = useState(endt);
  const [updateRequestStatus, setUpdateRequestStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();
  const history = useHistory();

  const onAllowAsRiderChanged = e => setAllowAsRider(e.target.value);
  const onBenefitChanged = e => {
    setBenefit(e.target.value);
    setErrorMessage('');
  };
  const onProceduresTimeLimitChanged = e =>
    setProceduresTimeLimit(e.target.value);
  const onEmployeeChanged = e => setEmployee(Number(e.target.value));
  const onSpouseChanged = e => setSpouse(Number(e.target.value));
  const onChildChanged = e => setChild(Number(e.target.value));

  const onEffStartDateChanged = e => {
    setEffStartDate(e);
    setErrorMessage('');
  };
  const onEffEndDateChanged = e => {
    setEffEndDate(e);
    setErrorMessage('');
  };

  const canSave =
    [
      !isNaN(allowAsRider),
      benefit,
      proceduresTimeLimit,
      !isNaN(employee),
      !isNaN(spouse),
      !isNaN(child),

      startDate,
    ].every(Boolean) && updateRequestStatus === 'idle';

  const onSaveBaseRatePercentClicked = async () => {
    if (canSave) {
      try {
        setUpdateRequestStatus('pending');

        var baseRatePercentOverlap = baseRatePercentList.filter(overlapCheck, {
          id: baseRatePercent.id,
          effStartDate: startDate,
          effEndDate: endDate,
          keys: [
            {
              name: 'benefit',
              value: benefit,
            },
            {
              name: 'proceduresTimeLimit',
              value: proceduresTimeLimit,
            },
          ],
        });
        if (baseRatePercentOverlap.length) {
          console.log(baseRatePercentOverlap);
          setErrorMessage(
            'The record being entered overlaps an existing effective date range for this key value.'
          );
          return false;
        }

        const resultAction = await dispatch(
          updateBaseRatePercent({
            id: Number(baseRatePercentId),
            allowAsRider,
            benefit,
            proceduresTimeLimit,
            employee,
            spouse,
            child,
            modifiedByName: user.profile.name,
            modifiedDate: new Date().toISOString(),
            effStartDate: dateToString(startDate),
            effEndDate: nullableDateToString(endDate),
          })
        );
        console.log(resultAction);
        setUpdateRequestStatus('idle');
        history.push(`/baseRatePercent`);
      } catch (err) {
        setUpdateRequestStatus('idle');
        console.error('Failed to save the baseRatePercent factor: ', err);
      }
    }
  };
  return (
    <Box
      px={{
        base: '4',
        md: '10',
      }}
      pb="16"
      maxWidth="3xl"
      mx="auto"
    >
      <form>
        <Stack spacing="4" divider={<StackDivider />}>
          <FieldGroup title="Edit Administration Factors">
            <VStack width="full" spacing="6">
              <FormControl id="allowAsRider">
                <FormLabel>AllowAsRider:</FormLabel>
                <Input
                  type="number"
                  value={allowAsRider}
                  onChange={onAllowAsRiderChanged}
                />
              </FormControl>
              <FormControl id="benefit">
                <FormLabel>Benefit:</FormLabel>
                <Input
                  type="text"
                  value={benefit}
                  onChange={onBenefitChanged}
                />
              </FormControl>
              <FormControl id="proceduresTimeLimit">
                <FormLabel>ProceduresTimeLimit:</FormLabel>
                <Input
                  type="text"
                  value={proceduresTimeLimit}
                  onChange={onProceduresTimeLimitChanged}
                />
              </FormControl>
              <FormControl id="employee">
                <FormLabel>Employee:</FormLabel>
                <Input
                  type="number"
                  value={employee}
                  onChange={onEmployeeChanged}
                />
              </FormControl>
              <FormControl id="spouse">
                <FormLabel>Spouse:</FormLabel>
                <Input
                  type="number"
                  value={spouse}
                  onChange={onSpouseChanged}
                />
              </FormControl>

              <FormControl id="child">
                <FormLabel>Child:</FormLabel>
                <Input type="number" value={child} onChange={onChildChanged} />
              </FormControl>

              <FormControl id="effStartDate">
                <FormLabel>Effective Start Date:</FormLabel>
                <DatePicker
                  id="effStartDate"
                  selectedDate={startDate}
                  onChange={onEffStartDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective Start Date</FormHelperText>
              </FormControl>
              <FormControl id="effEndDate">
                <FormLabel>Effective End Date:</FormLabel>
                <DatePicker
                  id="effEndDate"
                  selectedDate={endDate}
                  onChange={onEffEndDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective End Date</FormHelperText>
              </FormControl>
            </VStack>
          </FieldGroup>

          <Button
            onClick={onSaveBaseRatePercentClicked}
            disabled={!canSave}
            variant="brand-primary"
            size="xs"
          >
            Save BaseRatePercent Factor
          </Button>
          <ErrorDisplay
            title="The record dates overlap other records!"
            errorMessage={errorMessage}
          />
        </Stack>
      </form>
    </Box>
  );
};

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
  };
}

export const EditBaseRatePercentFormUser = connect(mapStateToProps)(
  EditBaseRatePercentForm
);
