import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import axios from 'axios';

const constantsAdminAdapter = createEntityAdapter();
const apiBaseUrl = window.ENV.api_base_url;

const initialState = constantsAdminAdapter.getInitialState({
  status: 'idle',
  error: null,
});

export const fetchConstantsAdmin = createAsyncThunk(
  'constantsAdmin/fetchConstantsAdmin',
  async (_, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };

    const response = await axios.get(
      `${apiBaseUrl}/api/ConstantsAdmin`,
      config
    );
    return response.data;
  }
);

export const addNewConstantsAdmin = createAsyncThunk(
  'constantsAdmin/addNewConstantsAdmin',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.post(
      `${apiBaseUrl}/api/ConstantsAdmin`,
      initialState,
      config
    );
    console.log('adding new constantsAdmin record');
    console.log(response);
    return { ...initialState, id: response.data };
  }
);

export const deleteConstantsAdmin = createAsyncThunk(
  'constantsAdmin/deleteConstantsAdmin',
  async (constantsAdminId, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.delete(
      `${apiBaseUrl}/api/ConstantsAdmin/${constantsAdminId}`,
      config
    );
    return constantsAdminId;
  }
);

export const updateConstantsAdmin = createAsyncThunk(
  'constantsAdmin/updateConstantsAdmin',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.put(
      `${apiBaseUrl}/api/ConstantsAdmin/${initialState.id}`,
      initialState,
      config
    );
    return { ...initialState, id: response.data };
  }
);

const constantsAdminSlice = createSlice({
  name: 'constantsAdmin',
  initialState,
  extraReducers: {
    [fetchConstantsAdmin.pending]: state => {
      state.status = 'loading';
    },
    [fetchConstantsAdmin.fulfilled]: (state, action) => {
      // Add any fetched posts to the array
      constantsAdminAdapter.upsertMany(state, action.payload);
      state.status = 'succeeded';
    },
    [fetchConstantsAdmin.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [addNewConstantsAdmin.fulfilled]: constantsAdminAdapter.addOne,
    [deleteConstantsAdmin.fulfilled]: constantsAdminAdapter.removeOne,
    [updateConstantsAdmin.fulfilled]: constantsAdminAdapter.upsertOne,
  },
});

export default constantsAdminSlice.reducer;

export const {
  selectAll: selectAllConstantsAdmin,
  selectById: selectConstantsAdminById,
  selectIds: selectConstantsAdminIds,
} = constantsAdminAdapter.getSelectors(state => state.constantsAdmin);
