import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Select,
  Stack,
  StackDivider,
  VStack,
} from '@chakra-ui/react';

import {
  fetchIdcardCharge,
  updateIdcardCharge,
  selectIdcardChargeById,
  selectAllIdcardCharge,
} from './idcardChargeSlice';
import { FieldGroup } from '../../components/common/FieldGroup';
/*import { endOfTime, newEffStartDate, newEffEndDate } from './WorkFile1';*/
import DatePicker from '../../components/common/DatePicker';
import { ErrorDisplay } from '../common/ErrorDisplay';
import { overlapCheck } from '../../services/overlapCheck';
import {
  dateToString,
  nullableDateToString,
  stringToDateFormat,
} from '../../services/dateConverters';

export const EditIdcardChargeForm = ({ match, user }) => {
  const { idcardChargeId } = match.params;
  const idcard = useSelector(state =>
    selectIdcardChargeById(state, idcardChargeId)
  );
  const idcardChargeList = useSelector(selectAllIdcardCharge);

  const stdt = stringToDateFormat(idcard.effStartDate);
  console.log(stdt);
  const endt = stringToDateFormat(idcard.effEndDate);
  console.log(endt);

  const [idcardDesc, setIdcardDesc] = useState(idcard.idcardDesc);
  const [costNewGroup, setCostNewGroup] = useState(idcard.costNewGroup);
  const [costExisting, setCostExisting] = useState(idcard.costExisting);

  /*const [effStartDate, setEffStartDate] = useState(roller.effStartDate);*/
  const [startDate, setEffStartDate] = useState(stdt);
  /* const [effEndDate, setEffEndDate] = useState(roller.effEndDate);*/
  const [endDate, setEffEndDate] = useState(endt);
  const [updateRequestStatus, setUpdateRequestStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();
  const history = useHistory();

  const onIdcardDescChanged = e => {
    setIdcardDesc(e.target.valuec);
    setErrorMessage('');
  };

  const onCostNewGroupChanged = e => setCostNewGroup(Number(e.target.value));
  const onCostExistingChanged = e => setCostExisting(Number(e.target.value));

  const onEffStartDateChanged = e => {
    setEffStartDate(e);
    setErrorMessage('');
  };
  const onEffEndDateChanged = e => {
    setEffEndDate(e);
    setErrorMessage('');
  };

  //const idcardChargestatus = useSelector(state => state.roller);
  //console.log(idcardChargestatus);
  //useEffect(() => {
  //  if (idcardChargestatus === 'idle') {
  //    dispatch(fetchIdcardCharge());
  //  }
  //}, [idcardChargestatus, dispatch]);
  //useEffect(() => {
  //    if (groupstatus === 'idle') {
  //        dispatch(fetchGroups())
  //    }
  //}, [groupstatus, dispatch])

  const canSave =
    [idcardDesc, !isNaN(costNewGroup), !isNaN(costExisting), startDate].every(
      Boolean
    ) && updateRequestStatus === 'idle';

  const onSaveIdcardChargeClicked = async () => {
    if (canSave) {
      try {
        setUpdateRequestStatus('pending');

        var idcardChargeOverlap = idcardChargeList.filter(overlapCheck, {
          id: idcard.id,
          effStartDate: startDate,
          effEndDate: endDate,
          keys: [
            {
              name: 'idcardDesc',
              value: idcardDesc,
            },
          ],
        });
        if (idcardChargeOverlap.length) {
          console.log(idcardChargeOverlap);
          setErrorMessage(
            'The record being entered overlaps an existing effective date range for this key value.'
          );
          return false;
        }

        const resultAction = await dispatch(
          updateIdcardCharge({
            id: Number(idcardChargeId),
            idcardDesc,
            costNewGroup,
            costExisting,

            modifiedByName: user.profile.name,
            modifiedDate: new Date().toISOString(),
            effStartDate: dateToString(startDate),
            effEndDate: nullableDateToString(endDate),
          })
        );
        console.log(resultAction);
        setUpdateRequestStatus('idle');
        history.push(`/idcardCharge`);
      } catch (err) {
        setUpdateRequestStatus('idle');
        console.error('Failed to save the idcardCharge: ', err);
      }
    }
  };

  return (
    <Box
      px={{
        base: '4',
        md: '10',
      }}
      pb="16"
      maxWidth="3xl"
      mx="auto"
    >
      <form>
        <Stack spacing="4" divider={<StackDivider />}>
          <FieldGroup title="Edit Id Card Charge">
            <VStack width="full" spacing="6">
              <FormControl id="idcardDesc">
                <FormLabel>Id Card Desc:</FormLabel>
                <Input
                  type="text"
                  value={idcardDesc}
                  onChange={onIdcardDescChanged}
                />
              </FormControl>
              <FormControl id="costNewGroup">
                <FormLabel>Cost New Group:</FormLabel>
                <Input
                  type="number"
                  value={costNewGroup}
                  onChange={onCostNewGroupChanged}
                />
              </FormControl>
              <FormControl id="costExisting">
                <FormLabel>Cost Existing:</FormLabel>
                <Input
                  type="number"
                  value={costExisting}
                  onChange={onCostExistingChanged}
                />
              </FormControl>

              <FormControl id="effStartDate">
                <FormLabel>Effective Start Date:</FormLabel>
                <DatePicker
                  id="effStartDate"
                  selectedDate={startDate}
                  onChange={onEffStartDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective Start Date</FormHelperText>
              </FormControl>
              <FormControl id="effEndDate">
                <FormLabel>Effective End Date:</FormLabel>
                <DatePicker
                  id="effEndDate"
                  selectedDate={endDate}
                  onChange={onEffEndDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective End Date</FormHelperText>
              </FormControl>
            </VStack>
          </FieldGroup>

          <Button
            onClick={onSaveIdcardChargeClicked}
            disabled={!canSave}
            variant="brand-primary"
            size="xs"
          >
            Save Id Card Charge
          </Button>
          <ErrorDisplay
            title="The record dates overlap other records!"
            errorMessage={errorMessage}
          />
        </Stack>
      </form>
    </Box>
  );
};

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
  };
}

export const EditIdcardChargeFormUser =
  connect(mapStateToProps)(EditIdcardChargeForm);
