import React, { useEffect } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalHeader,
  ModalFooter,
  ModalContent,
  ModalCloseButton,
  Heading,
  useDisclosure,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchAgeAdult,
  selectAgeAdultIds,
  selectAgeAdultById,
  deleteAgeAdult,
} from './ageAdultSlice';
import { unwrapResult } from '@reduxjs/toolkit';

import { NavLink } from 'react-router-dom';
import { FormattedDate } from '../common/FormattedDate';
import { FormattedTimestamp } from '../common/FormattedTimestamp';

const ItemDetails = ({ currentItem }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button onClick={onOpen} size="xs">
        Details
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Adult Age Range: {currentItem.low} - {currentItem.high}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Table size="sm">
              <Tbody>
                <Tr>
                  <Th>Low</Th>
                  <Th>High</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.low}</Td>
                  <Td>{currentItem.high}</Td>
                </Tr>
                <Tr>
                  <Th>Preventive</Th>
                  <Th>Radiographs</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.preventive}</Td>
                  <Td>{currentItem.radiographs}</Td>
                </Tr>
                <Tr>
                  <Th>MajorRestorative</Th>
                  <Th>OtherClass2</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.majorRestorative}</Td>
                  <Td>{currentItem.otherClass2}</Td>
                </Tr>

                <Tr>
                  <Th>Prosthodontics</Th>
                  <Th> </Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.prosthodontics}</Td>
                  <Td> </Td>
                </Tr>
                <Tr>
                  <Th>Modified By Name</Th>
                  <Th>Modified Date</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.modifiedByName}</Td>
                  <Td>
                    <FormattedTimestamp
                      dateToFormat={currentItem.modifiedDate}
                    />
                  </Td>
                </Tr>
                <Tr>
                  <Th>Eff Start Date</Th>
                  <Th>Eff End Date</Th>
                </Tr>
                <Tr>
                  <Td>
                    <FormattedDate dateToFormat={currentItem.effStartDate} />
                  </Td>
                  <Td>
                    <FormattedDate dateToFormat={currentItem.effEndDate} />
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="brand-primary-sm"
              mr={3}
              onClick={onClose}
              size="sm"
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

let AgeAdultTable = ({ ageAdultIdList }) => {
  let tableRows = ageAdultIdList.map(ageAdultId => (
    <AgeAdult ageAdultId={ageAdultId} key={ageAdultId} />
  ));

  return (
    <Box overflowX="auto">
      <Table size="sm">
        <Thead>
          <Tr>
            <Th></Th>
            <Th>Low</Th>
            <Th>High</Th>
            <Th>Preventive</Th>
            <Th>Radiographs</Th>
            <Th>Major Restorative</Th>
            <Th>Other Class2</Th>
            <Th>Prosthodontics</Th>

            <Th>Modified By Name</Th>
            <Th>Modified Date</Th>
            <Th>Eff Start Date</Th>
            <Th>Eff End Date</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>{tableRows}</Tbody>
      </Table>
    </Box>
  );
};

let AgeAdult = ({ ageAdultId }) => {
  const ageAdult = useSelector(state => selectAgeAdultById(state, ageAdultId));

  const dispatch = useDispatch();

  const onDeleteAgeAdultClicked = async () => {
    try {
      const resultAction = await dispatch(deleteAgeAdult(ageAdult.id));
      unwrapResult(resultAction);
    } catch (err) {
      console.error('Failed to save the ageAdult record', err);
    }
  };

  return (
    <Tr>
      <Td>
        <ItemDetails currentItem={ageAdult} />
      </Td>
      <Td>{ageAdult.low}</Td>
      <Td>{ageAdult.high}</Td>
      <Td>{ageAdult.preventive}</Td>
      <Td>{ageAdult.radiographs}</Td>
      <Td>{ageAdult.majorRestorative}</Td>
      <Td>{ageAdult.otherClass2}</Td>
      <Td>{ageAdult.prosthodontics}</Td>
      <Td>{ageAdult.modifiedByName}</Td>
      <Td>
        <FormattedTimestamp dateToFormat={ageAdult.modifiedDate} />
      </Td>
      <Td>
        <FormattedDate dateToFormat={ageAdult.effStartDate} />
      </Td>
      <Td>
        <FormattedDate dateToFormat={ageAdult.effEndDate} />
      </Td>
      <Td>
        <ButtonGroup variant="outline">
          <Button
            colorScheme="gray"
            size="sm"
            as={NavLink}
            to={`/editAgeAdult/${ageAdult.id}`}
          >
            Edit
          </Button>
          <Button colorScheme="red" size="sm" onClick={onDeleteAgeAdultClicked}>
            Delete
          </Button>
        </ButtonGroup>
      </Td>
    </Tr>
  );
};

export const AgeAdultList = () => {
  const dispatch = useDispatch();
  const orderedAgeAdultIds = useSelector(selectAgeAdultIds);

  const error = useSelector(state => state.ageAdult.error);

  const ageAdultStatus = useSelector(state => state.ageAdult.status);
  console.log(ageAdultStatus);
  useEffect(() => {
    if (ageAdultStatus === 'idle') {
      dispatch(fetchAgeAdult());
    }
  }, [ageAdultStatus, dispatch]);

  let content;

  if (ageAdultStatus === 'loading') {
    content = <div className="loader">Loading...</div>;
  } else if (ageAdultStatus === 'succeeded') {
    content = <AgeAdultTable ageAdultIdList={orderedAgeAdultIds} />;
  } else if (ageAdultStatus === 'failed') {
    content = <div>{error}</div>;
  }

  return (
    <section>
      <h2>
        <b>Age Adult Listing</b>
      </h2>
      {content}
    </section>
  );
};
