import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { Box, Button, FormControl, FormLabel, Input, Select, Stack, StackDivider, VStack } from '@chakra-ui/react';

import { addNewCategory } from './ratingCategorySlice';
import { fetchGroups, selectAllGroups } from './ratingGroupSlice';
import { FieldGroup } from '../../components/common/FieldGroup';

export const AddCategoryForm = () => {
  const [categoryName, setCategoryName] = useState('');
  const [categoryDescription, setCategoryDescription] = useState('');
  const [ratingGroupId, setRatingGroupId] = useState('');
  const [ucmCategory, setUcmCategory] = useState('');
  const [targetProcedureCode, setTargetProcedureCode] = useState('');
  const [addRequestStatus, setAddRequestStatus] = useState('idle');

  const dispatch = useDispatch();
  const ratingGroups = useSelector(selectAllGroups);

  const onCategoryNameChanged = e => setCategoryName(e.target.value);
  const onCategoryDescriptionChanged = e => setCategoryDescription(e.target.value);
  const onUcmCategoryChanged = e => setUcmCategory(e.target.value);
  const onTargetProcedureCodeChanged = e => setTargetProcedureCode(e.target.value);
  const onGroupChanged = e => setRatingGroupId(Number(e.target.value))

  const grouptatus = useSelector(state => state.ratingGroups.status)
  useEffect(() => {
    if (grouptatus === 'idle') {
      dispatch(fetchGroups())
    }
  }, [grouptatus, dispatch])

  const canSave = [categoryName, ratingGroupId, ucmCategory, targetProcedureCode].every(Boolean) && addRequestStatus === 'idle';

  const onSaveCategoryClicked = async () => {
    if (canSave) {
      try {
        setAddRequestStatus('pending')
        const resultAction = await dispatch(
          addNewCategory({ categoryName, categoryDescription, ratingGroupId, ucmCategory, targetProcedureCode })
        );
        unwrapResult(resultAction);
        setCategoryName('');
        setCategoryDescription('');
        setUcmCategory('');
        setTargetProcedureCode('');
        setRatingGroupId('');
      } catch (err) {
        console.error('Failed to save the category: ', err);
      } finally {
        setAddRequestStatus('idle');
      }
    }
  }

  const groupOptions = ratingGroups.map(grp => (
    <option key={grp.id} value={grp.id}>{grp.groupName}</option>
  ));

  return (
    <Box
      px={{
        base: '4',
        md: '10',
      }}
      pb="16"
      maxWidth="3xl"
      mx="auto"
    >
      <form>
        <Stack spacing="4" divider={<StackDivider />}>
        <FieldGroup title="Add Rating Category">
          <VStack width="full" spacing="6">
            <FormControl id="categoryName">
              <FormLabel>Category Name:</FormLabel>
              <Input type="text" value={categoryName} onChange={onCategoryNameChanged} />
            </FormControl>
            <FormControl id="categoryDescription">
              <FormLabel>Category Description:</FormLabel>
              <Input type="text" value={categoryDescription} onChange={onCategoryDescriptionChanged} />
            </FormControl>
        <FormControl id="ratingGroup">
        <FormLabel>Rating Group:</FormLabel>
          <Select value={ratingGroupId} onChange={onGroupChanged} placeholder="Select a rating group">
            {groupOptions}
          </Select>
        </FormControl>
            <FormControl id="ucmCategory">
              <FormLabel>Codeset Category:</FormLabel>
              <Input type="text" value={ucmCategory} onChange={onUcmCategoryChanged} />
            </FormControl>
            <FormControl id="targetProcedureCode">
              <FormLabel>Target Procedure Code:</FormLabel>
              <Input type="text" value={targetProcedureCode} onChange={onTargetProcedureCodeChanged} />
            </FormControl>
          </VStack>
        </FieldGroup>

        <Button onClick={onSaveCategoryClicked} disabled={!canSave} variant="brand-primary" size="xs" >Save Category</Button>
        </Stack>
      </form>
    </Box>
  )
}