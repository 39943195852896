import * as moment from 'moment';

function dateOverlap(currentItem, context) {
  return (
    moment(context.effStartDate).isBetween(
      currentItem.effStartDate,
      currentItem.effEndDate,
      null,
      '[]'
    ) ||
    moment(context.effEndDate).isBetween(
      currentItem.effStartDate,
      currentItem.effEndDate,
      null,
      '[]'
    )
  );
}

function between(val, low, high) {
  return val <= high && val >= low;
}

function matchKeys(currentItem, context) {
  let match = false;
  switch (context.checkType) {
    case 'any':
      //returns true if any supplied keys match
      match = context.keys.some(kv => {
        return currentItem[kv.name] === kv.value;
      });
      break;
    case 'range':
      //compares the first two key items as a range for overlap
      //the first key value is used as the low
      //the second key value is used as the high
      //the comparison is inclusive
      //returns true if either the high or the low value overlaps an existing range
      const itemLow = currentItem[context.keys[0].name];
      const itemHigh = currentItem[context.keys[1].name];
      const newLow = context.keys[0].value;
      const newHigh = context.keys[1].value;
      match =
        between(newLow, itemLow, itemHigh) ||
        between(newHigh, itemLow, itemHigh);
      break;
    default:
      //all
      //this is the default and identical to previous behavior
      //overlap checks that don't supply a checkType will use this comparison
      //returns true if all key supplied keys match
      match = context.keys.every(kv => {
        return currentItem[kv.name] === kv.value;
      });
      break;
  }
  return match;
}

function overlapCheck(currentItem) {
  if (this.id && currentItem.id === this.id) {
    return false;
  }
  return matchKeys(currentItem, this) && dateOverlap(currentItem, this);
}

export { overlapCheck };
