import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Select,
  Stack,
  StackDivider,
  VStack,
} from '@chakra-ui/react';

import {
  fetchPandemic,
  updatePandemic,
  selectPandemicById,
  selectAllPandemic,
} from './pandemicSlice';
import { FieldGroup } from '../../components/common/FieldGroup';
import DatePicker from '../../components/common/DatePicker';
import { ErrorDisplay } from '../common/ErrorDisplay';
import { overlapCheck } from '../../services/overlapCheck';
import {
  dateToString,
  nullableDateToString,
  stringToDateFormat,
} from '../../services/dateConverters';

export const EditPandemicForm = ({ match, user }) => {
  const { pandemicId } = match.params;
  const pandemic = useSelector(state => selectPandemicById(state, pandemicId));
  const pandemicList = useSelector(selectAllPandemic);

  const stdt = stringToDateFormat(pandemic.effStartDate);
  console.log(stdt);
  const endt = stringToDateFormat(pandemic.effEndDate);
  console.log(endt);

  const [pandemic1, setPandemic1] = useState(pandemic.pandemic1);
  const [endDate, setEndDate] = useState(dateToString(pandemic.endDate));
  const [factor, setFactor] = useState(pandemic.factor);

  const [startDate, setEffStartDate] = useState(stdt);

  const [endDate1, setEffEndDate] = useState(endt);
  const [updateRequestStatus, setUpdateRequestStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();
  const history = useHistory();

  const onPandemic1Changed = e => {
    setPandemic1(e.target.value);
    setErrorMessage('');
  };

  const onEndDateChanged = e => setEndDate(e.target.value);
  const onFactorChanged = e => setFactor(Number(e.target.value));

  const onEffStartDateChanged = e => {
    setEffStartDate(e);
    setErrorMessage('');
  };
  const onEffEndDateChanged = e => {
    setEffEndDate(e);
    setErrorMessage('');
  };

  const canSave =
    [pandemic1, endDate, !isNaN(factor), startDate].every(Boolean) &&
    updateRequestStatus === 'idle';

  const onSavePandemicClicked = async () => {
    if (canSave) {
      try {
        setUpdateRequestStatus('pending');

        var pandemicOverlap = pandemicList.filter(overlapCheck, {
          id: pandemic.id,
          effStartDate: startDate,
          effEndDate: endDate1,
          keys: [
            {
              name: 'pandemic1',
              value: pandemic1,
            },
            {
              name: 'endDate',
              value: endDate,
            },
          ],
        });
        if (pandemicOverlap.length) {
          console.log(pandemicOverlap);
          setErrorMessage(
            'The record being entered overlaps an existing effective date range for this key value.'
          );
          return false;
        }

        const resultAction = await dispatch(
          updatePandemic({
            id: Number(pandemicId),
            pandemic1,
            endDate,
            factor,

            modifiedByName: user.profile.name,
            modifiedDate: new Date().toISOString(),
            effStartDate: dateToString(startDate),
            effEndDate: nullableDateToString(endDate),
          })
        );
        console.log(resultAction);
        setUpdateRequestStatus('idle');
        history.push(`/pandemic`);
      } catch (err) {
        setUpdateRequestStatus('idle');
        console.error('Failed to save the pandemic factor: ', err);
      }
    }
  };
  return (
    <Box
      px={{
        base: '4',
        md: '10',
      }}
      pb="16"
      maxWidth="3xl"
      mx="auto"
    >
      <form>
        <Stack spacing="4" divider={<StackDivider />}>
          <FieldGroup title="Edit Pandemic Factors">
            <VStack width="full" spacing="6">
              <FormControl id="pandemic1">
                <FormLabel>Pandemic1:</FormLabel>
                <Input
                  type="text"
                  value={pandemic1}
                  onChange={onPandemic1Changed}
                />
              </FormControl>
              <FormControl id="endDate">
                <FormLabel>EndDate:</FormLabel>
                <Input
                  type="date"
                  value={endDate}
                  onChange={onEndDateChanged}
                />
              </FormControl>
              <FormControl id="factor">
                <FormLabel>Factor:</FormLabel>
                <Input
                  type="number"
                  value={factor}
                  onChange={onFactorChanged}
                />
              </FormControl>

              <FormControl id="effStartDate">
                <FormLabel>Effective Start Date:</FormLabel>
                <DatePicker
                  id="effStartDate"
                  selectedDate={startDate}
                  onChange={onEffStartDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective Start Date</FormHelperText>
              </FormControl>
              <FormControl id="effEndDate">
                <FormLabel>Effective End Date:</FormLabel>
                <DatePicker
                  id="effEndDate"
                  selectedDate={endDate1}
                  onChange={onEffEndDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective End Date</FormHelperText>
              </FormControl>
            </VStack>
          </FieldGroup>

          <Button
            onClick={onSavePandemicClicked}
            disabled={!canSave}
            variant="brand-primary"
            size="xs"
          >
            Save Pandemic Factor
          </Button>
          <ErrorDisplay
            title="The record dates overlap other records!"
            errorMessage={errorMessage}
          />
        </Stack>
      </form>
    </Box>
  );
};

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
  };
}

export const EditPandemicFormUser = connect(mapStateToProps)(EditPandemicForm);
