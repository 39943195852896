import React, { useEffect } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalHeader,
  ModalFooter,
  ModalContent,
  ModalCloseButton,
  Heading,
  useDisclosure,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchDependentAge,
  selectDependentAgeIds,
  selectDependentAgeById,
  deleteDependentAge,
} from './dependentAgeSlice';
import { unwrapResult } from '@reduxjs/toolkit';

import { NavLink } from 'react-router-dom';
import { FormattedDate } from '../common/FormattedDate';
import { FormattedTimestamp } from '../common/FormattedTimestamp';

const ItemDetails = ({ currentItem }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button onClick={onOpen} size="xs">
        Details
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Dependent Age:{currentItem.tierType} /{currentItem.lowRange}-
            {currentItem.highRange}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Table size="sm">
              <Tbody>
                <Tr>
                  <Th>TierType</Th>
                  <Th>AgeLimitType</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.tierType}</Td>
                  <Td>{currentItem.ageLimitType}</Td>
                </Tr>
                <Tr>
                  <Th>LowRange</Th>
                  <Th>HighRange</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.lowRange}</Td>
                  <Td>{currentItem.highRange}</Td>
                </Tr>
                <Tr>
                  <Th>Factor</Th>
                  <Th> </Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.factor}</Td>
                  <Td></Td>
                </Tr>
                <Tr>
                  <Th>Modified By Name</Th>
                  <Th>Modified Date</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.modifiedByName}</Td>
                  <Td>
                    <FormattedTimestamp
                      dateToFormat={currentItem.modifiedDate}
                    />
                  </Td>
                </Tr>
                <Tr>
                  <Th>Eff Start Date</Th>
                  <Th>Eff End Date</Th>
                </Tr>
                <Tr>
                  <Td>
                    <FormattedDate dateToFormat={currentItem.effStartDate} />
                  </Td>
                  <Td>
                    <FormattedDate dateToFormat={currentItem.effEndDate} />
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="brand-primary-sm"
              mr={3}
              onClick={onClose}
              size="sm"
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

let DependentAgeTable = ({ dependentAgeIdList }) => {
  let tableRows = dependentAgeIdList.map(dependentAgeId => (
    <DependentAge dependentAgeId={dependentAgeId} key={dependentAgeId} />
  ));

  return (
    <Box overflowX="auto">
      <Table size="sm">
        <Thead>
          <Tr>
            <Th></Th>
            <Th>TierType</Th>
            <Th>AgeLimitType</Th>
            <Th>LowRange</Th>
            <Th>HighRange</Th>
            <Th>Factor</Th>

            <Th>Modified By Name</Th>
            <Th>Modified Date</Th>
            <Th>Eff Start Date</Th>
            <Th>Eff End Date</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>{tableRows}</Tbody>
      </Table>
    </Box>
  );
};

let DependentAge = ({ dependentAgeId }) => {
  const dependentAge = useSelector(state =>
    selectDependentAgeById(state, dependentAgeId)
  );
  //console.log('In the dependentAge list');

  const dispatch = useDispatch();

  const onDeleteDependentAgeClicked = async () => {
    try {
      const resultAction = await dispatch(deleteDependentAge(dependentAge.id));
      unwrapResult(resultAction);
    } catch (err) {
      console.error('Failed to save the dependentAge record', err);
    }
  };

  return (
    <Tr>
      <Td>
        <ItemDetails currentItem={dependentAge} />
      </Td>
      <Td>{dependentAge.tierType}</Td>
      <Td>{dependentAge.ageLimitType}</Td>
      <Td>{dependentAge.lowRange}</Td>
      <Td>{dependentAge.highRange}</Td>
      <Td>{dependentAge.factor}</Td>
      <Td>{dependentAge.modifiedByName}</Td>
      <Td>
        <FormattedTimestamp dateToFormat={dependentAge.modifiedDate} />
      </Td>
      <Td>
        <FormattedDate dateToFormat={dependentAge.effStartDate} />
      </Td>
      <Td>
        <FormattedDate dateToFormat={dependentAge.effEndDate} />
      </Td>
      <Td>
        <ButtonGroup variant="outline">
          <Button
            colorScheme="gray"
            size="sm"
            as={NavLink}
            to={`/editDependentAge/${dependentAge.id}`}
          >
            Edit
          </Button>
          <Button
            colorScheme="red"
            size="sm"
            onClick={onDeleteDependentAgeClicked}
          >
            Delete
          </Button>
        </ButtonGroup>
      </Td>
    </Tr>
  );
};

export const DependentAgeList = () => {
  const dispatch = useDispatch();
  const orderedDependentAgeIds = useSelector(selectDependentAgeIds);

  const error = useSelector(state => state.dependentAge.error);

  const dependentAgeStatus = useSelector(state => state.dependentAge.status);
  console.log(dependentAgeStatus);
  useEffect(() => {
    if (dependentAgeStatus === 'idle') {
      dispatch(fetchDependentAge());
    }
  }, [dependentAgeStatus, dispatch]);

  let content;

  if (dependentAgeStatus === 'loading') {
    content = <div className="loader">Loading...</div>;
  } else if (dependentAgeStatus === 'succeeded') {
    content = <DependentAgeTable dependentAgeIdList={orderedDependentAgeIds} />;
  } else if (dependentAgeStatus === 'failed') {
    content = <div>{error}</div>;
  }

  return (
    <section>
      <h2>
        <b>DependentAge Listing</b>
      </h2>
      {content}
    </section>
  );
};
