import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
  AccordionIcon,
  Heading,
} from '@chakra-ui/react';
import {
  fetchBaseRatePercent,
  selectBaseRatePercentIds,
  selectAllBaseRatePercent,
} from './baseRatePercentSlice';
import { AddBaseRatePercentFormUser } from './AddBaseRatePercentForm';
import { BaseRatePercentList } from './BaseRatePercentList';
import BrandLinkButton from '../../components/common/BrandLinkButton';

export const BaseRatePercentPage = () => {
  const dispatch = useDispatch();

  const orderedBaseRatePercentIds = useSelector(selectBaseRatePercentIds);
  const baseRatePercentList = useSelector(selectAllBaseRatePercent);

  const error = useSelector(state => state.baseRatePercent.error);
  if (error) {
    console.log('BaseRatePercent List Error:' + error.toString());
  }

  const baseRatePercentStatus = useSelector(
    state => state.baseRatePercent.status
  );
  const [index, setIndex] = React.useState([]);

  useEffect(() => {
    if (baseRatePercentStatus === 'idle') {
      dispatch(fetchBaseRatePercent());
    }
  }, [baseRatePercentStatus, dispatch]);

  function closeAccordion() {
    setIndex([]);
  }

  let content;

  if (baseRatePercentStatus === 'loading') {
    content = <div className="loader">Loading...</div>;
  } else if (baseRatePercentStatus === 'succeeded') {
    console.log(orderedBaseRatePercentIds);
    content = (
      <div>
        <Heading size="xl" mb={8}>
          Base Rate Percent Factors
        </Heading>
        <Accordion
          allowMultiple
          allowToggle="true"
          mb={8}
          index={index}
          onChange={setIndex}
        >
          <AccordionItem>
            <Box>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Text variant="brand-green-link" fontSize="lg">
                    Add New Factor
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </Box>
            <AccordionPanel pb={4}>
              <AddBaseRatePercentFormUser
                baseRatePercentList={baseRatePercentList}
                closeAccordion={closeAccordion}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <BaseRatePercentList
          orderedBaseRatePercentIds={orderedBaseRatePercentIds}
        />
      </div>
    );
  } else if (baseRatePercentStatus === 'failed') {
    content = <div>{error}</div>;
  }

  return (
    <>
      {content}
      <Box mt={12}>
        <BrandLinkButton path="/settings">Back to Settings</BrandLinkButton>
      </Box>
    </>
  );
};

export default BaseRatePercentPage;
