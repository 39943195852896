import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import axios from 'axios';

const adminsAdapter = createEntityAdapter();
const apiBaseUrl = window.ENV.api_base_url;

const initialState = adminsAdapter.getInitialState({
  status: 'idle',
  error: null,
});

export const fetchAdmins = createAsyncThunk(
  'adminstration/fetchAdmins',
  async (_, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };

    const response = await axios.get(`${apiBaseUrl}/api/Admins`, config);
    return response.data;
  }
);

export const addNewAdmins = createAsyncThunk(
  'administration/addNewAdmins',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.post(
      `${apiBaseUrl}/api/Admins`,
      initialState,
      config
    );
    console.log('adding new administration record');
    console.log(response);
    return { ...initialState, id: response.data };
  }
);

export const deleteAdmins = createAsyncThunk(
  'administration/deleteAdmins',
  async (administrationId, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.delete(
      `${apiBaseUrl}/api/Admins/${administrationId}`,
      config
    );
    return administrationId;
  }
);

export const updateAdmins = createAsyncThunk(
  'administration/updateAdmins',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.put(
      `${apiBaseUrl}/api/Admins/${initialState.id}`,
      initialState,
      config
    );
    return { ...initialState, id: response.data };
  }
);

const administrationSlice = createSlice({
  name: 'administration',
  initialState,
  extraReducers: {
    [fetchAdmins.pending]: state => {
      state.status = 'loading';
    },
    [fetchAdmins.fulfilled]: (state, action) => {
      // Add any fetched posts to the array
      adminsAdapter.upsertMany(state, action.payload);
      state.status = 'succeeded';
    },
    [fetchAdmins.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [addNewAdmins.fulfilled]: adminsAdapter.addOne,
    [deleteAdmins.fulfilled]: adminsAdapter.removeOne,
    [updateAdmins.fulfilled]: adminsAdapter.upsertOne,
  },
});

export default administrationSlice.reducer;

export const {
  selectAll: selectAllAdmins,
  selectById: selectAdminsById,
  selectIds: selectAdminsIds,
} = adminsAdapter.getSelectors(state => state.administration);
