import React, { useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Button,
  CloseButton,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Stack,
  StackDivider,
  VStack,
} from '@chakra-ui/react';

import { addNewBasicGroupInfo } from './basicGroupInfoSlice';
import { FieldGroup } from '../../components/common/FieldGroup';
import DatePicker from '../../components/common/DatePicker';
import { ErrorDisplay } from '../common/ErrorDisplay';
import { overlapCheck } from '../../services/overlapCheck';
import {
  dateToString,
  nullableDateToString,
} from '../../services/dateConverters';

export const AddBasicGroupInfoForm = ({ user, basicGroupInfoList }) => {
  const [groupName, setGroupName] = useState(' ');
  const [groupNumber, setGroupNumber] = useState(' ');
  const [sales_Rep, setSales_Rep] = useState(' ');
  const [renewalDate, setRenewalDate] = useState(0);
  const [contractLength, setContractLength] = useState(0);
  const [experiencePeriodMonths, setExperiencePeriodMonths] = useState(0);
  const [currentRateEE, setCurrentRateEE] = useState(0);
  const [currentRateEESP, setCurrentRateEESP] = useState(0);
  const [currentRateEESPCH, setCurrentRateEESPCH] = useState(0);
  const [currentRateEECH, setCurrentRateEECH] = useState(0);

  const [currentRateEEChildren, setCurrentRateEEChildren] = useState(0);
  const [currentRateFamily, setCurrentRateFamily] = useState(0);
  const [sales_Commission, setSales_Commission] = useState(0);

  const [startDate, setEffStartDate] = useState(new Date());
  const [endDate, setEffEndDate] = useState('');
  const [addRequestStatus, setAddRequestStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();

  const onGroupNameChanged = e => setGroupName(e.target.value);
  const onGroupNumberChanged = e => {
    setGroupNumber(e.target.value);
    setErrorMessage('');
  };
  const onSales_RepChanged = e => setSales_Rep(e.target.value);
  const onRenewalDateChanged = e => setRenewalDate(Number(e.target.value));
  const onContractLengthChanged = e =>
    setContractLength(Number(e.target.value));
  const onExperiencePeriodMonthsChanged = e =>
    setExperiencePeriodMonths(Number(e.target.value));

  const onCurrentRateEEChanged = e => setCurrentRateEE(e.target.value);
  const onCurrentRateEESPChanged = e => setCurrentRateEESP(e.target.value);
  const onCurrentRateEESPCHChanged = e => setCurrentRateEESPCH(e.target.value);
  const onCurrentRateEECHChanged = e => setCurrentRateEECH(e.target.value);
  const onCurrentRateEEChildrenChanged = e =>
    setCurrentRateEEChildren(e.target.value);
  const onCurrentRateFamilyChanged = e => setCurrentRateFamily(e.target.value);
  const onSales_CommissionChanged = e => setSales_Commission(e.target.value);

  const canSave =
    [
      groupName,
      groupNumber,
      sales_Rep,
      renewalDate,
      !isNaN(contractLength),
      !isNaN(experiencePeriodMonths),
      currentRateEE,
      currentRateEESP,
      currentRateEESPCH,
      currentRateEECH,
      currentRateEEChildren,
      currentRateFamily,
      sales_Commission,
      startDate,
    ].every(Boolean) && addRequestStatus === 'idle';

  const onSaveBasicGroupInfoClicked = async () => {
    if (canSave) {
      try {
        setAddRequestStatus('pending');

        var basicGroupInfoOverlap = basicGroupInfoList.filter(overlapCheck, {
          effStartDate: startDate,
          effEndDate: endDate,
          keys: [
            {
              name: 'groupName',
              value: groupName,
            },
            {
              name: 'groupNumber',
              value: groupNumber,
            },
          ],
        });
        if (basicGroupInfoOverlap.length) {
          console.log(basicGroupInfoOverlap);
          setErrorMessage(
            'The record being entered overlaps an existing effective date range for this key value.'
          );
          return false;
        }

        const resultAction = await dispatch(
          addNewBasicGroupInfo({
            groupName,
            groupNumber,
            sales_Rep,
            renewalDate,
            contractLength,
            experiencePeriodMonths,
            currentRateEE,
            currentRateEESP,
            currentRateEESPCH,
            currentRateEECH,
            currentRateEEChildren,
            currentRateFamily,
            sales_Commission,
          })
        );

        unwrapResult(resultAction);

        setGroupName('');
        setGroupNumber('');
        setSales_Rep('');
        setRenewalDate(0);
        setContractLength(0);
        setExperiencePeriodMonths(0);
        setCurrentRateEE(0);
        setCurrentRateEESP(0);
        setCurrentRateEESPCH(0);
        setCurrentRateEECH(0);
        setCurrentRateEEChildren(0);
        setCurrentRateFamily(0);
        setSales_Commission(0);
      } catch (err) {
        console.error(
          'Failed to save the baseRatePercent factor record: ',
          err
        );
      } finally {
        setAddRequestStatus('idle');
      }
    }
  };

  return (
    <Box
      px={{
        base: '4',
        md: '10',
      }}
      pb="16"
      maxWidth="3xl"
      mx="auto"
    >
      <form>
        <Stack spacing="4" divider={<StackDivider />}>
          <FieldGroup title="Add Basic Group Info Record">
            <VStack width="full" spacing="6">
              <FormControl id="groupName">
                <FormLabel>GroupName:</FormLabel>
                <Input
                  type="text"
                  value={groupName}
                  onChange={onGroupNameChanged}
                />
              </FormControl>
              <FormControl id="groupNumber">
                <FormLabel>GroupNumber:</FormLabel>
                <Input
                  type="text"
                  value={groupNumber}
                  onChange={onGroupNumberChanged}
                />
              </FormControl>
              <FormControl id="sales_Rep">
                <FormLabel>Sales_Rep:</FormLabel>
                <Input
                  type="text"
                  value={sales_Rep}
                  onChange={onSales_RepChanged}
                />
              </FormControl>
              <FormControl id="renewalDate">
                <FormLabel>RenewalDate:</FormLabel>
                <Input
                  type="number"
                  value={renewalDate}
                  onChange={onRenewalDateChanged}
                />
              </FormControl>
              <FormControl id="contractLength">
                <FormLabel>ContractLength:</FormLabel>
                <Input
                  type="number"
                  value={contractLength}
                  onChange={onContractLengthChanged}
                />
              </FormControl>

              <FormControl id="experiencePeriodMonths">
                <FormLabel>ExperiencePeriodMonths:</FormLabel>
                <Input
                  type="number"
                  value={experiencePeriodMonths}
                  onChange={onExperiencePeriodMonthsChanged}
                />
              </FormControl>

              <FormControl id="currentRateEE">
                <FormLabel>CurrentRateEE:</FormLabel>
                <Input
                  type="number"
                  value={currentRateEE}
                  onChange={onCurrentRateEEChanged}
                />
              </FormControl>
              <FormControl id="currentRateEESP">
                <FormLabel>CurrentRateEESP:</FormLabel>
                <Input
                  type="number"
                  value={currentRateEESP}
                  onChange={onCurrentRateEESPChanged}
                />
              </FormControl>
              <FormControl id="currentRateEESPCH">
                <FormLabel>CurrentRateEESPCH:</FormLabel>
                <Input
                  type="number"
                  value={currentRateEESPCH}
                  onChange={onCurrentRateEESPCHChanged}
                />
              </FormControl>
              <FormControl id="currentRateEECH">
                <FormLabel>CurrentRateEECH:</FormLabel>
                <Input
                  type="number"
                  value={currentRateEECH}
                  onChange={onCurrentRateEECHChanged}
                />
              </FormControl>

              <FormControl id="currentRateEEChildren">
                <FormLabel>CurrentRateEEChildren:</FormLabel>
                <Input
                  type="number"
                  value={currentRateEEChildren}
                  onChange={onCurrentRateEEChildrenChanged}
                />
              </FormControl>
              <FormControl id="currentRateFamily">
                <FormLabel>CurrentRateFamily:</FormLabel>
                <Input
                  type="number"
                  value={currentRateFamily}
                  onChange={onCurrentRateFamilyChanged}
                />
              </FormControl>
              <FormControl id="sales_Commission">
                <FormLabel>Sales_Commission:</FormLabel>
                <Input
                  type="number"
                  value={sales_Commission}
                  onChange={onSales_CommissionChanged}
                />
              </FormControl>
            </VStack>
          </FieldGroup>

          <Button
            onClick={onSaveBasicGroupInfoClicked}
            disabled={!canSave}
            variant="brand-primary"
            size="xs"
          >
            Save basicGroupInfo Record
          </Button>
          <ErrorDisplay
            title="The record dates overlap other records!"
            errorMessage={errorMessage}
          />
        </Stack>
      </form>
    </Box>
  );
};

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
  };
}

export const AddBasicGroupInfoFormUser = connect(mapStateToProps)(
  AddBasicGroupInfoForm
);
