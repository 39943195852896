import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import axios from 'axios';

const maxAmtExcludedAdapter = createEntityAdapter();
const apiBaseUrl = window.ENV.api_base_url;

const initialState = maxAmtExcludedAdapter.getInitialState({
  status: 'idle',
  error: null,
});

export const fetchMaxAmtExcluded = createAsyncThunk(
  'maxAmtExcluded/fetchMaxAmtExcluded',
  async (_, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };

    const response = await axios.get(
      `${apiBaseUrl}/api/MaxAmtExcluded`,
      config
    );
    return response.data;
  }
);

export const addNewMaxAmtExcluded = createAsyncThunk(
  'maxAmtExcluded/addNewMaxAmtExcluded',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.post(
      `${apiBaseUrl}/api/MaxAmtExcluded`,
      initialState,
      config
    );
    console.log('adding new maxAmtExcluded record');
    console.log(response);
    return { ...initialState, id: response.data };
  }
);

export const deleteMaxAmtExcluded = createAsyncThunk(
  'maxAmtExcluded/deleteMaxAmtExcluded',
  async (maxAmtExcludedId, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.delete(
      `${apiBaseUrl}/api/MaxAmtExcluded/${maxAmtExcludedId}`,
      config
    );
    return maxAmtExcludedId;
  }
);

export const updateMaxAmtExcluded = createAsyncThunk(
  'maxAmtExcluded/updateMaxAmtExcluded',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.put(
      `${apiBaseUrl}/api/MaxAmtExcluded/${initialState.id}`,
      initialState,
      config
    );
    return { ...initialState, id: response.data };
  }
);

const maxAmtExcludedSlice = createSlice({
  name: 'maxAmtExcluded',
  initialState,
  extraReducers: {
    [fetchMaxAmtExcluded.pending]: state => {
      state.status = 'loading';
    },
    [fetchMaxAmtExcluded.fulfilled]: (state, action) => {
      // Add any fetched posts to the array
      maxAmtExcludedAdapter.upsertMany(state, action.payload);
      state.status = 'succeeded';
    },
    [fetchMaxAmtExcluded.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [addNewMaxAmtExcluded.fulfilled]: maxAmtExcludedAdapter.addOne,
    [deleteMaxAmtExcluded.fulfilled]: maxAmtExcludedAdapter.removeOne,
    [updateMaxAmtExcluded.fulfilled]: maxAmtExcludedAdapter.upsertOne,
  },
});

export default maxAmtExcludedSlice.reducer;

export const {
  selectAll: selectAllMaxAmtExcluded,
  selectById: selectMaxAmtExcludedById,
  selectIds: selectMaxAmtExcludedIds,
} = maxAmtExcludedAdapter.getSelectors(state => state.maxAmtExcluded);
