import React, { useEffect } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalHeader,
  ModalFooter,
  ModalContent,
  ModalCloseButton,
  Heading,
  useDisclosure,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchOccupation,
  selectOccupationIds,
  selectOccupationById,
  deleteOccupation,
} from './occupationSlice';
import { unwrapResult } from '@reduxjs/toolkit';

import { NavLink } from 'react-router-dom';
import { FormattedDate } from '../common/FormattedDate';
import { FormattedTimestamp } from '../common/FormattedTimestamp';

const ItemDetails = ({ currentItem }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button onClick={onOpen} size="xs">
        Details
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Occupation: {currentItem.occupationDesc}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Table size="sm">
              <Tbody>
                <Tr>
                  <Th>Occupation Desc</Th>
                  <Th>Factor</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.occupationDesc}</Td>
                  <Td>{currentItem.factor}</Td>
                </Tr>
                <Tr>
                  <Th>Age 0To24</Th>
                  <Th>Age 25To34</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.age0To24}</Td>
                  <Td>{currentItem.age25To34}</Td>
                </Tr>
                <Tr>
                  <Th>Age 35To44</Th>
                  <Th>Age 45To54</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.age35To44}</Td>
                  <Td>{currentItem.age45To54}</Td>
                </Tr>
                <Tr>
                  <Th>Age 55To64</Th>
                  <Th>Age 65Plus</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.age55To64}</Td>
                  <Td>{currentItem.age65Plus}</Td>
                </Tr>

                <Tr>
                  <Th>Age 0to5</Th>
                  <Th>F11</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.age0to5}</Td>
                  <Td>{currentItem.f11}</Td>
                </Tr>
                <Tr>
                  <Th>F12</Th>
                  <Th>Age 19Plus</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.f12}</Td>
                  <Td>{currentItem.age19Plus}</Td>
                </Tr>
                <Tr>
                  <Th>Employee</Th>
                  <Th>Spouse</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.employee}</Td>
                  <Td>{currentItem.spouse}</Td>
                </Tr>
                <Tr>
                  <Th>Child</Th>
                  <Th>Employee Percent</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.child}</Td>
                  <Td>{currentItem.employeePercent}</Td>
                </Tr>

                <Tr>
                  <Th>Spouse Percent</Th>
                  <Th>Child Percent</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.spousePercent}</Td>
                  <Td>{currentItem.childPercent}</Td>
                </Tr>
                <Tr>
                  <Th>Modified By Name</Th>
                  <Th>Modified Date</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.modifiedByName}</Td>
                  <Td>
                    <FormattedTimestamp
                      dateToFormat={currentItem.modifiedDate}
                    />
                  </Td>
                </Tr>
                <Tr>
                  <Th>Eff Start Date</Th>
                  <Th>Eff End Date</Th>
                </Tr>
                <Tr>
                  <Td>
                    <FormattedDate dateToFormat={currentItem.effStartDate} />
                  </Td>
                  <Td>
                    <FormattedDate dateToFormat={currentItem.effEndDate} />
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="brand-primary-sm"
              mr={3}
              onClick={onClose}
              size="sm"
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

let OccupationTable = ({ occupationIdList }) => {
  let tableRows = occupationIdList.map(occupationId => (
    <Occupation occupationId={occupationId} key={occupationId} />
  ));

  return (
    <Box overflowX="auto">
      <Table size="sm">
        <Thead>
          <Tr>
            <Th></Th>
            <Th>Occupation Desc</Th>
            <Th>Factor</Th>
            <Th>Age 0To24</Th>
            <Th>Age 25To34</Th>
            <Th>Age 35To44</Th>
            <Th>Age 45To54</Th>
            <Th>Age 55To64</Th>
            <Th>Age 65Plus</Th>
            {/*<Th>Age 0to5</Th>*/}
            {/*<Th>F11</Th>*/}
            {/*<Th>F12</Th>*/}
            {/*<Th>Age 19Plus</Th>*/}
            {/*<Th>Employee</Th>*/}
            {/*<Th>Spouse</Th>*/}
            {/* <Th>Child</Th>*/}
            <Th>Employee Percent</Th>
            <Th>Spouse Percent</Th>
            <Th>Child Percent</Th>
            {/* <Th>Modified By Name</Th>*/}
            <Th>Modified Date</Th>
            <Th>Eff Start Date</Th>
            <Th>Eff End Date</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>{tableRows}</Tbody>
      </Table>
    </Box>
  );
};

let Occupation = ({ occupationId }) => {
  const occupation = useSelector(state =>
    selectOccupationById(state, occupationId)
  );

  const dispatch = useDispatch();

  const onDeleteOccupationClicked = async () => {
    try {
      const resultAction = await dispatch(deleteOccupation(occupation.id));
      unwrapResult(resultAction);
    } catch (err) {
      console.error('Failed to save the occupation record', err);
    }
  };

  return (
    <Tr>
      <Td>
        <ItemDetails currentItem={occupation} />
      </Td>
      <Td>{occupation.occupationDesc}</Td>
      <Td>{occupation.factor}</Td>
      <Td>{occupation.age0To24}</Td>
      <Td>{occupation.age25To34}</Td>
      <Td>{occupation.age35To44}</Td>
      <Td>{occupation.age45To54}</Td>
      <Td>{occupation.age55To64}</Td>
      <Td>{occupation.age65Plus}</Td>
      {/*<Td>{occupation.age0to5}</Td>*/}
      {/*<Td>{occupation.f11}</Td>*/}
      {/*<Td>{occupation.f12}</Td>*/}
      {/*<Td>{occupation.age19Plus}</Td>*/}
      {/*<Td>{occupation.employee}</Td>*/}
      {/*<Td>{occupation.spouse}</Td>*/}
      {/*<Td>{occupation.child}</Td>*/}
      <Td>{occupation.employeePercent}</Td>
      <Td>{occupation.spousePercent}</Td>
      <Td>{occupation.childPercent}</Td>
      {/*<Td>{occupation.modifiedByName}</Td>*/}
      <Td>
        <FormattedTimestamp dateToFormat={occupation.modifiedDate} />
      </Td>
      <Td>
        <FormattedDate dateToFormat={occupation.effStartDate} />
      </Td>
      <Td>
        <FormattedDate dateToFormat={occupation.effEndDate} />
      </Td>
      <Td>
        <ButtonGroup variant="outline">
          <Button
            colorScheme="gray"
            size="sm"
            as={NavLink}
            to={`/editOccupation/${occupation.id}`}
          >
            Edit
          </Button>
          <Button
            colorScheme="red"
            size="sm"
            onClick={onDeleteOccupationClicked}
          >
            Delete
          </Button>
        </ButtonGroup>
      </Td>
    </Tr>
  );
};

export const OccupationList = () => {
  const dispatch = useDispatch();
  const orderedOccupationIds = useSelector(selectOccupationIds);

  const error = useSelector(state => state.occupation.error);

  const occupationStatus = useSelector(state => state.occupation.status);
  console.log(occupationStatus);
  useEffect(() => {
    if (occupationStatus === 'idle') {
      dispatch(fetchOccupation());
    }
  }, [occupationStatus, dispatch]);

  let content;

  if (occupationStatus === 'loading') {
    content = <div className="loader">Loading...</div>;
  } else if (occupationStatus === 'succeeded') {
    content = <OccupationTable occupationIdList={orderedOccupationIds} />;
  } else if (occupationStatus === 'failed') {
    content = <div>{error}</div>;
  }

  return (
    <section>
      <h2>
        <b>Occupation Listing</b>
      </h2>
      {content}
    </section>
  );
};
