import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Select,
  Stack,
  StackDivider,
  VStack,
} from '@chakra-ui/react';

import {
  fetchCopay,
  updateCopay,
  selectCopayById,
  selectAllCopay,
} from './copaySlice';
import { FieldGroup } from '../../components/common/FieldGroup';
import DatePicker from '../../components/common/DatePicker';
import { ErrorDisplay } from '../common/ErrorDisplay';
import { overlapCheck } from '../../services/overlapCheck';
import {
  dateToString,
  nullableDateToString,
  stringToDateFormat,
  stringToNullableDate,
} from '../../services/dateConverters';

export const EditCopayForm = ({ match, user }) => {
  const { copayId } = match.params;
  const copay = useSelector(state => selectCopayById(state, copayId));

  const stdt = stringToDateFormat(copay.effStartDate);
  console.log(stdt);
  const endt = stringToNullableDate(copay.effEndDate);
  console.log(endt);
  const copayList = useSelector(selectAllCopay);

  const [amount, setAmount] = useState(copay.amount);
  const [class1, setClass1] = useState(copay.class1);
  const [class2, setClass2] = useState(copay.class2);
  const [class3, setClass3] = useState(copay.class3);

  const [startDate, setEffStartDate] = useState(stdt);

  const [endDate, setEffEndDate] = useState(endt);
  const [updateRequestStatus, setUpdateRequestStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();
  const history = useHistory();

  const onAmountChanged = e => {
    setAmount(Number(e.target.value));
    setErrorMessage('');
  };
  const onClass1Changed = e => setClass1(Number(e.target.value));
  const onClass2Changed = e => setClass2(Number(e.target.value));
  const onClass3Changed = e => setClass3(Number(e.target.value));

  const onEffStartDateChanged = e => {
    setEffStartDate(e);
    setErrorMessage('');
  };
  const onEffEndDateChanged = e => {
    setEffEndDate(e);
    setErrorMessage('');
  };

  const canSave =
    [
      !isNaN(amount),
      !isNaN(class1),
      !isNaN(class2),
      !isNaN(class3),

      startDate,
    ].every(Boolean) && updateRequestStatus === 'idle';

  const onSaveCopayClicked = async () => {
    if (canSave) {
      try {
        setUpdateRequestStatus('pending');

        var copayOverlap = copayList.filter(overlapCheck, {
          id: copay.id,
          effStartDate: startDate,
          effEndDate: endDate,
          keys: [
            {
              name: 'amount',
              value: amount,
            },
          ],
        });
        if (copayOverlap.length) {
          console.log(copayOverlap);
          setErrorMessage(
            'The record being entered overlaps an existing effective date range for this key value.'
          );
          setUpdateRequestStatus('idle');
          return false;
        }

        const resultAction = await dispatch(
          updateCopay({
            id: Number(copayId),
            amount,
            class1,
            class2,
            class3,
            modifiedByName: user.profile.name,
            modifiedDate: new Date().toISOString(),
            effStartDate: dateToString(startDate),
            effEndDate: nullableDateToString(endDate),
          })
        );
        console.log(resultAction);
        setUpdateRequestStatus('idle');
        history.push(`/copay`);
      } catch (err) {
        setUpdateRequestStatus('idle');
        console.error('Failed to save the administration factor: ', err);
      }
    }
  };
  return (
    <Box
      px={{
        base: '4',
        md: '10',
      }}
      pb="16"
      maxWidth="3xl"
      mx="auto"
    >
      <form>
        <Stack spacing="4" divider={<StackDivider />}>
          <FieldGroup title="Edit Copay Factors">
            <VStack width="full" spacing="6">
              <FormControl id="amount">
                <FormLabel>Amount:</FormLabel>
                <Input
                  type="number"
                  value={amount}
                  onChange={onAmountChanged}
                />
              </FormControl>
              <FormControl id="class1">
                <FormLabel>Class1:</FormLabel>
                <Input
                  type="number"
                  value={class1}
                  onChange={onClass1Changed}
                />
              </FormControl>
              <FormControl id="class2">
                <FormLabel>Class2:</FormLabel>
                <Input
                  type="number"
                  value={class2}
                  onChange={onClass2Changed}
                />
              </FormControl>
              <FormControl id="class3">
                <FormLabel>Class3:</FormLabel>
                <Input
                  type="number"
                  value={class3}
                  onChange={onClass3Changed}
                />
              </FormControl>

              <FormControl id="effStartDate">
                <FormLabel>Effective Start Date:</FormLabel>
                <DatePicker
                  id="effStartDate"
                  selectedDate={startDate}
                  onChange={onEffStartDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective Start Date</FormHelperText>
              </FormControl>
              <FormControl id="effEndDate">
                <FormLabel>Effective End Date:</FormLabel>
                <DatePicker
                  id="effEndDate"
                  selectedDate={endDate}
                  onChange={onEffEndDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective End Date</FormHelperText>
              </FormControl>
            </VStack>
          </FieldGroup>

          <Button
            onClick={onSaveCopayClicked}
            disabled={!canSave}
            variant="brand-primary"
            size="xs"
          >
            Save Copay Factor
          </Button>
          <ErrorDisplay
            title="The record dates overlap other records!"
            errorMessage={errorMessage}
          />
        </Stack>
      </form>
    </Box>
  );
};

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
  };
}

export const EditCopayFormUser = connect(mapStateToProps)(EditCopayForm);
