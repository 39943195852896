import { configureStore } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import createOidcMiddleware from 'redux-oidc';
import { reducer as oidcReducer } from 'redux-oidc';
import userManager from '../services/userManager';
import categoriesReducer from '../features/configurationFeature/ratingCategorySlice';
import ratingGroupsReducer from '../features/configurationFeature/ratingGroupSlice';
import deductibleAmountsReducer from '../components/settings/deductibleAmountSlice';
import statesReducer from '../components/settings/statesSlice';
import rolloverReducer from '../components/settings/rolloverSlice';
import administrationReducer from '../components/settings/administrationSlice';
import copayReducer from '../components/settings/copaySlice';
import maximumAmountReducer from '../components/settings/maximumAmountSlice';
import idcardChargeReducer from '../components/settings/idcardChargeSlice';
import countyAreaReducer from '../components/settings/countyAreaSlice';
import credibilityReducer from '../components/settings/credibilitySlice';
import ehbRateReducer from '../components/settings/ehbRateSlice';
import ageAdultReducer from '../components/settings/ageAdultSlice';
import ageChildReducer from '../components/settings/ageChildSlice';
import areaReducer from '../components/settings/areaSlice';
import areaEhbReducer from '../components/settings/areaEhbSlice';
import pandemicReducer from '../components/settings/pandemicSlice';
import specialMixReducer from '../components/settings/specialMixSlice';
import aPRCReducer from '../components/settings/aPRCSlice';
import aPCAsoReducer from '../components/settings/aPCAsoSlice';

import voluntaryReducer from '../components/settings/voluntarySlice';
import usaTrendReducer from '../components/settings/usaTrendSlice';
import waitingPeriodReducer from '../components/settings/waitingPeriodSlice';

import baseRateAmountReducer from '../components/settings/baseRateAmountSlice';
import baseRatePercentReducer from '../components/settings/baseRatePercentSlice';
import calendarYearLoadReducer from '../components/settings/calendarYearLoadSlice';

import dependentAgeReducer from '../components/settings/dependentAgeSlice';
import deductibleCreditReducer from '../components/settings/deductibleCreditSlice';

import ehbTierDistReducer from '../components/settings/ehbTierDistSlice';
import nonParPaymentReducer from '../components/settings/nonParPaymentSlice';
import orthodonticBaseRateReducer from '../components/settings/orthodonticBaseRateSlice';
import constantsAdminReducer from '../components/settings/constantsAdminSlice';
import estimatedClaimReducer from '../components/settings/estimatedClaimSlice';
import estimatedClaimPlanReducer from '../components/settings/estimatedClaimPlanSlice';
import maxAmtExcludedReducer from '../components/settings/maxAmtExcludedSlice';
import occupationReducer from '../components/settings/occupationSlice';

import incurredClaimsReducer from '../components/renewals/incurredClaimsSlice';
import basicGroupInfoReducer from '../components/renewals/basicGroupInfoSlice';
import groupKeysReducer from '../components/renewals/groupKeysSlice';


export default function createStore(preloadedState, history) {
  return configureStore({
    reducer: {
      router: connectRouter(history),
      oidc: oidcReducer,
      categories: categoriesReducer,
      ratingGroups: ratingGroupsReducer,
          deductibleAmounts: deductibleAmountsReducer,
          states: statesReducer,
          rollover: rolloverReducer,
          administration: administrationReducer,
          copay: copayReducer,
          maximumAmount: maximumAmountReducer,
          idcardCharge: idcardChargeReducer,
          countyArea: countyAreaReducer,
          credibility: credibilityReducer,
          ehbRate: ehbRateReducer,
          ageAdult: ageAdultReducer,
          ageChild: ageChildReducer,
          area: areaReducer,
          areaEhb: areaEhbReducer,
          pandemic: pandemicReducer,
          specialMix: specialMixReducer,
          aPRC: aPRCReducer,
          aPCAso: aPCAsoReducer,
          voluntary: voluntaryReducer,
          usaTrend: usaTrendReducer,
          waitingPeriod: waitingPeriodReducer,
          baseRateAmount: baseRateAmountReducer,
          baseRatePercent: baseRatePercentReducer,
          calendarYearLoad: calendarYearLoadReducer,

          dependentAge: dependentAgeReducer,
          deductibleCredit: deductibleCreditReducer,
          ehbTierDist: ehbTierDistReducer,
          nonParPayment: nonParPaymentReducer,
          orthodonticBaseRate: orthodonticBaseRateReducer,
          constantsAdmin: constantsAdminReducer,
          estimatedClaim: estimatedClaimReducer,
          estimatedClaimPlan: estimatedClaimPlanReducer,
          maxAmtExcluded: maxAmtExcludedReducer,
          occupation: occupationReducer,

          incurredClaims: incurredClaimsReducer,
          basicGroupInfo: basicGroupInfoReducer,
          groupKeys: groupKeysReducer,
    },
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: ['redux-oidc/USER_FOUND'],
          ignoredPaths: ['oidc.user'],
        },
      }).concat(routerMiddleware(history), createOidcMiddleware(userManager)),
    preloadedState,
  });
}
