import * as moment from 'moment';

function dateToString(inputDate) {
  return moment(inputDate).format('YYYY-MM-DD');
}

function nullableDateToString(inputDate) {
  return inputDate
    ? moment(inputDate).format('YYYY-MM-DD')
    : '2099-12-31T00:00:00';
}

function stringToDateFormat(inputString) {
  return new moment(inputString).toDate();
}

function stringToNullableDate(inputString) {
  return inputString === '2099-12-31T00:00:00' || inputString === '2099-12-31'
    ? ''
    : moment(inputString).toDate();
}

export {
  dateToString,
  nullableDateToString,
  stringToDateFormat,
  stringToNullableDate,
};
