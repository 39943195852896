import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Stack,
  StackDivider,
  VStack,
} from '@chakra-ui/react';

import {
  fetchStates,
  updateStates,
  selectStatesById,
  selectAllStates,
} from './statesSlice';
import { FieldGroup } from '../../components/common/FieldGroup';
import DatePicker from '../../components/common/DatePicker';
import { ErrorDisplay } from '../common/ErrorDisplay';
import { overlapCheck } from '../../services/overlapCheck';
import {
  dateToString,
  nullableDateToString,
  stringToDateFormat,
} from '../../services/dateConverters';

export const EditStatForm = ({ match, user }) => {
  const { statesId } = match.params;
  const stat = useSelector(state => selectStatesById(state, statesId));
  console.log(statesId);
  const statesList = useSelector(selectAllStates);

  const stdt = stringToDateFormat(stat.effStartDate);
  console.log(stdt);
  const endt = stringToDateFormat(stat.effEndDate);
  console.log(endt);

  const [stateAbbrev, setStateAbbrev] = useState(stat.stateAbbrev);
  const [areaId, setAreaId] = useState(stat.areaId);
  const [prm, setPrm] = useState(stat.prm);
  const [ppo, setPpo] = useState(stat.ppo);
  const [oonStd, setOonStd] = useState(stat.oonStd);
  const [oonNat, setOonNat] = useState(stat.oonNat);
  const [pctParDentist, setPctParDentist] = useState(stat.pctParDentist);
  const [ppoDiscount, setPpoDiscount] = useState(stat.ppoDiscount);
  const [eparegion, setEparegion] = useState(stat.eparegion);
  const [allowPpoentry, setAllowPpoentry] = useState(stat.allowPpoentry);
  // user.profile.name

  const [startDate, setEffStartDate] = useState(stdt);

  const [endDate, setEffEndDate] = useState(endt);
  const [updateRequestStatus, setUpdateRequestStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();
  const history = useHistory();

  const onStateAbbrevChanged = e => {
    setErrorMessage('');
    setStateAbbrev(e.target.value);
  };
  const onAreaIdChanged = e => {
    setErrorMessage('');
    setAreaId(Number(e.target.value));
  };
  const onPrmChanged = e => setPrm(Number(e.target.value));
  const onPpoChanged = e => setPpo(Number(e.target.value));
  const onOonStdChanged = e => setOonStd(Number(e.target.value));
  const onOonNatChanged = e => setOonNat(Number(e.target.value));
  const onPctParDentistChanged = e => setPctParDentist(Number(e.target.value));
  const onPpoDiscountChanged = e => setPpoDiscount(Number(e.target.value));
  const onEparegionChanged = e => setEparegion(Number(e.target.value));
  const onAllowPpoentryChanged = e => setAllowPpoentry(Number(e.target.value));
  const onEffStartDateChanged = e => {
    setEffStartDate(e);
    setErrorMessage('');
  };
  const onEffEndDateChanged = e => {
    setEffEndDate(e);
    setErrorMessage('');
  };

  console.log(stat);

  const statestatus = useSelector(state => state.Stat);
  console.log(statestatus);
  useEffect(() => {
    if (statestatus === 'idle') {
      dispatch(fetchStates());
    }
  }, [statestatus, dispatch]);

  const canSave =
    [
      stateAbbrev,
      !isNaN(areaId),
      !isNaN(prm),
      !isNaN(ppo),
      !isNaN(oonStd),
      !isNaN(oonNat),
      !isNaN(pctParDentist),
      !isNaN(ppoDiscount),
      !isNaN(eparegion),
      !isNaN(allowPpoentry),
      startDate,
    ].every(Boolean) && updateRequestStatus === 'idle';

  const onSaveStatesClicked = async () => {
    if (canSave) {
      try {
        setUpdateRequestStatus('pending');

        // Here I am wondering whether the range should be used? Areaid is discrete...

        var statesOverlap = statesList.filter(overlapCheck, {
          id: stat.id,
          checkType: 'range',
          effStartDate: startDate,
          effEndDate: endDate,
          keys: [
            {
              name: 'stateAbbrev',
              value: stateAbbrev,
            },
            {
              name: 'areaId',
              value: areaId,
            },
          ],
        });
        if (statesOverlap.length) {
          console.log(statesOverlap);
          setErrorMessage(
            'The record being entered overlaps an existing effective date range for this key value.'
          );
          return false;
        }

        const resultAction = await dispatch(
          updateStates({
            id: Number(statesId),
            stateAbbrev,
            areaId,
            prm,
            ppo,
            oonStd,
            oonNat,
            pctParDentist,
            ppoDiscount,
            eparegion,
            allowPpoentry,
            modifiedByName: user.profile.name,
            modifiedDate: new Date().toISOString(),
            effStartDate: dateToString(startDate),
            effEndDate: nullableDateToString(endDate),
          })
        );
        setUpdateRequestStatus('idle');
        history.push(`/states`);
      } catch (err) {
        setUpdateRequestStatus('idle');
        console.error('Failed to save the state record: ', err);
      }
    }
  };

  return (
    <Box
      px={{
        base: '4',
        md: '10',
      }}
      pb="16"
      maxWidth="3xl"
      mx="auto"
    >
      <form>
        <Stack spacing="4" divider={<StackDivider />}>
          <FieldGroup title="Edit State Record">
            <VStack width="full" spacing="6">
              <FormControl id="stateAbbrev">
                <FormLabel>State Abbreviation:</FormLabel>
                <Input
                  type="text"
                  value={stateAbbrev}
                  onChange={onStateAbbrevChanged}
                />
              </FormControl>
              <FormControl id="areaId">
                <FormLabel>Area Id:</FormLabel>
                <Input
                  type="number"
                  value={areaId}
                  onChange={onAreaIdChanged}
                />
              </FormControl>
              <FormControl id="prm">
                <FormLabel>PRM:</FormLabel>
                <Input type="number" value={prm} onChange={onPrmChanged} />
              </FormControl>
              <FormControl id="ppo">
                <FormLabel>PPO:</FormLabel>
                <Input type="number" value={ppo} onChange={onPpoChanged} />
              </FormControl>

              <FormControl id="oonStd">
                <FormLabel>Oon Std:</FormLabel>
                <Input type="text" value={oonStd} onChange={onOonStdChanged} />
              </FormControl>
              <FormControl id="oonNat">
                <FormLabel>Oon Nat:</FormLabel>
                <Input
                  type="number"
                  value={oonNat}
                  onChange={onOonNatChanged}
                />
              </FormControl>
              <FormControl id="pctParDentist">
                <FormLabel>Pct Par Dentist:</FormLabel>
                <Input
                  type="number"
                  value={pctParDentist}
                  onChange={onPctParDentistChanged}
                />
              </FormControl>
              <FormControl id="ppoDiscount">
                <FormLabel>PPO Discount:</FormLabel>
                <Input
                  type="number"
                  value={ppoDiscount}
                  onChange={onPpoDiscountChanged}
                />
              </FormControl>

              <FormControl id="eparegion">
                <FormLabel>Epa Region:</FormLabel>
                <Input
                  type="number"
                  value={eparegion}
                  onChange={onEparegionChanged}
                />
              </FormControl>
              <FormControl id="allowPpoentry">
                <FormLabel>Allow Ppo Entry:</FormLabel>
                <Input
                  type="number"
                  value={allowPpoentry}
                  onChange={onAllowPpoentryChanged}
                />
              </FormControl>

              <FormControl id="effStartDate">
                <FormLabel>Effective Start Date:</FormLabel>
                <DatePicker
                  id="effStartDate"
                  selectedDate={startDate}
                  onChange={onEffStartDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective Start Date</FormHelperText>
              </FormControl>
              <FormControl id="effEndDate">
                <FormLabel>Effective End Date:</FormLabel>
                <DatePicker
                  id="effEndDate"
                  selectedDate={endDate}
                  onChange={onEffEndDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective End Date</FormHelperText>
              </FormControl>
            </VStack>
          </FieldGroup>

          <Button
            onClick={onSaveStatesClicked}
            disabled={!canSave}
            variant="brand-primary"
            size="xs"
          >
            Save State Record
          </Button>
          <ErrorDisplay
            title="The record dates overlap other records!"
            errorMessage={errorMessage}
          />
        </Stack>
      </form>
    </Box>
  );
};

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
  };
}

export const EditStatFormUser = connect(mapStateToProps)(EditStatForm);
