import React from 'react';
import {
  Box,
  Button,
  Container,
  Text,
  Grid,
  Heading,
  HStack,
  Radio,
  RadioGroup,
  Select,
  Stack,
} from '@chakra-ui/react';
import BrandLinkButton from '../../components/common/BrandLinkButton';

function ConstantsPage() {
  return (
    <Box textAlign="left" fontSize="xl" p={8}>
      <Grid p={3}>
        <Stack>
          <Heading>Constants</Heading>
          <Text>Here the constant configuration values to be entered....</Text>

          <Container textAlign="left" p={8}>
            <Text>Choose the plan default options you wish to use.</Text>
            <Select placeholder="Option 0 - Nothing Selected">
              <option value="option1">Option 1 - Summer Package</option>
              <option value="option2">Option 2 - Fall Package</option>
              <option value="option3">Option 3 - Winter Package</option>
            </Select>
          </Container>
          <Container textAlign="left" p={8}>
            <Text>Choose the audio formats you wish to use.</Text>
            <RadioGroup defaultValue="2">
              <Stack spacing={5} direction="row">
                <Radio colorScheme="red" value="1">
                  Computer Based Audio
                </Radio>
                <Radio colorScheme="green" value="2">
                  External Speakers Audio
                </Radio>
              </Stack>
            </RadioGroup>
          </Container>

          <Container textAlign="left" p={8}>
            <HStack>
              <Button variant="brand-primary">Credibility</Button>) }
              <Button variant="brand-primary">Special Mix</Button>
              <Button variant="brand-primary">Pandemic</Button>
              <Button variant="brand-primary">Voluntary</Button>
            </HStack>
          </Container>
        </Stack>
      </Grid>
      <Box mt={12}>
        <BrandLinkButton path="/settings">Back to Settings</BrandLinkButton>
      </Box>
    </Box>
  );
}

export default ConstantsPage;
