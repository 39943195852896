import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import axios from 'axios';

const baseRateAmountAdapter = createEntityAdapter();
const apiBaseUrl = window.ENV.api_base_url;

const initialState = baseRateAmountAdapter.getInitialState({
  status: 'idle',
  error: null,
});

export const fetchBaseRateAmount = createAsyncThunk(
  'baseRateAmount/fetchBaseRateAmount',
  async (_, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };

    const response = await axios.get(
      `${apiBaseUrl}/api/BaseRateAmount`,
      config
    );
    return response.data;
  }
);

export const addNewBaseRateAmount = createAsyncThunk(
  'baseRateAmount/addNewBaseRateAmount',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.post(
      `${apiBaseUrl}/api/BaseRateAmount`,
      initialState,
      config
    );
    console.log('adding new baseRateAmount record');
    console.log(response);
    return { ...initialState, id: response.data };
  }
);

export const deleteBaseRateAmount = createAsyncThunk(
  'baseRateAmount/deleteBaseRateAmount',
  async (baseRateAmountId, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.delete(
      `${apiBaseUrl}/api/BaseRateAmount/${baseRateAmountId}`,
      config
    );
    return baseRateAmountId;
  }
);

export const updateBaseRateAmount = createAsyncThunk(
  'baseRateAmount/updateBaseRateAmount',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.put(
      `${apiBaseUrl}/api/BaseRateAmount/${initialState.id}`,
      initialState,
      config
    );
    return { ...initialState, id: response.data };
  }
);

const baseRateAmountSlice = createSlice({
  name: 'baseRateAmount',
  initialState,
  extraReducers: {
    [fetchBaseRateAmount.pending]: state => {
      state.status = 'loading';
    },
    [fetchBaseRateAmount.fulfilled]: (state, action) => {
      // Add any fetched posts to the array
      baseRateAmountAdapter.upsertMany(state, action.payload);
      state.status = 'succeeded';
    },
    [fetchBaseRateAmount.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [addNewBaseRateAmount.fulfilled]: baseRateAmountAdapter.addOne,
    [deleteBaseRateAmount.fulfilled]: baseRateAmountAdapter.removeOne,
    [updateBaseRateAmount.fulfilled]: baseRateAmountAdapter.upsertOne,
  },
});

export default baseRateAmountSlice.reducer;

export const {
  selectAll: selectAllBaseRateAmount,
  selectById: selectBaseRateAmountById,
  selectIds: selectBaseRateAmountIds,
} = baseRateAmountAdapter.getSelectors(state => state.baseRateAmount);
