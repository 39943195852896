import { Center, useStyleConfig } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

export default function BrandCenterNav(props) {
  const { variant, path, children, ...rest } = props;

  const styles = useStyleConfig('BrandCenter', { variant });
  const history = useHistory();

  return (
    <Center
      __css={styles}
      onClick={e => {
        e.preventDefault();
        history.push(path);
      }}
      {...rest}
    >
      {children}
    </Center>
  );
}
