import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import axios from 'axios';

const ehbTierDistAdapter = createEntityAdapter();
const apiBaseUrl = window.ENV.api_base_url;

const initialState = ehbTierDistAdapter.getInitialState({
  status: 'idle',
  error: null,
});

export const fetchEhbTierDist = createAsyncThunk(
  'ehbTierDist/fetchEhbTierDist',
  async (_, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };

    const response = await axios.get(`${apiBaseUrl}/api/EhbTierDist`, config);
    return response.data;
  }
);

export const addNewEhbTierDist = createAsyncThunk(
  'ehbTierDist/addNewEhbTierDist',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.post(
      `${apiBaseUrl}/api/EhbTierDist`,
      initialState,
      config
    );
    console.log('adding new ehbTierDist record');
    console.log(response);
    return { ...initialState, id: response.data };
  }
);

export const deleteEhbTierDist = createAsyncThunk(
  'ehbTierDist/deleteEhbTierDist',
  async (ehbTierDistId, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.delete(
      `${apiBaseUrl}/api/EhbTierDist/${ehbTierDistId}`,
      config
    );
    return ehbTierDistId;
  }
);

export const updateEhbTierDist = createAsyncThunk(
  'ehbTierDist/updateEhbTierDist',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.put(
      `${apiBaseUrl}/api/EhbTierDist/${initialState.id}`,
      initialState,
      config
    );
    return { ...initialState, id: response.data };
  }
);

const ehbTierDistSlice = createSlice({
  name: 'ehbTierDist',
  initialState,
  extraReducers: {
    [fetchEhbTierDist.pending]: state => {
      state.status = 'loading';
    },
    [fetchEhbTierDist.fulfilled]: (state, action) => {
      // Add any fetched posts to the array
      ehbTierDistAdapter.upsertMany(state, action.payload);
      state.status = 'succeeded';
    },
    [fetchEhbTierDist.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [addNewEhbTierDist.fulfilled]: ehbTierDistAdapter.addOne,
    [deleteEhbTierDist.fulfilled]: ehbTierDistAdapter.removeOne,
    [updateEhbTierDist.fulfilled]: ehbTierDistAdapter.upsertOne,
  },
});

export default ehbTierDistSlice.reducer;

export const {
  selectAll: selectAllEhbTierDist,
  selectById: selectEhbTierDistById,
  selectIds: selectEhbTierDistIds,
} = ehbTierDistAdapter.getSelectors(state => state.ehbTierDist);
