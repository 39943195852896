import React from 'react';
import { Box, Text, Grid, Heading, SimpleGrid, Stack } from '@chakra-ui/react';
import BrandCenterNav from '../../components/common/BrandCenterNav';

function SettingsPage() {
  return (
    <Box textAlign="left" fontSize="xl" p={8}>
      <Grid p={3}>
        <Stack>
          <Heading>Settings</Heading>
          <Text>
            Here the group ratings settings can be applied and modified.
          </Text>

          <SimpleGrid columns={2} spacing={8}>
            <BrandCenterNav variant="ctr-brand-primary" path="/Admins">
              Administration Factors
            </BrandCenterNav>

            <BrandCenterNav variant="ctr-brand-primary" path="/APCAso">
              Admin Per Claim Aso Factors
            </BrandCenterNav>

            <BrandCenterNav variant="ctr-brand-primary" path="/APRC">
              Affiliate Product Rate Change Factors
            </BrandCenterNav>

            <BrandCenterNav variant="ctr-brand-primary" path="/AgeAdult">
              Age Adult Factors
            </BrandCenterNav>
            <BrandCenterNav variant="ctr-brand-primary" path="/AgeChild">
              Age Child Factors
            </BrandCenterNav>

            <BrandCenterNav variant="ctr-brand-primary" path="/Area">
              Area Factors
            </BrandCenterNav>
            <BrandCenterNav variant="ctr-brand-primary" path="/AreaEhb">
              Area Ehb Factors
            </BrandCenterNav>

            <BrandCenterNav variant="ctr-brand-primary" path="/BaseRateAmount">
              Base Rate Amount Factors
            </BrandCenterNav>

            <BrandCenterNav variant="ctr-brand-primary" path="/BaseRatePercent">
              Base Rate Percent Factors
            </BrandCenterNav>
            <BrandCenterNav
              variant="ctr-brand-primary"
              path="/CalendarYearLoad"
            >
              Calendar Year Load Factors
            </BrandCenterNav>

            <BrandCenterNav variant="ctr-brand-primary" path="/ConstantsAdmin">
              Constant Admin Factors
            </BrandCenterNav>

            <BrandCenterNav variant="ctr-brand-primary" path="/Copay">
              Copay Factors
            </BrandCenterNav>

            <BrandCenterNav variant="ctr-brand-primary" path="/CountyArea">
              County Area Factors
            </BrandCenterNav>

            <BrandCenterNav variant="ctr-brand-primary" path="/Credibility">
              Credibility Factors
            </BrandCenterNav>

            <BrandCenterNav variant="ctr-brand-primary" path="/DeductAmt">
              Deductible Amount
            </BrandCenterNav>

            <BrandCenterNav
              variant="ctr-brand-primary"
              path="/DeductibleCredit"
            >
              Deductible Credit Factors
            </BrandCenterNav>

            <BrandCenterNav variant="ctr-brand-primary" path="/DependentAge">
              Dependent Age Factors
            </BrandCenterNav>

            <BrandCenterNav variant="ctr-brand-primary" path="/EhbRate">
              Ehb Rate Factors
            </BrandCenterNav>

            <BrandCenterNav variant="ctr-brand-primary" path="/EhbTierDist">
              EhbTierDist Factors
            </BrandCenterNav>

            <BrandCenterNav variant="ctr-brand-primary" path="/EstimatedClaim">
              Estimated Claims Factors
            </BrandCenterNav>
            <BrandCenterNav
              variant="ctr-brand-primary"
              path="/EstimatedClaimPlan"
            >
              Estimated Claim Plan Factors
            </BrandCenterNav>

            <BrandCenterNav variant="ctr-brand-primary" path="/IdcardCharge">
              Id Card Factors
            </BrandCenterNav>

            <BrandCenterNav variant="ctr-brand-primary" path="/MaximumAmount">
              Maximum Amount Factors
            </BrandCenterNav>
            <BrandCenterNav variant="ctr-brand-primary" path="/MaxAmtExcluded">
              Max Amount Excluded Factors
            </BrandCenterNav>

            <BrandCenterNav variant="ctr-brand-primary" path="/NonParPayment">
              Non Par Payment Factors
            </BrandCenterNav>
            <BrandCenterNav variant="ctr-brand-primary" path="/Occupation">
              Occupation Factors
            </BrandCenterNav>
            <BrandCenterNav
              variant="ctr-brand-primary"
              path="/OrthodonticBaseRate"
            >
              Orthodontic Base Rate Factors
            </BrandCenterNav>

            <BrandCenterNav variant="ctr-brand-primary" path="/Pandemic">
              Pandemic Factors
            </BrandCenterNav>
            <BrandCenterNav variant="ctr-brand-primary" path="/Roller">
              Rollover Info
            </BrandCenterNav>
            <BrandCenterNav variant="ctr-brand-primary" path="/SpecialMix">
              Special Mix Factors
            </BrandCenterNav>
            <BrandCenterNav variant="ctr-brand-primary" path="/States">
              States Info
            </BrandCenterNav>
            <BrandCenterNav variant="ctr-brand-primary" path="/UsaTrend">
              USA Trend Factors
            </BrandCenterNav>
            <BrandCenterNav variant="ctr-brand-primary" path="/Voluntary">
              Voluntary Factors
            </BrandCenterNav>
            <BrandCenterNav variant="ctr-brand-primary" path="/WaitingPeriod">
              Waiting Period Factors
            </BrandCenterNav>
          </SimpleGrid>
        </Stack>
      </Grid>
    </Box>
  );
}

export default SettingsPage;
