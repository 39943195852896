import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
  AccordionIcon,
  Heading,
} from '@chakra-ui/react';
import { fetchCopay, selectCopayIds, selectAllCopay } from './copaySlice';
import { AddCopayFormUser } from './AddCopayForm';
import { CopayList } from './CopayList';
import BrandLinkButton from '../../components/common/BrandLinkButton';

export const CopayPage = () => {
  const dispatch = useDispatch();

  const orderedCopayIds = useSelector(selectCopayIds);
  const copayList = useSelector(selectAllCopay);

  const error = useSelector(state => state.copay.error);
  if (error) {
    console.log('Copay List Error:' + error.toString());
  }

  const copayStatus = useSelector(state => state.copay.status);
  const [index, setIndex] = React.useState([]);

  useEffect(() => {
    if (copayStatus === 'idle') {
      dispatch(fetchCopay());
    }
  }, [copayStatus, dispatch]);

  function closeAccordion() {
    setIndex([]);
  }

  let content;

  if (copayStatus === 'loading') {
    content = <div className="loader">Loading...</div>;
  } else if (copayStatus === 'succeeded') {
    console.log(orderedCopayIds);
    content = (
      <div>
        <Heading size="xl" mb={8}>
          Copay Factors
        </Heading>
        <Accordion
          allowMultiple
          allowToggle="true"
          mb={8}
          index={index}
          onChange={setIndex}
        >
          <AccordionItem>
            <Box>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Text variant="brand-green-link" fontSize="lg">
                    Add New Factor
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </Box>
            <AccordionPanel pb={4}>
              <AddCopayFormUser
                copayList={copayList}
                closeAccordion={closeAccordion}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <CopayList orderedCopayIds={orderedCopayIds} />
      </div>
    );
  } else if (copayStatus === 'failed') {
    content = <div>{error}</div>;
  }

  return (
    <>
      {content}
      <Box mt={12}>
        <BrandLinkButton path="/settings">Back to Settings</BrandLinkButton>
      </Box>
    </>
  );
};

export default CopayPage;
