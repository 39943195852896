import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Select,
  Stack,
  StackDivider,
  VStack,
} from '@chakra-ui/react';

import {
  fetchBaseRateAmount,
  updateBaseRateAmount,
  selectBaseRateAmountById,
  selectAllBaseRateAmount,
} from './baseRateAmountSlice';
import { FieldGroup } from '../../components/common/FieldGroup';
import DatePicker from '../../components/common/DatePicker';
import { ErrorDisplay } from '../common/ErrorDisplay';
import { overlapCheck } from '../../services/overlapCheck';
import {
  dateToString,
  nullableDateToString,
  stringToDateFormat,
} from '../../services/dateConverters';

export const EditBaseRateAmountForm = ({ match, user }) => {
  const { baseRateAmountId } = match.params;
  const baseRateAmount = useSelector(state =>
    selectBaseRateAmountById(state, baseRateAmountId)
  );
  const baseRateAmountList = useSelector(selectAllBaseRateAmount);

  const stdt = stringToDateFormat(baseRateAmount.effStartDate);
  console.log(stdt);
  const endt = stringToDateFormat(baseRateAmount.effEndDate);
  console.log(endt);

  const [category, setCategory] = useState(baseRateAmount.category);
  const [description, setDescription] = useState(baseRateAmount.description);
  const [employee, setEmployee] = useState(baseRateAmount.employee);
  const [spouse, setSpouse] = useState(baseRateAmount.spouse);
  const [child, setChild] = useState(baseRateAmount.child);

  const [startDate, setEffStartDate] = useState(stdt);

  const [endDate, setEffEndDate] = useState(endt);
  const [updateRequestStatus, setUpdateRequestStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();
  const history = useHistory();

  const onCategoryChanged = e => {
    setCategory(e.target.value);
    setErrorMessage('');
  };
  const onDescriptionChanged = e => setDescription(e.target.value);
  const onEmployeeChanged = e => setEmployee(Number(e.target.value));
  const onSpouseChanged = e => setSpouse(Number(e.target.value));
  const onChildChanged = e => setChild(Number(e.target.value));

  const onEffStartDateChanged = e => {
    setEffStartDate(e);
    setErrorMessage('');
  };
  const onEffEndDateChanged = e => {
    setEffEndDate(e);
    setErrorMessage('');
  };
  const canSave =
    [
      category,
      description,
      !isNaN(employee),
      !isNaN(spouse),
      !isNaN(child),
      startDate,
    ].every(Boolean) && updateRequestStatus === 'idle';

  const onSaveBaseRateAmountClicked = async () => {
    if (canSave) {
      try {
        setUpdateRequestStatus('pending');

        var baseRateAmountOverlap = baseRateAmountList.filter(overlapCheck, {
          id: baseRateAmount.id,
          effStartDate: startDate,
          effEndDate: endDate,
          keys: [
            {
              name: 'category',
              value: category,
            },
            {
              name: 'description',
              value: description,
            },
          ],
        });
        if (baseRateAmountOverlap.length) {
          console.log(baseRateAmountOverlap);
          setErrorMessage(
            'The record being entered overlaps an existing effective date range for this key value.'
          );
          return false;
        }

        const resultAction = await dispatch(
          updateBaseRateAmount({
            id: Number(baseRateAmountId),
            category,
            description,
            employee,
            spouse,
            child,
            modifiedByName: user.profile.name,
            modifiedDate: new Date().toISOString(),
            effStartDate: dateToString(startDate),
            effEndDate: nullableDateToString(endDate),
          })
        );
        console.log(resultAction);
        setUpdateRequestStatus('idle');
        history.push(`/baseRateAmount`);
      } catch (err) {
        setUpdateRequestStatus('idle');
        console.error('Failed to save the baseRateAmount factor: ', err);
      }
    }
  };
  return (
    <Box
      px={{
        base: '4',
        md: '10',
      }}
      pb="16"
      maxWidth="3xl"
      mx="auto"
    >
      <form>
        <Stack spacing="4" divider={<StackDivider />}>
          <FieldGroup title="Edit Administration Factors">
            <VStack width="full" spacing="6">
              <FormControl id="category">
                <FormLabel>Category:</FormLabel>
                <Input
                  type="text"
                  value={category}
                  onChange={onCategoryChanged}
                />
              </FormControl>
              <FormControl id="description">
                <FormLabel>Description:</FormLabel>
                <Input
                  type="text"
                  value={description}
                  onChange={onDescriptionChanged}
                />
              </FormControl>
              <FormControl id="employee">
                <FormLabel>Employee:</FormLabel>
                <Input
                  type="number"
                  value={employee}
                  onChange={onEmployeeChanged}
                />
              </FormControl>
              <FormControl id="spouse">
                <FormLabel>Spouse:</FormLabel>
                <Input
                  type="number"
                  value={spouse}
                  onChange={onSpouseChanged}
                />
              </FormControl>

              <FormControl id="child">
                <FormLabel>Child:</FormLabel>
                <Input type="number" value={child} onChange={onChildChanged} />
              </FormControl>

              <FormControl id="effStartDate">
                <FormLabel>Effective Start Date:</FormLabel>
                <DatePicker
                  id="effStartDate"
                  selectedDate={startDate}
                  onChange={onEffStartDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective Start Date</FormHelperText>
              </FormControl>
              <FormControl id="effEndDate">
                <FormLabel>Effective End Date:</FormLabel>
                <DatePicker
                  id="effEndDate"
                  selectedDate={endDate}
                  onChange={onEffEndDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective End Date</FormHelperText>
              </FormControl>
            </VStack>
          </FieldGroup>

          <Button
            onClick={onSaveBaseRateAmountClicked}
            disabled={!canSave}
            variant="brand-primary"
            size="xs"
          >
            Save BaseRateAmount Factor
          </Button>
          <ErrorDisplay
            title="The record dates overlap other records!"
            errorMessage={errorMessage}
          />
        </Stack>
      </form>
    </Box>
  );
};

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
  };
}

export const EditBaseRateAmountFormUser = connect(mapStateToProps)(
  EditBaseRateAmountForm
);
