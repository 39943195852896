import React, { useEffect } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalHeader,
  ModalFooter,
  ModalContent,
  ModalCloseButton,
  Heading,
  useDisclosure,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchEstimatedClaim,
  selectEstimatedClaimIds,
  selectEstimatedClaimById,
  deleteEstimatedClaim,
} from './estimatedClaimSlice';
import { unwrapResult } from '@reduxjs/toolkit';

import { NavLink } from 'react-router-dom';
import { FormattedDate } from '../common/FormattedDate';
import { FormattedTimestamp } from '../common/FormattedTimestamp';

const ItemDetails = ({ currentItem }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button onClick={onOpen} size="xs">
        Details
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Estimated Claims: Yr ID {currentItem.standardYearId}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Table size="sm">
              <Tbody>
                <Tr>
                  <Th>StandardYearId</Th>
                  <Th>EmployeeEstClaims</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.standardYearId}</Td>
                  <Td>{currentItem.employeeEstClaims}</Td>
                </Tr>
                <Tr>
                  <Th>SpouseEstClaims</Th>
                  <Th>ChildEstClaims</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.spouseEstClaims}</Td>
                  <Td>{currentItem.childEstClaims}</Td>
                </Tr>
                <Tr>
                  <Th>OrthoClaims</Th>
                  <Th>MajorWeight</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.orthoClaims}</Td>
                  <Td>{currentItem.majorWeight}</Td>
                </Tr>
                <Tr>
                  <Th>OtherWeight</Th>
                  <Th>PrevWeight</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.otherWeight}</Td>
                  <Td>{currentItem.prevWeight}</Td>
                </Tr>

                <Tr>
                  <Th>ProstoWeight</Th>
                  <Th>RadioWeight</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.prostoWeight}</Td>
                  <Td>{currentItem.radioWeight}</Td>
                </Tr>
                <Tr>
                  <Th>Modified By Name</Th>
                  <Th>Modified Date</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.modifiedByName}</Td>
                  <Td>
                    <FormattedTimestamp
                      dateToFormat={currentItem.modifiedDate}
                    />
                  </Td>
                </Tr>
                <Tr>
                  <Th>Eff Start Date</Th>
                  <Th>Eff End Date</Th>
                </Tr>
                <Tr>
                  <Td>
                    <FormattedDate dateToFormat={currentItem.effStartDate} />
                  </Td>
                  <Td>
                    <FormattedDate dateToFormat={currentItem.effEndDate} />
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="brand-primary-sm"
              mr={3}
              onClick={onClose}
              size="sm"
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

let EstimatedClaimTable = ({ estimatedClaimIdList }) => {
  let tableRows = estimatedClaimIdList.map(estimatedClaimId => (
    <EstimatedClaim
      estimatedClaimId={estimatedClaimId}
      key={estimatedClaimId}
    />
  ));

  return (
    <Box overflowX="auto">
      <Table size="sm">
        <Thead>
          <Tr>
            <Th></Th>
            <Th>Standard YearId</Th>
            <Th>Employee EstClaims</Th>
            <Th>Spouse EstClaims</Th>
            <Th>Child EstClaims</Th>
            <Th>Ortho Claims</Th>
            <Th>Major Weight</Th>
            <Th>Other Weight</Th>
            <Th>Prev Weight</Th>
            <Th>Prosto Weight</Th>
            <Th>Radio Weight</Th>

            {/*<Th>Modified By Name</Th>*/}
            <Th>Modified Date</Th>
            <Th>Eff Start Date</Th>
            <Th>Eff End Date</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>{tableRows}</Tbody>
      </Table>
    </Box>
  );
};

let EstimatedClaim = ({ estimatedClaimId }) => {
  const estimatedClaim = useSelector(state =>
    selectEstimatedClaimById(state, estimatedClaimId)
  );

  const dispatch = useDispatch();

  const onDeleteEstimatedClaimClicked = async () => {
    try {
      const resultAction = await dispatch(
        deleteEstimatedClaim(estimatedClaim.id)
      );
      unwrapResult(resultAction);
    } catch (err) {
      console.error('Failed to save the estimatedClaim record', err);
    }
  };

  return (
    <Tr>
      <Td>
        <ItemDetails currentItem={estimatedClaim} />
      </Td>
      <Td>{estimatedClaim.standardYearId}</Td>
      <Td>{estimatedClaim.employeeEstClaims}</Td>
      <Td>{estimatedClaim.spouseEstClaims}</Td>
      <Td>{estimatedClaim.childEstClaims}</Td>
      <Td>{estimatedClaim.orthoClaims}</Td>
      <Td>{estimatedClaim.majorWeight}</Td>
      <Td>{estimatedClaim.otherWeight}</Td>
      <Td>{estimatedClaim.prevWeight}</Td>
      <Td>{estimatedClaim.prostoWeight}</Td>
      <Td>{estimatedClaim.radioWeight}</Td>
      {/* <Td>{estimatedClaim.modifiedByName}</Td>*/}
      <Td>
        <FormattedTimestamp dateToFormat={estimatedClaim.modifiedDate} />
      </Td>
      <Td>
        <FormattedDate dateToFormat={estimatedClaim.effStartDate} />
      </Td>
      <Td>
        <FormattedDate dateToFormat={estimatedClaim.effEndDate} />
      </Td>
      <Td>
        <ButtonGroup variant="outline">
          <Button
            colorScheme="gray"
            size="sm"
            as={NavLink}
            to={`/editEstimatedClaim/${estimatedClaim.id}`}
          >
            Edit
          </Button>
          <Button
            colorScheme="red"
            size="sm"
            onClick={onDeleteEstimatedClaimClicked}
          >
            Delete
          </Button>
        </ButtonGroup>
      </Td>
    </Tr>
  );
};

export const EstimatedClaimList = () => {
  const dispatch = useDispatch();
  const orderedEstimatedClaimIds = useSelector(selectEstimatedClaimIds);
  console.log('in the roller list');
  const error = useSelector(state => state.estimatedClaim.error);

  const estimatedClaimStatus = useSelector(
    state => state.estimatedClaim.status
  );
  console.log(estimatedClaimStatus);
  useEffect(() => {
    if (estimatedClaimStatus === 'idle') {
      dispatch(fetchEstimatedClaim());
    }
  }, [estimatedClaimStatus, dispatch]);

  let content;

  if (estimatedClaimStatus === 'loading') {
    content = <div className="loader">Loading...</div>;
  } else if (estimatedClaimStatus === 'succeeded') {
    content = (
      <EstimatedClaimTable estimatedClaimIdList={orderedEstimatedClaimIds} />
    );
  } else if (estimatedClaimStatus === 'failed') {
    content = <div>{error}</div>;
  }

  return (
    <section>
      <h2>
        <b>EstimatedClaim Listing</b>
      </h2>
      {content}
    </section>
  );
};
