import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import axios from 'axios';

const ehbRateAdapter = createEntityAdapter();
const apiBaseUrl = window.ENV.api_base_url;

const initialState = ehbRateAdapter.getInitialState({
  status: 'idle',
  error: null,
});

export const fetchEhbRate = createAsyncThunk(
  'ehbRate/fetchEhbRate',
  async (_, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };

    const response = await axios.get(`${apiBaseUrl}/api/EhbRate`, config);
    return response.data;
  }
);

export const addNewEhbRate = createAsyncThunk(
  'ehbRate/addNewEhbRate',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.post(
      `${apiBaseUrl}/api/EhbRate`,
      initialState,
      config
    );
    console.log('adding new ehbRate record');
    console.log(response);
    return { ...initialState, id: response.data };
  }
);

export const deleteEhbRate = createAsyncThunk(
  'ehbRate/deleteEhbRate',
  async (ehbRateId, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.delete(
      `${apiBaseUrl}/api/EhbRate/${ehbRateId}`,
      config
    );
    return ehbRateId;
  }
);

export const updateEhbRate = createAsyncThunk(
  'ehbRate/updateEhbRate',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.put(
      `${apiBaseUrl}/api/EhbRate/${initialState.id}`,
      initialState,
      config
    );
    return { ...initialState, id: response.data };
  }
);

const ehbRateSlice = createSlice({
  name: 'ehbRate',
  initialState,
  extraReducers: {
    [fetchEhbRate.pending]: state => {
      state.status = 'loading';
    },
    [fetchEhbRate.fulfilled]: (state, action) => {
      // Add any fetched posts to the array
      ehbRateAdapter.upsertMany(state, action.payload);
      state.status = 'succeeded';
    },
    [fetchEhbRate.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [addNewEhbRate.fulfilled]: ehbRateAdapter.addOne,
    [deleteEhbRate.fulfilled]: ehbRateAdapter.removeOne,
    [updateEhbRate.fulfilled]: ehbRateAdapter.upsertOne,
  },
});

export default ehbRateSlice.reducer;

export const {
  selectAll: selectAllEhbRate,
  selectById: selectEhbRateById,
  selectIds: selectEhbRateIds,
} = ehbRateAdapter.getSelectors(state => state.ehbRate);
