import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import axios from 'axios';

const categoryAdapter = createEntityAdapter();
const apiBaseUrl = window.ENV.api_base_url;

const initialState = categoryAdapter.getInitialState({
  status: 'idle',
  error: null,
});

export const fetchCategories = createAsyncThunk(
  'categories/fetchCategories',
  async (_, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };

    const response = await axios.get(
      `${apiBaseUrl}/api/RatingCategory`,
      config
    );
    return response.data;
  }
);

export const addNewCategory = createAsyncThunk(
  'categories/addNewCategory',
  async (initialCategory, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.post(
      `${apiBaseUrl}/api/RatingCategory`,
      initialCategory,
      config
    );
    return { ...initialCategory, id: response.data };
  }
);

export const deleteCategory = createAsyncThunk(
  'categories/deleteCategory',
  async (categoryId, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.delete(
      `${apiBaseUrl}/api/RatingCategory/${categoryId}`,
      config
    );
    return categoryId;
  }
);

export const updateCategory = createAsyncThunk(
  'categories/updateCategory',
  async (initialCategory, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.put(
      `${apiBaseUrl}/api/RatingCategory/${initialCategory.id}`,
      initialCategory,
      config
    );
    return { ...initialCategory, id: response.data };
  }
);

const ratingCategorySlice = createSlice({
  name: 'categories',
  initialState,
  extraReducers: {
    [fetchCategories.pending]: state => {
      state.status = 'loading';
    },
    [fetchCategories.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      // Add any fetched posts to the array
      categoryAdapter.upsertMany(state, action.payload);
    },
    [fetchCategories.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [addNewCategory.fulfilled]: categoryAdapter.addOne,
    [deleteCategory.fulfilled]: categoryAdapter.removeOne,
    [updateCategory.fulfilled]: categoryAdapter.upsertOne,
  },
});

export default ratingCategorySlice.reducer;

export const {
  selectAll: selectAllCategories,
  selectById: selectCategoryById,
  selectIds: selectCategoryIds,
} = categoryAdapter.getSelectors(state => state.categories);
