import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import axios from 'axios';

const pandemicAdapter = createEntityAdapter();
const apiBaseUrl = window.ENV.api_base_url;

const initialState = pandemicAdapter.getInitialState({
  status: 'idle',
  error: null,
});

export const fetchPandemic = createAsyncThunk(
  'pandemic/fetchPandemic',
  async (_, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };

    const response = await axios.get(`${apiBaseUrl}/api/Pandemic`, config);
    return response.data;
  }
);

export const addNewPandemic = createAsyncThunk(
  'pandemic/addNewPandemic',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.post(
      `${apiBaseUrl}/api/Pandemic`,
      initialState,
      config
    );
    console.log('adding new pandemic record');
    console.log(response);
    return { ...initialState, id: response.data };
  }
);

export const deletePandemic = createAsyncThunk(
  'pandemic/deletePandemic',
  async (pandemicId, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.delete(
      `${apiBaseUrl}/api/Pandemic/${pandemicId}`,
      config
    );
    return pandemicId;
  }
);

export const updatePandemic = createAsyncThunk(
  'pandemic/updatePandemic',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.put(
      `${apiBaseUrl}/api/Pandemic/${initialState.id}`,
      initialState,
      config
    );
    return { ...initialState, id: response.data };
  }
);

const pandemicSlice = createSlice({
  name: 'pandemic',
  initialState,
  extraReducers: {
    [fetchPandemic.pending]: state => {
      state.status = 'loading';
    },
    [fetchPandemic.fulfilled]: (state, action) => {
      // Add any fetched posts to the array
      pandemicAdapter.upsertMany(state, action.payload);
      state.status = 'succeeded';
    },
    [fetchPandemic.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [addNewPandemic.fulfilled]: pandemicAdapter.addOne,
    [deletePandemic.fulfilled]: pandemicAdapter.removeOne,
    [updatePandemic.fulfilled]: pandemicAdapter.upsertOne,
  },
});

export default pandemicSlice.reducer;

export const {
  selectAll: selectAllPandemic,
  selectById: selectPandemicById,
  selectIds: selectPandemicIds,
} = pandemicAdapter.getSelectors(state => state.pandemic);
