import React, { useEffect } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalHeader,
  ModalFooter,
  ModalContent,
  ModalCloseButton,
  Heading,
  useDisclosure,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchUsaTrend,
  selectUsaTrendIds,
  selectUsaTrendById,
  deleteUsaTrend,
} from './usaTrendSlice';
import { unwrapResult } from '@reduxjs/toolkit';

import { NavLink } from 'react-router-dom';
import { FormattedDate } from '../common/FormattedDate';
import { FormattedTimestamp } from '../common/FormattedTimestamp';

const ItemDetails = ({ currentItem }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button onClick={onOpen} size="xs">
        Details
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>UsaTrend Number: {currentItem.state}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Table size="sm">
              <Tbody>
                <Tr>
                  <Th>State</Th>
                  <Th>Premier</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.state}</Td>
                  <Td>{currentItem.premier}</Td>
                </Tr>
                <Tr>
                  <Th>Ppo</Th>
                  <Th> </Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.ppo}</Td>
                  <Td> </Td>
                </Tr>

                <Tr>
                  <Th>Modified By Name</Th>
                  <Th>Modified Date</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.modifiedByName}</Td>
                  <Td>
                    <FormattedTimestamp
                      dateToFormat={currentItem.modifiedDate}
                    />
                  </Td>
                </Tr>
                <Tr>
                  <Th>Eff Start Date</Th>
                  <Th>Eff End Date</Th>
                </Tr>
                <Tr>
                  <Td>
                    <FormattedDate dateToFormat={currentItem.effStartDate} />
                  </Td>
                  <Td>
                    <FormattedDate dateToFormat={currentItem.effEndDate} />
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="brand-primary-sm"
              mr={3}
              onClick={onClose}
              size="sm"
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

let UsaTrendTable = ({ usaTrendIdList }) => {
  let tableRows = usaTrendIdList.map(usaTrendId => (
    <UsaTrend usaTrendId={usaTrendId} key={usaTrendId} />
  ));

  return (
    <Box overflowX="auto">
      <Table size="sm">
        <Thead>
          <Tr>
            <Th></Th>
            <Th>State</Th>
            <Th>Premier</Th>
            <Th>Ppo</Th>

            <Th>Modified By Name</Th>
            <Th>Modified Date</Th>
            <Th>Eff Start Date</Th>
            <Th>Eff End Date</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>{tableRows}</Tbody>
      </Table>
    </Box>
  );
};

let UsaTrend = ({ usaTrendId }) => {
  const usaTrend = useSelector(state => selectUsaTrendById(state, usaTrendId));

  const dispatch = useDispatch();

  const onDeleteUsaTrendClicked = async () => {
    try {
      const resultAction = await dispatch(deleteUsaTrend(usaTrend.id));
      unwrapResult(resultAction);
    } catch (err) {
      console.error('Failed to save the UsaTrend record', err);
    }
  };

  return (
    <Tr>
      <Td>
        <ItemDetails currentItem={usaTrend} />
      </Td>
      <Td>{usaTrend.state}</Td>
      <Td>{usaTrend.premier}</Td>
      <Td>{usaTrend.ppo}</Td>

      <Td>{usaTrend.modifiedByName}</Td>
      <Td>
        <FormattedTimestamp dateToFormat={usaTrend.modifiedDate} />
      </Td>
      <Td>
        <FormattedDate dateToFormat={usaTrend.effStartDate} />
      </Td>
      <Td>
        <FormattedDate dateToFormat={usaTrend.effEndDate} />
      </Td>
      <Td>
        <ButtonGroup variant="outline">
          <Button
            colorScheme="gray"
            size="sm"
            as={NavLink}
            to={`/editUsaTrend/${usaTrend.id}`}
          >
            Edit
          </Button>
          <Button colorScheme="red" size="sm" onClick={onDeleteUsaTrendClicked}>
            Delete
          </Button>
        </ButtonGroup>
      </Td>
    </Tr>
  );
};

export const UsaTrendList = () => {
  const dispatch = useDispatch();
  const orderedUsaTrendIds = useSelector(selectUsaTrendIds);
  console.log('in the roller list');
  const error = useSelector(state => state.usaTrend.error);

  const usaTrendStatus = useSelector(state => state.usaTrend.status);
  console.log(usaTrendStatus);
  useEffect(() => {
    if (usaTrendStatus === 'idle') {
      dispatch(fetchUsaTrend());
    }
  }, [usaTrendStatus, dispatch]);

  let content;

  if (usaTrendStatus === 'loading') {
    content = <div className="loader">Loading...</div>;
  } else if (usaTrendStatus === 'succeeded') {
    content = <UsaTrendTable usaTrendIdList={orderedUsaTrendIds} />;
  } else if (usaTrendStatus === 'failed') {
    content = <div>{error}</div>;
  }

  return (
    <section>
      <h2>
        <b>Usa Trend Listing</b>
      </h2>
      {content}
    </section>
  );
};
