import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Select,
  Stack,
  StackDivider,
  VStack,
} from '@chakra-ui/react';

import {
  fetchAgeAdult,
  updateAgeAdult,
  selectAgeAdultById,
  selectAllAgeAdult,
} from './ageAdultSlice';
import { FieldGroup } from '../../components/common/FieldGroup';
import DatePicker from '../../components/common/DatePicker';
import { ErrorDisplay } from '../common/ErrorDisplay';
import { overlapCheck } from '../../services/overlapCheck';
import {
  dateToString,
  nullableDateToString,
  stringToDateFormat,
} from '../../services/dateConverters';

export const EditAgeAdultForm = ({ match, user }) => {
  const { ageAdultId } = match.params;
  const ageAdult = useSelector(state => selectAgeAdultById(state, ageAdultId));
  const ageAdultList = useSelector(selectAllAgeAdult);

  const stdt = stringToDateFormat(ageAdult.effStartDate);
  console.log(stdt);
  const endt = stringToDateFormat(ageAdult.effEndDate);
  console.log(endt);

  const [low, setLow] = useState(ageAdult.low);
  const [high, setHigh] = useState(ageAdult.high);
  const [preventive, setPreventive] = useState(ageAdult.preventive);
  const [radiographs, setRadiographs] = useState(ageAdult.radiographs);
  const [majorRestorative, setMajorRestorative] = useState(
    ageAdult.majorRestorative
  );
  const [otherClass2, setOtherClass2] = useState(ageAdult.otherClass2);
  const [prosthodontics, setProsthodontics] = useState(ageAdult.prosthodontics);

  const [startDate, setEffStartDate] = useState(stdt);

  const [endDate, setEffEndDate] = useState(endt);
  const [updateRequestStatus, setUpdateRequestStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();
  const history = useHistory();

  const onLowChanged = e => {
    setLow(Number(e.target.value));
    setErrorMessage('');
  };
  const onHighChanged = e => setHigh(Number(e.target.value));
  const onPreventiveChanged = e => setPreventive(Number(e.target.value));
  const onRadiographsChanged = e => setRadiographs(Number(e.target.value));
  const onMajorRestorativeChanged = e =>
    setMajorRestorative(Number(e.target.value));
  const onOtherClass2Changed = e => setOtherClass2(Number(e.target.value));
  const onProsthodonticsChanged = e =>
    setProsthodontics(Number(e.target.value));

  const onEffStartDateChanged = e => {
    setEffStartDate(e);
    setErrorMessage('');
  };
  const onEffEndDateChanged = e => {
    setEffEndDate(e);
    setErrorMessage('');
  };

  const canSave =
    [
      !isNaN(low),
      !isNaN(high),
      !isNaN(preventive),
      !isNaN(radiographs),
      !isNaN(majorRestorative),
      !isNaN(otherClass2),
      !isNaN(prosthodontics),

      startDate,
    ].every(Boolean) && updateRequestStatus === 'idle';

  const onSaveAgeAdultClicked = async () => {
    if (canSave) {
      try {
        setUpdateRequestStatus('pending');

        var ageAdultOverlap = ageAdultList.filter(overlapCheck, {
          id: ageAdult.id,
          checkType: 'range',
          effStartDate: startDate,
          effEndDate: endDate,
          keys: [
            {
              name: 'low',
              value: Number(low),
            },
            {
              name: 'high',
              value: Number(high),
            },
          ],
        });
        if (ageAdultOverlap.length) {
          console.log(ageAdultOverlap);
          setErrorMessage(
            'The record being entered overlaps an existing effective date range for this key value.'
          );
          setUpdateRequestStatus('idle');
          return false;
        }

        const resultAction = await dispatch(
          updateAgeAdult({
            id: Number(ageAdultId),
            low,
            high,
            preventive,
            radiographs,
            majorRestorative,
            otherClass2,
            prosthodontics,
            modifiedByName: user.profile.name,
            modifiedDate: new Date().toISOString(),
            effStartDate: dateToString(startDate),
            effEndDate: nullableDateToString(endDate),
          })
        );
        console.log(resultAction);
        setUpdateRequestStatus('idle');
        history.push(`/ageAdult`);
      } catch (err) {
        setUpdateRequestStatus('idle');
        console.error('Failed to save the administration factor: ', err);
      }
    }
  };
  return (
    <Box
      px={{
        base: '4',
        md: '10',
      }}
      pb="16"
      maxWidth="3xl"
      mx="auto"
    >
      <form>
        <Stack spacing="4" divider={<StackDivider />}>
          <FieldGroup title="Edit Administration Factors">
            <VStack width="full" spacing="6">
              <FormControl id="low">
                <FormLabel>Low:</FormLabel>
                <Input type="number" value={low} onChange={onLowChanged} />
              </FormControl>
              <FormControl id="high">
                <FormLabel>High:</FormLabel>
                <Input type="number" value={high} onChange={onHighChanged} />
              </FormControl>
              <FormControl id="preventive">
                <FormLabel>Preventive:</FormLabel>
                <Input
                  type="number"
                  value={preventive}
                  onChange={onPreventiveChanged}
                />
              </FormControl>
              <FormControl id="radiographs">
                <FormLabel>Radiographs:</FormLabel>
                <Input
                  type="number"
                  value={radiographs}
                  onChange={onRadiographsChanged}
                />
              </FormControl>

              <FormControl id="majorRestorative">
                <FormLabel>MajorRestorative:</FormLabel>
                <Input
                  type="number"
                  value={majorRestorative}
                  onChange={onMajorRestorativeChanged}
                />
              </FormControl>
              <FormControl id="otherClass2">
                <FormLabel>OtherClass2:</FormLabel>
                <Input
                  type="number"
                  value={otherClass2}
                  onChange={onOtherClass2Changed}
                />
              </FormControl>
              <FormControl id="prosthodontics">
                <FormLabel>Prosthodontics:</FormLabel>
                <Input
                  type="number"
                  value={prosthodontics}
                  onChange={onProsthodonticsChanged}
                />
              </FormControl>

              <FormControl id="effStartDate">
                <FormLabel>Effective Start Date:</FormLabel>
                <DatePicker
                  id="effStartDate"
                  selectedDate={startDate}
                  onChange={onEffStartDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective Start Date</FormHelperText>
              </FormControl>
              <FormControl id="effEndDate">
                <FormLabel>Effective End Date:</FormLabel>
                <DatePicker
                  id="effEndDate"
                  selectedDate={endDate}
                  onChange={onEffEndDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective End Date</FormHelperText>
              </FormControl>
            </VStack>
          </FieldGroup>

          <Button
            onClick={onSaveAgeAdultClicked}
            disabled={!canSave}
            variant="brand-primary"
            size="xs"
          >
            Save AgeAdult Factor
          </Button>
          <ErrorDisplay
            title="The record dates overlap other records!"
            errorMessage={errorMessage}
          />
        </Stack>
      </form>
    </Box>
  );
};

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
  };
}

export const EditAgeAdultFormUser = connect(mapStateToProps)(EditAgeAdultForm);
