import React, { useEffect } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalHeader,
  ModalFooter,
  ModalContent,
  ModalCloseButton,
  Heading,
  useDisclosure,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchNonParPayment,
  selectNonParPaymentIds,
  selectNonParPaymentById,
  deleteNonParPayment,
} from './nonParPaymentSlice';
import { unwrapResult } from '@reduxjs/toolkit';

import { NavLink } from 'react-router-dom';
import { FormattedDate } from '../common/FormattedDate';
import { FormattedTimestamp } from '../common/FormattedTimestamp';

const ItemDetails = ({ currentItem }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button onClick={onOpen} size="xs">
        Details
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Non Par Payment: {currentItem.progDesc}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Table size="sm">
              <Tbody>
                <Tr>
                  <Th>ProgDesc</Th>
                  <Th>PpopaymentFactor</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.progDesc}</Td>
                  <Td>{currentItem.ppopaymentFactor}</Td>
                </Tr>
                <Tr>
                  <Th>PremierPaymentFactor</Th>
                  <Th>NonParPaymentFactor</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.premierPaymentFactor}</Td>
                  <Td>{currentItem.nonParPaymentFactor}</Td>
                </Tr>

                <Tr>
                  <Th>Modified By Name</Th>
                  <Th>Modified Date</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.modifiedByName}</Td>
                  <Td>
                    <FormattedTimestamp
                      dateToFormat={currentItem.modifiedDate}
                    />
                  </Td>
                </Tr>
                <Tr>
                  <Th>Eff Start Date</Th>
                  <Th>Eff End Date</Th>
                </Tr>
                <Tr>
                  <Td>
                    <FormattedDate dateToFormat={currentItem.effStartDate} />
                  </Td>
                  <Td>
                    <FormattedDate dateToFormat={currentItem.effEndDate} />
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="brand-primary-sm"
              mr={3}
              onClick={onClose}
              size="sm"
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

let NonParPaymentTable = ({ nonParPaymentIdList }) => {
  let tableRows = nonParPaymentIdList.map(nonParPaymentId => (
    <NonParPayment nonParPaymentId={nonParPaymentId} key={nonParPaymentId} />
  ));

  return (
    <Box overflowX="auto">
      <Table size="sm">
        <Thead>
          <Tr>
            <Th></Th>
            <Th>ProgDesc</Th>
            <Th>PpopaymentFactor</Th>
            <Th>PremierPaymentFactor</Th>
            <Th>NonParPaymentFactor</Th>

            <Th>Modified By Name</Th>
            <Th>Modified Date</Th>
            <Th>Eff Start Date</Th>
            <Th>Eff End Date</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>{tableRows}</Tbody>
      </Table>
    </Box>
  );
};

let NonParPayment = ({ nonParPaymentId }) => {
  const nonParPayment = useSelector(state =>
    selectNonParPaymentById(state, nonParPaymentId)
  );
  //console.log('In the nonParPayment list');

  const dispatch = useDispatch();

  const onDeleteNonParPaymentClicked = async () => {
    try {
      const resultAction = await dispatch(
        deleteNonParPayment(nonParPayment.id)
      );
      unwrapResult(resultAction);
    } catch (err) {
      console.error('Failed to save the nonParPayment record', err);
    }
  };

  return (
    <Tr>
      <Td>
        <ItemDetails currentItem={nonParPayment} />
      </Td>
      <Td>{nonParPayment.progDesc}</Td>
      <Td>{nonParPayment.ppopaymentFactor}</Td>
      <Td>{nonParPayment.premierPaymentFactor}</Td>
      <Td>{nonParPayment.nonParPaymentFactor}</Td>

      <Td>{nonParPayment.modifiedByName}</Td>
      <Td>
        <FormattedTimestamp dateToFormat={nonParPayment.modifiedDate} />
      </Td>
      <Td>
        <FormattedDate dateToFormat={nonParPayment.effStartDate} />
      </Td>
      <Td>
        <FormattedDate dateToFormat={nonParPayment.effEndDate} />
      </Td>
      <Td>
        <ButtonGroup variant="outline">
          <Button
            colorScheme="gray"
            size="sm"
            as={NavLink}
            to={`/editNonParPayment/${nonParPayment.id}`}
          >
            Edit
          </Button>
          <Button
            colorScheme="red"
            size="sm"
            onClick={onDeleteNonParPaymentClicked}
          >
            Delete
          </Button>
        </ButtonGroup>
      </Td>
    </Tr>
  );
};

export const NonParPaymentList = () => {
  const dispatch = useDispatch();
  const orderedNonParPaymentIds = useSelector(selectNonParPaymentIds);
  console.log('in the roller list');
  const error = useSelector(state => state.nonParPayment.error);

  const nonParPaymentStatus = useSelector(state => state.nonParPayment.status);
  console.log(nonParPaymentStatus);
  useEffect(() => {
    if (nonParPaymentStatus === 'idle') {
      dispatch(fetchNonParPayment());
    }
  }, [nonParPaymentStatus, dispatch]);

  let content;

  if (nonParPaymentStatus === 'loading') {
    content = <div className="loader">Loading...</div>;
  } else if (nonParPaymentStatus === 'succeeded') {
    content = (
      <NonParPaymentTable nonParPaymentIdList={orderedNonParPaymentIds} />
    );
  } else if (nonParPaymentStatus === 'failed') {
    content = <div>{error}</div>;
  }

  return (
    <section>
      <h2>
        <b>Non Par Payment Listing</b>
      </h2>
      {content}
    </section>
  );
};
