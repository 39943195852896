import { Button } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

export default function BrandCenterNav(props) {
  const { path, children, ...rest } = props;
  const history = useHistory();

  return (
    <Button
      variant="brand-primary"
      onClick={e => {
        e.preventDefault();
        history.push(path);
      }}
      {...rest}
    >
      {children}
    </Button>
  );
}
