import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Select,
  Stack,
  StackDivider,
  VStack,
} from '@chakra-ui/react';

import {
  fetchNonParPayment,
  updateNonParPayment,
  selectNonParPaymentById,
  selectAllNonParPayment,
} from './nonParPaymentSlice';
import { FieldGroup } from '../../components/common/FieldGroup';
import DatePicker from '../../components/common/DatePicker';
import { ErrorDisplay } from '../common/ErrorDisplay';
import { overlapCheck } from '../../services/overlapCheck';
import {
  dateToString,
  nullableDateToString,
  stringToDateFormat,
} from '../../services/dateConverters';

export const EditNonParPaymentForm = ({ match, user }) => {
  const { nonParPaymentId } = match.params;
  const nonParPayment = useSelector(state =>
    selectNonParPaymentById(state, nonParPaymentId)
  );
  const nonParPaymentList = useSelector(selectAllNonParPayment);

  const stdt = stringToDateFormat(nonParPayment.effStartDate);
  console.log(stdt);
  const endt = stringToDateFormat(nonParPayment.effEndDate);
  console.log(endt);

  const [progDesc, setProgDesc] = useState(nonParPayment.progDesc);
  const [ppopaymentFactor, setPpopaymentFactor] = useState(
    nonParPayment.ppopaymentFactor
  );
  const [premierPaymentFactor, setPremierPaymentFactor] = useState(
    nonParPayment.premierPaymentFactor
  );
  const [nonParPaymentFactor, setNonParPaymentFactor] = useState(
    nonParPayment.nonParPaymentFactor
  );

  const [startDate, setEffStartDate] = useState(stdt);

  const [endDate, setEffEndDate] = useState(endt);
  const [updateRequestStatus, setUpdateRequestStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();
  const history = useHistory();

  const onProgDescChanged = e => {
    setProgDesc(e.target.value);
    setErrorMessage('');
  };
  const onPpopaymentFactorChanged = e =>
    setPpopaymentFactor(Number(e.target.value));
  const onPremierPaymentFactorChanged = e =>
    setPremierPaymentFactor(Number(e.target.value));
  const onNonParPaymentFactorChanged = e =>
    setNonParPaymentFactor(Number(e.target.value));

  const onEffStartDateChanged = e => {
    setEffStartDate(e);
    setErrorMessage('');
  };
  const onEffEndDateChanged = e => {
    setEffEndDate(e);
    setErrorMessage('');
  };

  const canSave =
    [
      progDesc,
      !isNaN(ppopaymentFactor),
      !isNaN(premierPaymentFactor),
      !isNaN(nonParPaymentFactor),

      startDate,
    ].every(Boolean) && updateRequestStatus === 'idle';

  const onSaveNonParPaymentClicked = async () => {
    if (canSave) {
      try {
        setUpdateRequestStatus('pending');

        var nonParPaymentOverlap = nonParPaymentList.filter(overlapCheck, {
          id: nonParPayment.id,
          effStartDate: startDate,
          effEndDate: endDate,
          keys: [
            {
              name: 'progDesc',
              value: progDesc,
            },
          ],
        });
        if (nonParPaymentOverlap.length) {
          console.log(nonParPaymentOverlap);
          setErrorMessage(
            'The record being entered overlaps an existing effective date range for this key value.'
          );
          return false;
        }

        const resultAction = await dispatch(
          updateNonParPayment({
            id: Number(nonParPaymentId),
            progDesc,
            ppopaymentFactor,
            premierPaymentFactor,
            nonParPaymentFactor,
            modifiedByName: user.profile.name,
            modifiedDate: new Date().toISOString(),
            effStartDate: dateToString(startDate),
            effEndDate: nullableDateToString(endDate),
          })
        );
        console.log(resultAction);
        setUpdateRequestStatus('idle');
        history.push(`/nonParPayment`);
      } catch (err) {
        setUpdateRequestStatus('idle');
        console.error('Failed to save the non par payment factor: ', err);
      }
    }
  };
  return (
    <Box
      px={{
        base: '4',
        md: '10',
      }}
      pb="16"
      maxWidth="3xl"
      mx="auto"
    >
      <form>
        <Stack spacing="4" divider={<StackDivider />}>
          <FieldGroup title="Edit Non Par Payment Factors">
            <VStack width="full" spacing="6">
              <FormControl id="progDesc">
                <FormLabel>ProgDesc:</FormLabel>
                <Input
                  type="text"
                  value={progDesc}
                  onChange={onProgDescChanged}
                />
              </FormControl>
              <FormControl id="ppopaymentFactor">
                <FormLabel>PpopaymentFactor:</FormLabel>
                <Input
                  type="number"
                  value={ppopaymentFactor}
                  onChange={onPpopaymentFactorChanged}
                />
              </FormControl>
              <FormControl id="premierPaymentFactor">
                <FormLabel>PremierPaymentFactor:</FormLabel>
                <Input
                  type="number"
                  value={premierPaymentFactor}
                  onChange={onPremierPaymentFactorChanged}
                />
              </FormControl>
              <FormControl id="nonParPaymentFactor">
                <FormLabel>NonParPaymentFactor:</FormLabel>
                <Input
                  type="number"
                  value={nonParPaymentFactor}
                  onChange={onNonParPaymentFactorChanged}
                />
              </FormControl>

              <FormControl id="effStartDate">
                <FormLabel>Effective Start Date:</FormLabel>
                <DatePicker
                  id="effStartDate"
                  selectedDate={startDate}
                  onChange={onEffStartDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective Start Date</FormHelperText>
              </FormControl>
              <FormControl id="effEndDate">
                <FormLabel>Effective End Date:</FormLabel>
                <DatePicker
                  id="effEndDate"
                  selectedDate={endDate}
                  onChange={onEffEndDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective End Date</FormHelperText>
              </FormControl>
            </VStack>
          </FieldGroup>

          <Button
            onClick={onSaveNonParPaymentClicked}
            disabled={!canSave}
            variant="brand-primary"
            size="xs"
          >
            Save NonParPayment Factor
          </Button>
          <ErrorDisplay
            title="The record dates overlap other records!"
            errorMessage={errorMessage}
          />
        </Stack>
      </form>
    </Box>
  );
};

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
  };
}

export const EditNonParPaymentFormUser = connect(mapStateToProps)(
  EditNonParPaymentForm
);
