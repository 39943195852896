import React, { useEffect } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalHeader,
  ModalFooter,
  ModalContent,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchAdmins,
  selectAdminsIds,
  selectAdminsById,
  deleteAdmins,
} from './administrationSlice';
import { unwrapResult } from '@reduxjs/toolkit';

import { NavLink } from 'react-router-dom';
import { FormattedDate } from '../common/FormattedDate';
import { FormattedTimestamp } from '../common/FormattedTimestamp';

const ItemDetails = ({ currentItem }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button onClick={onOpen} size="xs">
        Details
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Administration: (Low: {currentItem.low} - High: {currentItem.high})
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Table size="sm">
              <Tbody>
                <Tr>
                  <Th>New Claims Expense</Th>
                  <Th>Claims Expense</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.newClaimsExpense}</Td>
                  <Td>{currentItem.claimsExpense}</Td>
                </Tr>
                <Tr>
                  <Th>Risk Expense</Th>
                  <Th>Premium Tax</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.riskexpense}</Td>
                  <Td>{currentItem.premiumtax}</Td>
                </Tr>
                <Tr>
                  <Th>Aca Tax</Th>
                  <Th>Total Retention</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.acatax}</Td>
                  <Td>{currentItem.totalRetention}</Td>
                </Tr>
                <Tr>
                  <Th>New Retention</Th>
                  <Th>Surplus</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.newRetention}</Td>
                  <Td>{currentItem.surplus}</Td>
                </Tr>
                <Tr>
                  <Th>Add Admin</Th>
                  <Th>Group Fee</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.addAdmin}</Td>
                  <Td>{currentItem.groupFee}</Td>
                </Tr>
                <Tr>
                  <Th>Flat</Th>
                  <Th>Minimum</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.flat}</Td>
                  <Td>{currentItem.minimum}</Td>
                </Tr>
                <Tr>
                  <Th>Group Size</Th>
                  <Th>Step</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.groupSize}</Td>
                  <Td>{currentItem.step}</Td>
                </Tr>
                <Tr>
                  <Th>Employee</Th>
                  <Th>Spouse</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.employee}</Td>
                  <Td>{currentItem.spouse}</Td>
                </Tr>
                <Tr>
                  <Th>Child</Th>
                  <Th> </Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.child}</Td>
                  <Td> </Td>
                </Tr>

                <Tr>
                  <Th>Modified By Name</Th>
                  <Th>Modified Date</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.modifiedByName}</Td>
                  <Td>
                    <FormattedTimestamp
                      dateToFormat={currentItem.modifiedDate}
                    />
                  </Td>
                </Tr>
                <Tr>
                  <Th>Eff Start Date</Th>
                  <Th>Eff End Date</Th>
                </Tr>
                <Tr>
                  <Td>
                    <FormattedDate dateToFormat={currentItem.effStartDate} />
                  </Td>
                  <Td>
                    <FormattedDate dateToFormat={currentItem.effEndDate} />
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="brand-primary-sm"
              mr={3}
              onClick={onClose}
              size="sm"
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

let AdminsTable = ({ adminsIdList }) => {
  let tableRows = adminsIdList.map(adminsId => (
    <Admins adminsId={adminsId} key={adminsId} />
  ));

  return (
    <Box overflowX="auto">
      <Table size="sm">
        <Thead>
          <Tr>
            <Th></Th>
            <Th>Low</Th>
            <Th>High</Th>
            <Th>New Claims Expense</Th>
            <Th>Claims Expense</Th>
            <Th>Risk Expense</Th>

            {/*<Th>Premium Tax</Th>*/}
            {/*<Th>Acatax</Th>*/}

            <Th>Total Retention</Th>
            <Th>New Retention</Th>
            {/*<Th>Surplus</Th>*/}
            {/*<Th>Add Admin</Th>*/}
            {/*<Th>Group Fee</Th>*/}
            {/*<Th>Flat</Th>*/}
            {/*<Th>Minimum</Th>*/}

            {/*<Th>Group Size</Th>*/}
            {/*<Th>Step</Th>*/}
            {/*<Th>Employee</Th>*/}
            {/*<Th>Spouse</Th>*/}
            {/*<Th>Child</Th>*/}
            <Th>Modified By Name</Th>
            <Th>Modified Date</Th>
            <Th>Eff Start Date</Th>
            <Th>Eff End Date</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>{tableRows}</Tbody>
      </Table>
    </Box>
  );
};

let Admins = ({ adminsId }) => {
  const admins = useSelector(state => selectAdminsById(state, adminsId));
  //console.log('In the administration factor list');

  const dispatch = useDispatch();

  const onDeleteAdminsClicked = async () => {
    try {
      const resultAction = await dispatch(deleteAdmins(admins.id));
      unwrapResult(resultAction);
    } catch (err) {
      console.error('Failed to save the administration factor record', err);
    }
  };

  return (
    <Tr>
      <Td>
        <ItemDetails currentItem={admins} />
      </Td>

      <Td>{admins.low}</Td>
      <Td>{admins.high}</Td>
      <Td>{admins.newClaimsExpense}</Td>
      <Td>{admins.claimsExpense}</Td>
      <Td>{admins.riskExpense}</Td>

      {/*<Td>{admins.premiumTax}</Td>*/}
      {/*<Td>{admins.acatax}</Td>*/}

      <Td>{admins.totalRetention}</Td>
      <Td>{admins.newRetention}</Td>
      {/*<Td>{admins.surplus}</Td>*/}
      {/*<Td>{admins.addAdmin}</Td>*/}
      {/*<Td>{admins.groupFee}</Td>*/}
      {/*<Td>{admins.flat}</Td>*/}
      {/*<Td>{admins.minimum}</Td>*/}

      {/*<Td>{admins.groupSize}</Td>*/}
      {/*<Td>{admins.step}</Td>*/}
      {/*<Td>{admins.employee}</Td>*/}
      {/*<Td>{admins.spouse}</Td>*/}
      {/*<Td>{admins.child}</Td>*/}
      <Td>{admins.modifiedByName}</Td>
      <Td>
        <FormattedTimestamp dateToFormat={admins.modifiedDate} />
      </Td>
      <Td>
        <FormattedDate dateToFormat={admins.effStartDate} />
      </Td>
      <Td>
        <FormattedDate dateToFormat={admins.effEndDate} />
      </Td>
      <Td>
        <ButtonGroup variant="outline">
          <Button
            colorScheme="gray"
            size="sm"
            as={NavLink}
            to={`/editAdmins/${admins.id}`}
          >
            Edit
          </Button>
          <Button colorScheme="red" size="sm" onClick={onDeleteAdminsClicked}>
            Delete
          </Button>
        </ButtonGroup>
      </Td>
    </Tr>
  );
};

export const AdministrationList = () => {
  const dispatch = useDispatch();
  const orderedAdminsIds = useSelector(selectAdminsIds);

  const error = useSelector(state => state.administration.error);

  const adminStatus = useSelector(state => state.administration.status);

  useEffect(() => {
    if (adminStatus === 'idle') {
      dispatch(fetchAdmins());
    }
  }, [adminStatus, dispatch]);

  let content;

  if (adminStatus === 'loading') {
    content = <div className="loader">Loading...</div>;
  } else if (adminStatus === 'succeeded') {
    content = <AdminsTable adminsIdList={orderedAdminsIds} />;
  } else if (adminStatus === 'failed') {
    content = <div>{error}</div>;
  }

  return (
    <section>
      <h2>
        <b>Administration Factor Listing</b>
      </h2>
      {content}
    </section>
  );
};
