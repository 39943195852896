import React, { useState } from 'react';
import {
  Box,
  Text,
  Grid,
  Heading,
  Stack,
  Checkbox,
  Button,
  Container,
  Select,
  FormControl,
  FormLabel,
  FormHelperText,
  CheckboxGroup,
  RadioGroup,
  Radio,
} from '@chakra-ui/react';

import DatePicker from '../common/DatePicker';

function HomePage() {
  const [publishedDate, setPublishedDate] = useState(new Date().now);
  const onPublishedDateChanged = function (e) {
    setPublishedDate(e);
  };

  return (
    <Box textAlign="left" fontSize="xl" p={8}>
      <Grid p={3}>
        <Stack>
          <Heading>Style Guide - Heading</Heading>
          <Text>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris in
            enim varius ligula sagittis pharetra vel sit amet sapien. Maecenas
            varius feugiat tellus a luctus. Praesent iaculis nibh nec lectus
            sollicitudin volutpat. Nam dignissim hendrerit risus, eu mattis
            sapien molestie sit amet. Quisque tellus purus, tincidunt sed justo
            vitae, viverra ultricies tortor. Donec sed metus dui. Suspendisse
            lacinia cursus condimentum. Vestibulum a maximus leo. Maecenas
            mattis est mi, ut accumsan tellus pharetra id. Cras et posuere nibh.
            Nam posuere elit urna, sit amet pharetra ante dignissim sagittis.
          </Text>
          <Text>
            Fusce gravida malesuada lacus et congue. Proin tempus ut libero at
            tincidunt. Cras et lectus elit. Proin elit augue, viverra et nunc
            eget, bibendum dapibus erat. Proin aliquam quam at dolor elementum
            blandit. In varius at neque consequat pellentesque. Donec porttitor
            erat quis velit porta, blandit consequat sapien accumsan. Cras
            consectetur, nunc sit amet faucibus hendrerit, sem purus tincidunt
            leo, eu luctus enim augue id quam.
          </Text>
        </Stack>
      </Grid>
      <Container textAlign="left" p={8}>
        <FormControl>
          <FormLabel>Check some boxes</FormLabel>
          <CheckboxGroup>
            <Box alignItems="left">
              <Checkbox defaultChecked>Checkbox</Checkbox>
            </Box>
            <Box alignItems="left">
              <Checkbox>Unchecked</Checkbox>
            </Box>
          </CheckboxGroup>
        </FormControl>
      </Container>
      <Container textAlign="left" p={8}>
        <FormControl>
          <FormLabel>Radio group</FormLabel>
          <RadioGroup>
            <Box alignItems="left">
              <Radio defaultChecked value="1">
                Option 1
              </Radio>
            </Box>
            <Box alignItems="left">
              <Radio value="2">Option 2</Radio>
            </Box>
          </RadioGroup>
        </FormControl>
      </Container>
      <Container textAlign="left" p={8}>
        <Button variant="brand-primary">Primary Button</Button>
        <Button variant="brand-secondary">Secondary Button</Button>
      </Container>
      <Container textAlign="left" p={8}>
        <FormControl>
          <FormLabel>Select Option</FormLabel>
          <Select>
            <option>Option 1</option>
            <option>Option 2</option>
            <option>Option 3</option>
          </Select>
        </FormControl>
      </Container>
      <Container textAlign="left" p={8}>
        <FormControl>
          <FormLabel htmlFor="published-date">Published Date</FormLabel>
          <DatePicker
            id="published-date"
            selectedDate={publishedDate}
            onChange={onPublishedDateChanged}
            showPopperArrow={true}
          />
          <FormHelperText>Date this widget was published</FormHelperText>
        </FormControl>
      </Container>
    </Box>
  );
}

export default HomePage;
