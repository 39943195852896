import React, { useEffect } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalHeader,
  ModalFooter,
  ModalContent,
  ModalCloseButton,
  Heading,
  useDisclosure,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchBasicGroupInfo,
  selectBasicGroupInfoById,
  selectBasicGroupInfoIds,
} from './basicGroupInfoSlice';
import { unwrapResult } from '@reduxjs/toolkit';

import { NavLink } from 'react-router-dom';
import { FormattedDate } from '../common/FormattedDate';
import { FormattedTimestamp } from '../common/FormattedTimestamp';

const ItemDetails = ({ currentItem }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button onClick={onOpen} size="xs">
        Details
      </Button>
    </>
  );
};

let BasicGroupInfoTable = ({ basicGroupInfoIdList }) => {
  let tableRows = basicGroupInfoIdList.map(basicGroupInfoId => (
    <BasicGroupInfo
      basicGroupInfoId={basicGroupInfoId}
      key={basicGroupInfoId}
    />
  ));

  return (
    <Box overflowX="auto">
      <Table size="sm">
        <Thead>
          <Tr>
            <Th></Th>
            <Th>Group Name</Th>
            <Th>Group Number</Th>
            <Th>Sales REp</Th>
            <Th>Renewal Date</Th>

            <Th>Contract Length</Th>
            <Th>Experieince Period Months</Th>
            <Th>Current Rate EE</Th>

            <Th>Current RAte EESP</Th>
            <Th>Current Rate EESPCH</Th>
            <Th>Current Rate EECH</Th>

            <Th>Current Rate EEChildren</Th>
            <Th>Current Rate EEFamily</Th>
            <Th>Sales Commission</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>{tableRows}</Tbody>
      </Table>
    </Box>
  );
};

let BasicGroupInfo = ({ basicGroupInfoId }) => {
  const basicGroupInfo = useSelector(state =>
    selectBasicGroupInfoById(state, basicGroupInfoId)
  );
  //console.log('In the copay list');

  const dispatch = useDispatch();

  /*const onDeleteCopayClicked = async () => {
    try {
      const resultAction = await dispatch(deleteCopay(copay.id));
      unwrapResult(resultAction);
    } catch (err) {
      console.error('Failed to save the copay record', err);
    }
  };*/

  return (
    <Tr>
      <Td>
        <ItemDetails currentItem={basicGroupInfo} />
      </Td>
      <Td>{basicGroupInfo.groupName}</Td>
      <Td>{basicGroupInfo.groupNumber}</Td>
      <Td>{basicGroupInfo.sales_Rep}</Td>
      <Td>{basicGroupInfo.renewalDate}</Td>

      <Td>{basicGroupInfo.contractLength}</Td>
      <Td>{basicGroupInfo.experiencePeriodMonths}</Td>
      <Td>{basicGroupInfo.currentRateEE}</Td>
      <Td>{basicGroupInfo.currentRateEESP}</Td>
      <Td>{basicGroupInfo.CurrentRateEESPCH}</Td>
      <Td>{basicGroupInfo.currentRateEECH}</Td>
      <Td>{basicGroupInfo.currentRateEEChildren}</Td>
      <Td>{basicGroupInfo.currentRateFamily}</Td>
      <Td>{basicGroupInfo.sales_Commission}</Td>
      <Td>Button Placeholder</Td>
    </Tr>
  );
};

export const BasicGroupInfoList = () => {
  const dispatch = useDispatch();
  const orderedBasicGroupInfoIds = useSelector(selectBasicGroupInfoIds);
  console.log('in the basic group list');
  const error = useSelector(state => state.basicGroupInfo.error);

  const basicGroupInfoStatus = useSelector(
    state => state.basicGroupInfo.status
  );
  console.log(basicGroupInfoStatus);
  useEffect(() => {
    if (basicGroupInfoStatus === 'idle') {
      dispatch(fetchBasicGroupInfo());
    }
  }, [basicGroupInfoStatus, dispatch]);

  let content;

  if (basicGroupInfoStatus === 'loading') {
    content = <div className="loader">Loading...</div>;
  } else if (basicGroupInfoStatus === 'succeeded') {
    content = (
      <BasicGroupInfoTable basicGroupInfoIdList={orderedBasicGroupInfoIds} />
    );
  } else if (basicGroupInfoStatus === 'failed') {
    content = <div>{error}</div>;
  }

  return (
    <section>
      <h2>
        <b>Basic Group Info Listing</b>
      </h2>
      {content}
    </section>
  );
};
