import React from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import userManager from '../../services/userManager';

const PrivateRoute = ({ component: Component, path, ...rest }) => {
  const { isAuthenticated } = rest;

  if (!isAuthenticated) {
    userManager.signinRedirect({
      state: { redirectUrl: window.location.pathname },
    });
  }

  return (
    <Route
      path={path}
      {...rest}
      component={props => <Component {...props} />}
    />
  );
};

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
    isAuthenticated: state.oidc.user && !state.oidc.user.expired,
  };
}

export default connect(mapStateToProps)(PrivateRoute);
