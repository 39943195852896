import React from 'react';
import {
  Box,
  Button,
  Text,
  Grid,
  Heading,
  SimpleGrid,
  Stack,
} from '@chakra-ui/react';
import BrandLinkButton from '../../components/common/BrandLinkButton';

function StatisticalFactorsPage() {
  return (
    <Box textAlign="left" fontSize="xl" p={8}>
      <Grid p={3}>
        <Stack>
          <Heading>Statistical Factors</Heading>
          <Text>
            Here the group ratings' statistical data settings can be applied and
            modified.
          </Text>
          <SimpleGrid columns={4} spacing={10}>
            <Button colorScheme="blue">Area</Button>
            <Button colorScheme="blue">Area(EHB)</Button>
            <Button colorScheme="blue">Calendar Year Loads</Button>
            <Button colorScheme="blue">Constants</Button>
            <Button colorScheme="blue">USA States</Button>
            <Button colorScheme="blue">USA Trend</Button>
            <Button colorScheme="blue">Waiting Period</Button>
            <Button colorScheme="blue">Statistical Value 1</Button>
            <Button colorScheme="blue">Statistical Value 2</Button>
          </SimpleGrid>
        </Stack>
      </Grid>
      <Box mt={12}>
        <BrandLinkButton path="/settings">Back to Settings</BrandLinkButton>
      </Box>
    </Box>
  );
}

export default StatisticalFactorsPage;
