import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import axios from 'axios';

const specialMixAdapter = createEntityAdapter();
const apiBaseUrl = window.ENV.api_base_url;

const initialState = specialMixAdapter.getInitialState({
  status: 'idle',
  error: null,
});

export const fetchSpecialMix = createAsyncThunk(
  'specialMix/fetchSpecialMix',
  async (_, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };

    const response = await axios.get(`${apiBaseUrl}/api/SpecialMix`, config);
    return response.data;
  }
);

export const addNewSpecialMix = createAsyncThunk(
  'specialMix/addNewSpecialMix',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.post(
      `${apiBaseUrl}/api/SpecialMix`,
      initialState,
      config
    );
    console.log('adding new specialMix record');
    console.log(response);
    return { ...initialState, id: response.data };
  }
);

export const deleteSpecialMix = createAsyncThunk(
  'specialMix/deleteSpecialMix',
  async (specialMixId, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.delete(
      `${apiBaseUrl}/api/SpecialMix/${specialMixId}`,
      config
    );
    return specialMixId;
  }
);

export const updateSpecialMix = createAsyncThunk(
  'specialMix/updateSpecialMix',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.put(
      `${apiBaseUrl}/api/SpecialMix/${initialState.id}`,
      initialState,
      config
    );
    return { ...initialState, id: response.data };
  }
);

const specialMixSlice = createSlice({
  name: 'specialMix',
  initialState,
  extraReducers: {
    [fetchSpecialMix.pending]: state => {
      state.status = 'loading';
    },
    [fetchSpecialMix.fulfilled]: (state, action) => {
      // Add any fetched posts to the array
      specialMixAdapter.upsertMany(state, action.payload);
      state.status = 'succeeded';
    },
    [fetchSpecialMix.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [addNewSpecialMix.fulfilled]: specialMixAdapter.addOne,
    [deleteSpecialMix.fulfilled]: specialMixAdapter.removeOne,
    [updateSpecialMix.fulfilled]: specialMixAdapter.upsertOne,
  },
});

export default specialMixSlice.reducer;

export const {
  selectAll: selectAllSpecialMix,
  selectById: selectSpecialMixById,
  selectIds: selectSpecialMixIds,
} = specialMixAdapter.getSelectors(state => state.specialMix);
