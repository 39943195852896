import React, { useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Button,
  CloseButton,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Stack,
  StackDivider,
  VStack,
} from '@chakra-ui/react';

import { addNewEhbTierDist } from './ehbTierDistSlice';
import { FieldGroup } from '../../components/common/FieldGroup';
import DatePicker from '../../components/common/DatePicker';
import { ErrorDisplay } from '../common/ErrorDisplay';
import { overlapCheck } from '../../services/overlapCheck';
import {
  dateToString,
  nullableDateToString,
} from '../../services/dateConverters';

export const AddEhbTierDistForm = ({ user, ehbTierDistList }) => {
  const [ehbRateType, setEhbRateType] = useState(' ');
  const [tierPercent, setTierPercent] = useState(0);

  const [startDate, setEffStartDate] = useState(new Date());
  const [endDate, setEffEndDate] = useState('');
  const [addRequestStatus, setAddRequestStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();

  const onEhbRateTypeChanged = e => {
    setEhbRateType(e.target.value);
    setErrorMessage('');
  };

  const onTierPercentChanged = e => setTierPercent(Number(e.target.value));

  const onEffStartDateChanged = e => {
    setEffStartDate(e);
    setErrorMessage('');
  };
  const onEffEndDateChanged = e => {
    setEffEndDate(e);
    setErrorMessage('');
  };

  const canSave =
    [ehbRateType, !isNaN(tierPercent), startDate].every(Boolean) &&
    addRequestStatus === 'idle';

  const onSaveEhbTierDistClicked = async () => {
    if (canSave) {
      try {
        setAddRequestStatus('pending');

        var ehbTierDistOverlap = ehbTierDistList.filter(overlapCheck, {
          effStartDate: startDate,
          effEndDate: endDate,
          keys: [
            {
              name: 'ehbRateType',
              value: ehbRateType,
            },
          ],
        });
        if (ehbTierDistOverlap.length) {
          console.log(ehbTierDistOverlap);
          setErrorMessage(
            'The record being entered overlaps an existing effective date range for this key value.'
          );
          return false;
        }

        const resultAction = await dispatch(
          addNewEhbTierDist({
            ehbRateType,
            tierPercent,
            modifiedByName: user.profile.name,
            modifiedDate: new Date().toISOString(),
            effStartDate: dateToString(startDate),
            effEndDate: nullableDateToString(endDate),
          })
        );

        unwrapResult(resultAction);

        setEhbRateType('');
        setTierPercent(0);

        setEffStartDate(new Date());
        setEffEndDate('');
      } catch (err) {
        console.error('Failed to save the ehbTierDist factor record: ', err);
      } finally {
        setAddRequestStatus('idle');
      }
    }
  };

  return (
    <Box
      px={{
        base: '4',
        md: '10',
      }}
      pb="16"
      maxWidth="3xl"
      mx="auto"
    >
      <form>
        <Stack spacing="4" divider={<StackDivider />}>
          <FieldGroup title="Add EhbTierDist Record">
            <VStack width="full" spacing="6">
              <FormControl id="ehbRateType">
                <FormLabel>EhbRateType:</FormLabel>
                <Input
                  type="text"
                  value={ehbRateType}
                  onChange={onEhbRateTypeChanged}
                />
              </FormControl>
              <FormControl id="tierPercent">
                <FormLabel>TierPercent:</FormLabel>
                <Input
                  type="number"
                  value={tierPercent}
                  onChange={onTierPercentChanged}
                />
              </FormControl>

              <FormControl>
                <FormLabel htmlFor="effStartDate">
                  Effective Start Date
                </FormLabel>
                <DatePicker
                  id="effStartDate"
                  selectedDate={startDate}
                  onChange={onEffStartDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective Start Date</FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="effEndDate">Effective End Date</FormLabel>
                <DatePicker
                  id="effEndDate"
                  selectedDate={endDate}
                  onChange={onEffEndDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective End Date</FormHelperText>
              </FormControl>
            </VStack>
          </FieldGroup>

          <Button
            onClick={onSaveEhbTierDistClicked}
            disabled={!canSave}
            variant="brand-primary"
            size="xs"
          >
            Save EhbTierDist Factor Record
          </Button>
          <ErrorDisplay
            title="The record dates overlap other records!"
            errorMessage={errorMessage}
          />
        </Stack>
      </form>
    </Box>
  );
};

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
  };
}

export const AddEhbTierDistFormUser =
  connect(mapStateToProps)(AddEhbTierDistForm);
