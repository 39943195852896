import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
  AccordionIcon,
  Heading,
} from '@chakra-ui/react';
import {
  fetchEhbRate,
  selectEhbRateIds,
  selectAllEhbRate,
} from './ehbRateSlice';
import { AddEhbRateFormUser } from './AddEhbRateForm';
import { EhbRateList } from './EhbRateList';
import BrandLinkButton from '../../components/common/BrandLinkButton';

export const EhbRatePage = () => {
  const dispatch = useDispatch();

  const orderedEhbRateIds = useSelector(selectEhbRateIds);
  const ehbRateList = useSelector(selectAllEhbRate);

  const error = useSelector(state => state.ehbRate.error);
  if (error) {
    console.log('EhbRate List Error:' + error.toString());
  }

  const ehbRateStatus = useSelector(state => state.ehbRate.status);
  const [index, setIndex] = React.useState([]);

  useEffect(() => {
    if (ehbRateStatus === 'idle') {
      dispatch(fetchEhbRate());
    }
  }, [ehbRateStatus, dispatch]);

  function closeAccordion() {
    setIndex([]);
  }

  let content;

  if (ehbRateStatus === 'loading') {
    content = <div className="loader">Loading...</div>;
  } else if (ehbRateStatus === 'succeeded') {
    console.log(orderedEhbRateIds);
    content = (
      <div>
        <Heading size="xl" mb={8}>
          Ehb Rate Factors
        </Heading>
        <Accordion
          allowMultiple
          allowToggle="true"
          mb={8}
          index={index}
          onChange={setIndex}
        >
          <AccordionItem>
            <Box>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Text variant="brand-green-link" fontSize="lg">
                    Add New Factor
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </Box>
            <AccordionPanel pb={4}>
              <AddEhbRateFormUser
                ehbRateList={ehbRateList}
                closeAccordion={closeAccordion}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <EhbRateList orderedEhbRateIds={orderedEhbRateIds} />
      </div>
    );
  } else if (ehbRateStatus === 'failed') {
    content = <div>{error}</div>;
  }

  return (
    <>
      {content}
      <Box mt={12}>
        <BrandLinkButton path="/settings">Back to Settings</BrandLinkButton>
      </Box>
    </>
  );
};

export default EhbRatePage;
