import React from 'react';
import { Box, Text, Grid, Heading, SimpleGrid, Stack } from '@chakra-ui/react';
import BrandCenterNav from '../../components/common/BrandCenterNav';

function RenewalsPage() {
  return (
    <Box textAlign="left" fontSize="xl" p={8}>
      <Grid p={3}>
        <Stack>
          <Heading>Renewals</Heading>
          <Text>
            Choose the desired client id, group number, and/or group name to
            begin the renewal process:
          </Text>

          <SimpleGrid columns={2} spacing={8}>
            <BrandCenterNav variant="ctr-brand-primary" path="/IncurredClaims">
              Incurred Claims
            </BrandCenterNav>

            <BrandCenterNav variant="ctr-brand-primary" path="/BasicGroupInfo">
              Basic Group Info
            </BrandCenterNav>

            <BrandCenterNav variant="ctr-brand-primary" path="/GroupKeys">
              Group Keys
            </BrandCenterNav>

            <BrandCenterNav variant="ctr-brand-primary" path="/APRC">
              Affiliate Product Rate Change Factors
            </BrandCenterNav>
          </SimpleGrid>
        </Stack>
      </Grid>
    </Box>
  );
}

export default RenewalsPage;
