import React, { useEffect } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalHeader,
  ModalFooter,
  ModalContent,
  ModalCloseButton,
  Heading,
  useDisclosure,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchSpecialMix,
  selectSpecialMixIds,
  selectSpecialMixById,
  deleteSpecialMix,
} from './specialMixSlice';
import { unwrapResult } from '@reduxjs/toolkit';

import { NavLink } from 'react-router-dom';
import { FormattedDate } from '../common/FormattedDate';
import { FormattedTimestamp } from '../common/FormattedTimestamp';

const ItemDetails = ({ currentItem }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button onClick={onOpen} size="xs">
        Details
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Special Mix: {currentItem.description}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Table size="sm">
              <Tbody>
                <Tr>
                  <Th>Description</Th>
                  <Th>Employee</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.description}</Td>
                  <Td>{currentItem.employee}</Td>
                </Tr>
                <Tr>
                  <Th>Spouse</Th>
                  <Th>Child</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.spouse}</Td>
                  <Td>{currentItem.child}</Td>
                </Tr>
                <Tr>
                  <Th>OneParty</Th>
                  <Th>EmployeeSpouse</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.oneParty}</Td>
                  <Td>{currentItem.employeeSpouse}</Td>
                </Tr>
                <Tr>
                  <Th>EmployeeChild</Th>
                  <Th>Family</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.employeeChild}</Td>
                  <Td>{currentItem.family}</Td>
                </Tr>
                <Tr>
                  <Th>TwoParty</Th>
                  <Th>ThreeParty</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.twoParty}</Td>
                  <Td>{currentItem.threeParty}</Td>
                </Tr>
                <Tr>
                  <Th>Modified By Name</Th>
                  <Th>Modified Date</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.modifiedByName}</Td>
                  <Td>
                    <FormattedTimestamp
                      dateToFormat={currentItem.modifiedDate}
                    />
                  </Td>
                </Tr>
                <Tr>
                  <Th>Eff Start Date</Th>
                  <Th>Eff End Date</Th>
                </Tr>
                <Tr>
                  <Td>
                    <FormattedDate dateToFormat={currentItem.effStartDate} />
                  </Td>
                  <Td>
                    <FormattedDate dateToFormat={currentItem.effEndDate} />
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="brand-primary-sm"
              mr={3}
              onClick={onClose}
              size="sm"
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

let SpecialMixTable = ({ specialMixIdList }) => {
  let tableRows = specialMixIdList.map(specialMixId => (
    <SpecialMix specialMixId={specialMixId} key={specialMixId} />
  ));

  return (
    <Box overflowX="auto">
      <Table size="sm">
        <Thead>
          <Tr>
            <Th></Th>
            <Th>Description</Th>
            <Th>Employee</Th>
            <Th>Spouse</Th>
            <Th>Child</Th>
            <Th>One Party</Th>
            <Th>Employee Spouse</Th>
            <Th>Employee Child</Th>
            <Th>Family</Th>
            <Th>Two Party</Th>
            <Th>Three Party</Th>

            <Th>Modified By Name</Th>
            <Th>Modified Date</Th>
            <Th>Eff Start Date</Th>
            <Th>Eff End Date</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>{tableRows}</Tbody>
      </Table>
    </Box>
  );
};

let SpecialMix = ({ specialMixId }) => {
  const specialMix = useSelector(state =>
    selectSpecialMixById(state, specialMixId)
  );

  const dispatch = useDispatch();

  const onDeleteSpecialMixClicked = async () => {
    try {
      const resultAction = await dispatch(deleteSpecialMix(specialMix.id));
      unwrapResult(resultAction);
    } catch (err) {
      console.error('Failed to save the specialMix record', err);
    }
  };

  return (
    <Tr>
      <Td>
        <ItemDetails currentItem={specialMix} />
      </Td>
      <Td>{specialMix.description}</Td>
      <Td>{specialMix.employee}</Td>
      <Td>{specialMix.spouse}</Td>
      <Td>{specialMix.child}</Td>
      <Td>{specialMix.oneParty}</Td>
      <Td>{specialMix.employeeSpouse}</Td>
      <Td>{specialMix.employeeChild}</Td>
      <Td>{specialMix.family}</Td>
      <Td>{specialMix.twoParty}</Td>
      <Td>{specialMix.threeParty}</Td>
      <Td>{specialMix.modifiedByName}</Td>
      <Td>
        <FormattedTimestamp dateToFormat={specialMix.modifiedDate} />
      </Td>
      <Td>
        <FormattedDate dateToFormat={specialMix.effStartDate} />
      </Td>
      <Td>
        <FormattedDate dateToFormat={specialMix.effEndDate} />
      </Td>
      <Td>
        <ButtonGroup variant="outline">
          <Button
            colorScheme="gray"
            size="sm"
            as={NavLink}
            to={`/editSpecialMix/${specialMix.id}`}
          >
            Edit
          </Button>
          <Button
            colorScheme="red"
            size="sm"
            onClick={onDeleteSpecialMixClicked}
          >
            Delete
          </Button>
        </ButtonGroup>
      </Td>
    </Tr>
  );
};

export const SpecialMixList = () => {
  const dispatch = useDispatch();
  const orderedSpecialMixIds = useSelector(selectSpecialMixIds);

  const error = useSelector(state => state.specialMix.error);

  const specialMixStatus = useSelector(state => state.specialMix.status);
  console.log(specialMixStatus);
  useEffect(() => {
    if (specialMixStatus === 'idle') {
      dispatch(fetchSpecialMix());
    }
  }, [specialMixStatus, dispatch]);

  let content;

  if (specialMixStatus === 'loading') {
    content = <div className="loader">Loading...</div>;
  } else if (specialMixStatus === 'succeeded') {
    content = <SpecialMixTable specialMixIdList={orderedSpecialMixIds} />;
  } else if (specialMixStatus === 'failed') {
    content = <div>{error}</div>;
  }

  return (
    <section>
      <h2>
        <b>Special Mix Listing</b>
      </h2>
      {content}
    </section>
  );
};
