import React from 'react';
import { connect } from 'react-redux';
import { CallbackComponent } from 'redux-oidc';
import { push } from 'react-router-redux';
import userManager from '../../services/userManager';

const CallbackPage = ({ redirectToIndex, dispatch }) => {
  return (
    <CallbackComponent
      userManager={userManager}
      successCallback={redirectToIndex}
      errorCallback={error => {
        dispatch(push('/'));
        console.error(error);
      }}
    >
      <div>Redirecting...</div>
    </CallbackComponent>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    redirectToIndex: user => {
      // Trying to get redirectUrl here but state is undefined
      dispatch(push(user.state ? user.state.redirectUrl : '/'));
    },
    dispatch,
  };
};

export default connect(null, mapDispatchToProps)(CallbackPage);
