import React, { useEffect } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalHeader,
  ModalFooter,
  ModalContent,
  ModalCloseButton,
  Heading,
  useDisclosure,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchDeductibleCredit,
  selectDeductibleCreditIds,
  selectDeductibleCreditById,
  deleteDeductibleCredit,
} from './deductibleCreditSlice';
import { unwrapResult } from '@reduxjs/toolkit';

import { NavLink } from 'react-router-dom';
import { FormattedDate } from '../common/FormattedDate';
import { FormattedTimestamp } from '../common/FormattedTimestamp';

const ItemDetails = ({ currentItem }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button onClick={onOpen} size="xs">
        Details
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Deductible Credit: {currentItem.dedAppliesDesc}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Table size="sm">
              <Tbody>
                <Tr>
                  <Th>Amount</Th>
                  <Th>DedAppliesDesc</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.amount}</Td>
                  <Td>{currentItem.dedAppliesDesc}</Td>
                </Tr>
                <Tr>
                  <Th>Preventive</Th>
                  <Th>Radiographs</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.preventive}</Td>
                  <Td>{currentItem.radiographs}</Td>
                </Tr>
                <Tr>
                  <Th>MajorRestorative</Th>
                  <Th>OtherClass2</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.majorRestorative}</Td>
                  <Td>{currentItem.otherClass2}</Td>
                </Tr>
                <Tr>
                  <Th>Prosthodontics</Th>
                  <Th>Preventive1</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.prosthodontics}</Td>
                  <Td>{currentItem.preventive1}</Td>
                </Tr>

                <Tr>
                  <Th>Radiographs1</Th>
                  <Th>MajorRestorative1</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.radiographs1}</Td>
                  <Td>{currentItem.majorRestorative1}</Td>
                </Tr>
                <Tr>
                  <Th>OtherClass21</Th>
                  <Th>Prosthodontics1</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.otherClass21}</Td>
                  <Td>{currentItem.prosthodontics1}</Td>
                </Tr>
                <Tr>
                  <Th>Preventive2</Th>
                  <Th>Radiographs2</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.preventive2}</Td>
                  <Td>{currentItem.radiographs2}</Td>
                </Tr>
                <Tr>
                  <Th>MajorRestorative2</Th>
                  <Th>OtherClass22</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.majorRestorative2}</Td>
                  <Td>{currentItem.otherClass22}</Td>
                </Tr>

                <Tr>
                  <Th>Prosthodontics2</Th>
                  <Th> </Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.prosthodontics2}</Td>
                  <Td></Td>
                </Tr>

                <Tr>
                  <Th>Modified By Name</Th>
                  <Th>Modified Date</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.modifiedByName}</Td>
                  <Td>
                    <FormattedTimestamp
                      dateToFormat={currentItem.modifiedDate}
                    />
                  </Td>
                </Tr>
                <Tr>
                  <Th>Eff Start Date</Th>
                  <Th>Eff End Date</Th>
                </Tr>
                <Tr>
                  <Td>
                    <FormattedDate dateToFormat={currentItem.effStartDate} />
                  </Td>
                  <Td>
                    <FormattedDate dateToFormat={currentItem.effEndDate} />
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="brand-primary-sm"
              mr={3}
              onClick={onClose}
              size="sm"
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

let DeductibleCreditTable = ({ deductibleCreditIdList }) => {
  let tableRows = deductibleCreditIdList.map(deductibleCreditId => (
    <DeductibleCredit
      deductibleCreditId={deductibleCreditId}
      key={deductibleCreditId}
    />
  ));

  return (
    <Box overflowX="auto">
      <Table size="sm">
        <Thead>
          <Tr>
            <Th></Th>
            <Th>Amount</Th>
            <Th>DedAppliesDesc</Th>
            <Th>Preventive</Th>
            <Th>Radiographs</Th>
            <Th>MajorRestorative</Th>
            <Th>OtherClass2</Th>
            <Th>Prosthodontics</Th>

            <Th>Preventive1</Th>
            {/*<Th>Radiographs1</Th>*/}
            {/*<Th>MajorRestorative1</Th>*/}
            {/*<Th>OtherClass21</Th>*/}
            {/* <Th>Prosthodontics1</Th>*/}

            {/*<Th>Preventive2</Th>*/}
            {/*<Th>Radiographs2</Th>*/}
            {/*<Th>MajorRestorative2</Th>*/}
            {/*<Th>OtherClass22</Th>*/}
            {/*<Th>Prosthodontics2</Th>*/}

            {/*<Th>Modified By Name</Th>*/}
            {/*<Th>Modified Date</Th>*/}
            <Th>Eff Start Date</Th>
            <Th>Eff End Date</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>{tableRows}</Tbody>
      </Table>
    </Box>
  );
};

let DeductibleCredit = ({ deductibleCreditId }) => {
  const deductibleCredit = useSelector(state =>
    selectDeductibleCreditById(state, deductibleCreditId)
  );
  //console.log('In the deductibleCredit list');

  const dispatch = useDispatch();

  const onDeleteDeductibleCreditClicked = async () => {
    try {
      const resultAction = await dispatch(
        deleteDeductibleCredit(deductibleCredit.id)
      );
      unwrapResult(resultAction);
    } catch (err) {
      console.error('Failed to save the deductible Credit record', err);
    }
  };

  return (
    <Tr>
      <Td>
        <ItemDetails currentItem={deductibleCredit} />
      </Td>
      <Td>{deductibleCredit.amount}</Td>
      <Td>{deductibleCredit.dedAppliesDesc}</Td>
      <Td>{deductibleCredit.preventive}</Td>
      <Td>{deductibleCredit.radiographs}</Td>
      <Td>{deductibleCredit.majorRestorative}</Td>
      <Td>{deductibleCredit.otherClass2}</Td>
      <Td>{deductibleCredit.prosthodontics}</Td>

      <Td>{deductibleCredit.preventive1}</Td>
      {/*<Td>{deductibleCredit.radiographs1}</Td>*/}
      {/*<Td>{deductibleCredit.majorRestorative1}</Td>*/}
      {/*<Td>{deductibleCredit.otherClass21}</Td>*/}
      {/*<Td>{deductibleCredit.prosthodontics1}</Td>*/}

      {/* <Td>{deductibleCredit.preventive2}</Td>*/}
      {/*<Td>{deductibleCredit.radiographs2}</Td>*/}
      {/* <Td>{deductibleCredit.majorRestorative2}</Td>*/}
      {/*<Td>{deductibleCredit.otherClass22}</Td>*/}
      {/*<Td>{deductibleCredit.prosthodontics2}</Td>*/}

      {/*<Td>{deductibleCredit.modifiedByName}</Td>
     {/* <Td>
        <FormattedTimestamp dateToFormat={deductibleCredit.modifiedDate} />
      </Td>*/}
      <Td>
        <FormattedDate dateToFormat={deductibleCredit.effStartDate} />
      </Td>
      <Td>
        <FormattedDate dateToFormat={deductibleCredit.effEndDate} />
      </Td>
      <Td>
        <ButtonGroup variant="outline">
          <Button
            colorScheme="gray"
            size="sm"
            as={NavLink}
            to={`/editDeductibleCredit/${deductibleCredit.id}`}
          >
            Edit
          </Button>
          <Button
            colorScheme="red"
            size="sm"
            onClick={onDeleteDeductibleCreditClicked}
          >
            Delete
          </Button>
        </ButtonGroup>
      </Td>
    </Tr>
  );
};

export const DeductibleCreditList = () => {
  const dispatch = useDispatch();
  const orderedDeductibleCreditIds = useSelector(selectDeductibleCreditIds);

  const error = useSelector(state => state.deductibleCredit.error);

  const deductibleCreditStatus = useSelector(
    state => state.deductibleCredit.status
  );
  console.log(deductibleCreditStatus);
  useEffect(() => {
    if (deductibleCreditStatus === 'idle') {
      dispatch(fetchDeductibleCredit());
    }
  }, [deductibleCreditStatus, dispatch]);

  let content;

  if (deductibleCreditStatus === 'loading') {
    content = <div className="loader">Loading...</div>;
  } else if (deductibleCreditStatus === 'succeeded') {
    content = (
      <DeductibleCreditTable
        deductibleCreditIdList={orderedDeductibleCreditIds}
      />
    );
  } else if (deductibleCreditStatus === 'failed') {
    content = <div>{error}</div>;
  }

  return (
    <section>
      <h2>
        <b>Deductible Credit Listing</b>
      </h2>
      {content}
    </section>
  );
};
