import * as moment from 'moment';

export const FormattedDate = props => {
  const { dateToFormat } = props;
  return dateToFormat
    ? dateToFormat === '2099-12-31T00:00:00'
      ? ''
      : moment(dateToFormat).format('MM/DD/YYYY')
    : '';
};
