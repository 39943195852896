import React from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Text,
  Grid,
  Heading,
  HStack,
  Spacer,
  Stack,
} from '@chakra-ui/react';
import BrandLinkButton from '../../components/common/BrandLinkButton';

function ConfigurationFactorsPage() {
  return (
    <Box textAlign="left" fontSize="xl" p={8}>
      <Grid p={3}>
        <Stack>
          <Heading>Configurations Factors</Heading>
          <Text>
            Here the special configuration values are to be entered....
          </Text>
          <Container textAlign="left" p={8}>
            <HStack>
              <Button variant="brand-primary">Credibility</Button>

              <Button variant="brand-primary">Special Mix</Button>

              <Button variant="brand-primary">Pandemic</Button>

              <Button variant="brand-primary">Voluntary</Button>
            </HStack>
          </Container>
        </Stack>
      </Grid>
      <Box mt={12}>
        <BrandLinkButton path="/settings">Back to Settings</BrandLinkButton>
      </Box>
    </Box>
  );
}

export default ConfigurationFactorsPage;
