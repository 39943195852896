import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
  AccordionIcon,
  Heading,
} from '@chakra-ui/react';
import {
  fetchVoluntary,
  selectVoluntaryIds,
  selectAllVoluntary,
} from './voluntarySlice';
import { AddVoluntaryFormUser } from './AddVoluntaryForm';
import { VoluntaryList } from './VoluntaryList';
import BrandLinkButton from '../../components/common/BrandLinkButton';

export const VoluntaryPage = () => {
  const dispatch = useDispatch();

  const orderedVoluntaryIds = useSelector(selectVoluntaryIds);
  const voluntaryList = useSelector(selectAllVoluntary);

  const error = useSelector(state => state.voluntary.error);
  if (error) {
    console.log('Voluntary List Error:' + error.toString());
  }

  const voluntaryStatus = useSelector(state => state.voluntary.status);
  const [index, setIndex] = React.useState([]);

  useEffect(() => {
    if (voluntaryStatus === 'idle') {
      dispatch(fetchVoluntary());
    }
  }, [voluntaryStatus, dispatch]);

  function closeAccordion() {
    setIndex([]);
  }

  let content;

  if (voluntaryStatus === 'loading') {
    content = <div className="loader">Loading...</div>;
  } else if (voluntaryStatus === 'succeeded') {
    console.log(orderedVoluntaryIds);
    content = (
      <div>
        <Heading size="xl" mb={8}>
          Voluntary Factors
        </Heading>
        <Accordion
          allowMultiple
          allowToggle="true"
          mb={8}
          index={index}
          onChange={setIndex}
        >
          <AccordionItem>
            <Box>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Text variant="brand-green-link" fontSize="lg">
                    Add New Factor
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </Box>
            <AccordionPanel pb={4}>
              <AddVoluntaryFormUser
                voluntaryList={voluntaryList}
                closeAccordion={closeAccordion}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <VoluntaryList orderedVoluntaryIds={orderedVoluntaryIds} />
      </div>
    );
  } else if (voluntaryStatus === 'failed') {
    content = <div>{error}</div>;
  }

  return (
    <>
      {content}
      <Box mt={12}>
        <BrandLinkButton path="/settings">Back to Settings</BrandLinkButton>
      </Box>
    </>
  );
};

export default VoluntaryPage;
