import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Select,
  Stack,
  StackDivider,
  VStack,
} from '@chakra-ui/react';

import {
  fetchEhbRate,
  updateEhbRate,
  selectEhbRateById,
  selectAllEhbRate,
} from './ehbRateSlice';
import { FieldGroup } from '../../components/common/FieldGroup';
import DatePicker from '../../components/common/DatePicker';
import { ErrorDisplay } from '../common/ErrorDisplay';
import { overlapCheck } from '../../services/overlapCheck';
import {
  dateToString,
  nullableDateToString,
  stringToDateFormat,
} from '../../services/dateConverters';

export const EditEhbRateForm = ({ match, user }) => {
  const { ehbRateId } = match.params;
  const ehbRate = useSelector(state => selectEhbRateById(state, ehbRateId));
  const ehbRateList = useSelector(selectAllEhbRate);

  const stdt = stringToDateFormat(ehbRate.effStartDate);
  console.log(stdt);
  const endt = stringToDateFormat(ehbRate.effEndDate);
  console.log(endt);

  const [ehbType, setEhbType] = useState(ehbRate.ehbType);
  const [dbKey, setDbKey] = useState(ehbRate.dbKey);
  const [rate, setRate] = useState(ehbRate.rate);
  const [admin, setAdmin] = useState(ehbRate.admin);

  const [startDate, setEffStartDate] = useState(stdt);

  const [endDate, setEffEndDate] = useState(endt);
  const [updateRequestStatus, setUpdateRequestStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();
  const history = useHistory();

  const onEhbTypeChanged = e => {
    setEhbType(e.target.value);
    setErrorMessage('');
  };
  const onDbKeyChanged = e => setDbKey(Number(e.target.value));
  const onRateChanged = e => setRate(Number(e.target.value));
  const onAdminChanged = e => setAdmin(Number(e.target.value));

  const onEffStartDateChanged = e => {
    setEffStartDate(e);
    setErrorMessage('');
  };
  const onEffEndDateChanged = e => {
    setEffEndDate(e);
    setErrorMessage('');
  };

  const canSave =
    [ehbType, !isNaN(dbKey), !isNaN(rate), !isNaN(admin), startDate].every(
      Boolean
    ) && updateRequestStatus === 'idle';

  const onSaveEhbRateClicked = async () => {
    if (canSave) {
      try {
        setUpdateRequestStatus('pending');

        var ehbRateOverlap = ehbRateList.filter(overlapCheck, {
          id: ehbRate.id,
          effStartDate: startDate,
          effEndDate: endDate,
          keys: [
            {
              name: 'ehbType',
              value: ehbType,
            },
          ],
        });
        if (ehbRateOverlap.length) {
          console.log(ehbRateOverlap);
          setErrorMessage(
            'The record being entered overlaps an existing effective date range for this key value.'
          );
          return false;
        }

        const resultAction = await dispatch(
          updateEhbRate({
            id: Number(ehbRateId),
            ehbType,
            dbKey,
            rate,
            admin,
            modifiedByName: user.profile.name,
            modifiedDate: new Date().toISOString(),
            effStartDate: dateToString(startDate),
            effEndDate: nullableDateToString(endDate),
          })
        );
        console.log(resultAction);
        setUpdateRequestStatus('idle');
        history.push(`/ehbRate`);
      } catch (err) {
        setUpdateRequestStatus('idle');
        console.error('Failed to save the ehb rate factor: ', err);
      }
    }
  };
  return (
    <Box
      px={{
        base: '4',
        md: '10',
      }}
      pb="16"
      maxWidth="3xl"
      mx="auto"
    >
      <form>
        <Stack spacing="4" divider={<StackDivider />}>
          <FieldGroup title="Edit Ehb Rate Factors">
            <VStack width="full" spacing="6">
              <FormControl id="ehbType">
                <FormLabel>Ehb Type:</FormLabel>
                <Input
                  type="text"
                  value={ehbType}
                  onChange={onEhbTypeChanged}
                />
              </FormControl>
              <FormControl id="dbKey">
                <FormLabel>DbKey:</FormLabel>
                <Input type="number" value={dbKey} onChange={onDbKeyChanged} />
              </FormControl>
              <FormControl id="rate">
                <FormLabel>Rate:</FormLabel>
                <Input type="number" value={rate} onChange={onRateChanged} />
              </FormControl>
              <FormControl id="admin">
                <FormLabel>Admin:</FormLabel>
                <Input type="number" value={admin} onChange={onAdminChanged} />
              </FormControl>

              <FormControl id="effStartDate">
                <FormLabel>Effective Start Date:</FormLabel>
                <DatePicker
                  id="effStartDate"
                  selectedDate={startDate}
                  onChange={onEffStartDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective Start Date</FormHelperText>
              </FormControl>
              <FormControl id="effEndDate">
                <FormLabel>Effective End Date:</FormLabel>
                <DatePicker
                  id="effEndDate"
                  selectedDate={endDate}
                  onChange={onEffEndDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective End Date</FormHelperText>
              </FormControl>
            </VStack>
          </FieldGroup>

          <Button
            onClick={onSaveEhbRateClicked}
            disabled={!canSave}
            variant="brand-primary"
            size="xs"
          >
            Save Ehb Rate Factor
          </Button>
          <ErrorDisplay
            title="The record dates overlap other records!"
            errorMessage={errorMessage}
          />
        </Stack>
      </form>
    </Box>
  );
};

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
  };
}

export const EditEhbRateFormUser = connect(mapStateToProps)(EditEhbRateForm);
