import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Stack,
  StackDivider,
  VStack,
} from '@chakra-ui/react';

import {
  fetchDeductibleAmounts,
  updateDeductibleAmount,
  selectDeductibleAmountById,
  selectAllDeductibleAmounts,
} from './deductibleAmountSlice';
import { FieldGroup } from '../../components/common/FieldGroup';
import DatePicker from '../../components/common/DatePicker';
import { ErrorDisplay } from '../common/ErrorDisplay';
import { overlapCheck } from '../../services/overlapCheck';
import {
  dateToString,
  nullableDateToString,
  stringToDateFormat,
} from '../../services/dateConverters';

export const EditDeductAmtForm = ({ match, user }) => {
  const { deductibleAmountId } = match.params;
  const deductAmt = useSelector(state =>
    selectDeductibleAmountById(state, deductibleAmountId)
  );
  const deductAmtList = useSelector(selectAllDeductibleAmounts);

  const stdt = stringToDateFormat(deductAmt.effStartDate);
  console.log(stdt);
  const endt = stringToDateFormat(deductAmt.effEndDate);
  console.log(endt);

  /* useEffect(() => {
    if (deductibleAmountStatus === 'idle') {
      dispatch(fetchDeductibleAmounts());
    }
    if (deductibleAmountStatus === 'succeeded') {
      setAmount(deductAmt.amount);
      setEmployee(deductAmt.employee);
      setSpouse(deductAmt.spouse);
      setChild(deductAmt.child);
      stringToDateFormat(deductAmt.effStartDate);

      stringToDateFormat(deductAmt.effEndDate);
    }
  }, [deductibleAmountStatus, deductAmt, dispatch]);
  */

  const [amount, setAmount] = useState(deductAmt.amount);
  const [employee, setEmployee] = useState(deductAmt.employee);
  const [spouse, setSpouse] = useState(deductAmt.spouse);
  const [child, setChild] = useState(deductAmt.child);
  const [startDate, setEffStartDate] = useState(stdt);
  const [endDate, setEffEndDate] = useState(endt);
  const [updateRequestStatus, setUpdateRequestStatus] = useState('idle');
  // effective dating routine
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();
  const history = useHistory();

  const onAmountChanged = e => {
    setAmount(Number(e.target.value));
    setErrorMessage('');
  };
  const onEmployeeChanged = e => setEmployee(Number(e.target.value));
  const onSpouseChanged = e => setSpouse(Number(e.target.value));
  const onChildChanged = e => setChild(Number(e.target.value));
  const onEffStartDateChanged = e => {
    setEffStartDate(e);
    setErrorMessage('');
  };
  const onEffEndDateChanged = e => {
    setEffEndDate(e);
    setErrorMessage('');
  };

  const canSave =
    [
      !isNaN(amount),
      !isNaN(employee),
      !isNaN(spouse),
      !isNaN(child),
      startDate,
    ].every(Boolean) && updateRequestStatus === 'idle';

  const onSaveDeductibleAmountClicked = async () => {
    if (canSave) {
      try {
        setUpdateRequestStatus('pending');

        var deductAmtOverlap = deductAmtList.filter(overlapCheck, {
          id: deductAmt.id,
          effStartDate: startDate,
          effEndDate: endDate,
          keys: [
            {
              name: 'amount',
              value: amount,
            },
          ],
        });
        if (deductAmtOverlap.length) {
          console.log(deductAmtOverlap);
          setErrorMessage(
            'The record being entered overlaps an existing effective date range for this key value.'
          );
          return false;
        }

        const resultAction = await dispatch(
          updateDeductibleAmount({
            id: Number(deductibleAmountId),
            amount,
            employee,
            spouse,
            child,
            modifiedByName: user.profile.name,
            modifiedDate: new Date().toISOString(),
            effStartDate: dateToString(startDate),
            effEndDate: nullableDateToString(endDate),
          })
        );
        console.log(resultAction);
        setUpdateRequestStatus('idle');
        history.push(`/DeductAmt`);
      } catch (err) {
        setUpdateRequestStatus('idle');
        console.error('Failed to save the deductible amount: ', err);
      }
    }
  };

  return (
    <Box
      px={{
        base: '4',
        md: '10',
      }}
      pb="16"
      maxWidth="3xl"
      mx="auto"
    >
      <form>
        <Stack spacing="4" divider={<StackDivider />}>
          <FieldGroup title="Edit Deductible Amount">
            <VStack width="full" spacing="6">
              <FormControl id="amount">
                <FormLabel>Amount:</FormLabel>
                <Input
                  type="number"
                  value={amount}
                  onChange={onAmountChanged}
                />
              </FormControl>
              <FormControl id="employee">
                <FormLabel>Employee:</FormLabel>
                <Input
                  type="number"
                  value={employee}
                  onChange={onEmployeeChanged}
                />
              </FormControl>
              <FormControl id="spouse">
                <FormLabel>Spouse:</FormLabel>
                <Input
                  type="number"
                  value={spouse}
                  onChange={onSpouseChanged}
                />
              </FormControl>
              <FormControl id="child">
                <FormLabel>Child:</FormLabel>
                <Input type="number" value={child} onChange={onChildChanged} />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="effStartDate">
                  Effective Start Date
                </FormLabel>
                <DatePicker
                  id="effStartDate"
                  selectedDate={startDate}
                  onChange={onEffStartDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective Start Date</FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="effEndDate">Effective End Date</FormLabel>
                <DatePicker
                  id="effEndDate"
                  selectedDate={endDate}
                  onChange={onEffEndDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective End Date</FormHelperText>
              </FormControl>
            </VStack>
          </FieldGroup>

          <Button
            onClick={onSaveDeductibleAmountClicked}
            disabled={!canSave}
            variant="brand-primary"
            size="xs"
          >
            Save Deductible Amount
          </Button>
          <ErrorDisplay
            title="The record dates overlap other records!"
            errorMessage={errorMessage}
          />
        </Stack>
      </form>
    </Box>
  );
};

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
  };
}

export const EditDeductAmtFormUser =
  connect(mapStateToProps)(EditDeductAmtForm);
