import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import { Box, Button, FormControl, FormLabel, Input, Select, Stack, StackDivider, VStack } from '@chakra-ui/react';

import { updateCategory, selectCategoryById } from './ratingCategorySlice';
import { fetchGroups, selectAllGroups } from './ratingGroupSlice';
import { FieldGroup } from '../../components/common/FieldGroup';

export const EditCategoryForm = ({match}) => {
  const {categoryId} = match.params;
  const category = useSelector(state => selectCategoryById(state, categoryId));

  const [categoryName, setCategoryName] = useState(category.categoryName);
  const [categoryDescription, setCategoryDescription] = useState(category.categoryDescription);
  const [ratingGroupId, setRatingGroupId] = useState(category.ratingGroupId);
  const [ucmCategory, setUcmCategory] = useState(category.ucmCategory);
  const [targetProcedureCode, setTargetProcedureCode] = useState(category.targetProcedureCode);
  const [updateRequestStatus, setUpdateRequestStatus] = useState('idle');

  const dispatch = useDispatch();
  const history = useHistory();
  const ratingGroups = useSelector(selectAllGroups);

  const onCategoryNameChanged = e => setCategoryName(e.target.value);
  const onCategoryDescriptionChanged = e => setCategoryDescription(e.target.value);
  const onUcmCategoryChanged = e => setUcmCategory(e.target.value);
  const onTargetProcedureCodeChanged = e => setTargetProcedureCode(e.target.value);
  const onGroupChanged = e => setRatingGroupId(Number(e.target.value))

  const groupstatus = useSelector(state => state.ratingGroups.status)
  useEffect(() => {
    if (groupstatus === 'idle') {
      dispatch(fetchGroups())
    }
  }, [groupstatus, dispatch])

  const canSave = [categoryName, ratingGroupId, ucmCategory, targetProcedureCode].every(Boolean) && updateRequestStatus === 'idle';

  const onSaveCategoryClicked = async () => {
    if (canSave) {
      try {
        setUpdateRequestStatus('pending')
        const resultAction = await dispatch(
          updateCategory({id: Number(categoryId), categoryName, categoryDescription, ratingGroupId, ucmCategory, targetProcedureCode })
        );
        setUpdateRequestStatus('idle');
        history.push(`/categories`);
      } catch (err) {
        setUpdateRequestStatus('idle');
        console.error('Failed to save the category: ', err);
      }
    }
  }

  const groupOptions = ratingGroups.map(grp => (
    <option key={grp.id} value={grp.id}>{grp.groupName}</option>
  ));

  return (
    <Box
      px={{
        base: '4',
        md: '10',
      }}
      pb="16"
      maxWidth="3xl"
      mx="auto"
    >
      <form>
        <Stack spacing="4" divider={<StackDivider />}>
        <FieldGroup title="Add Rating Category">
          <VStack width="full" spacing="6">
            <FormControl id="categoryName">
              <FormLabel>Category Name:</FormLabel>
              <Input type="text" value={categoryName} onChange={onCategoryNameChanged} />
            </FormControl>
            <FormControl id="categoryDescription">
              <FormLabel>Category Description:</FormLabel>
              <Input type="text" value={categoryDescription} onChange={onCategoryDescriptionChanged} />
            </FormControl>
        <FormControl id="ratingGroup">
        <FormLabel>Rating Group:</FormLabel>
          <Select value={ratingGroupId} onChange={onGroupChanged} placeholder="Select a rating group">
            {groupOptions}
          </Select>
        </FormControl>
            <FormControl id="ucmCategory">
              <FormLabel>Codeset Category:</FormLabel>
              <Input type="text" value={ucmCategory} onChange={onUcmCategoryChanged} />
            </FormControl>
            <FormControl id="targetProcedureCode">
              <FormLabel>Target Procedure Code:</FormLabel>
              <Input type="text" value={targetProcedureCode} onChange={onTargetProcedureCodeChanged} />
            </FormControl>
          </VStack>
        </FieldGroup>

        <Button onClick={onSaveCategoryClicked} disabled={!canSave} variant="brand-primary" size="xs" >Save Category</Button>
        </Stack>
      </form>
    </Box>
  )
}