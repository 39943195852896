import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import axios from 'axios';

const maximumAmountAdapter = createEntityAdapter();
const apiBaseUrl = window.ENV.api_base_url;

const initialState = maximumAmountAdapter.getInitialState({
  status: 'idle',
  error: null,
});

export const fetchMaximumAmount = createAsyncThunk(
  'maximumAmount/fetchMaximumAmount',
  async (_, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };

    const response = await axios.get(`${apiBaseUrl}/api/MaximumAmount`, config);
    return response.data;
  }
);

export const addNewMaximumAmount = createAsyncThunk(
  'maximumAmount/addNewMaximumAmount',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.post(
      `${apiBaseUrl}/api/MaximumAmount`,
      initialState,
      config
    );
    console.log('adding new maximumAmount record');
    console.log(response);
    return { ...initialState, id: response.data };
  }
);

export const deleteMaximumAmount = createAsyncThunk(
  'maximumAmount/deleteMaximumAmount',
  async (maximumAmountId, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.delete(
      `${apiBaseUrl}/api/MaximumAmount/${maximumAmountId}`,
      config
    );
    return maximumAmountId;
  }
);

export const updateMaximumAmount = createAsyncThunk(
  'maximumAmount/updateMaximumAmount',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.put(
      `${apiBaseUrl}/api/MaximumAmount/${initialState.id}`,
      initialState,
      config
    );
    return { ...initialState, id: response.data };
  }
);

const maximumAmountSlice = createSlice({
  name: 'maximumAmount',
  initialState,
  extraReducers: {
    [fetchMaximumAmount.pending]: state => {
      state.status = 'loading';
    },
    [fetchMaximumAmount.fulfilled]: (state, action) => {
      // Add any fetched posts to the array
      maximumAmountAdapter.upsertMany(state, action.payload);
      state.status = 'succeeded';
    },
    [fetchMaximumAmount.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [addNewMaximumAmount.fulfilled]: maximumAmountAdapter.addOne,
    [deleteMaximumAmount.fulfilled]: maximumAmountAdapter.removeOne,
    [updateMaximumAmount.fulfilled]: maximumAmountAdapter.upsertOne,
  },
});

export default maximumAmountSlice.reducer;

export const {
  selectAll: selectAllMaximumAmount,
  selectById: selectMaximumAmountById,
  selectIds: selectMaximumAmountIds,
} = maximumAmountAdapter.getSelectors(state => state.maximumAmount);
