import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import axios from 'axios';

const countyAreaAdapter = createEntityAdapter();
const apiBaseUrl = window.ENV.api_base_url;

const initialState = countyAreaAdapter.getInitialState({
  status: 'idle',
  error: null,
});

export const fetchCountyArea = createAsyncThunk(
  'countyArea/fetchCountyArea',
  async (_, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };

    const response = await axios.get(`${apiBaseUrl}/api/CountyArea`, config);
    return response.data;
  }
);

export const addNewCountyArea = createAsyncThunk(
  'countyArea/addNewCountyArea',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.post(
      `${apiBaseUrl}/api/CountyArea`,
      initialState,
      config
    );
    console.log('adding new administration record');
    console.log(response);
    return { ...initialState, id: response.data };
  }
);

export const deleteCountyArea = createAsyncThunk(
  'countyArea/deleteCountyArea',
  async (countyAreaId, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.delete(
      `${apiBaseUrl}/api/CountyArea/${countyAreaId}`,
      config
    );
    return countyAreaId;
  }
);

export const updateCountyArea = createAsyncThunk(
  'countyArea/updateCountyArea',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.put(
      `${apiBaseUrl}/api/CountyArea/${initialState.id}`,
      initialState,
      config
    );
    return { ...initialState, id: response.data };
  }
);

const countyAreaSlice = createSlice({
  name: 'countyArea',
  initialState,
  extraReducers: {
    [fetchCountyArea.pending]: state => {
      state.status = 'loading';
    },
    [fetchCountyArea.fulfilled]: (state, action) => {
      // Add any fetched posts to the array
      countyAreaAdapter.upsertMany(state, action.payload);
      state.status = 'succeeded';
    },
    [fetchCountyArea.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [addNewCountyArea.fulfilled]: countyAreaAdapter.addOne,
    [deleteCountyArea.fulfilled]: countyAreaAdapter.removeOne,
    [updateCountyArea.fulfilled]: countyAreaAdapter.upsertOne,
  },
});

export default countyAreaSlice.reducer;

export const {
  selectAll: selectAllCountyArea,
  selectById: selectCountyAreaById,
  selectIds: selectCountyAreaIds,
} = countyAreaAdapter.getSelectors(state => state.countyArea);
