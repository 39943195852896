import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
  AccordionIcon,
  Heading,
} from '@chakra-ui/react';
import {
  fetchDependentAge,
  selectDependentAgeIds,
  selectAllDependentAge,
} from './dependentAgeSlice';
import { AddDependentAgeFormUser } from './AddDependentAgeForm';
import { DependentAgeList } from './DependentAgeList';
import BrandLinkButton from '../../components/common/BrandLinkButton';

export const DependentAgePage = () => {
  const dispatch = useDispatch();

  const orderedDependentAgeIds = useSelector(selectDependentAgeIds);
  const dependentAgeList = useSelector(selectAllDependentAge);

  const error = useSelector(state => state.dependentAge.error);
  if (error) {
    console.log('DependentAge List Error:' + error.toString());
  }

  const dependentAgeStatus = useSelector(state => state.dependentAge.status);
  const [index, setIndex] = React.useState([]);

  useEffect(() => {
    if (dependentAgeStatus === 'idle') {
      dispatch(fetchDependentAge());
    }
  }, [dependentAgeStatus, dispatch]);

  function closeAccordion() {
    setIndex([]);
  }

  let content;

  if (dependentAgeStatus === 'loading') {
    content = <div className="loader">Loading...</div>;
  } else if (dependentAgeStatus === 'succeeded') {
    console.log(orderedDependentAgeIds);
    content = (
      <div>
        <Heading size="xl" mb={8}>
          Dependent Age Factors
        </Heading>
        <Accordion
          allowMultiple
          allowToggle="true"
          mb={8}
          index={index}
          onChange={setIndex}
        >
          <AccordionItem>
            <Box>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Text variant="brand-green-link" fontSize="lg">
                    Add New Factor
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </Box>
            <AccordionPanel pb={4}>
              <AddDependentAgeFormUser
                dependentAgeList={dependentAgeList}
                closeAccordion={closeAccordion}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <DependentAgeList orderedDependentAgeIds={orderedDependentAgeIds} />
      </div>
    );
  } else if (dependentAgeStatus === 'failed') {
    content = <div>{error}</div>;
  }

  return (
    <>
      {content}
      <Box mt={12}>
        <BrandLinkButton path="/settings">Back to Settings</BrandLinkButton>
      </Box>
    </>
  );
};

export default DependentAgePage;
