import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Select,
  Stack,
  StackDivider,
  VStack,
} from '@chakra-ui/react';

import {
  fetchMaxAmtExcluded,
  updateMaxAmtExcluded,
  selectMaxAmtExcludedById,
  selectAllMaxAmtExcluded,
} from './maxAmtExcludedSlice';
import { FieldGroup } from '../../components/common/FieldGroup';
import DatePicker from '../../components/common/DatePicker';
import { ErrorDisplay } from '../common/ErrorDisplay';
import { overlapCheck } from '../../services/overlapCheck';
import {
  dateToString,
  nullableDateToString,
  stringToDateFormat,
} from '../../services/dateConverters';

export const EditMaxAmtExcludedForm = ({ match, user }) => {
  const { maxAmtExcludedId } = match.params;
  const maxAmtExcluded = useSelector(state =>
    selectMaxAmtExcludedById(state, maxAmtExcludedId)
  );
  const maxAmtExcludedList = useSelector(selectAllMaxAmtExcluded);

  const stdt = stringToDateFormat(maxAmtExcluded.effStartDate);
  console.log(stdt);
  const endt = stringToDateFormat(maxAmtExcluded.effEndDate);
  console.log(endt);

  const [benefitType, setBenefitType] = useState(maxAmtExcluded.benefitType);
  const [amount, setAmount] = useState(maxAmtExcluded.amount);
  const [adult, setAdult] = useState(maxAmtExcluded.adult);
  const [child, setChild] = useState(maxAmtExcluded.child);

  const [startDate, setEffStartDate] = useState(stdt);

  const [endDate, setEffEndDate] = useState(endt);
  const [updateRequestStatus, setUpdateRequestStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();
  const history = useHistory();

  const onBenefitTypeChanged = e => {
    setBenefitType(e.target.value);
    setErrorMessage('');
  };
  const onAmountChanged = e => setAmount(Number(e.target.value));
  const onAdultChanged = e => setAdult(Number(e.target.value));
  const onChildChanged = e => setChild(Number(e.target.value));

  const onEffStartDateChanged = e => {
    setEffStartDate(e);
    setErrorMessage('');
  };
  const onEffEndDateChanged = e => {
    setEffEndDate(e);
    setErrorMessage('');
  };

  const canSave =
    [
      benefitType,
      !isNaN(amount),
      !isNaN(adult),
      !isNaN(child),
      startDate,
    ].every(Boolean) && updateRequestStatus === 'idle';

  const onSaveMaxAmtExcludedClicked = async () => {
    if (canSave) {
      try {
        setUpdateRequestStatus('pending');

        var maxAmtExcludedOverlap = maxAmtExcludedList.filter(overlapCheck, {
          id: maxAmtExcluded.id,
          effStartDate: startDate,
          effEndDate: endDate,
          keys: [
            {
              name: 'benefitType',
              value: benefitType,
            },
            {
              name: 'amount',
              value: amount,
            },
          ],
        });
        if (maxAmtExcludedOverlap.length) {
          console.log(maxAmtExcludedOverlap);
          setErrorMessage(
            'The record being entered overlaps an existing effective date range for this key value.'
          );
          return false;
        }

        const resultAction = await dispatch(
          updateMaxAmtExcluded({
            id: Number(maxAmtExcludedId),
            benefitType,
            amount,
            adult,
            child,
            modifiedByName: user.profile.name,
            modifiedDate: new Date().toISOString(),
            effStartDate: dateToString(startDate),
            effEndDate: nullableDateToString(endDate),
          })
        );
        console.log(resultAction);
        setUpdateRequestStatus('idle');
        history.push(`/maxAmtExcluded`);
      } catch (err) {
        setUpdateRequestStatus('idle');
        console.error('Failed to save the maxAmtExcluded factor: ', err);
      }
    }
  };
  return (
    <Box
      px={{
        base: '4',
        md: '10',
      }}
      pb="16"
      maxWidth="3xl"
      mx="auto"
    >
      <form>
        <Stack spacing="4" divider={<StackDivider />}>
          <FieldGroup title="Edit Max Amt Excluded Factors">
            <VStack width="full" spacing="6">
              <FormControl id="benefitType">
                <FormLabel>Benefit Type:</FormLabel>
                <Input
                  type="text"
                  value={benefitType}
                  onChange={onBenefitTypeChanged}
                />
              </FormControl>
              <FormControl id="amount">
                <FormLabel>Amount:</FormLabel>
                <Input
                  type="number"
                  value={amount}
                  onChange={onAmountChanged}
                />
              </FormControl>
              <FormControl id="adult">
                <FormLabel>Adult:</FormLabel>
                <Input type="number" value={adult} onChange={onAdultChanged} />
              </FormControl>
              <FormControl id="child">
                <FormLabel>Child:</FormLabel>
                <Input type="number" value={child} onChange={onChildChanged} />
              </FormControl>

              <FormControl id="effStartDate">
                <FormLabel>Effective Start Date:</FormLabel>
                <DatePicker
                  id="effStartDate"
                  selectedDate={startDate}
                  onChange={onEffStartDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective Start Date</FormHelperText>
              </FormControl>
              <FormControl id="effEndDate">
                <FormLabel>Effective End Date:</FormLabel>
                <DatePicker
                  id="effEndDate"
                  selectedDate={endDate}
                  onChange={onEffEndDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective End Date</FormHelperText>
              </FormControl>
            </VStack>
          </FieldGroup>

          <Button
            onClick={onSaveMaxAmtExcludedClicked}
            disabled={!canSave}
            variant="brand-primary"
            size="xs"
          >
            Save Max Amt Excluded Factor
          </Button>
          <ErrorDisplay
            title="The record dates overlap other records!"
            errorMessage={errorMessage}
          />
        </Stack>
      </form>
    </Box>
  );
};

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
  };
}

export const EditMaxAmtExcludedFormUser = connect(mapStateToProps)(
  EditMaxAmtExcludedForm
);
