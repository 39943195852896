import React, { useEffect } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalHeader,
  ModalFooter,
  ModalContent,
  ModalCloseButton,
  Heading,
  useDisclosure,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchIncurredClaims,
  selectAllIncurredClaims,
  selectIncurredClaimsById,
} from './incurredClaimsSlice';
import { unwrapResult } from '@reduxjs/toolkit';

import { NavLink } from 'react-router-dom';
import { FormattedDate } from '../common/FormattedDate';
import { FormattedTimestamp } from '../common/FormattedTimestamp';
import IncurredClaimsPage from './IncurredClaimsPage';

const ItemDetails = ({ currentItem }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button onClick={onOpen} size="xs">
        Details
      </Button>
    </>
  );
};

const premiumGroupId = '6992';

let IncurredClaimsTable = ({ incurredClaimsIdList }) => {
  let tableRows = incurredClaimsIdList
    .filter(IncurredClaims => IncurredClaims.premiumGroupId === premiumGroupId)
    .map(filteredIncurredClaimsIdList => {
      return <IncurredClaims key={premiumGroupId} />;
    });

  return (
    <Box overflowX="auto">
      <Table size="sm">
        <Thead>
          <Tr>
            <Th></Th>
            <Th>Client Id</Th>
            <Th>Premium Group Id</Th>
            <Th>Product</Th>
            <Th>Network</Th>

            <Th>Funding Type</Th>
            <Th>Finance Code</Th>
            <Th>Par Status</Th>

            <Th>Incurred Period</Th>
            <Th>Paid Period</Th>
            <Th>Paid Amount</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>{tableRows}</Tbody>
      </Table>
    </Box>
  );
};

let IncurredClaims = ({ premiumGroupId }) => {
  const incurredClaims = useSelector(state =>
    selectIncurredClaimsById(state, premiumGroupId)
  );
  //console.log('In the copay list');

  const dispatch = useDispatch();

  /*const onDeleteCopayClicked = async () => {
    try {
      const resultAction = await dispatch(deleteCopay(copay.id));
      unwrapResult(resultAction);
    } catch (err) {
      console.error('Failed to save the copay record', err);
    }
  };*/

  return (
    <Tr>
      <Td>
        <ItemDetails currentItem={incurredClaims} />
      </Td>
      <Td>{incurredClaims.clientId}</Td>
      <Td>{incurredClaims.premiumGroupId}</Td>
      <Td>{incurredClaims.product}</Td>
      <Td>{incurredClaims.network}</Td>

      <Td>{incurredClaims.fundingType}</Td>
      <Td>{incurredClaims.financeCode}</Td>
      <Td>{incurredClaims.parstatus}</Td>
      <Td>{incurredClaims.incurredPeriod}</Td>
      <Td>{incurredClaims.paidPeriod}</Td>
      <Td>{IncurredClaimsPage.paidAmount}</Td>
      <Td>Button placeholder</Td>
    </Tr>
  );
};

export const IncurredClaimsList = () => {
  const dispatch = useDispatch();
  const orderedIncurredClaimsIds = useSelector(selectIncurredClaimsById);
  console.log('in the incurred claims list');
  const error = useSelector(state => state.incurredClaims.error);

  const incurredClaimsStatus = useSelector(
    state => state.incurredClaims.status
  );
  console.log(incurredClaimsStatus);
  useEffect(() => {
    if (incurredClaimsStatus === 'idle') {
      dispatch(fetchIncurredClaims());
    }
  }, [incurredClaimsStatus, dispatch]);

  let content;

  if (incurredClaimsStatus === 'loading') {
    content = <div className="loader">Loading...</div>;
  } else if (incurredClaimsStatus === 'succeeded') {
    content = (
      <IncurredClaimsTable incurredClaimsIdList={orderedIncurredClaimsIds} />
    );
  } else if (incurredClaimsStatus === 'failed') {
    content = <div>{error}</div>;
  }

  return (
    <section>
      <h2>
        <b>Incurred Claims Listing</b>
      </h2>
      {content}
    </section>
  );
};
