import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import axios from 'axios';

const calendarYearLoadAdapter = createEntityAdapter();
const apiBaseUrl = window.ENV.api_base_url;

const initialState = calendarYearLoadAdapter.getInitialState({
  status: 'idle',
  error: null,
});

export const fetchCalendarYearLoad = createAsyncThunk(
  'calendarYearLoad/fetchCalendarYearLoad',
  async (_, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };

    const response = await axios.get(
      `${apiBaseUrl}/api/CalendarYearLoad`,
      config
    );
    return response.data;
  }
);

export const addNewCalendarYearLoad = createAsyncThunk(
  'calendarYearLoad/addNewCalendarYearLoad',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.post(
      `${apiBaseUrl}/api/CalendarYearLoad`,
      initialState,
      config
    );
    console.log('adding new calendarYearLoad record');
    console.log(response);
    return { ...initialState, id: response.data };
  }
);

export const deleteCalendarYearLoad = createAsyncThunk(
  'calendarYearLoad/deleteCalendarYearLoad',
  async (calendarYearLoadId, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.delete(
      `${apiBaseUrl}/api/CalendarYearLoad/${calendarYearLoadId}`,
      config
    );
    return calendarYearLoadId;
  }
);

export const updateCalendarYearLoad = createAsyncThunk(
  'calendarYearLoad/updateCalendarYearLoad',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.put(
      `${apiBaseUrl}/api/CalendarYearLoad/${initialState.id}`,
      initialState,
      config
    );
    return { ...initialState, id: response.data };
  }
);

const calendarYearLoadSlice = createSlice({
  name: 'calendarYearLoad',
  initialState,
  extraReducers: {
    [fetchCalendarYearLoad.pending]: state => {
      state.status = 'loading';
    },
    [fetchCalendarYearLoad.fulfilled]: (state, action) => {
      // Add any fetched posts to the array
      calendarYearLoadAdapter.upsertMany(state, action.payload);
      state.status = 'succeeded';
    },
    [fetchCalendarYearLoad.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [addNewCalendarYearLoad.fulfilled]: calendarYearLoadAdapter.addOne,
    [deleteCalendarYearLoad.fulfilled]: calendarYearLoadAdapter.removeOne,
    [updateCalendarYearLoad.fulfilled]: calendarYearLoadAdapter.upsertOne,
  },
});

export default calendarYearLoadSlice.reducer;

export const {
  selectAll: selectAllCalendarYearLoad,
  selectById: selectCalendarYearLoadById,
  selectIds: selectCalendarYearLoadIds,
} = calendarYearLoadAdapter.getSelectors(state => state.calendarYearLoad);
