import React, { useEffect } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalHeader,
  ModalFooter,
  ModalContent,
  ModalCloseButton,
  Heading,
  useDisclosure,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchWaitingPeriod,
  selectWaitingPeriodIds,
  selectWaitingPeriodById,
  deleteWaitingPeriod,
} from './waitingPeriodSlice';
import { unwrapResult } from '@reduxjs/toolkit';

import { NavLink } from 'react-router-dom';
import { FormattedDate } from '../common/FormattedDate';
import { FormattedTimestamp } from '../common/FormattedTimestamp';

const ItemDetails = ({ currentItem }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button onClick={onOpen} size="xs">
        Details
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Waiting Period: {currentItem.waitingPeriodDesc}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Table size="sm">
              <Tbody>
                <Tr>
                  <Th>Waiting Period Description</Th>
                  <Th>Prev</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.waitingPeriodDesc}</Td>
                  <Td>{currentItem.prev}</Td>
                </Tr>
                <Tr>
                  <Th>Xray</Th>
                  <Th>Mres</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.xray}</Td>
                  <Td>{currentItem.mres}</Td>
                </Tr>

                <Tr>
                  <Th>Oth2</Th>
                  <Th>Pros</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.oth2}</Td>
                  <Td>{currentItem.pros}</Td>
                </Tr>

                <Tr>
                  <Th>Ortho</Th>
                  <Th> </Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.ortho}</Td>
                  <Td> </Td>
                </Tr>
                <Tr>
                  <Th>Modified By Name</Th>
                  <Th>Modified Date</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.modifiedByName}</Td>
                  <Td>
                    <FormattedTimestamp
                      dateToFormat={currentItem.modifiedDate}
                    />
                  </Td>
                </Tr>
                <Tr>
                  <Th>Eff Start Date</Th>
                  <Th>Eff End Date</Th>
                </Tr>
                <Tr>
                  <Td>
                    <FormattedDate dateToFormat={currentItem.effStartDate} />
                  </Td>
                  <Td>
                    <FormattedDate dateToFormat={currentItem.effEndDate} />
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="brand-primary-sm"
              mr={3}
              onClick={onClose}
              size="sm"
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

let WaitingPeriodTable = ({ waitingPeriodIdList }) => {
  let tableRows = waitingPeriodIdList.map(waitingPeriodId => (
    <WaitingPeriod waitingPeriodId={waitingPeriodId} key={waitingPeriodId} />
  ));

  return (
    <Box overflowX="auto">
      <Table size="sm">
        <Thead>
          <Tr>
            <Th></Th>
            <Th>Waiting Period Description</Th>
            <Th>Prev</Th>
            <Th>Xray</Th>
            <Th>Mres</Th>
            <Th>Oth2</Th>
            <Th>Pros</Th>
            <Th>Ortho</Th>

            <Th>Modified By Name</Th>
            <Th>Modified Date</Th>
            <Th>Eff Start Date</Th>
            <Th>Eff End Date</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>{tableRows}</Tbody>
      </Table>
    </Box>
  );
};

let WaitingPeriod = ({ waitingPeriodId }) => {
  const waitingPeriod = useSelector(state =>
    selectWaitingPeriodById(state, waitingPeriodId)
  );

  const dispatch = useDispatch();

  const onDeleteWaitingPeriodClicked = async () => {
    try {
      const resultAction = await dispatch(
        deleteWaitingPeriod(waitingPeriod.id)
      );
      unwrapResult(resultAction);
    } catch (err) {
      console.error('Failed to save the WaitingPeriod record', err);
    }
  };

  return (
    <Tr>
      <Td>
        <ItemDetails currentItem={waitingPeriod} />
      </Td>
      <Td>{waitingPeriod.waitingPeriodDesc}</Td>
      <Td>{waitingPeriod.prev}</Td>
      <Td>{waitingPeriod.xray}</Td>
      <Td>{waitingPeriod.mres}</Td>
      <Td>{waitingPeriod.oth2}</Td>
      <Td>{waitingPeriod.pros}</Td>
      <Td>{waitingPeriod.ortho}</Td>

      <Td>{waitingPeriod.modifiedByName}</Td>
      <Td>
        <FormattedTimestamp dateToFormat={waitingPeriod.modifiedDate} />
      </Td>
      <Td>
        <FormattedDate dateToFormat={waitingPeriod.effStartDate} />
      </Td>
      <Td>
        <FormattedDate dateToFormat={waitingPeriod.effEndDate} />
      </Td>
      <Td>
        <ButtonGroup variant="outline">
          <Button
            colorScheme="gray"
            size="sm"
            as={NavLink}
            to={`/editWaitingPeriod/${waitingPeriod.id}`}
          >
            Edit
          </Button>
          <Button
            colorScheme="red"
            size="sm"
            onClick={onDeleteWaitingPeriodClicked}
          >
            Delete
          </Button>
        </ButtonGroup>
      </Td>
    </Tr>
  );
};

export const WaitingPeriodList = () => {
  const dispatch = useDispatch();
  const orderedWaitingPeriodIds = useSelector(selectWaitingPeriodIds);

  const error = useSelector(state => state.waitingPeriod.error);

  const waitingPeriodStatus = useSelector(state => state.waitingPeriod.status);
  console.log(waitingPeriodStatus);
  useEffect(() => {
    if (waitingPeriodStatus === 'idle') {
      dispatch(fetchWaitingPeriod());
    }
  }, [waitingPeriodStatus, dispatch]);

  let content;

  if (waitingPeriodStatus === 'loading') {
    content = <div className="loader">Loading...</div>;
  } else if (waitingPeriodStatus === 'succeeded') {
    content = (
      <WaitingPeriodTable waitingPeriodIdList={orderedWaitingPeriodIds} />
    );
  } else if (waitingPeriodStatus === 'failed') {
    content = <div>{error}</div>;
  }

  return (
    <section>
      <h2>
        <b>Waiting Period Listing</b>
      </h2>
      {content}
    </section>
  );
};
