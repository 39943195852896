import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
  AccordionIcon,
  Heading,
} from '@chakra-ui/react';
import {
  fetchDeductibleAmounts,
  selectDeductibleAmountIds,
  selectAllDeductibleAmounts,
} from './deductibleAmountSlice';
import { AddDeductAmtFormUser } from './AddDeductAmtForm';
import { DeductAmtList } from './DeductAmtList';
import BrandLinkButton from '../../components/common/BrandLinkButton';

export const DeductAmtPage = () => {
  const dispatch = useDispatch();

  const orderedDeductAmtIds = useSelector(selectDeductibleAmountIds);
  const deductAmtList = useSelector(selectAllDeductibleAmounts);

  const error = useSelector(state => state.deductibleAmounts.error);
  if (error) {
    console.log('DeductAmt List Error:' + error.toString());
  }

  const deductAmtStatus = useSelector(state => state.deductibleAmounts.status);

  const [index, setIndex] = useState([]);

  useEffect(() => {
    if (deductAmtStatus === 'idle') {
      dispatch(fetchDeductibleAmounts());
    }
  }, [deductAmtStatus, dispatch]);

  function closeAccordion() {
    setIndex([]);
  }

  let content;

  if (deductAmtStatus === 'loading') {
    content = <div className="loader">Loading...</div>;
  } else if (deductAmtStatus === 'succeeded') {
    console.log(orderedDeductAmtIds);

    content = (
      <div>
        <Heading size="xl" mb={8}>
          Deductible Amount Factors
        </Heading>
        <Accordion
          allowMultiple
          allowToggle="true"
          mb={8}
          index={index}
          onChange={setIndex}
        >
          <AccordionItem>
            <Box>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Text variant="brand-green-link" fontSize="lg">
                    Add New Factor
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </Box>
            <AccordionPanel pb={4}>
              <AddDeductAmtFormUser
                deductAmtList={deductAmtList}
                closeAccordion={closeAccordion}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <DeductAmtList orderedDeductAmtIds={orderedDeductAmtIds} />
      </div>
    );
  } else if (deductAmtStatus === 'failed') {
    content = <div>{error}</div>;
  }

  return (
    <>
      {content}
      <Box mt={12}>
        <BrandLinkButton path="/settings">Back to Settings</BrandLinkButton>
      </Box>
    </>
  );
};

export default DeductAmtPage;
