import React, { useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Button,
  CloseButton,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Stack,
  StackDivider,
  VStack,
} from '@chakra-ui/react';

import { addNewDependentAge } from './dependentAgeSlice';
import { FieldGroup } from '../../components/common/FieldGroup';
import DatePicker from '../../components/common/DatePicker';

import { ErrorDisplay } from '../common/ErrorDisplay';
import { overlapCheck } from '../../services/overlapCheck';
import {
  dateToString,
  nullableDateToString,
} from '../../services/dateConverters';

export const AddDependentAgeForm = ({ user, dependentAgeList }) => {
  const [tierType, setTierType] = useState(' ');
  const [ageLimitType, setAgeLimitType] = useState(' ');
  const [lowRange, setLowRange] = useState(0);
  const [highRange, setHighRange] = useState(0);
  const [factor, setFactor] = useState(0);

  const [startDate, setEffStartDate] = useState(new Date());
  const [endDate, setEffEndDate] = useState('');
  const [addRequestStatus, setAddRequestStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();

  const onTierTypeChanged = e => {
    setTierType(e.target.value);
    setErrorMessage('');
  };
  const onAgeLimitTypeChanged = e => setAgeLimitType(e.target.value);
  const onLowRangeChanged = e => setLowRange(Number(e.target.value));
  const onHighRangeChanged = e => setHighRange(Number(e.target.value));
  const onFactorChanged = e => setFactor(Number(e.target.value));

  const onEffStartDateChanged = e => {
    setEffStartDate(e);
    setErrorMessage('');
  };
  const onEffEndDateChanged = e => {
    setEffEndDate(e);
    setErrorMessage('');
  };

  const canSave =
    [
      tierType,
      ageLimitType,
      !isNaN(lowRange),
      !isNaN(highRange),
      !isNaN(factor),

      startDate,
    ].every(Boolean) && addRequestStatus === 'idle';

  const onSaveDependentAgeClicked = async () => {
    if (canSave) {
      try {
        setAddRequestStatus('pending');

        var dependentAgeOverlap = dependentAgeList.filter(overlapCheck, {
          effStartDate: startDate,
          effEndDate: endDate,
          keys: [
            {
              name: 'tierType',
              value: tierType,
            },
            {
              name: 'ageLimitType',
              value: ageLimitType,
            },
            {
              name: 'lowRange',
              value: lowRange,
            },
            {
              name: 'highRange',
              value: highRange,
            },
          ],
        });
        if (dependentAgeOverlap.length) {
          console.log(dependentAgeOverlap);
          setErrorMessage(
            'The record being entered overlaps an existing effective date range for this key value.'
          );
          return false;
        }

        const resultAction = await dispatch(
          addNewDependentAge({
            tierType,
            ageLimitType,
            lowRange,
            highRange,
            factor,
            modifiedByName: user.profile.name,
            modifiedDate: new Date().toISOString(),
            effStartDate: dateToString(startDate),
            effEndDate: nullableDateToString(endDate),
          })
        );

        unwrapResult(resultAction);

        setTierType('');
        setAgeLimitType('');
        setLowRange(0);
        setHighRange(0);
        setFactor(0);

        setEffStartDate(new Date());
        setEffEndDate('');
      } catch (err) {
        console.error('Failed to save the dependentAge factor record: ', err);
      } finally {
        setAddRequestStatus('idle');
      }
    }
  };

  return (
    <Box
      px={{
        base: '4',
        md: '10',
      }}
      pb="16"
      maxWidth="3xl"
      mx="auto"
    >
      <form>
        <Stack spacing="4" divider={<StackDivider />}>
          <FieldGroup title="Add DependentAge Record">
            <VStack width="full" spacing="6">
              <FormControl id="tierType">
                <FormLabel>TierType:</FormLabel>
                <Input
                  type="text"
                  value={tierType}
                  onChange={onTierTypeChanged}
                />
              </FormControl>
              <FormControl id="ageLimitType">
                <FormLabel>AgeLimitType:</FormLabel>
                <Input
                  type="text"
                  value={ageLimitType}
                  onChange={onAgeLimitTypeChanged}
                />
              </FormControl>
              <FormControl id="lowRange">
                <FormLabel>LowRange:</FormLabel>
                <Input
                  type="number"
                  value={lowRange}
                  onChange={onLowRangeChanged}
                />
              </FormControl>
              <FormControl id="highRange">
                <FormLabel>HighRange:</FormLabel>
                <Input
                  type="number"
                  value={highRange}
                  onChange={onHighRangeChanged}
                />
              </FormControl>

              <FormControl id="factor">
                <FormLabel>Factor:</FormLabel>
                <Input
                  type="number"
                  value={factor}
                  onChange={onFactorChanged}
                />
              </FormControl>

              <FormControl>
                <FormLabel htmlFor="effStartDate">
                  Effective Start Date
                </FormLabel>
                <DatePicker
                  id="effStartDate"
                  selectedDate={startDate}
                  onChange={onEffStartDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective Start Date</FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="effEndDate">Effective End Date</FormLabel>
                <DatePicker
                  id="effEndDate"
                  selectedDate={endDate}
                  onChange={onEffEndDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective End Date</FormHelperText>
              </FormControl>
            </VStack>
          </FieldGroup>

          <Button
            onClick={onSaveDependentAgeClicked}
            disabled={!canSave}
            variant="brand-primary"
            size="xs"
          >
            Save DependentAge Factor Record
          </Button>
          <ErrorDisplay
            title="The record dates overlap other records!"
            errorMessage={errorMessage}
          />
        </Stack>
      </form>
    </Box>
  );
};

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
  };
}

export const AddDependentAgeFormUser =
  connect(mapStateToProps)(AddDependentAgeForm);
