import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Accordion,
    AccordionButton,
    AccordionItem,
    AccordionPanel,
    Box,
    Text,
    AccordionIcon,
    Heading,
} from '@chakra-ui/react';
import { fetchRollover, selectRolloverIds, selectAllRollover } from './rolloverSlice';
import { AddRolloverFormUser } from './AddRolloverForm';
import { RolloverList } from './RolloverList';
import BrandLinkButton from '../../components/common/BrandLinkButton';

export const RolloverPage = () => {
    const dispatch = useDispatch();

    const orderedRolloverIds = useSelector(selectRolloverIds);
    const rolloverList = useSelector(selectAllRollover);
   
    const error = useSelector(state => state.rollover.error);
    if (error) {
        console.log('Rollover List Error:' + error.toString());
    }

    const rolloverStatus = useSelector(state => state.rollover.status);
    const [index, setIndex] = React.useState([]);
    
    useEffect(() => {
        if (rolloverStatus === 'idle') {
            dispatch(fetchRollover());
        }
    }, [rolloverStatus, dispatch]);

    function closeAccordion() {
        setIndex([]);
    }

    let content;

    if (rolloverStatus === 'loading') {
        content = <div className="loader">Loading...</div>;
    } else if (rolloverStatus === 'succeeded') {
        console.log(orderedRolloverIds);
        content = (           
            <div>
        <Heading size="xl" mb={8}>
                    Rollover Factors
        </Heading>
        <Accordion
          allowMultiple
          allowToggle="true"
          mb={8}
          index={index}
          onChange={setIndex}
        >
          <AccordionItem>
            <Box>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Text variant="brand-green-link" fontSize="lg">
                                        Add New Factor
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </Box>
            <AccordionPanel pb={4}>
               <AddRolloverFormUser rolloverList={rolloverList}
                closeAccordion={closeAccordion}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <RolloverList orderedRolloverIds={orderedRolloverIds} />
      </div>
        );
    } else if (rolloverStatus === 'failed') {
        content = <div>{error}</div>;
    }

  return (
    <>
      {content}
      <Box mt={12}>
        <BrandLinkButton path="/settings">Back to Settings</BrandLinkButton>
      </Box>
    </>
  );
};

export default RolloverPage;
