import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import axios from 'axios';

const areaEhbAdapter = createEntityAdapter();
const apiBaseUrl = window.ENV.api_base_url;

const initialState = areaEhbAdapter.getInitialState({
  status: 'idle',
  error: null,
});

export const fetchAreaEhb = createAsyncThunk(
  'areaEhb/fetchAreaEhb',
  async (_, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };

    const response = await axios.get(`${apiBaseUrl}/api/AreaEhb`, config);
    return response.data;
  }
);

export const addNewAreaEhb = createAsyncThunk(
  'areaEhb/addNewAreaEhb',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.post(
      `${apiBaseUrl}/api/AreaEhb`,
      initialState,
      config
    );
    console.log('adding new areaEhb record');
    console.log(response);
    return { ...initialState, id: response.data };
  }
);

export const deleteAreaEhb = createAsyncThunk(
  'areaEhb/deleteAreaEhb',
  async (areaEhbId, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.delete(
      `${apiBaseUrl}/api/AreaEhb/${areaEhbId}`,
      config
    );
    return areaEhbId;
  }
);

export const updateAreaEhb = createAsyncThunk(
  'areaEhb/updateAreaEhb',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.put(
      `${apiBaseUrl}/api/AreaEhb/${initialState.id}`,
      initialState,
      config
    );
    return { ...initialState, id: response.data };
  }
);

const areaEhbSlice = createSlice({
  name: 'areaEhb',
  initialState,
  extraReducers: {
    [fetchAreaEhb.pending]: state => {
      state.status = 'loading';
    },
    [fetchAreaEhb.fulfilled]: (state, action) => {
      // Add any fetched posts to the array
      areaEhbAdapter.upsertMany(state, action.payload);
      state.status = 'succeeded';
    },
    [fetchAreaEhb.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [addNewAreaEhb.fulfilled]: areaEhbAdapter.addOne,
    [deleteAreaEhb.fulfilled]: areaEhbAdapter.removeOne,
    [updateAreaEhb.fulfilled]: areaEhbAdapter.upsertOne,
  },
});

export default areaEhbSlice.reducer;

export const {
  selectAll: selectAllAreaEhb,
  selectById: selectAreaEhbById,
  selectIds: selectAreaEhbIds,
} = areaEhbAdapter.getSelectors(state => state.areaEhb);
