import React, { useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Stack,
  StackDivider,
  VStack,
} from '@chakra-ui/react';

import { addNewCopay } from './copaySlice';
import { FieldGroup } from '../../components/common/FieldGroup';
import DatePicker from '../../components/common/DatePicker';
import * as moment from 'moment';

import { ErrorDisplay } from '../common/ErrorDisplay';
import { overlapCheck } from '../../services/overlapCheck';
import {
  dateToString,
  nullableDateToString,
} from '../../services/dateConverters';

export const AddCopayForm = ({ user, copayList, closeAccordion }) => {
  const [amount, setAmount] = useState(0);
  const [class1, setClass1] = useState(0);
  const [class2, setClass2] = useState(0);
  const [class3, setClass3] = useState(0);
  const [startDate, setEffStartDate] = useState(new Date());
  const [endDate, setEffEndDate] = useState('');
  const [addRequestStatus, setAddRequestStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();

  const onAmountChanged = e => {
    setAmount(Number(e.target.value));
    setErrorMessage('');
  };
  const onClass1Changed = e => setClass1(Number(e.target.value));
  const onClass2Changed = e => setClass2(Number(e.target.value));
  const onClass3Changed = e => setClass3(Number(e.target.value));
  const onEffStartDateChanged = e => {
    setEffStartDate(e);
    setErrorMessage('');
  };
  const onEffEndDateChanged = e => {
    setEffEndDate(e);
    setErrorMessage('');
  };

  const canSave =
    [
      !isNaN(amount),
      !isNaN(class1),
      !isNaN(class2),
      !isNaN(class3),
      startDate,
    ].every(Boolean) && addRequestStatus === 'idle';

  const onSaveCopayClicked = async () => {
    if (canSave) {
      try {
        setAddRequestStatus('pending');

        var copayOverlap = copayList.filter(overlapCheck, {
          effStartDate: startDate,
          effEndDate: endDate,
          keys: [
            {
              name: 'amount',
              value: amount,
            },
          ],
        });
        console.log('copayOverlap:');
        console.log(copayOverlap);
        if (copayOverlap.length) {
          setErrorMessage(
            'The record being entered overlaps an existing effective date range for this key value.'
          );
          return false;
        }

        const resultAction = await dispatch(
          addNewCopay({
            amount,
            class1,
            class2,
            class3,
            modifiedByName: user.profile.name,
            modifiedDate: new Date().toISOString(),
            effStartDate: dateToString(startDate),
            effEndDate: nullableDateToString(endDate),
          })
        );

        unwrapResult(resultAction);

        setAmount(0);
        setClass1(0);
        setClass2(0);
        setClass3(0);
        setEffStartDate(new Date());
        setEffEndDate('');

        closeAccordion();
      } catch (err) {
        console.error('Failed to save the copay factor record: ', err);
      } finally {
        setAddRequestStatus('idle');
      }
    }
  };

  return (
    <Box
      px={{
        base: '4',
        md: '10',
      }}
      pb="16"
      maxWidth="3xl"
      mx="auto"
    >
      <form>
        <Stack spacing="4" divider={<StackDivider />}>
          <FieldGroup title="Add Copay Record">
            <VStack width="full" spacing="6">
              <FormControl id="amount">
                <FormLabel>Amount:</FormLabel>
                <Input
                  type="number"
                  value={amount}
                  onChange={onAmountChanged}
                />
              </FormControl>
              <FormControl id="class1">
                <FormLabel>Class1:</FormLabel>
                <Input
                  type="number"
                  value={class1}
                  onChange={onClass1Changed}
                />
              </FormControl>
              <FormControl id="class2">
                <FormLabel>Class2:</FormLabel>
                <Input
                  type="number"
                  value={class2}
                  onChange={onClass2Changed}
                />
              </FormControl>
              <FormControl id="class3">
                <FormLabel>Class3:</FormLabel>
                <Input
                  type="number"
                  value={class3}
                  onChange={onClass3Changed}
                />
              </FormControl>

              <FormControl>
                <FormLabel htmlFor="effStartDate">
                  Effective Start Date
                </FormLabel>
                <DatePicker
                  id="effStartDate"
                  selectedDate={startDate}
                  onChange={onEffStartDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective Start Date</FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="effEndDate">Effective End Date</FormLabel>
                <DatePicker
                  id="effEndDate"
                  selectedDate={endDate}
                  onChange={onEffEndDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective End Date</FormHelperText>
              </FormControl>
            </VStack>
          </FieldGroup>

          <Button
            onClick={onSaveCopayClicked}
            disabled={!canSave}
            variant="brand-primary"
            size="xs"
          >
            Save Copay Factor Record
          </Button>
          <ErrorDisplay
            title="The record dates overlap other records!"
            errorMessage={errorMessage}
          />
        </Stack>
      </form>
    </Box>
  );
};

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
  };
}

export const AddCopayFormUser = connect(mapStateToProps)(AddCopayForm);
