import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Select,
  Stack,
  StackDivider,
  VStack,
} from '@chakra-ui/react';

import {
  fetchCalendarYearLoad,
  updateCalendarYearLoad,
  selectCalendarYearLoadById,
  selectAllCalendarYearLoad,
} from './calendarYearLoadSlice';
import { FieldGroup } from '../../components/common/FieldGroup';
import DatePicker from '../../components/common/DatePicker';
import { ErrorDisplay } from '../common/ErrorDisplay';
import { overlapCheck } from '../../services/overlapCheck';
import {
  dateToString,
  nullableDateToString,
  stringToDateFormat,
} from '../../services/dateConverters';

export const EditCalendarYearLoadForm = ({ match, user }) => {
  const { calendarYearLoadId } = match.params;
  const calendarYearLoad = useSelector(state =>
    selectCalendarYearLoadById(state, calendarYearLoadId)
  );
  const calendarYearLoadList = useSelector(selectAllCalendarYearLoad);

  const stdt = stringToDateFormat(calendarYearLoad.effStartDate);
  console.log(stdt);
  const endt = stringToDateFormat(calendarYearLoad.effEndDate);
  console.log(endt);

  const [month, setMonth] = useState(calendarYearLoad.month);
  const [factor, setFactor] = useState(calendarYearLoad.factor);

  const [startDate, setEffStartDate] = useState(stdt);

  const [endDate, setEffEndDate] = useState(endt);
  const [updateRequestStatus, setUpdateRequestStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();
  const history = useHistory();

  const onMonthChanged = e => {
    setMonth(e.target.value);
    setErrorMessage('');
  };
  const onFactorChanged = e => setFactor(Number(e.target.value));

  const onEffStartDateChanged = e => setEffStartDate(e);
  const onEffEndDateChanged = e => setEffEndDate(e);

  const canSave =
    [!isNaN(month), !isNaN(factor), startDate].every(Boolean) &&
    updateRequestStatus === 'idle';

  const onSaveCalendarYearLoadClicked = async () => {
    if (canSave) {
      try {
        setUpdateRequestStatus('pending');

        var calendarYearLoadOverlap = calendarYearLoadList.filter(
          overlapCheck,
          {
            id: calendarYearLoad.id,
            effStartDate: startDate,
            effEndDate: endDate,
            keys: [
              {
                name: 'month',
                value: month,
              },
            ],
          }
        );
        if (calendarYearLoadOverlap.length) {
          console.log(calendarYearLoadOverlap);
          setErrorMessage(
            'The record being entered overlaps an existing effective date range for this key value.'
          );
          return false;
        }

        const resultAction = await dispatch(
          updateCalendarYearLoad({
            id: Number(calendarYearLoadId),
            month,
            factor,
            modifiedByName: user.profile.name,
            modifiedDate: new Date().toISOString(),
            effStartDate: dateToString(startDate),
            effEndDate: nullableDateToString(endDate),
          })
        );
        console.log(resultAction);
        setUpdateRequestStatus('idle');
        history.push(`/calendarYearLoad`);
      } catch (err) {
        setUpdateRequestStatus('idle');
        console.error('Failed to save the administration factor: ', err);
      }
    }
  };
  return (
    <Box
      px={{
        base: '4',
        md: '10',
      }}
      pb="16"
      maxWidth="3xl"
      mx="auto"
    >
      <form>
        <Stack spacing="4" divider={<StackDivider />}>
          <FieldGroup title="Edit Administration Factors">
            <VStack width="full" spacing="6">
              <FormControl id="month">
                <FormLabel>Month:</FormLabel>
                <Input type="number" value={month} onChange={onMonthChanged} />
              </FormControl>
              <FormControl id="factor">
                <FormLabel>Factor:</FormLabel>
                <Input
                  type="number"
                  value={factor}
                  onChange={onFactorChanged}
                />
              </FormControl>

              <FormControl id="effStartDate">
                <FormLabel>Effective Start Date:</FormLabel>
                <DatePicker
                  id="effStartDate"
                  selectedDate={startDate}
                  onChange={onEffStartDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective Start Date</FormHelperText>
              </FormControl>
              <FormControl id="effEndDate">
                <FormLabel>Effective End Date:</FormLabel>
                <DatePicker
                  id="effEndDate"
                  selectedDate={endDate}
                  onChange={onEffEndDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective End Date</FormHelperText>
              </FormControl>
            </VStack>
          </FieldGroup>

          <Button
            onClick={onSaveCalendarYearLoadClicked}
            disabled={!canSave}
            variant="brand-primary"
            size="xs"
          >
            Save CalendarYearLoad Factor
          </Button>
          <ErrorDisplay
            title="The record dates overlap other records!"
            errorMessage={errorMessage}
          />
        </Stack>
      </form>
    </Box>
  );
};

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
  };
}

export const EditCalendarYearLoadFormUser = connect(mapStateToProps)(
  EditCalendarYearLoadForm
);
