import React from 'react';
import {
  Box,
  Button,
  Text,
  Grid,
  Heading,
  HStack,
  Stack,
} from '@chakra-ui/react';
import BrandLinkButton from '../../components/common/BrandLinkButton';

function MemberFactorsPage() {
  return (
    <Box textAlign="left" fontSize="xl" p={8}>
      <Grid p={3}>
        <Stack>
          <Heading>Member Factors</Heading>
          <Text>Here the group ratings' Member-associated settings.</Text>
          <HStack>
            <Button colorScheme="blue">Age(Adult)</Button>
            <Button colorScheme="blue">Age(Child)</Button>
            <Button colorScheme="blue">County Area</Button>
            <Button colorScheme="blue">Dependent Age</Button>
            <Button colorScheme="blue">Occupation</Button>
            <Button colorScheme="blue">Zip Code Prefix</Button>
          </HStack>
        </Stack>
      </Grid>
      <Box mt={12}>
        <BrandLinkButton path="/settings">Back to Settings</BrandLinkButton>
      </Box>
    </Box>
  );
}

export default MemberFactorsPage;
