import React, { useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Button,
  CloseButton,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Stack,
  StackDivider,
  VStack,
} from '@chakra-ui/react';

import { addNewUsaTrend } from './usaTrendSlice';
import { FieldGroup } from '../../components/common/FieldGroup';
import DatePicker from '../../components/common/DatePicker';
import { ErrorDisplay } from '../common/ErrorDisplay';
import { overlapCheck } from '../../services/overlapCheck';
import {
  dateToString,
  nullableDateToString,
} from '../../services/dateConverters';

export const AddUsaTrendForm = ({ user, usaTrendList }) => {
  const [state, setState] = useState(' ');
  const [premier, setPremier] = useState(0);
  const [ppo, setPpo] = useState(0);

  const [startDate, setEffStartDate] = useState(new Date());
  const [endDate, setEffEndDate] = useState('');
  const [addRequestStatus, setAddRequestStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();

  const onStateChanged = e => setState(e.target.value);
  const onPremierChanged = e => setPremier(Number(e.target.value));
  const onPpoChanged = e => setPpo(Number(e.target.value));

  const onEffStartDateChanged = e => setEffStartDate(e);
  const onEffEndDateChanged = e => setEffEndDate(e);

  const canSave =
    [state, !isNaN(premier), !isNaN(ppo), startDate].every(Boolean) &&
    addRequestStatus === 'idle';

  const onSaveUsaTrendClicked = async () => {
    if (canSave) {
      try {
        setAddRequestStatus('pending');

        var usaTrendOverlap = usaTrendList.filter(overlapCheck, {
          effStartDate: startDate,
          effEndDate: endDate,
          keys: [
            {
              name: 'state',
              value: state,
            },
          ],
        });
        if (usaTrendOverlap.length) {
          console.log(usaTrendOverlap);
          setErrorMessage(
            'The record being entered overlaps an existing effective date range for this key value.'
          );
          return false;
        }

        const resultAction = await dispatch(
          addNewUsaTrend({
            state,
            premier,
            ppo,

            modifiedByName: user.profile.name,
            modifiedDate: new Date().toISOString(),
            effStartDate: dateToString(startDate),
            effEndDate: nullableDateToString(endDate),
            //startDate,
            //endDate,
          })
        );

        unwrapResult(resultAction);

        setState('');
        setPremier(0);
        setPpo(0);

        setEffStartDate(new Date());
        setEffEndDate('');

        // closeAccordion();
      } catch (err) {
        console.error('Failed to save the usaTrend factor record: ', err);
      } finally {
        setAddRequestStatus('idle');
      }
    }
  };

  return (
    <Box
      px={{
        base: '4',
        md: '10',
      }}
      pb="16"
      maxWidth="3xl"
      mx="auto"
    >
      <form>
        <Stack spacing="4" divider={<StackDivider />}>
          <FieldGroup title="Add UsaTrend Record">
            <VStack width="full" spacing="6">
              <FormControl id="state">
                <FormLabel>State:</FormLabel>
                <Input type="text" value={state} onChange={onStateChanged} />
              </FormControl>
              <FormControl id="premier">
                <FormLabel>Premier:</FormLabel>
                <Input
                  type="number"
                  value={premier}
                  onChange={onPremierChanged}
                />
              </FormControl>
              <FormControl id="ppo">
                <FormLabel>PPO:</FormLabel>
                <Input type="number" value={ppo} onChange={onPpoChanged} />
              </FormControl>

              <FormControl>
                <FormLabel htmlFor="effStartDate">
                  Effective Start Date
                </FormLabel>
                <DatePicker
                  id="effStartDate"
                  selectedDate={startDate}
                  onChange={onEffStartDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective Start Date</FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="effEndDate">Effective End Date</FormLabel>
                <DatePicker
                  id="effEndDate"
                  selectedDate={endDate}
                  onChange={onEffEndDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective End Date</FormHelperText>
              </FormControl>
            </VStack>
          </FieldGroup>

          <Button
            onClick={onSaveUsaTrendClicked}
            disabled={!canSave}
            variant="brand-primary"
            size="xs"
          >
            Save UsaTrend Factor Record
          </Button>
          <ErrorDisplay
            title="The record dates overlap other records!"
            errorMessage={errorMessage}
          />
        </Stack>
      </form>
    </Box>
  );
};

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
  };
}

export const AddUsaTrendFormUser = connect(mapStateToProps)(AddUsaTrendForm);
