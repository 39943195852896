import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import axios from 'axios';

const usaTrendAdapter = createEntityAdapter();
const apiBaseUrl = window.ENV.api_base_url;

const initialState = usaTrendAdapter.getInitialState({
  status: 'idle',
  error: null,
});

export const fetchUsaTrend = createAsyncThunk(
  'usaTrend/fetchUsaTrend',
  async (_, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };

    const response = await axios.get(`${apiBaseUrl}/api/UsaTrend`, config);
    return response.data;
  }
);

export const addNewUsaTrend = createAsyncThunk(
  'usaTrend/addNewUsaTrend',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.post(
      `${apiBaseUrl}/api/UsaTrend`,
      initialState,
      config
    );
    console.log('adding new usaTrend record');
    console.log(response);
    return { ...initialState, id: response.data };
  }
);

export const deleteUsaTrend = createAsyncThunk(
  'usaTrend/deleteUsaTrend',
  async (usaTrendId, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.delete(
      `${apiBaseUrl}/api/UsaTrend/${usaTrendId}`,
      config
    );
    return usaTrendId;
  }
);

export const updateUsaTrend = createAsyncThunk(
  'usaTrend/updateUsaTrend',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.put(
      `${apiBaseUrl}/api/UsaTrend/${initialState.id}`,
      initialState,
      config
    );
    return { ...initialState, id: response.data };
  }
);

const usaTrendSlice = createSlice({
  name: 'usaTrend',
  initialState,
  extraReducers: {
    [fetchUsaTrend.pending]: state => {
      state.status = 'loading';
    },
    [fetchUsaTrend.fulfilled]: (state, action) => {
      // Add any fetched posts to the array
      usaTrendAdapter.upsertMany(state, action.payload);
      state.status = 'succeeded';
    },
    [fetchUsaTrend.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [addNewUsaTrend.fulfilled]: usaTrendAdapter.addOne,
    [deleteUsaTrend.fulfilled]: usaTrendAdapter.removeOne,
    [updateUsaTrend.fulfilled]: usaTrendAdapter.upsertOne,
  },
});

export default usaTrendSlice.reducer;

export const {
  selectAll: selectAllUsaTrend,
  selectById: selectUsaTrendById,
  selectIds: selectUsaTrendIds,
} = usaTrendAdapter.getSelectors(state => state.usaTrend);
