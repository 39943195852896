import { Box, Flex, ChakraProvider } from '@chakra-ui/react';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import theme from './Theme';
import { Fonts } from './Fonts';
import LeftNav from './components/common/LeftNav';
import HomePage from './components/home/HomePage';
import SettingsPage from './components/settings/SettingsPage';
import CallbackPage from './components/callback/CallbackPage';
import ProfilePage from './components/profile/ProfilePage';
import { CategoryPage } from './features/configurationFeature/CategoryPage';
import { EditCategoryForm } from './features/configurationFeature/EditCategoryForm';
import FinancialFactorsPage from './components/settings/FinancialFactorsPage';
import MemberFactorsPage from './components/settings/MemberFactorsPage';
import OtherFactorsPage from './components/settings/OtherFactorsPage';
import StatisticalFactorsPage from './components/settings/StatisticalFactorsPage';
import ConfigurationFactorsPage from './components/settings/ConfigurationFactorsPage';
import ConstantsPage from './components/settings/ConstantsPage';
import DeductAmtPage from './components/settings/DeductAmtPage';
import StatesPage from './components/settings/StatesPage';
import { EditAdminsFormUser } from './components/settings/EditAdminsForm';
import { EditCopayFormUser } from './components/settings/EditCopayForm';
import { EditDeductAmtFormUser } from './components/settings/EditDeductAmtForm';
import { EditStatFormUser } from './components/settings/EditStatForm';
import { EditRolloverFormUser } from './components/settings/EditRolloverForm';
import { EditMaximumAmountFormUser } from './components/settings/EditMaximumAmountForm';
import { EditIdcardChargeFormUser } from './components/settings/EditIdcardChargeForm';
import { EditCredibilityFormUser } from './components/settings/EditCredibilityForm';
import { EditEhbRateFormUser } from './components/settings/EditEhbRateForm';
import { EditCountyAreaFormUser } from './components/settings/EditCountyAreaForm';
import { EditAgeAdultFormUser } from './components/settings/EditAgeAdultForm';
import { EditAgeChildFormUser } from './components/settings/EditAgeChildForm';
import { EditAreaFormUser } from './components/settings/EditAreaForm';
import { EditAreaEhbFormUser } from './components/settings/EditAreaEhbForm';

import { EditPandemicFormUser } from './components/settings/EditPandemicForm';
import { EditSpecialMixFormUser } from './components/settings/EditSpecialMixForm';
import { EditAPRCFormUser } from './components/settings/EditAPRCForm';
import { EditAPCAsoFormUser } from './components/settings/EditAPCAsoForm';

import { EditVoluntaryFormUser } from './components/settings/EditVoluntaryForm';
import { EditUsaTrendFormUser } from './components/settings/EditUsaTrendForm';
import { EditWaitingPeriodFormUser } from './components/settings/EditWaitingPeriodForm';

import { EditBaseRateAmountFormUser } from './components/settings/EditBaseRateAmountForm';
import { EditBaseRatePercentFormUser } from './components/settings/EditBaseRatePercentForm';
import { EditCalendarYearLoadFormUser } from './components/settings/EditCalendarYearLoadForm';

import { EditDependentAgeFormUser } from './components/settings/EditDependentAgeForm';
import { EditDeductibleCreditFormUser } from './components/settings/EditDeductibleCreditForm';
import { EditEhbTierDistFormUser } from './components/settings/EditEhbTierDistForm';
import { EditNonParPaymentFormUser } from './components/settings/EditNonParPaymentForm';
import { EditOrthodonticBaseRateFormUser } from './components/settings/EditOrthodonticBaseRateForm';
import { EditConstantsAdminFormUser } from './components/settings/EditConstantsAdminForm';
import { EditEstimatedClaimFormUser } from './components/settings/EditEstimatedClaimForm';
import { EditEstimatedClaimPlanFormUser } from './components/settings/EditEstimatedClaimPlanForm';
import { EditMaxAmtExcludedFormUser } from './components/settings/EditMaxAmtExcludedForm';
import { EditOccupationFormUser } from './components/settings/EditOccupationForm';

import RolloverPage from './components/settings/RolloverPage';
import PrivateRoute from './components/common/PrivateRoute';
import AdminsPage from './components/settings/AdminsPage';
import CopayPage from './components/settings/CopayPage';
import MaximumAmountPage from './components/settings/MaximumAmountPage';
import IdcardChargePage from './components/settings/IdcardChargePage';
import CredibilityPage from './components/settings/CredibilityPage';
import EhbRatePage from './components/settings/EhbRatePage';
import CountyAreaPage from './components/settings/CountyAreaPage';
import AgeAdultPage from './components/settings/AgeAdultPage';
import AgeChildPage from './components/settings/AgeChildPage';
import AreaPage from './components/settings/AreaPage';
import AreaEhbPage from './components/settings/AreaEhbPage';

import PandemicPage from './components/settings/PandemicPage';
import SpecialMixPage from './components/settings/SpecialMixPage';
import APRCPage from './components/settings/APRCPage';
import APCAsoPage from './components/settings/APCAsoPage';

import VoluntaryPage from './components/settings/VoluntaryPage';
import UsaTrendPage from './components/settings/UsaTrendPage';
import WaitingPeriodPage from './components/settings/WaitingPeriodPage';

import BaseRateAmountPage from './components/settings/BaseRateAmountPage';
import BaseRatePercentPage from './components/settings/BaseRatePercentPage';
import CalendarYearLoadPage from './components/settings/CalendarYearLoadPage';

import DependentAgePage from './components/settings/DependentAgePage';
import DeductibleCreditPage from './components/settings/DeductibleCreditPage';
import EhbTierDistPage from './components/settings/EhbTierDistPage';
import NonParPaymentPage from './components/settings/NonParPaymentPage';
import OrthodonticBaseRatePage from './components/settings/OrthodonticBaseRatePage';

import ConstantsAdminPage from './components/settings/ConstantsAdminPage';
import EstimatedClaimPage from './components/settings/EstimatedClaimPage';
import EstimatedClaimPlanPage from './components/settings/EstimatedClaimPlanPage';
import MaxAmtExcludedPage from './components/settings/MaxAmtExcludedPage';
import OccupationPage from './components/settings/OccupationPage';

import RenewalsPage from './components/renewals/RenewalsPage';
import IncurredClaimsPage from './components/renewals/IncurredClaimsPage';
import BasicGroupInfoPage from './components/renewals/BasicGroupInfoPage';
import GroupKeysPage from './components/renewals/GroupKeysPage';






function App() {
  return (
    <ChakraProvider theme={theme}>
      <Fonts />
      <Box height="100vh" overflow="hidden" position="relative">
        <Flex h="full" id="app-container">
          <LeftNav />
          <Box bg="white" flex="1" p="6" overflowX="auto">
            <Box
              w="full"
              h="full"
              rounded="lg"
              border="none"
              color="brand_black.85"
              overflow="auto"
            >
              <Switch>
                <Route exact path="/" component={HomePage} />
                <PrivateRoute exact path="/settings" component={SettingsPage} />
                <PrivateRoute exact path="/renewals" component={RenewalsPage} />
                <Route path="/callback" component={CallbackPage} />
                <PrivateRoute path="/profile" component={ProfilePage} />
                <PrivateRoute path="/categories" component={CategoryPage} />
                <PrivateRoute
                  path="/editCategory/:categoryId"
                  component={EditCategoryForm}
                />
                <PrivateRoute
                  exact
                  path="/FinancialFactors"
                  component={FinancialFactorsPage}
                />
                <PrivateRoute
                  exact
                  path="/MemberFactors"
                  component={MemberFactorsPage}
                />
                <PrivateRoute
                  exact
                  path="/OtherFactors"
                  component={OtherFactorsPage}
                />
                <PrivateRoute
                  exact
                  path="/StatisticalFactors"
                  component={StatisticalFactorsPage}
                />
                <PrivateRoute
                  exact
                  path="/ConfigurationFactors"
                  component={ConfigurationFactorsPage}
                />
                <PrivateRoute
                  exact
                  path="/Constants"
                  component={ConstantsPage}
                />
                <PrivateRoute
                  exact
                  path="/DeductAmt"
                  component={DeductAmtPage}
                />
                <PrivateRoute exact path="/States" component={StatesPage} />
                <PrivateRoute
                  path="/editDeductAmt/:deductibleAmountId"
                  component={EditDeductAmtFormUser}
                />
                <PrivateRoute
                  path="/editStat/:statesId"
                  component={EditStatFormUser}
                />
               
                <PrivateRoute
                  path="/editRollover/:rolloverId"
                  component={EditRolloverFormUser}
                />
                <PrivateRoute exact path="/Roller" component={RolloverPage} />

                <PrivateRoute
                    path="/editAdmins/:adminsId"
                    component={EditAdminsFormUser}
                />
                              <PrivateRoute exact path="/Admins" component={AdminsPage} />

                              <PrivateRoute
                                  path="/editCopay/:copayId"
                                  component={EditCopayFormUser}
                              />
                              <PrivateRoute exact path="/Copay" component={CopayPage} />
                              <PrivateRoute
                                  path="/editMaximumAmount/:maximumAmountId"
                                  component={EditMaximumAmountFormUser}
                              />
                              <PrivateRoute exact path="/MaximumAmount" component={MaximumAmountPage} />

                              <PrivateRoute
                                  path="/editIdcardCharge/:idcardChargeId"
                                  component={EditIdcardChargeFormUser}
                              />
                              <PrivateRoute exact path="/IdcardCharge" component={IdcardChargePage} />

                              <PrivateRoute
                                  path="/editCredibility/:credibilityId"
                                  component={EditCredibilityFormUser}
                              />
                              <PrivateRoute exact path="/Credibility" component={CredibilityPage} />

                              <PrivateRoute
                                  path="/editEhbRate/:ehbRateId"
                                  component={EditEhbRateFormUser}
                              />
                              <PrivateRoute exact path="/EhbRate" component={EhbRatePage} />

                              <PrivateRoute
                                  path="/editCountyArea/:countyAreaId"
                                  component={EditCountyAreaFormUser}
                              />
                              <PrivateRoute exact path="/CountyArea" component={CountyAreaPage} />

                              <PrivateRoute
                                  path="/editAgeAdult/:ageAdultId"
                                  component={EditAgeAdultFormUser}
                              />
                              <PrivateRoute exact path="/AgeAdult" component={AgeAdultPage} />

                              <PrivateRoute
                                  path="/editAgeChild/:ageChildId"
                                  component={EditAgeChildFormUser}
                              />
                              <PrivateRoute exact path="/AgeChild" component={AgeChildPage} />

                              <PrivateRoute
                                  path="/editArea/:areaId"
                                  component={EditAreaFormUser}
                              />
                              <PrivateRoute exact path="/Area" component={AreaPage} />

                              <PrivateRoute
                                  path="/editAreaEhb/:areaEhbId"
                                  component={EditAreaEhbFormUser}
                              />
                              <PrivateRoute exact path="/AreaEhb" component={AreaEhbPage} />

                              <PrivateRoute
                                  path="/editPandemic/:pandemicId"
                                  component={EditPandemicFormUser}
                              />
                              <PrivateRoute exact path="/Pandemic" component={PandemicPage} />

                              <PrivateRoute
                                  path="/editSpecialMix/:specialMixId"
                                  component={EditSpecialMixFormUser}
                              />
                              <PrivateRoute exact path="/SpecialMix" component={SpecialMixPage} />

                              <PrivateRoute
                                  path="/editAPRC/:aPRCId"
                                  component={EditAPRCFormUser}
                              />
                              <PrivateRoute exact path="/APRC" component={APRCPage} />

                              <PrivateRoute
                                  path="/editAPCAso/:aPCAsoId"
                                  component={EditAPCAsoFormUser}
                              />
                              <PrivateRoute exact path="/APCAso" component={APCAsoPage} />

                              <PrivateRoute
                                  path="/editVoluntary/:voluntaryId"
                                  component={EditVoluntaryFormUser}
                              />
                              <PrivateRoute exact path="/Voluntary" component={VoluntaryPage} />

                              <PrivateRoute
                                  path="/editUsaTrend/:usaTrendId"
                                  component={EditUsaTrendFormUser}
                              />
                              <PrivateRoute exact path="/UsaTrend" component={UsaTrendPage} />

                              <PrivateRoute
                                  path="/editWaitingPeriod/:waitingPeriodId"
                                  component={EditWaitingPeriodFormUser}
                              />
                              <PrivateRoute exact path="/WaitingPeriod" component={WaitingPeriodPage} />

                              <PrivateRoute
                                  path="/editBaseRateAmount/:baseRateAmountId"
                                  component={EditBaseRateAmountFormUser}
                              />
                              <PrivateRoute exact path="/BaseRateAmount" component={BaseRateAmountPage} />

                              <PrivateRoute
                                  path="/editBaseRatePercent/:baseRatePercentId"
                                  component={EditBaseRatePercentFormUser}
                              />
                              <PrivateRoute exact path="/BaseRatePercent" component={BaseRatePercentPage} />

                              <PrivateRoute
                                  path="/editCalendarYearLoad/:calendarYearLoadId"
                                  component={EditCalendarYearLoadFormUser}
                              />
                              <PrivateRoute exact path="/CalendarYearLoad" component={CalendarYearLoadPage} />

                              <PrivateRoute
                                  path="/editDependentAge/:dependentAgeId"
                                  component={EditDependentAgeFormUser}
                              />
                              <PrivateRoute exact path="/DependentAge" component={DependentAgePage} />

                              <PrivateRoute
                                  path="/editDeductibleCredit/:deductibleCreditId"
                                  component={EditDeductibleCreditFormUser}
                              />
                              <PrivateRoute exact path="/DeductibleCredit" component={DeductibleCreditPage} />

                              <PrivateRoute
                                  path="/editEhbTierDist/:ehbTierDistId"
                                  component={EditEhbTierDistFormUser}
                              />
                              <PrivateRoute exact path="/EhbTierDist" component={EhbTierDistPage} />

                              <PrivateRoute
                                  path="/editNonParPayment/:nonParPaymentId"
                                  component={EditNonParPaymentFormUser}
                              />
                              <PrivateRoute exact path="/NonParPayment" component={NonParPaymentPage} />

                              <PrivateRoute
                                  path="/editOrthodonticBaseRate/:orthodonticBaseRateId"
                                  component={EditOrthodonticBaseRateFormUser}
                              />
                              <PrivateRoute exact path="/OrthodonticBaseRate" component={OrthodonticBaseRatePage} />

                              <PrivateRoute
                                  path="/editConstantsAdmin/:constantsAdminId"
                                  component={EditConstantsAdminFormUser}
                              />
                              <PrivateRoute exact path="/ConstantsAdmin" component={ConstantsAdminPage} />

                              <PrivateRoute
                                  path="/editEstimatedClaim/:estimatedClaimId"
                                  component={EditEstimatedClaimFormUser}
                              />
                              <PrivateRoute exact path="/EstimatedClaim" component={EstimatedClaimPage} />

                              <PrivateRoute
                                  path="/editEstimatedClaimPlan/:estimatedClaimPlanId"
                                  component={EditEstimatedClaimPlanFormUser}
                              />
                              <PrivateRoute exact path="/EstimatedClaimPlan" component={EstimatedClaimPlanPage} />

                              <PrivateRoute
                                  path="/editMaxAmtExcluded/:maxAmtExcludedId"
                                  component={EditMaxAmtExcludedFormUser}
                              />
                              <PrivateRoute exact path="/MaxAmtExcluded" component={MaxAmtExcludedPage} />

                              <PrivateRoute
                                  path="/editOccupation/:occupationId"
                                  component={EditOccupationFormUser}
                              />
                              <PrivateRoute exact path="/Occupation" component={OccupationPage} />

                              <PrivateRoute exact path="/IncurredClaims" component={IncurredClaimsPage} />

                              <PrivateRoute exact path="/BasicGroupInfo" component={BasicGroupInfoPage} />

                              <PrivateRoute exact path="/GroupKeys" component={GroupKeysPage} />
              </Switch>
            </Box>
          </Box>
        </Flex>
      </Box>
    </ChakraProvider>
  );
}

export default App;
