import React, { useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Button,
  CloseButton,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Stack,
  StackDivider,
  VStack,
} from '@chakra-ui/react';

import { addNewOccupation } from './occupationSlice';
import { FieldGroup } from '../../components/common/FieldGroup';
import DatePicker from '../../components/common/DatePicker';
import { ErrorDisplay } from '../common/ErrorDisplay';
import { overlapCheck } from '../../services/overlapCheck';
import {
  dateToString,
  nullableDateToString,
} from '../../services/dateConverters';

export const AddOccupationForm = ({ user, occupationList }) => {
  const [occupationDesc, setOccupationDesc] = useState(' ');
  const [factor, setFactor] = useState(0);
  const [age0To24, setAge0To24] = useState(0);
  const [age25To34, setAge25To34] = useState(0);
  const [age35To44, setAge35To44] = useState(0);
  const [age45To54, setAge45To54] = useState(0);
  const [age55To64, setAge55To64] = useState(0);

  const [age65Plus, setAge65Plus] = useState(0);
  const [age0to5, setAge0to5] = useState(0);
  const [f11, setF11] = useState(0);
  const [f12, setF12] = useState(0);
  const [age19Plus, setAge19Plus] = useState(0);
  const [employee, setEmployee] = useState(0);
  const [spouse, setSpouse] = useState(0);
  const [child, setChild] = useState(0);

  const [employeePercent, setEmployeePercent] = useState(0);
  const [spousePercent, setSpousePercent] = useState(0);
  const [childPercent, setChildPercent] = useState(0);

  const [startDate, setEffStartDate] = useState(new Date());
  const [endDate, setEffEndDate] = useState('');
  const [addRequestStatus, setAddRequestStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();

  const onOccupationDescChanged = e => {
    setOccupationDesc(e.target.value);
    setErrorMessage('');
  };

  const onFactorChanged = e => setFactor(Number(e.target.value));
  const onAge0To24Changed = e => setAge0To24(Number(e.target.value));
  const onAge25To34Changed = e => setAge25To34(Number(e.target.value));
  const onAge35To44Changed = e => setAge35To44(Number(e.target.value));
  const onAge45To54Changed = e => setAge45To54(Number(e.target.value));
  const onAge55To64Changed = e => setAge55To64(Number(e.target.value));

  const onAge65PlusChanged = e => setAge65Plus(Number(e.target.value));
  const onAge0to5Changed = e => setAge0to5(Number(e.target.value));
  const onF11Changed = e => setF11(Number(e.target.value));
  const onF12Changed = e => setF12(Number(e.target.value));
  const onAge19PlusChanged = e => setAge19Plus(Number(e.target.value));
  const onEmployeeChanged = e => setEmployee(Number(e.target.value));
  const onSpouseChanged = e => setSpouse(Number(e.target.value));

  const onChildChanged = e => setChild(Number(e.target.value));
  const onEmployeePercentChanged = e =>
    setEmployeePercent(Number(e.target.value));
  const onSpousePercentChanged = e => setSpousePercent(Number(e.target.value));
  const onChildPercentChanged = e => setChildPercent(Number(e.target.value));

  const onEffStartDateChanged = e => {
    setEffStartDate(e);
    setErrorMessage('');
  };
  const onEffEndDateChanged = e => {
    setEffEndDate(e);
    setErrorMessage('');
  };

  const canSave =
    [
      occupationDesc,
      !isNaN(factor),
      !isNaN(age0To24),
      !isNaN(age25To34),
      !isNaN(age35To44),
      !isNaN(age45To54),
      !isNaN(age55To64),
      !isNaN(age65Plus),
      !isNaN(age0to5),
      !isNaN(f11),
      !isNaN(f12),
      !isNaN(age19Plus),
      !isNaN(employee),
      !isNaN(spouse),
      !isNaN(child),
      !isNaN(employeePercent),
      !isNaN(spousePercent),
      !isNaN(childPercent),

      startDate,
    ].every(Boolean) && addRequestStatus === 'idle';

  const onSaveOccupationClicked = async () => {
    if (canSave) {
      try {
        setAddRequestStatus('pending');

        var occupationOverlap = occupationList.filter(overlapCheck, {
          effStartDate: startDate,
          effEndDate: endDate,
          keys: [
            {
              name: 'occupationDesc',
              value: occupationDesc,
            },
          ],
        });
        if (occupationOverlap.length) {
          console.log(occupationOverlap);
          setErrorMessage(
            'The record being entered overlaps an existing effective date range for this key value.'
          );
          return false;
        }

        const resultAction = await dispatch(
          addNewOccupation({
            occupationDesc,
            factor,
            age0To24,
            age25To34,
            age35To44,
            age45To54,
            age55To64,
            age65Plus,
            age0to5,
            f11,
            f12,
            age19Plus,
            employee,
            spouse,
            child,
            employeePercent,
            spousePercent,
            childPercent,
            modifiedByName: user.profile.name,
            modifiedDate: new Date().toISOString(),
            effStartDate: dateToString(startDate),
            effEndDate: nullableDateToString(endDate),
          })
        );

        unwrapResult(resultAction);
        setOccupationDesc(' ');
        setFactor(0);
        setAge0To24(0);
        setAge25To34(0);
        setAge35To44(0);
        setAge45To54(0);
        setAge55To64(0);
        setAge65Plus(0);
        setAge0to5(0);
        setF11(0);
        setF12(0);
        setAge19Plus(0);
        setEmployee(0);
        setSpouse(0);
        setChild(0);
        setEmployeePercent(0);
        setSpousePercent(0);
        setChildPercent(0);

        setEffStartDate(new Date());
        setEffEndDate('');
      } catch (err) {
        console.error('Failed to save the occupation record: ', err);
      } finally {
        setAddRequestStatus('idle');
      }
    }
  };

  return (
    <Box
      px={{
        base: '4',
        md: '10',
      }}
      pb="16"
      maxWidth="3xl"
      mx="auto"
    >
      <form>
        <Stack spacing="4" divider={<StackDivider />}>
          <FieldGroup title="Add Occupation Record">
            <VStack width="full" spacing="6">
              <FormControl id="occupationDesc">
                <FormLabel>OccupationDesc:</FormLabel>
                <Input
                  type="text"
                  value={occupationDesc}
                  onChange={onOccupationDescChanged}
                />
              </FormControl>
              <FormControl id="factor">
                <FormLabel>Factor:</FormLabel>
                <Input
                  type="number"
                  value={factor}
                  onChange={onFactorChanged}
                />
              </FormControl>
              <FormControl id="age0To24">
                <FormLabel>Age0To24:</FormLabel>
                <Input
                  type="number"
                  value={age0To24}
                  onChange={onAge0To24Changed}
                />
              </FormControl>
              <FormControl id="age25To34">
                <FormLabel>Age25To34:</FormLabel>
                <Input
                  type="number"
                  value={age25To34}
                  onChange={onAge25To34Changed}
                />
              </FormControl>

              <FormControl id="age35To44">
                <FormLabel>Age35To44:</FormLabel>
                <Input
                  type="number"
                  value={age35To44}
                  onChange={onAge35To44Changed}
                />
              </FormControl>
              <FormControl id="age45To54">
                <FormLabel>Age45To54:</FormLabel>
                <Input
                  type="text"
                  value={age45To54}
                  onChange={onAge45To54Changed}
                />
              </FormControl>
              <FormControl id="age55To64">
                <FormLabel>Age55To64:</FormLabel>
                <Input
                  type="number"
                  value={age55To64}
                  onChange={onAge55To64Changed}
                />
              </FormControl>

              <FormControl id="age65Plus">
                <FormLabel>Age65Plus:</FormLabel>
                <Input
                  type="number"
                  value={age65Plus}
                  onChange={onAge65PlusChanged}
                />
              </FormControl>
              <FormControl id="age0to5">
                <FormLabel>Age0to5:</FormLabel>
                <Input
                  type="number"
                  value={age0to5}
                  onChange={onAge0to5Changed}
                />
              </FormControl>
              <FormControl id="f11">
                <FormLabel>F11:</FormLabel>
                <Input type="number" value={f11} onChange={onF11Changed} />
              </FormControl>
              <FormControl id="f12">
                <FormLabel>f12:</FormLabel>
                <Input type="number" value={f12} onChange={onF12Changed} />
              </FormControl>

              <FormControl id="age19Plus">
                <FormLabel>Age19Plus:</FormLabel>
                <Input
                  type="number"
                  value={age19Plus}
                  onChange={onAge19PlusChanged}
                />
              </FormControl>
              <FormControl id="employee">
                <FormLabel>Employee:</FormLabel>
                <Input
                  type="text"
                  value={employee}
                  onChange={onEmployeeChanged}
                />
              </FormControl>
              <FormControl id="Spouse">
                <FormLabel>Spouse:</FormLabel>
                <Input
                  type="number"
                  value={spouse}
                  onChange={onSpouseChanged}
                />
              </FormControl>

              <FormControl id="child">
                <FormLabel>Child:</FormLabel>
                <Input type="number" value={child} onChange={onChildChanged} />
              </FormControl>
              <FormControl id="employeePercent">
                <FormLabel>EmployeePercent:</FormLabel>
                <Input
                  type="number"
                  value={employeePercent}
                  onChange={onEmployeePercentChanged}
                />
              </FormControl>
              <FormControl id="spousePercent">
                <FormLabel>SpousePercent:</FormLabel>
                <Input
                  type="number"
                  value={spousePercent}
                  onChange={onSpousePercentChanged}
                />
              </FormControl>
              <FormControl id="childPercent">
                <FormLabel>ChildPercent:</FormLabel>
                <Input
                  type="number"
                  value={childPercent}
                  onChange={onChildPercentChanged}
                />
              </FormControl>

              <FormControl>
                <FormLabel htmlFor="effStartDate">
                  Effective Start Date
                </FormLabel>
                <DatePicker
                  id="effStartDate"
                  selectedDate={startDate}
                  onChange={onEffStartDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective Start Date</FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="effEndDate">Effective End Date</FormLabel>
                <DatePicker
                  id="effEndDate"
                  selectedDate={endDate}
                  onChange={onEffEndDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective End Date</FormHelperText>
              </FormControl>
            </VStack>
          </FieldGroup>

          <Button
            onClick={onSaveOccupationClicked}
            disabled={!canSave}
            variant="brand-primary"
            size="xs"
          >
            Save Occupation Record
          </Button>
          <ErrorDisplay
            title="The record dates overlap other records!"
            errorMessage={errorMessage}
          />
        </Stack>
      </form>
    </Box>
  );
};

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
  };
}

export const AddOccupationFormUser =
  connect(mapStateToProps)(AddOccupationForm);
