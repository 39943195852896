import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import axios from 'axios';

const nonParPaymentAdapter = createEntityAdapter();
const apiBaseUrl = window.ENV.api_base_url;

const initialState = nonParPaymentAdapter.getInitialState({
  status: 'idle',
  error: null,
});

export const fetchNonParPayment = createAsyncThunk(
  'nonParPayment/fetchNonParPayment',
  async (_, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };

    const response = await axios.get(`${apiBaseUrl}/api/NonParPayment`, config);
    return response.data;
  }
);

export const addNewNonParPayment = createAsyncThunk(
  'nonParPayment/addNewNonParPayment',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.post(
      `${apiBaseUrl}/api/NonParPayment`,
      initialState,
      config
    );
    console.log('adding new nonParPayment record');
    console.log(response);
    return { ...initialState, id: response.data };
  }
);

export const deleteNonParPayment = createAsyncThunk(
  'nonParPayment/deleteNonParPayment',
  async (nonParPaymentId, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.delete(
      `${apiBaseUrl}/api/NonParPayment/${nonParPaymentId}`,
      config
    );
    return nonParPaymentId;
  }
);

export const updateNonParPayment = createAsyncThunk(
  'nonParPayment/updateNonParPayment',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.put(
      `${apiBaseUrl}/api/NonParPayment/${initialState.id}`,
      initialState,
      config
    );
    return { ...initialState, id: response.data };
  }
);

const nonParPaymentSlice = createSlice({
  name: 'nonParPayment',
  initialState,
  extraReducers: {
    [fetchNonParPayment.pending]: state => {
      state.status = 'loading';
    },
    [fetchNonParPayment.fulfilled]: (state, action) => {
      // Add any fetched posts to the array
      nonParPaymentAdapter.upsertMany(state, action.payload);
      state.status = 'succeeded';
    },
    [fetchNonParPayment.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [addNewNonParPayment.fulfilled]: nonParPaymentAdapter.addOne,
    [deleteNonParPayment.fulfilled]: nonParPaymentAdapter.removeOne,
    [updateNonParPayment.fulfilled]: nonParPaymentAdapter.upsertOne,
  },
});

export default nonParPaymentSlice.reducer;

export const {
  selectAll: selectAllNonParPayment,
  selectById: selectNonParPaymentById,
  selectIds: selectNonParPaymentIds,
} = nonParPaymentAdapter.getSelectors(state => state.nonParPayment);
