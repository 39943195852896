import React, { useEffect } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalHeader,
  ModalFooter,
  ModalContent,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchEhbRate,
  selectEhbRateIds,
  selectEhbRateById,
  deleteEhbRate,
} from './ehbRateSlice';
import { unwrapResult } from '@reduxjs/toolkit';

import { NavLink } from 'react-router-dom';
import { FormattedDate } from '../common/FormattedDate';
import { FormattedTimestamp } from '../common/FormattedTimestamp';

const ItemDetails = ({ currentItem }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button onClick={onOpen} size="xs">
        Details
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Ehb Rate Factor: {currentItem.ehbType}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Table size="sm">
              <Tbody>
                <Tr>
                  <Th>Ehb Type</Th>
                  <Th>DbKey</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.ehbType}</Td>
                  <Td>{currentItem.dbKey}</Td>
                </Tr>
                <Tr>
                  <Th>Rate</Th>
                  <Th>Admin</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.rate}</Td>
                  <Td>{currentItem.admin}</Td>
                </Tr>

                <Tr>
                  <Th>Modified By Name</Th>
                  <Th>Modified Date</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.modifiedByName}</Td>
                  <Td>
                    <FormattedTimestamp
                      dateToFormat={currentItem.modifiedDate}
                    />
                  </Td>
                </Tr>
                <Tr>
                  <Th>Eff Start Date</Th>
                  <Th>Eff End Date</Th>
                </Tr>
                <Tr>
                  <Td>
                    <FormattedDate dateToFormat={currentItem.effStartDate} />
                  </Td>
                  <Td>
                    <FormattedDate dateToFormat={currentItem.effEndDate} />
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="brand-primary-sm"
              mr={3}
              onClick={onClose}
              size="sm"
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

let EhbRateTable = ({ ehbRateIdList }) => {
  let tableRows = ehbRateIdList.map(ehbRateId => (
    <EhbRate ehbRateId={ehbRateId} key={ehbRateId} />
  ));

  return (
    <Box overflowX="auto">
      <Table size="sm">
        <Thead>
          <Tr>
            <Th></Th>
            <Th>Ehb Type</Th>
            <Th>DbKey</Th>
            <Th>Rate</Th>
            <Th>Admin</Th>
            <Th>Modified By Name</Th>
            <Th>Modified Date</Th>
            <Th>Eff Start Date</Th>
            <Th>Eff End Date</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>{tableRows}</Tbody>
      </Table>
    </Box>
  );
};

let EhbRate = ({ ehbRateId }) => {
  const ehbRate = useSelector(state => selectEhbRateById(state, ehbRateId));

  const dispatch = useDispatch();

  const onDeleteEhbRateClicked = async () => {
    try {
      const resultAction = await dispatch(deleteEhbRate(ehbRate.id));
      unwrapResult(resultAction);
    } catch (err) {
      console.error('Failed to save the ehbRate record', err);
    }
  };

  return (
    <Tr>
      <Td>
        <ItemDetails currentItem={ehbRate} />
      </Td>
      <Td>{ehbRate.ehbType}</Td>
      <Td>{ehbRate.dbKey}</Td>
      <Td>{ehbRate.rate}</Td>
      <Td>{ehbRate.admin}</Td>
      <Td>{ehbRate.modifiedByName}</Td>
      <Td>
        <FormattedTimestamp dateToFormat={ehbRate.modifiedDate} />
      </Td>
      <Td>
        <FormattedDate dateToFormat={ehbRate.effStartDate} />
      </Td>
      <Td>
        <FormattedDate dateToFormat={ehbRate.effEndDate} />
      </Td>
      <Td>
        <ButtonGroup variant="outline">
          <Button
            colorScheme="gray"
            size="sm"
            as={NavLink}
            to={`/editEhbRate/${ehbRate.id}`}
          >
            Edit
          </Button>
          <Button colorScheme="red" size="sm" onClick={onDeleteEhbRateClicked}>
            Delete
          </Button>
        </ButtonGroup>
      </Td>
    </Tr>
  );
};

export const EhbRateList = () => {
  const dispatch = useDispatch();
  const orderedEhbRateIds = useSelector(selectEhbRateIds);

  const error = useSelector(state => state.ehbRate.error);

  const ehbRateStatus = useSelector(state => state.ehbRate.status);
  console.log(ehbRateStatus);
  useEffect(() => {
    if (ehbRateStatus === 'idle') {
      dispatch(fetchEhbRate());
    }
  }, [ehbRateStatus, dispatch]);

  let content;

  if (ehbRateStatus === 'loading') {
    content = <div className="loader">Loading...</div>;
  } else if (ehbRateStatus === 'succeeded') {
    content = <EhbRateTable ehbRateIdList={orderedEhbRateIds} />;
  } else if (ehbRateStatus === 'failed') {
    content = <div>{error}</div>;
  }

  return (
    <section>
      <h2>
        <b>Ehb Rate Listing</b>
      </h2>
      {content}
    </section>
  );
};
