import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import axios from 'axios';

const ageChildAdapter = createEntityAdapter();
const apiBaseUrl = window.ENV.api_base_url;

const initialState = ageChildAdapter.getInitialState({
  status: 'idle',
  error: null,
});

export const fetchAgeChild = createAsyncThunk(
  'ageChild/fetchAgeChild',
  async (_, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };

    const response = await axios.get(`${apiBaseUrl}/api/AgeChild`, config);
    return response.data;
  }
);

export const addNewAgeChild = createAsyncThunk(
  'ageChild/addNewAgeChild',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.post(
      `${apiBaseUrl}/api/AgeChild`,
      initialState,
      config
    );
    console.log('adding new ageChild record');
    console.log(response);
    return { ...initialState, id: response.data };
  }
);

export const deleteAgeChild = createAsyncThunk(
  'ageChild/deleteAgeChild',
  async (ageChildId, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.delete(
      `${apiBaseUrl}/api/AgeChild/${ageChildId}`,
      config
    );
    return ageChildId;
  }
);

export const updateAgeChild = createAsyncThunk(
  'ageChild/updateAgeChild',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.put(
      `${apiBaseUrl}/api/AgeChild/${initialState.id}`,
      initialState,
      config
    );
    return { ...initialState, id: response.data };
  }
);

const ageChildSlice = createSlice({
  name: 'ageChild',
  initialState,
  extraReducers: {
    [fetchAgeChild.pending]: state => {
      state.status = 'loading';
    },
    [fetchAgeChild.fulfilled]: (state, action) => {
      // Add any fetched posts to the array
      ageChildAdapter.upsertMany(state, action.payload);
      state.status = 'succeeded';
    },
    [fetchAgeChild.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [addNewAgeChild.fulfilled]: ageChildAdapter.addOne,
    [deleteAgeChild.fulfilled]: ageChildAdapter.removeOne,
    [updateAgeChild.fulfilled]: ageChildAdapter.upsertOne,
  },
});

export default ageChildSlice.reducer;

export const {
  selectAll: selectAllAgeChild,
  selectById: selectAgeChildById,
  selectIds: selectAgeChildIds,
} = ageChildAdapter.getSelectors(state => state.ageChild);
