import React from 'react';
import {
  Box,
  Button,
  Text,
  Grid,
  Heading,
  Stack,
  SimpleGrid,
} from '@chakra-ui/react';
import BrandLinkButton from '../../components/common/BrandLinkButton';

function FinancialFactorsPage() {
  return (
    <Box textAlign="left" fontSize="xl" p={8}>
      <Grid p={3}>
        <Stack>
          <Heading>Financial Factors</Heading>
          <Text>
            Here the financial factor settings can be applied and modified.
          </Text>
          <SimpleGrid columns={4} spacing={10}>
            <Button colorScheme="blue" size="md">
              BaseRate Amounts
            </Button>
            <Button colorScheme="blue" size="md">
              Book Rates
            </Button>
            <Button colorScheme="blue" size="md">
              Copays
            </Button>
            <Button
              colorScheme="blue"
              size="md"
              onClick={e => {
                e.preventDefault();
                window.location.href = '/DeductAmt';
              }}
            >
              Deductible Amount
            </Button>
            <Button colorScheme="blue" size="md">
              Deductible Credits
            </Button>
            <Button colorScheme="blue" size="md">
              EBH Rate
            </Button>
            <Button colorScheme="blue" size="md">
              Estimated Claim
            </Button>
            <Button colorScheme="blue" size="md">
              ID Card Changes
            </Button>

            <Button colorScheme="blue" size="md">
              Maximum Amounts
            </Button>
            <Button colorScheme="blue" size="md">
              Maximum (P&D Excluded)
            </Button>
            <Button colorScheme="blue" size="md">
              Non Par Payment
            </Button>
            <Button colorScheme="blue" size="md">
              Orthodontic Baserates aa
            </Button>
            <Button colorScheme="blue" size="md">
              Premium Commission Rate
            </Button>
            <Button colorScheme="blue" size="md">
              Rollover
            </Button>
          </SimpleGrid>
        </Stack>
      </Grid>
      <Box mt={12}>
        <BrandLinkButton path="/settings">Back to Settings</BrandLinkButton>
      </Box>
    </Box>
  );
}

export default FinancialFactorsPage;
