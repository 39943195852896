import React, { useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Button,
  CloseButton,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Stack,
  StackDivider,
  VStack,
} from '@chakra-ui/react';

import { addNewEstimatedClaim } from './estimatedClaimSlice';
import { FieldGroup } from '../../components/common/FieldGroup';
import DatePicker from '../../components/common/DatePicker';

import { ErrorDisplay } from '../common/ErrorDisplay';
import { overlapCheck } from '../../services/overlapCheck';
import {
  dateToString,
  nullableDateToString,
} from '../../services/dateConverters';

export const AddEstimatedClaimForm = ({ user, estimatedClaimList }) => {
  const [standardYearId, setStandardYearId] = useState(0);
  const [employeeEstClaims, setEmployeeEstClaims] = useState(0);
  const [spouseEstClaims, setSpouseEstClaims] = useState(0);
  const [childEstClaims, setChildEstClaims] = useState(0);
  const [orthoClaims, setOrthoClaims] = useState(0);
  const [majorWeight, setMajorWeight] = useState(0);
  const [otherWeight, setOtherWeight] = useState(0);
  const [prevWeight, setPrevWeight] = useState(0);
  const [prostoWeight, setProstoWeight] = useState(0);
  const [radioWeight, setRadioWeight] = useState(0);

  const [startDate, setEffStartDate] = useState(new Date());
  const [endDate, setEffEndDate] = useState('');
  const [addRequestStatus, setAddRequestStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();

  const onStandardYearIdChanged = e => {
    setStandardYearId(e.target.value);
    setErrorMessage('');
  };
  const onEmployeeEstClaimsChanged = e =>
    setEmployeeEstClaims(Number(e.target.value));
  const onSpouseEstClaimsChanged = e =>
    setSpouseEstClaims(Number(e.target.value));
  const onChildEstClaimsChanged = e =>
    setChildEstClaims(Number(e.target.value));
  const onOrthoClaimsChanged = e => setOrthoClaims(Number(e.target.value));

  const onMajorWeightChanged = e => setMajorWeight(Number(e.target.value));
  const onOtherWeightChanged = e => setOtherWeight(Number(e.target.value));
  const onPrevWeightChanged = e => setPrevWeight(Number(e.target.value));
  const onProstoWeightChanged = e => setProstoWeight(Number(e.target.value));
  const onRadioWeightChanged = e => setRadioWeight(Number(e.target.value));

  const onEffStartDateChanged = e => {
    setEffStartDate(e);
    setErrorMessage('');
  };
  const onEffEndDateChanged = e => {
    setEffEndDate(e);
    setErrorMessage('');
  };

  const canSave =
    [
      !isNaN(standardYearId),
      !isNaN(employeeEstClaims),
      !isNaN(spouseEstClaims),
      !isNaN(childEstClaims),
      !isNaN(orthoClaims),
      !isNaN(majorWeight),
      !isNaN(otherWeight),
      !isNaN(prevWeight),
      !isNaN(prostoWeight),
      !isNaN(radioWeight),

      startDate,
    ].every(Boolean) && addRequestStatus === 'idle';

  const onSaveEstimatedClaimClicked = async () => {
    if (canSave) {
      try {
        setAddRequestStatus('pending');

        var estimatedClaimOverlap = estimatedClaimList.filter(overlapCheck, {
          effStartDate: startDate,
          effEndDate: endDate,
          keys: [
            {
              name: 'standardYearId',
              value: standardYearId,
            },
          ],
        });
        if (estimatedClaimOverlap.length) {
          console.log(estimatedClaimOverlap);
          setErrorMessage(
            'The record being entered overlaps an existing effective date range for this key value.'
          );
          return false;
        }

        const resultAction = await dispatch(
          addNewEstimatedClaim({
            standardYearId,
            employeeEstClaims,
            spouseEstClaims,
            childEstClaims,
            orthoClaims,
            majorWeight,
            otherWeight,
            prevWeight,
            prostoWeight,
            radioWeight,
            modifiedByName: user.profile.name,
            modifiedDate: new Date().toISOString(),
            effStartDate: dateToString(startDate),
            effEndDate: nullableDateToString(endDate),
          })
        );

        unwrapResult(resultAction);

        setStandardYearId(0);
        setEmployeeEstClaims(0);
        setSpouseEstClaims(0);
        setChildEstClaims(0);
        setOrthoClaims(0);
        setMajorWeight(0);
        setOtherWeight(0);
        setPrevWeight(0);
        setProstoWeight(0);
        setRadioWeight(0);

        setEffStartDate(new Date());
        setEffEndDate('');
      } catch (err) {
        console.error('Failed to save the estimatedClaim factor record: ', err);
      } finally {
        setAddRequestStatus('idle');
      }
    }
  };

  return (
    <Box
      px={{
        base: '4',
        md: '10',
      }}
      pb="16"
      maxWidth="3xl"
      mx="auto"
    >
      <form>
        <Stack spacing="4" divider={<StackDivider />}>
          <FieldGroup title="Add EstimatedClaim Record">
            <VStack width="full" spacing="6">
              <FormControl id="standardYearId">
                <FormLabel>StandardYearId:</FormLabel>
                <Input
                  type="number"
                  value={standardYearId}
                  onChange={onStandardYearIdChanged}
                />
              </FormControl>
              <FormControl id="employeeEstClaims">
                <FormLabel>EmployeeEstClaims:</FormLabel>
                <Input
                  type="number"
                  value={employeeEstClaims}
                  onChange={onEmployeeEstClaimsChanged}
                />
              </FormControl>
              <FormControl id="spouseEstClaims">
                <FormLabel>SpouseEstClaims:</FormLabel>
                <Input
                  type="number"
                  value={spouseEstClaims}
                  onChange={onSpouseEstClaimsChanged}
                />
              </FormControl>
              <FormControl id="childEstClaims">
                <FormLabel>ChildEstClaims:</FormLabel>
                <Input
                  type="number"
                  value={childEstClaims}
                  onChange={onChildEstClaimsChanged}
                />
              </FormControl>

              <FormControl id="orthoClaims">
                <FormLabel>OrthoClaims:</FormLabel>
                <Input
                  type="number"
                  value={orthoClaims}
                  onChange={onOrthoClaimsChanged}
                />
              </FormControl>

              <FormControl id="majorWeight">
                <FormLabel>MajorWeight:</FormLabel>
                <Input
                  type="number"
                  value={majorWeight}
                  onChange={onMajorWeightChanged}
                />
              </FormControl>
              <FormControl id="otherWeight">
                <FormLabel>OtherWeight:</FormLabel>
                <Input
                  type="number"
                  value={otherWeight}
                  onChange={onOtherWeightChanged}
                />
              </FormControl>
              <FormControl id="prevWeight">
                <FormLabel>PrevWeight:</FormLabel>
                <Input
                  type="number"
                  value={prevWeight}
                  onChange={onPrevWeightChanged}
                />
              </FormControl>
              <FormControl id="prostoWeight">
                <FormLabel>ProstoWeight:</FormLabel>
                <Input
                  type="number"
                  value={prostoWeight}
                  onChange={onProstoWeightChanged}
                />
              </FormControl>

              <FormControl id="radioWeight">
                <FormLabel>RadioWeight:</FormLabel>
                <Input
                  type="number"
                  value={radioWeight}
                  onChange={onRadioWeightChanged}
                />
              </FormControl>

              <FormControl>
                <FormLabel htmlFor="effStartDate">
                  Effective Start Date
                </FormLabel>
                <DatePicker
                  id="effStartDate"
                  selectedDate={startDate}
                  onChange={onEffStartDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective Start Date</FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="effEndDate">Effective End Date</FormLabel>
                <DatePicker
                  id="effEndDate"
                  selectedDate={endDate}
                  onChange={onEffEndDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective End Date</FormHelperText>
              </FormControl>
            </VStack>
          </FieldGroup>

          <Button
            onClick={onSaveEstimatedClaimClicked}
            disabled={!canSave}
            variant="brand-primary"
            size="xs"
          >
            Save EstimatedClaim Factor Record
          </Button>
          <ErrorDisplay
            title="The record dates overlap other records!"
            errorMessage={errorMessage}
          />
        </Stack>
      </form>
    </Box>
  );
};

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
  };
}

export const AddEstimatedClaimFormUser = connect(mapStateToProps)(
  AddEstimatedClaimForm
);
