import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import axios from 'axios';

const statesAdapter = createEntityAdapter();
const apiBaseUrl = window.ENV.api_base_url;

const initialState = statesAdapter.getInitialState({
  status: 'idle',
  error: null,
});

export const fetchStates = createAsyncThunk(
  'states/fetchStates',
  async (_, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };

    const response = await axios.get(`${apiBaseUrl}/api/States`, config);
    return response.data;
  }
);

export const addNewStates = createAsyncThunk(
  'states/addNewStates',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.post(
      `${apiBaseUrl}/api/States`,
      initialState,
      config
    );
    console.log('adding new states record');
    console.log(response);
    return { ...initialState, id: response.data };
  }
);

export const deleteStates = createAsyncThunk(
  'states/deleteStates',
  async (statesId, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.delete(
      `${apiBaseUrl}/api/States/${statesId}`,
      config
    );
    return statesId;
  }
);

export const updateStates = createAsyncThunk(
  'states/updateStates',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.put(
      `${apiBaseUrl}/api/States/${initialState.id}`,
      initialState,
      config
    );
    return { ...initialState, id: response.data };
  }
);

const statesSlice = createSlice({
  name: 'states',
  initialState,
  extraReducers: {
    [fetchStates.pending]: state => {
      state.status = 'loading';
    },
    [fetchStates.fulfilled]: (state, action) => {
      // Add any fetched posts to the array
      statesAdapter.upsertMany(state, action.payload);
      state.status = 'succeeded';
    },
    [fetchStates.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [addNewStates.fulfilled]: statesAdapter.addOne,
    [deleteStates.fulfilled]: statesAdapter.removeOne,
    [updateStates.fulfilled]: statesAdapter.upsertOne,
  },
});

export default statesSlice.reducer;

export const {
  selectAll: selectAllStates,
  selectById: selectStatesById,
  selectIds: selectStatesIds,
} = statesAdapter.getSelectors(state => state.states);

