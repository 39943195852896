import React, { useEffect } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  ButtonGroup,
  Box,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalHeader,
  ModalFooter,
  ModalContent,
  ModalCloseButton,
  Heading,
  useDisclosure,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchStates,
  selectStatesIds,
  selectStatesById,
  deleteStates,
} from './statesSlice';
import { FormattedDate } from '../common/FormattedDate';
import { FormattedTimestamp } from '../common/FormattedTimestamp';
import { unwrapResult } from '@reduxjs/toolkit';

import { NavLink } from 'react-router-dom';

const ItemDetails = ({ currentItem }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button onClick={onOpen} size="xs">
        Details
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>State: {currentItem.stateAbbrev}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Table size="sm">
              <Tbody>
                <Tr>
                  <Th>State Abbrev</Th>
                  <Th>Area Id</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.stateAbbrev}</Td>
                  <Td>{currentItem.areaId}</Td>
                </Tr>
                <Tr>
                  <Th>PRM</Th>
                  <Th>PPO</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.prm}</Td>
                  <Td>{currentItem.ppo}</Td>
                </Tr>
                <Tr>
                  <Th>Oon Std</Th>
                  <Th>Oon Nat</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.oonStd}</Td>
                  <Td>{currentItem.oonNat}</Td>
                </Tr>
                <Tr>
                  <Th>Pct Par Dentist</Th>
                  <Th>PPO Discount</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.pctParDentist}</Td>
                  <Td>{currentItem.ppoDiscount}</Td>
                </Tr>
                <Tr>
                  <Th>Eparegion</Th>
                  <Th>Allow PPO Entry</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.eparegion}</Td>
                  <Td>{currentItem.allowPpoentry}</Td>
                </Tr>
                <Tr>
                  <Th>Modified By Name</Th>
                  <Th>Modified Date</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.modifiedByName}</Td>
                  <Td>
                    <FormattedTimestamp
                      dateToFormat={currentItem.modifiedDate}
                    />
                  </Td>
                </Tr>
                <Tr>
                  <Th>Eff Start Date</Th>
                  <Th>Eff End Date</Th>
                </Tr>
                <Tr>
                  <Td>
                    <FormattedDate dateToFormat={currentItem.effStartDate} />
                  </Td>
                  <Td>
                    <FormattedDate dateToFormat={currentItem.effEndDate} />
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="brand-primary-sm"
              mr={3}
              onClick={onClose}
              size="sm"
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

let StatesTable = ({ statesIdList }) => {
  let tableRows = statesIdList.map(statesId => (
    <States statesId={statesId} key={statesId} />
  ));

  return (
    <Box overflowX="auto">
      <Table size="sm">
        <Thead>
          <Tr>
            <Th></Th>
            <Th>State Abbrev</Th>
            <Th>Area Id</Th>
            <Th>Modified By Name</Th>
            <Th>Modified Date</Th>
            <Th>Eff Start Date</Th>
            <Th>Eff End Date</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>{tableRows}</Tbody>
      </Table>
    </Box>
  );
};

let States = ({ statesId }) => {
  const states = useSelector(state => selectStatesById(state, statesId));

  const dispatch = useDispatch();

  const onDeleteStatesClicked = async () => {
    try {
      const resultAction = await dispatch(deleteStates(states.id));
      unwrapResult(resultAction);
    } catch (err) {
      console.error('Failed to save the states record', err);
    }
  };

  return (
    <Tr>
      <Td>
        <ItemDetails currentItem={states} />
      </Td>
      <Td>{states.stateAbbrev}</Td>
      <Td>{states.areaId}</Td>
      <Td>{states.modifiedByName}</Td>
      <Td>
        <FormattedTimestamp dateToFormat={states.modifiedDate} />
      </Td>
      <Td>
        <FormattedDate dateToFormat={states.effStartDate} />
      </Td>
      <Td>
        <FormattedDate dateToFormat={states.effEndDate} />
      </Td>
      <Td>
        <ButtonGroup variant="outline">
          <Button
            colorScheme="gray"
            size="xs"
            as={NavLink}
            to={`/editStat/${states.id}`}
          >
            Edit
          </Button>
          <Button colorScheme="red" size="xs" onClick={onDeleteStatesClicked}>
            Delete
          </Button>
        </ButtonGroup>
      </Td>
    </Tr>
  );
};

export const StatList = ({ orderedStatesIds }) => {
  return (
    <section>
      <Heading size="md" mb={4}>
        State Factors List
      </Heading>
      <StatesTable statesIdList={orderedStatesIds} />;
    </section>
  );
};
