import React, { useEffect } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalHeader,
  ModalFooter,
  ModalContent,
  ModalCloseButton,
  Heading,
  useDisclosure,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchOrthodonticBaseRate,
  selectOrthodonticBaseRateIds,
  selectOrthodonticBaseRateById,
  deleteOrthodonticBaseRate,
} from './orthodonticBaseRateSlice';
import { unwrapResult } from '@reduxjs/toolkit';

import { NavLink } from 'react-router-dom';
import { FormattedDate } from '../common/FormattedDate';
import { FormattedTimestamp } from '../common/FormattedTimestamp';

const ItemDetails = ({ currentItem }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button onClick={onOpen} size="xs">
        Details
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Orthodontic Base Rate: Copay/Max - {currentItem.copay}/
            {currentItem.maximum}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Table size="sm">
              <Tbody>
                <Tr>
                  <Th>Copay</Th>
                  <Th>Maximum</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.copay}</Td>
                  <Td>{currentItem.maximum}</Td>
                </Tr>
                <Tr>
                  <Th>OneYear</Th>
                  <Th>TwoYear</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.oneYear}</Td>
                  <Td>{currentItem.twoYear}</Td>
                </Tr>

                <Tr>
                  <Th>Modified By Name</Th>
                  <Th>Modified Date</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.modifiedByName}</Td>
                  <Td>
                    <FormattedTimestamp
                      dateToFormat={currentItem.modifiedDate}
                    />
                  </Td>
                </Tr>
                <Tr>
                  <Th>Eff Start Date</Th>
                  <Th>Eff End Date</Th>
                </Tr>
                <Tr>
                  <Td>
                    <FormattedDate dateToFormat={currentItem.effStartDate} />
                  </Td>
                  <Td>
                    <FormattedDate dateToFormat={currentItem.effEndDate} />
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="brand-primary-sm"
              mr={3}
              onClick={onClose}
              size="sm"
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

let OrthodonticBaseRateTable = ({ orthodonticBaseRateIdList }) => {
  let tableRows = orthodonticBaseRateIdList.map(orthodonticBaseRateId => (
    <OrthodonticBaseRate
      orthodonticBaseRateId={orthodonticBaseRateId}
      key={orthodonticBaseRateId}
    />
  ));

  return (
    <Box overflowX="auto">
      <Table size="sm">
        <Thead>
          <Tr>
            <Th></Th>
            <Th>Copay</Th>
            <Th>Maximum</Th>
            <Th>OneYear</Th>
            <Th>TwoYear</Th>

            <Th>Modified By Name</Th>
            <Th>Modified Date</Th>
            <Th>Eff Start Date</Th>
            <Th>Eff End Date</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>{tableRows}</Tbody>
      </Table>
    </Box>
  );
};

let OrthodonticBaseRate = ({ orthodonticBaseRateId }) => {
  const orthodonticBaseRate = useSelector(state =>
    selectOrthodonticBaseRateById(state, orthodonticBaseRateId)
  );
  //console.log('In the orthodonticBaseRate list');

  const dispatch = useDispatch();

  const onDeleteOrthodonticBaseRateClicked = async () => {
    try {
      const resultAction = await dispatch(
        deleteOrthodonticBaseRate(orthodonticBaseRate.id)
      );
      unwrapResult(resultAction);
    } catch (err) {
      console.error('Failed to save the orthodonticBaseRate record', err);
    }
  };

  return (
    <Tr>
      <Td>
        <ItemDetails currentItem={orthodonticBaseRate} />
      </Td>
      <Td>{orthodonticBaseRate.copay}</Td>
      <Td>{orthodonticBaseRate.maximum}</Td>
      <Td>{orthodonticBaseRate.oneYear}</Td>
      <Td>{orthodonticBaseRate.twoYear}</Td>

      <Td>{orthodonticBaseRate.modifiedByName}</Td>
      <Td>
        <FormattedTimestamp dateToFormat={orthodonticBaseRate.modifiedDate} />
      </Td>
      <Td>
        <FormattedDate dateToFormat={orthodonticBaseRate.effStartDate} />
      </Td>
      <Td>
        <FormattedDate dateToFormat={orthodonticBaseRate.effEndDate} />
      </Td>
      <Td>
        <ButtonGroup variant="outline">
          <Button
            colorScheme="gray"
            size="sm"
            as={NavLink}
            to={`/editOrthodonticBaseRate/${orthodonticBaseRate.id}`}
          >
            Edit
          </Button>
          <Button
            colorScheme="red"
            size="sm"
            onClick={onDeleteOrthodonticBaseRateClicked}
          >
            Delete
          </Button>
        </ButtonGroup>
      </Td>
    </Tr>
  );
};

export const OrthodonticBaseRateList = () => {
  const dispatch = useDispatch();
  const orderedOrthodonticBaseRateIds = useSelector(
    selectOrthodonticBaseRateIds
  );

  const error = useSelector(state => state.orthodonticBaseRate.error);

  const orthodonticBaseRateStatus = useSelector(
    state => state.orthodonticBaseRate.status
  );
  console.log(orthodonticBaseRateStatus);
  useEffect(() => {
    if (orthodonticBaseRateStatus === 'idle') {
      dispatch(fetchOrthodonticBaseRate());
    }
  }, [orthodonticBaseRateStatus, dispatch]);

  let content;

  if (orthodonticBaseRateStatus === 'loading') {
    content = <div className="loader">Loading...</div>;
  } else if (orthodonticBaseRateStatus === 'succeeded') {
    content = (
      <OrthodonticBaseRateTable
        orthodonticBaseRateIdList={orderedOrthodonticBaseRateIds}
      />
    );
  } else if (orthodonticBaseRateStatus === 'failed') {
    content = <div>{error}</div>;
  }

  return (
    <section>
      <h2>
        <b>Orthodontic Base Rate Listing</b>
      </h2>
      {content}
    </section>
  );
};
