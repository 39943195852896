import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import axios from 'axios';

const deductAmtAdapter = createEntityAdapter();
const apiBaseUrl = window.ENV.api_base_url;

const initialState = deductAmtAdapter.getInitialState({
  status: 'idle',
  error: null,
});

export const fetchDeductibleAmounts = createAsyncThunk(
  'deductibleAmounts/fetchDeductibleAmounts',
  async (_, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };

    const response = await axios.get(
      `${apiBaseUrl}/api/DeductibleAmount`,
      config
    );
    return response.data;
  }
);

export const addNewDeductibleAmount = createAsyncThunk(
  'deductibleAmounts/addNewDeductibleAmount',
  async (initialDeductibleAmount, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.post(
      `${apiBaseUrl}/api/DeductibleAmount`,
      initialDeductibleAmount,
      config
    );
    console.log('adding new deductible record');
    console.log(response);
    return { ...initialDeductibleAmount, id: response.data };
  }
);

export const deleteDeductibleAmount = createAsyncThunk(
  'deductibleAmount/deleteDeductibleAmount',
  async (deductibleAmountId, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.delete(
      `${apiBaseUrl}/api/DeductibleAmount/${deductibleAmountId}`,
      config
    );
    return deductibleAmountId;
  }
);

export const updateDeductibleAmount = createAsyncThunk(
  'deductibleAmount/updateDeductibleAmount',
  async (initialDeductibleAmount, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.put(
      `${apiBaseUrl}/api/DeductibleAmount/${initialDeductibleAmount.id}`,
      initialDeductibleAmount,
      config
    );
    return { ...initialDeductibleAmount, id: response.data };
  }
);

const deductibleAmountSlice = createSlice({
  name: 'deductibleAmounts',
  initialState,
  extraReducers: {
    [fetchDeductibleAmounts.pending]: state => {
      state.status = 'loading';
    },
    [fetchDeductibleAmounts.fulfilled]: (state, action) => {
      // Add any fetched posts to the array
      deductAmtAdapter.upsertMany(state, action.payload);
      state.status = 'succeeded';
    },
    [fetchDeductibleAmounts.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [addNewDeductibleAmount.fulfilled]: deductAmtAdapter.addOne,
    [deleteDeductibleAmount.fulfilled]: deductAmtAdapter.removeOne,
    [updateDeductibleAmount.fulfilled]: deductAmtAdapter.upsertOne,
  },
});

export default deductibleAmountSlice.reducer;

export const {
  selectAll: selectAllDeductibleAmounts,
  selectById: selectDeductibleAmountById,
  selectIds: selectDeductibleAmountIds,
} = deductAmtAdapter.getSelectors(state => state.deductibleAmounts);

