import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import axios from 'axios';

const waitingPeriodAdapter = createEntityAdapter();
const apiBaseUrl = window.ENV.api_base_url;

const initialState = waitingPeriodAdapter.getInitialState({
  status: 'idle',
  error: null,
});

export const fetchWaitingPeriod = createAsyncThunk(
  'waitingPeriod/fetchWaitingPeriod',
  async (_, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };

    const response = await axios.get(`${apiBaseUrl}/api/WaitingPeriod`, config);
    return response.data;
  }
);

export const addNewWaitingPeriod = createAsyncThunk(
  'waitingPeriod/addNewWaitingPeriod',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.post(
      `${apiBaseUrl}/api/WaitingPeriod`,
      initialState,
      config
    );
    console.log('adding new waitingPeriod record');
    console.log(response);
    return { ...initialState, id: response.data };
  }
);

export const deleteWaitingPeriod = createAsyncThunk(
  'waitingPeriod/deleteWaitingPeriod',
  async (waitingPeriodId, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.delete(
      `${apiBaseUrl}/api/WaitingPeriod/${waitingPeriodId}`,
      config
    );
    return waitingPeriodId;
  }
);

export const updateWaitingPeriod = createAsyncThunk(
  'waitingPeriod/updateWaitingPeriod',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.put(
      `${apiBaseUrl}/api/WaitingPeriod/${initialState.id}`,
      initialState,
      config
    );
    return { ...initialState, id: response.data };
  }
);

const waitingPeriodSlice = createSlice({
  name: 'waitingPeriod',
  initialState,
  extraReducers: {
    [fetchWaitingPeriod.pending]: state => {
      state.status = 'loading';
    },
    [fetchWaitingPeriod.fulfilled]: (state, action) => {
      // Add any fetched posts to the array
      waitingPeriodAdapter.upsertMany(state, action.payload);
      state.status = 'succeeded';
    },
    [fetchWaitingPeriod.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [addNewWaitingPeriod.fulfilled]: waitingPeriodAdapter.addOne,
    [deleteWaitingPeriod.fulfilled]: waitingPeriodAdapter.removeOne,
    [updateWaitingPeriod.fulfilled]: waitingPeriodAdapter.upsertOne,
  },
});

export default waitingPeriodSlice.reducer;

export const {
  selectAll: selectAllWaitingPeriod,
  selectById: selectWaitingPeriodById,
  selectIds: selectWaitingPeriodIds,
} = waitingPeriodAdapter.getSelectors(state => state.waitingPeriod);
