import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import axios from 'axios';

const dependentAgeAdapter = createEntityAdapter();
const apiBaseUrl = window.ENV.api_base_url;

const initialState = dependentAgeAdapter.getInitialState({
  status: 'idle',
  error: null,
});

export const fetchDependentAge = createAsyncThunk(
  'dependentAge/fetchDependentAge',
  async (_, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };

    const response = await axios.get(`${apiBaseUrl}/api/DependentAge`, config);
    return response.data;
  }
);

export const addNewDependentAge = createAsyncThunk(
  'dependentAge/addNewDependentAge',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.post(
      `${apiBaseUrl}/api/DependentAge`,
      initialState,
      config
    );
    console.log('adding new dependentAge record');
    console.log(response);
    return { ...initialState, id: response.data };
  }
);

export const deleteDependentAge = createAsyncThunk(
  'dependentAge/deleteDependentAge',
  async (dependentAgeId, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.delete(
      `${apiBaseUrl}/api/DependentAge/${dependentAgeId}`,
      config
    );
    return dependentAgeId;
  }
);

export const updateDependentAge = createAsyncThunk(
  'dependentAge/updateDependentAge',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.put(
      `${apiBaseUrl}/api/DependentAge/${initialState.id}`,
      initialState,
      config
    );
    return { ...initialState, id: response.data };
  }
);

const dependentAgeSlice = createSlice({
  name: 'dependentAge',
  initialState,
  extraReducers: {
    [fetchDependentAge.pending]: state => {
      state.status = 'loading';
    },
    [fetchDependentAge.fulfilled]: (state, action) => {
      // Add any fetched posts to the array
      dependentAgeAdapter.upsertMany(state, action.payload);
      state.status = 'succeeded';
    },
    [fetchDependentAge.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [addNewDependentAge.fulfilled]: dependentAgeAdapter.addOne,
    [deleteDependentAge.fulfilled]: dependentAgeAdapter.removeOne,
    [updateDependentAge.fulfilled]: dependentAgeAdapter.upsertOne,
  },
});

export default dependentAgeSlice.reducer;

export const {
  selectAll: selectAllDependentAge,
  selectById: selectDependentAgeById,
  selectIds: selectDependentAgeIds,
} = dependentAgeAdapter.getSelectors(state => state.dependentAge);
