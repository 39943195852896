import React, { useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Button,
  CloseButton,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Stack,
  StackDivider,
  VStack,
} from '@chakra-ui/react';

import { addNewWaitingPeriod } from './waitingPeriodSlice';
import { FieldGroup } from '../../components/common/FieldGroup';
import DatePicker from '../../components/common/DatePicker';
import * as moment from 'moment';

import { ErrorDisplay } from '../common/ErrorDisplay';
import { overlapCheck } from '../../services/overlapCheck';
import {
  dateToString,
  nullableDateToString,
} from '../../services/dateConverters';

export const AddWaitingPeriodForm = ({ user, waitingPeriodList }) => {
  const currdate = new Date().toISOString();
  const currStrDate = currdate.toString();
  const curruser = user.profile.name;
  const [waitingPeriodDesc, setWaitingPeriodDesc] = useState(' ');
  const [prev, setPrev] = useState(0);
  const [xray, setXray] = useState(0);
  const [mres, setMres] = useState(0);
  const [oth2, setOth2] = useState(0);
  const [pros, setPros] = useState(0);
  const [ortho, setOrtho] = useState(0);

  const [startDate, setEffStartDate] = useState(new Date());
  const [endDate, setEffEndDate] = useState('');
  const [addRequestStatus, setAddRequestStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();

  const onWaitingPeriodDescChanged = e => {
    setWaitingPeriodDesc(e.target.value);
    setErrorMessage('');
  };

  const onPrevChanged = e => setPrev(Number(e.target.value));
  const onXrayChanged = e => setXray(Number(e.target.value));
  const onMresChanged = e => setMres(Number(e.target.value));
  const onOth2Changed = e => setOth2(Number(e.target.value));
  const onProsChanged = e => setPros(Number(e.target.value));
  const onOrthoChanged = e => setOrtho(Number(e.target.value));

  const onEffStartDateChanged = e => {
    setEffStartDate(e);
    setErrorMessage('');
  };
  const onEffEndDateChanged = e => {
    setEffEndDate(e);
    setErrorMessage('');
  };

  const canSave =
    [
      waitingPeriodDesc,
      !isNaN(prev),
      !isNaN(xray),
      !isNaN(mres),
      !isNaN(oth2),
      !isNaN(pros),
      !isNaN(ortho),
      startDate,
    ].every(Boolean) && addRequestStatus === 'idle';

  const onSaveWaitingPeriodClicked = async () => {
    if (canSave) {
      try {
        setAddRequestStatus('pending');

        var waitingPeriodOverlap = waitingPeriodList.filter(overlapCheck, {
          effStartDate: startDate,
          effEndDate: endDate,
          keys: [
            {
              name: 'waitingPeriodDesc',
              value: waitingPeriodDesc,
            },
          ],
        });
        if (waitingPeriodOverlap.length) {
          console.log(waitingPeriodOverlap);
          setErrorMessage(
            'The record being entered overlaps an existing effective date range for this key value.'
          );
          return false;
        }

        const resultAction = await dispatch(
          addNewWaitingPeriod({
            waitingPeriodDesc,
            prev,
            xray,
            mres,
            oth2,
            pros,
            ortho,
            modifiedByName: user.profile.name,
            modifiedDate: new Date().toISOString(),
            effStartDate: dateToString(startDate),
            effEndDate: nullableDateToString(endDate),
          })
        );

        unwrapResult(resultAction);

        setWaitingPeriodDesc('');
        setPrev(0);
        setXray(0);
        setMres(0);
        setOth2(0);
        setPros(0);
        setOrtho(0);

        setEffStartDate(new Date());
        setEffEndDate('');
        //closeAccordion();
      } catch (err) {
        console.error('Failed to save the waitingPeriod factor record: ', err);
      } finally {
        setAddRequestStatus('idle');
      }
    }
  };

  return (
    <Box
      px={{
        base: '4',
        md: '10',
      }}
      pb="16"
      maxWidth="3xl"
      mx="auto"
    >
      <form>
        <Stack spacing="4" divider={<StackDivider />}>
          <FieldGroup title="Add WaitingPeriod Record">
            <VStack width="full" spacing="6">
              <FormControl id="waitingPeriodDesc">
                <FormLabel>Waiting Period Desc:</FormLabel>
                <Input
                  type="text"
                  value={waitingPeriodDesc}
                  onChange={onWaitingPeriodDescChanged}
                />
              </FormControl>
              <FormControl id="prev">
                <FormLabel>Prev:</FormLabel>
                <Input type="number" value={prev} onChange={onPrevChanged} />
              </FormControl>
              <FormControl id="xray">
                <FormLabel>Xray:</FormLabel>
                <Input type="number" value={xray} onChange={onXrayChanged} />
              </FormControl>
              <FormControl id="mres">
                <FormLabel>Mres:</FormLabel>
                <Input type="number" value={mres} onChange={onMresChanged} />
              </FormControl>
              <FormControl id="oth2">
                <FormLabel>Oth2:</FormLabel>
                <Input type="number" value={oth2} onChange={onOth2Changed} />
              </FormControl>
              <FormControl id="pros">
                <FormLabel>Pros:</FormLabel>
                <Input type="number" value={pros} onChange={onProsChanged} />
              </FormControl>
              <FormControl id="ortho">
                <FormLabel>Ortho:</FormLabel>
                <Input type="number" value={ortho} onChange={onOrthoChanged} />
              </FormControl>

              <FormControl>
                <FormLabel htmlFor="effStartDate">
                  Effective Start Date
                </FormLabel>
                <DatePicker
                  id="effStartDate"
                  selectedDate={startDate}
                  onChange={onEffStartDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective Start Date</FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="effEndDate">Effective End Date</FormLabel>
                <DatePicker
                  id="effEndDate"
                  selectedDate={endDate}
                  onChange={onEffEndDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective End Date</FormHelperText>
              </FormControl>
            </VStack>
          </FieldGroup>

          <Button
            onClick={onSaveWaitingPeriodClicked}
            disabled={!canSave}
            variant="brand-primary"
            size="xs"
          >
            Save WaitingPeriod Factor Record
          </Button>
          <ErrorDisplay
            title="The record dates overlap other records!"
            errorMessage={errorMessage}
          />
        </Stack>
      </form>
    </Box>
  );
};

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
  };
}

export const AddWaitingPeriodFormUser =
  connect(mapStateToProps)(AddWaitingPeriodForm);
