import React, { useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Button,
  CloseButton,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Stack,
  StackDivider,
  VStack,
} from '@chakra-ui/react';

import { addNewMaximumAmount } from './maximumAmountSlice';
import { FieldGroup } from '../../components/common/FieldGroup';
import DatePicker from '../../components/common/DatePicker';

import { ErrorDisplay } from '../common/ErrorDisplay';
import { overlapCheck } from '../../services/overlapCheck';
import {
  dateToString,
  nullableDateToString,
} from '../../services/dateConverters';

export const AddMaximumAmountForm = ({ user, maximumAmountList }) => {
  const [benefitType, setBenefitType] = useState(' ');
  const [amount, setAmount] = useState(0);
  const [adult, setAdult] = useState(0);
  const [child, setChild] = useState(0);
  const [incentiveOk, setIncentiveOk] = useState(' ');
  const [rolloverOk, setRolloverOk] = useState(' ');

  const [startDate, setEffStartDate] = useState(new Date());
  const [endDate, setEffEndDate] = useState('');
  const [addRequestStatus, setAddRequestStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();

  const onBenefitTypeChanged = e => {
    setBenefitType(e.target.value);
    setErrorMessage('');
  };

  const onAmountChanged = e => setAmount(Number(e.target.value));
  const onAdultChanged = e => setAdult(Number(e.target.value));
  const onChildChanged = e => setChild(Number(e.target.value));
  const onIncentiveOkChanged = e => setIncentiveOk(e.target.value);
  const onRolloverOkChanged = e => setRolloverOk(e.target.value);

  const onEffStartDateChanged = e => {
    setEffStartDate(e);
    setErrorMessage('');
  };
  const onEffEndDateChanged = e => {
    setEffEndDate(e);
    setErrorMessage('');
  };

  const canSave =
    [
      benefitType,
      !isNaN(amount),
      !isNaN(adult),
      !isNaN(child),
      incentiveOk,
      rolloverOk,
      startDate,
    ].every(Boolean) && addRequestStatus === 'idle';

  const onSaveMaximumAmountClicked = async () => {
    if (canSave) {
      try {
        setAddRequestStatus('pending');

        var maximumAmountOverlap = maximumAmountList.filter(overlapCheck, {
          effStartDate: startDate,
          effEndDate: endDate,
          keys: [
            {
              name: 'benefitType',
              value: benefitType,
            },
            {
              name: 'amount',
              value: amount,
            },
          ],
        });
        if (maximumAmountOverlap.length) {
          console.log(maximumAmountOverlap);
          setErrorMessage(
            'The record being entered overlaps an existing effective date range for this key value.'
          );
          return false;
        }

        const resultAction = await dispatch(
          addNewMaximumAmount({
            benefitType,
            amount,
            adult,
            child,
            incentiveOk,
            rolloverOk,
            modifiedByName: user.profile.name,
            modifiedDate: new Date().toISOString(),
            effStartDate: dateToString(startDate),
            effEndDate: nullableDateToString(endDate),
          })
        );

        unwrapResult(resultAction);

        setBenefitType('');
        setAmount(0);
        setAdult(0);
        setChild(0);
        setIncentiveOk('');
        setRolloverOk('');

        setEffStartDate(new Date());
        setEffEndDate('');
      } catch (err) {
        console.error('Failed to save the maximumAmount factor record: ', err);
      } finally {
        setAddRequestStatus('idle');
      }
    }
  };

  return (
    <Box
      px={{
        base: '4',
        md: '10',
      }}
      pb="16"
      maxWidth="3xl"
      mx="auto"
    >
      <form>
        <Stack spacing="4" divider={<StackDivider />}>
          <FieldGroup title="Add MaximumAmount Record">
            <VStack width="full" spacing="6">
              <FormControl id="benefitType">
                <FormLabel>BenefitType:</FormLabel>
                <Input
                  type="text"
                  value={benefitType}
                  onChange={onBenefitTypeChanged}
                />
              </FormControl>
              <FormControl id="amount">
                <FormLabel>Amount:</FormLabel>
                <Input
                  type="number"
                  value={amount}
                  onChange={onAmountChanged}
                />
              </FormControl>
              <FormControl id="adult">
                <FormLabel>Adult:</FormLabel>
                <Input type="number" value={adult} onChange={onAdultChanged} />
              </FormControl>
              <FormControl id="child">
                <FormLabel>Child:</FormLabel>
                <Input type="number" value={child} onChange={onChildChanged} />
              </FormControl>
              <FormControl id="incentiveOk">
                <FormLabel>IncentiveOk:</FormLabel>
                <Input
                  type="text"
                  value={incentiveOk}
                  onChange={onIncentiveOkChanged}
                />
              </FormControl>
              <FormControl id="rolloverOk">
                <FormLabel>RolloverOk:</FormLabel>
                <Input
                  type="text"
                  value={rolloverOk}
                  onChange={onRolloverOkChanged}
                />
              </FormControl>

              <FormControl>
                <FormLabel htmlFor="effStartDate">
                  Effective Start Date
                </FormLabel>
                <DatePicker
                  id="effStartDate"
                  selectedDate={startDate}
                  onChange={onEffStartDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective Start Date</FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="effEndDate">Effective End Date</FormLabel>
                <DatePicker
                  id="effEndDate"
                  selectedDate={endDate}
                  onChange={onEffEndDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective End Date</FormHelperText>
              </FormControl>
            </VStack>
          </FieldGroup>

          <Button
            onClick={onSaveMaximumAmountClicked}
            disabled={!canSave}
            variant="brand-primary"
            size="xs"
          >
            Save MaximumAmount Factor Record
          </Button>
          <ErrorDisplay
            title="The record dates overlap other records!"
            errorMessage={errorMessage}
          />
        </Stack>
      </form>
    </Box>
  );
};

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
  };
}

export const AddMaximumAmountFormUser =
  connect(mapStateToProps)(AddMaximumAmountForm);
