import React, { useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Button,
  CloseButton,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Stack,
  StackDivider,
  VStack,
} from '@chakra-ui/react';

import { addNewStates } from './statesSlice';
import { FieldGroup } from '../../components/common/FieldGroup';
import DatePicker from '../../components/common/DatePicker';
import { ErrorDisplay } from '../common/ErrorDisplay';
import { overlapCheck } from '../../services/overlapCheck';
import {
  dateToString,
  nullableDateToString,
} from '../../services/dateConverters';

export const AddStatesForm = ({ user, statesList, closeAccordion }) => {
  const [stateAbbrev, setStateAbbrev] = useState(' ');
  const [areaId, setAreaId] = useState(0);
  const [prm, setPrm] = useState(0);
  const [ppo, setPpo] = useState(0);
  const [oonStd, setOonStd] = useState(0);
  const [oonNat, setOonNat] = useState(0);
  const [pctParDentist, setPctParDentist] = useState(0);
  const [ppoDiscount, setPpoDiscount] = useState(0);
  const [eparegion, setEparegion] = useState(0);
  const [allowPpoentry, setAllowPpoentry] = useState(0);

  const [startDate, setEffStartDate] = useState(new Date());
  const [endDate, setEffEndDate] = useState('');
  const [addRequestStatus, setAddRequestStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();

  const onStateAbbrevChanged = e => {
    setErrorMessage('');
    setStateAbbrev(e.target.value);
  };
  const onAreaIdChanged = e => {
    setErrorMessage('');
    setAreaId(Number(e.target.value));
  };
  const onPrmChanged = e => setPrm(Number(e.target.value));
  const onPpoChanged = e => setPpo(Number(e.target.value));
  const onOonStdChanged = e => setOonStd(Number(e.target.value));
  const onOonNatChanged = e => setOonNat(Number(e.target.value));
  const onPctParDentistChanged = e => setPctParDentist(Number(e.target.value));
  const onPpoDiscountChanged = e => setPpoDiscount(Number(e.target.value));
  const onEparegionChanged = e => setEparegion(Number(e.target.value));
  const onAllowPpoentryChanged = e => setAllowPpoentry(Number(e.target.value));
  const onEffStartDateChanged = e => {
    setEffStartDate(e);
    setErrorMessage('');
  };
  const onEffEndDateChanged = e => {
    setEffEndDate(e);
    setErrorMessage('');
  };

  const canSave =
    [
      stateAbbrev,
      !isNaN(areaId),
      !isNaN(prm),
      !isNaN(ppo),
      !isNaN(oonStd),
      !isNaN(oonNat),
      !isNaN(pctParDentist),
      !isNaN(ppoDiscount),
      !isNaN(eparegion),
      !isNaN(allowPpoentry),

      startDate,
    ].every(Boolean) && addRequestStatus === 'idle';

  const onSaveStatesClicked = async () => {
    if (canSave) {
      setErrorMessage('');
      try {
        setAddRequestStatus('pending');

        var statesOverlap = statesList.filter(overlapCheck, {
          effStartDate: startDate,
          effEndDate: endDate,
          keys: [
            {
              name: 'stateAbbrev',
              value: stateAbbrev,
            },
            {
              name: 'areaId',
              value: areaId,
            },
          ],
        });
        if (statesOverlap.length) {
          console.log(statesOverlap);
          setErrorMessage(
            'The record being entered overlaps an existing effective date range for this key value.'
          );
          return false;
        }

        const resultAction = await dispatch(
          addNewStates({
            stateAbbrev,
            areaId,
            prm,
            ppo,
            oonStd,
            oonNat,
            pctParDentist,
            ppoDiscount,
            eparegion,
            allowPpoentry,
            modifiedByName: user.profile.name,
            modifiedDate: new Date().toISOString(),
            effStartDate: dateToString(startDate),
            effEndDate: nullableDateToString(endDate),
          })
        );

        unwrapResult(resultAction);
        setStateAbbrev('');
        setAreaId(0);
        setPrm(0);
        setPpo(0);
        setOonStd(0);
        setOonNat(0);
        setPctParDentist(0);
        setPpoDiscount(0);
        setEparegion(0);
        setAllowPpoentry(0);

        setEffStartDate(new Date());
        setEffEndDate('');
        closeAccordion();
      } catch (err) {
        console.error('Failed to save the states record: ', err);
      } finally {
        setAddRequestStatus('idle');
      }
    }
  };

  return (
    <Box
      px={{
        base: '4',
        md: '10',
      }}
      pb="16"
      maxWidth="3xl"
      mx="auto"
    >
      <form>
        <Stack spacing="4" divider={<StackDivider />}>
          <FieldGroup title="Add New State Information">
            <VStack width="full" spacing="6">
              <FormControl id="stateAbbrev">
                <FormLabel>State Abbreviation:</FormLabel>
                <Input
                  type="text"
                  value={stateAbbrev}
                  onChange={onStateAbbrevChanged}
                />
              </FormControl>

              <FormControl id="areaId">
                <FormLabel>Area ID:</FormLabel>
                <Input
                  type="number"
                  value={areaId}
                  onChange={onAreaIdChanged}
                />
              </FormControl>
              <FormControl id="prm">
                <FormLabel>Prm:</FormLabel>
                <Input type="number" value={prm} onChange={onPrmChanged} />
              </FormControl>
              <FormControl id="ppo">
                <FormLabel>PPO:</FormLabel>
                <Input type="number" value={ppo} onChange={onPpoChanged} />
              </FormControl>

              <FormControl id="oonStd">
                <FormLabel>Oon Std:</FormLabel>
                <Input
                  type="number"
                  value={oonStd}
                  onChange={onOonStdChanged}
                />
              </FormControl>
              <FormControl id="oonNat">
                <FormLabel>Oon Nat:</FormLabel>
                <Input
                  type="number"
                  value={oonNat}
                  onChange={onOonNatChanged}
                />
              </FormControl>
              <FormControl id="pctParDentist">
                <FormLabel>Pct Par Dentist:</FormLabel>
                <Input
                  type="number"
                  value={pctParDentist}
                  onChange={onPctParDentistChanged}
                />
              </FormControl>
              <FormControl id="Ppo Discount">
                <FormLabel>PPO Discount:</FormLabel>
                <Input
                  type="number"
                  value={ppoDiscount}
                  onChange={onPpoDiscountChanged}
                />
              </FormControl>
              <FormControl id="eparegion">
                <FormLabel>Epa Region:</FormLabel>
                <Input
                  type="number"
                  value={eparegion}
                  onChange={onEparegionChanged}
                />
              </FormControl>
              <FormControl id="allowPpoentry">
                <FormLabel>Allow Ppo Entry:</FormLabel>
                <Input
                  type="number"
                  value={allowPpoentry}
                  onChange={onAllowPpoentryChanged}
                />
              </FormControl>

              <FormControl id="effStartDate">
                <FormLabel>Effective Start Date:</FormLabel>
                <DatePicker
                  id="effStartDate"
                  selectedDate={startDate}
                  onChange={onEffStartDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective Start Date</FormHelperText>
              </FormControl>
              <FormControl id="effEndDate">
                <FormLabel>Effective End Date:</FormLabel>
                <DatePicker
                  id="effEndDate"
                  selectedDate={endDate}
                  onChange={onEffEndDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective End Date</FormHelperText>
              </FormControl>
            </VStack>
          </FieldGroup>

          <Button
            onClick={onSaveStatesClicked}
            disabled={!canSave}
            variant="brand-primary"
            size="xs"
          >
            Save New Factor
          </Button>
          <ErrorDisplay
            title="The record dates overlap other records!"
            errorMessage={errorMessage}
          />
        </Stack>
      </form>
    </Box>
  );
};

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
  };
}

export const AddStatesFormUser = connect(mapStateToProps)(AddStatesForm);
