import React, { useEffect } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalHeader,
  ModalFooter,
  ModalContent,
  ModalCloseButton,
  Heading,
  useDisclosure,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchConstantsAdmin,
  selectConstantsAdminIds,
  selectConstantsAdminById,
  deleteConstantsAdmin,
} from './constantsAdminSlice';
import { unwrapResult } from '@reduxjs/toolkit';

import { NavLink } from 'react-router-dom';
import { FormattedDate } from '../common/FormattedDate';
import { FormattedTimestamp } from '../common/FormattedTimestamp';

const ItemDetails = ({ currentItem }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button onClick={onOpen} size="xs">
        Details
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Constant Admin Detail</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Table size="sm">
              <Tbody>
                <Tr>
                  <Th>GeneralAgent</Th>
                  <Th>AdminTrend</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.generalAgent}</Td>
                  <Td>{currentItem.adminTrend}</Td>
                </Tr>
                <Tr>
                  <Th>InterestIncome</Th>
                  <Th>PremiumPaymentLostInterest</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.interestIncome}</Td>
                  <Td>{currentItem.premiumPaymentLostInterest}</Td>
                </Tr>{' '}
                <Tr>
                  <Th>TwoYearWaitingPeriodUnder1000</Th>
                  <Th>TwoYearWaitingPeriodOver1000</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.twoYearWaitingPeriodUnder1000}</Td>
                  <Td>{currentItem.twoYearWaitingPeriodOver1000}</Td>
                </Tr>
                <Tr>
                  <Th>DpoPanelFeeUnder1000</Th>
                  <Th>DpoPanelFeeUnder1000</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.dpoPanelFeeUnder1000}</Td>
                  <Td>{currentItem.dpoPanelFeeUnder1000}</Td>
                </Tr>{' '}
                <Tr>
                  <Th>PerTransactionNoWithHold</Th>
                  <Th>PerTransactionNpfUsage</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.perTransactionNoWithHold}</Td>
                  <Td>{currentItem.perTransactionNpfUsage}</Td>
                </Tr>
                <Tr>
                  <Th>AllowedSubGroups1000</Th>
                  <Th>AllowedSubGroups10001</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.allowedSubGroups1000}</Td>
                  <Td>{currentItem.allowedSubGroups10001}</Td>
                </Tr>{' '}
                <Tr>
                  <Th>LostInterest1Month</Th>
                  <Th>LostInterest2Month</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.lostInterest1Month}</Td>
                  <Td>{currentItem.lostInterest2Month}</Td>
                </Tr>
                <Tr>
                  <Th>LostInterestWeekly</Th>
                  <Th>AdditionalRiskPremiumDelay</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.lostInterestWeekly}</Td>
                  <Td>{currentItem.additionalRiskPremiumDelay}</Td>
                </Tr>{' '}
                <Tr>
                  <Th>AdditionalRiskRetention</Th>
                  <Th>AdditionalRiskRetroRetention</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.additionalRiskRetention}</Td>
                  <Td>{currentItem.additionalRiskRetroRetention}</Td>
                </Tr>
                <Tr>
                  <Th>RetentionDollarAmount</Th>
                  <Th>Amount</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.retentionDollarAmount}</Td>
                  <Td>{currentItem.amount}</Td>
                </Tr>{' '}
                <Tr>
                  <Th>Maximum</Th>
                  <Th>Factor</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.maximum}</Td>
                  <Td>{currentItem.factor}</Td>
                </Tr>
                <Tr>
                  <Th>Risk</Th>
                  <Th>Aso2500</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.risk}</Td>
                  <Td>{currentItem.aso2500}</Td>
                </Tr>{' '}
                <Tr>
                  <Th>Aso25004999</Th>
                  <Th>Aso5000</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.aso25004999}</Td>
                  <Td>{currentItem.aso5000}</Td>
                </Tr>
                <Tr>
                  <Th>Unknown0To999</Th>
                  <Th>UnknownGT1000</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.unknown0To999}</Td>
                  <Td>{currentItem.unknownGT1000}</Td>
                </Tr>{' '}
                <Tr>
                  <Th>Additional</Th>
                  <Th>PerEligible</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.additional}</Td>
                  <Td>{currentItem.perEligible}</Td>
                </Tr>
                <Tr>
                  <Th>Sublocation</Th>
                  <Th>PassThroughRateUnder800</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.sublocation}</Td>
                  <Td>{currentItem.passThroughRateUnder800}</Td>
                </Tr>{' '}
                <Tr>
                  <Th>PassThroughRateOver800</Th>
                  <Th>PerClaimDpo</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.passThroughRateOver800}</Td>
                  <Td>{currentItem.perClaimDpo}</Td>
                </Tr>
                <Tr>
                  <Th>PerClaimNonDpo</Th>
                  <Th>PerSubscriber</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.perClaimNonDpo}</Td>
                  <Td>{currentItem.perSubscriber}</Td>
                </Tr>{' '}
                <Tr>
                  <Th>SubGroupCharge</Th>
                  <Th>OfPaidClaims</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.subGroupCharge}</Td>
                  <Td>{currentItem.ofPaidClaims}</Td>
                </Tr>
                <Tr>
                  <Th>Subsidy</Th>
                  <Th>AsoProfitLoad</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.subsidy}</Td>
                  <Td>{currentItem.asoProfitLoad}</Td>
                </Tr>{' '}
                <Tr>
                  <Th>Charge</Th>
                  <Th>LT10001</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.charge}</Td>
                  <Td>{currentItem.lT10001}</Td>
                </Tr>
                <Tr>
                  <Th>Range1000To4999</Th>
                  <Th>GT5000</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.range1000To4999}</Td>
                  <Td>{currentItem.gT5000}</Td>
                </Tr>
                <Tr>
                  <Th>Modified By Name</Th>
                  <Th>Modified Date</Th>
                </Tr>
                <Tr>
                  <Td>{currentItem.modifiedByName}</Td>
                  <Td>
                    <FormattedTimestamp
                      dateToFormat={currentItem.modifiedDate}
                    />
                  </Td>
                </Tr>
                <Tr>
                  <Th>Eff Start Date</Th>
                  <Th>Eff End Date</Th>
                </Tr>
                <Tr>
                  <Td>
                    <FormattedDate dateToFormat={currentItem.effStartDate} />
                  </Td>
                  <Td>
                    <FormattedDate dateToFormat={currentItem.effEndDate} />
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="brand-primary-sm"
              mr={3}
              onClick={onClose}
              size="sm"
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

let ConstantsAdminTable = ({ constantsAdminIdList }) => {
  let tableRows = constantsAdminIdList.map(constantsAdminId => (
    <ConstantsAdmin
      constantsAdminId={constantsAdminId}
      key={constantsAdminId}
    />
  ));

  return (
    <Box overflowX="auto">
      <Table size="sm">
        <Thead>
          <Tr>
            <Th></Th>
            <Th>General Agent</Th>
            <Th>Admin Trend</Th>
            <Th>Interest Income</Th>
            {/*<Th>PremiumPayment LostInterest</Th>*/}
            {/*<Th>TwoYearWaitingPeriodUnder1000</Th>*/}
            {/*<Th>TwoYearWaitingPeriodOver1000</Th>*/}
            {/*<Th>DpoPanelFeeUnder1000</Th>*/}
            {/*<Th>DpoPanelFeeOver1000</Th>*/}
            {/*<Th>PerTransaction NoWithHold</Th>*/}
            {/*<Th>PerTransaction NpfUsage</Th>*/}
            {/*<Th>AllowedSubGroups1000</Th>*/}
            {/*<Th>AllowedSubGroups10001</Th>*/}
            {/*<Th>LostInterest1Month</Th>*/}
            {/*<Th>LostInterest2Month</Th>*/}
            {/*<Th>LostInterestWeekly</Th>*/}
            {/*<Th>AdditionalRiskPremiumDelay</Th>*/}
            {/*<Th>AdditionalRiskRetention</Th>*/}
            {/*<Th>AdditionalRiskRetroRetention</Th>*/}
            {/*<Th>Retention Dollar Amount</Th>*/}
            <Th>Amount</Th>
            <Th>Maximum</Th>
            <Th>Factor</Th>
            <Th>Risk</Th>
            <Th>Aso2500</Th>
            {/*<Th>Aso25004999</Th>*/}
            <Th>Aso5000</Th>
            {/*<Th>Unknown 0To999</Th>
            <Th>Unknown GT1000</Th>
            <Th>Additional</Th>
            <Th>PerEligible</Th>
            <Th>Sublocation</Th>
            <Th>PassThroughRateUnder800</Th>
            <Th>PassThroughRateOver800</Th>
            <Th>PerClaimDpo</Th>
            <Th>PerClaimNonDpo</Th>
            <Th>PerSubscriber</Th>
            <Th>SubGroupCharge</Th>
            <Th>OfPaidClaims</Th>
            <Th>Subsidy</Th>
            <Th>AsoProfitLoad</Th>*/}
            <Th>Charge</Th>
            <Th>LT10001</Th>
            {/*<Th>Range1000To4999</Th>*/}
            <Th>GT5000</Th>
            {/* <Th>Modified Date</Th>*/}
            <Th>Eff Start Date</Th>
            <Th>Eff End Date</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>{tableRows}</Tbody>
      </Table>
    </Box>
  );
};

let ConstantsAdmin = ({ constantsAdminId }) => {
  const constantsAdmin = useSelector(state =>
    selectConstantsAdminById(state, constantsAdminId)
  );

  const dispatch = useDispatch();

  const onDeleteConstantsAdminClicked = async () => {
    try {
      const resultAction = await dispatch(
        deleteConstantsAdmin(constantsAdmin.id)
      );
      unwrapResult(resultAction);
    } catch (err) {
      console.error('Failed to save the constantsAdmin record', err);
    }
  };

  return (
    <Tr>
      <Td>
        <ItemDetails currentItem={constantsAdmin} />
      </Td>
      <Td>{constantsAdmin.generalAgent}</Td>
      <Td>{constantsAdmin.adminTrend}</Td>
      <Td>{constantsAdmin.interestIncome}</Td>
      {/*<Td>{constantsAdmin.premiumPaymentLostInterest}</Td>*/}
      {/*<Td>{constantsAdmin.TwoYearWaitingPeriodUnder1000}</Td>
      <Td>{constantsAdmin.TwoYearWaitingPeriodOver1000}</Td>
      <Td>{constantsAdmin.dpoPanelFeeUnder1000}</Td>
      <Td>{constantsAdmin.dpoPanelFeeOver1000}</Td>
      <Td>{constantsAdmin.perTransactionNoWithHold}</Td>
      <Td>{constantsAdmin.perTransactionNpfUsage}</Td>
      <Td>{constantsAdmin.allowedSubGroups1000}</Td>
      <Td>{constantsAdmin.allowedSubGroups10001}</Td>
      <Td>{constantsAdmin.lostInterest1Month}</Td>
      <Td>{constantsAdmin.lostInterest2Month}</Td>
      <Td>{constantsAdmin.lostInterestWeekly}</Td>
      <Td>{constantsAdmin.additionalRiskPremiumDelay}</Td>
      <Td>{constantsAdmin.additionalRiskRetention}</Td>
      <Td>{constantsAdmin.additionalRiskRetroRetention}</Td>
      <Td>{constantsAdmin.retentionDollarAmount}</Td>*/}
      <Td>{constantsAdmin.amount}</Td>
      <Td>{constantsAdmin.maximum}</Td>
      <Td>{constantsAdmin.factor}</Td>
      <Td>{constantsAdmin.risk}</Td>
      <Td>{constantsAdmin.aso2500}</Td>
      {/*<Td>{constantsAdmin.aso25004999}</Td>*/}
      <Td>{constantsAdmin.aso5000}</Td>
      {/*<Td>{constantsAdmin.unknown0To999}</Td>
      <Td>{constantsAdmin.unknownGT1000}</Td>
      <Td>{constantsAdmin.additional}</Td>
      <Td>{constantsAdmin.perEligible}</Td>
      <Td>{constantsAdmin.sublocation}</Td>
      <Td>{constantsAdmin.passThroughRateUnder800}</Td>
      <Td>{constantsAdmin.passThroughRateOver800}</Td>
      <Td>{constantsAdmin.perClaimDpo}</Td>
      <Td>{constantsAdmin.perClaimNonDpo}</Td>
      <Td>{constantsAdmin.perSubscriber}</Td>
      <Td>{constantsAdmin.subGroupCharge}</Td>
      <Td>{constantsAdmin.ofPaidClaims}</Td>
      <Td>{constantsAdmin.subsidy}</Td>
      <Td>{constantsAdmin.asoProfitLoad}</Td>*/}
      <Td>{constantsAdmin.charge}</Td>
      <Td>{constantsAdmin.lT10001}</Td>
      {/*<Td>{constantsAdmin.range1000To4999}</Td>*/}
      <Td>{constantsAdmin.gT5000}</Td>

      {/*<Td>
        <FormattedTimestamp dateToFormat={constantsAdmin.modifiedDate} />
      </Td>*/}
      <Td>
        <FormattedDate dateToFormat={constantsAdmin.effStartDate} />
      </Td>
      <Td>
        <FormattedDate dateToFormat={constantsAdmin.effEndDate} />
      </Td>
      <Td>
        <ButtonGroup variant="outline">
          <Button
            colorScheme="gray"
            size="sm"
            as={NavLink}
            to={`/editConstantsAdmin/${constantsAdmin.id}`}
          >
            Edit
          </Button>
          <Button
            colorScheme="red"
            size="sm"
            onClick={onDeleteConstantsAdminClicked}
          >
            Delete
          </Button>
        </ButtonGroup>
      </Td>
    </Tr>
  );
};

export const ConstantsAdminList = () => {
  const dispatch = useDispatch();
  const orderedConstantsAdminIds = useSelector(selectConstantsAdminIds);
  console.log('in the roller list');
  const error = useSelector(state => state.constantsAdmin.error);

  const constantsAdminStatus = useSelector(
    state => state.constantsAdmin.status
  );
  console.log(constantsAdminStatus);
  useEffect(() => {
    if (constantsAdminStatus === 'idle') {
      dispatch(fetchConstantsAdmin());
    }
  }, [constantsAdminStatus, dispatch]);

  let content;

  if (constantsAdminStatus === 'loading') {
    content = <div className="loader">Loading...</div>;
  } else if (constantsAdminStatus === 'succeeded') {
    content = (
      <ConstantsAdminTable constantsAdminIdList={orderedConstantsAdminIds} />
    );
  } else if (constantsAdminStatus === 'failed') {
    content = <div>{error}</div>;
  }

  return (
    <section>
      <h2>
        <b>Constants Admin Listing</b>
      </h2>
      {content}
    </section>
  );
};
