import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Select,
  Stack,
  StackDivider,
  VStack,
} from '@chakra-ui/react';

import {
  fetchUsaTrend,
  updateUsaTrend,
  selectUsaTrendById,
  selectAllUsaTrend,
} from './usaTrendSlice';
import { FieldGroup } from '../../components/common/FieldGroup';
import DatePicker from '../../components/common/DatePicker';
import { ErrorDisplay } from '../common/ErrorDisplay';
import { overlapCheck } from '../../services/overlapCheck';
import {
  dateToString,
  nullableDateToString,
  stringToDateFormat,
} from '../../services/dateConverters';

export const EditUsaTrendForm = ({ match, user }) => {
  const { usaTrendId } = match.params;
  const usaTrend = useSelector(state => selectUsaTrendById(state, usaTrendId));
  const usaTrendList = useSelector(selectAllUsaTrend);

  const stdt = stringToDateFormat(usaTrend.effStartDate);
  console.log(stdt);
  const endt = stringToDateFormat(usaTrend.effEndDate);
  console.log(endt);

  const [state, setState] = useState(usaTrend.state);
  const [premier, setPremier] = useState(usaTrend.premier);
  const [ppo, setPpo] = useState(usaTrend.ppo);

  const [startDate, setEffStartDate] = useState(stdt);

  const [endDate, setEffEndDate] = useState(endt);
  const [updateRequestStatus, setUpdateRequestStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();
  const history = useHistory();

  const onStateChanged = e => {
    setState(e.target.value);
    setErrorMessage('');
  };
  const onPremierChanged = e => setPremier(Number(e.target.value));
  const onPpoChanged = e => setPpo(Number(e.target.value));

  const onEffStartDateChanged = e => {
    setEffStartDate(e);
    setErrorMessage('');
  };
  const onEffEndDateChanged = e => {
    setEffEndDate(e);
    setErrorMessage('');
  };

  const canSave =
    [state, !isNaN(premier), !isNaN(ppo), startDate].every(Boolean) &&
    updateRequestStatus === 'idle';

  const onSaveUsaTrendClicked = async () => {
    if (canSave) {
      try {
        setUpdateRequestStatus('pending');

        var usaTrendOverlap = usaTrendList.filter(overlapCheck, {
          id: usaTrend.id,
          effStartDate: startDate,
          effEndDate: endDate,
          keys: [
            {
              name: 'state',
              value: state,
            },
          ],
        });
        if (usaTrendOverlap.length) {
          console.log(usaTrendOverlap);
          setErrorMessage(
            'The record being entered overlaps an existing effective date range for this key value.'
          );
          return false;
        }

        const resultAction = await dispatch(
          updateUsaTrend({
            id: Number(usaTrendId),
            state,
            premier,
            ppo,
            modifiedByName: user.profile.name,
            modifiedDate: new Date().toISOString(),
            effStartDate: dateToString(startDate),
            effEndDate: nullableDateToString(endDate),
          })
        );
        console.log(resultAction);
        setUpdateRequestStatus('idle');
        history.push(`/usaTrend`);
      } catch (err) {
        setUpdateRequestStatus('idle');
        console.error('Failed to save the administration factor: ', err);
      }
    }
  };
  return (
    <Box
      px={{
        base: '4',
        md: '10',
      }}
      pb="16"
      maxWidth="3xl"
      mx="auto"
    >
      <form>
        <Stack spacing="4" divider={<StackDivider />}>
          <FieldGroup title="Edit Administration Factors">
            <VStack width="full" spacing="6">
              <FormControl id="state">
                <FormLabel>State:</FormLabel>
                <Input type="text" value={state} onChange={onStateChanged} />
              </FormControl>
              <FormControl id="premier">
                <FormLabel>Premier:</FormLabel>
                <Input
                  type="number"
                  value={premier}
                  onChange={onPremierChanged}
                />
              </FormControl>
              <FormControl id="ppo">
                <FormLabel>PPO:</FormLabel>
                <Input type="number" value={ppo} onChange={onPpoChanged} />
              </FormControl>
              <FormControl id="effStartDate">
                <FormLabel>Effective Start Date:</FormLabel>
                <DatePicker
                  id="effStartDate"
                  selectedDate={startDate}
                  onChange={onEffStartDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective Start Date</FormHelperText>
              </FormControl>
              <FormControl id="effEndDate">
                <FormLabel>Effective End Date:</FormLabel>
                <DatePicker
                  id="effEndDate"
                  selectedDate={endDate}
                  onChange={onEffEndDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective End Date</FormHelperText>
              </FormControl>
            </VStack>
          </FieldGroup>

          <Button
            onClick={onSaveUsaTrendClicked}
            disabled={!canSave}
            variant="brand-primary"
            size="xs"
          >
            Save UsaTrend Factor
          </Button>
          <ErrorDisplay
            title="The record dates overlap other records!"
            errorMessage={errorMessage}
          />
        </Stack>
      </form>
    </Box>
  );
};

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
  };
}

export const EditUsaTrendFormUser = connect(mapStateToProps)(EditUsaTrendForm);
