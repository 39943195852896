import React, { useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Button,
  CloseButton,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Stack,
  StackDivider,
  VStack,
} from '@chakra-ui/react';

import { addNewSpecialMix } from './specialMixSlice';
import { FieldGroup } from '../../components/common/FieldGroup';
import DatePicker from '../../components/common/DatePicker';
import { ErrorDisplay } from '../common/ErrorDisplay';
import { overlapCheck } from '../../services/overlapCheck';
import {
  dateToString,
  nullableDateToString,
} from '../../services/dateConverters';

export const AddSpecialMixForm = ({ user, specialMixList }) => {
  const [description, setDescription] = useState(' ');
  const [employee, setEmployee] = useState(0);
  const [spouse, setSpouse] = useState(0);
  const [child, setChild] = useState(0);
  const [oneParty, setOneParty] = useState(0);
  const [employeeSpouse, setEmployeeSpouse] = useState(0);
  const [employeeChild, setEmployeeChild] = useState(0);
  const [family, setFamily] = useState(0);

  const [twoParty, setTwoParty] = useState(0);

  const [threeParty, setThreeParty] = useState(0);

  const [startDate, setEffStartDate] = useState(new Date());
  const [endDate, setEffEndDate] = useState('');
  const [addRequestStatus, setAddRequestStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();

  const onDescriptionChanged = e => {
    setDescription(e.target.value);
    setErrorMessage('');
  };
  const onEmployeeChanged = e => setEmployee(Number(e.target.value));
  const onSpouseChanged = e => setSpouse(Number(e.target.value));
  const onChildChanged = e => setChild(Number(e.target.value));
  const onOnePartyChanged = e => setOneParty(Number(e.target.value));

  const onEmployeeSpouseChanged = e =>
    setEmployeeSpouse(Number(e.target.value));
  const onEmployeeChildChanged = e => setEmployeeChild(Number(e.target.value));
  const onFamilyChanged = e => setFamily(Number(e.target.value));
  const onTwoPartyChanged = e => setTwoParty(Number(e.target.value));
  const onThreePartyChanged = e => setThreeParty(Number(e.target.value));

  const onEffStartDateChanged = e => {
    setEffStartDate(e);
    setErrorMessage('');
  };
  const onEffEndDateChanged = e => {
    setEffEndDate(e);
    setErrorMessage('');
  };

  const canSave =
    [
      description,
      !isNaN(employee),
      !isNaN(spouse),
      !isNaN(child),
      !isNaN(oneParty),
      !isNaN(employeeSpouse),
      !isNaN(employeeChild),
      !isNaN(family),
      !isNaN(twoParty),
      !isNaN(threeParty),

      startDate,
    ].every(Boolean) && addRequestStatus === 'idle';

  const onSaveSpecialMixClicked = async () => {
    if (canSave) {
      try {
        setAddRequestStatus('pending');

        var specialMixOverlap = specialMixList.filter(overlapCheck, {
          effStartDate: startDate,
          effEndDate: endDate,
          keys: [
            {
              name: 'description',
              value: description,
            },
          ],
        });
        if (specialMixOverlap.length) {
          console.log(specialMixOverlap);
          setErrorMessage(
            'The record being entered overlaps an existing effective date range for this key value.'
          );
          return false;
        }

        const resultAction = await dispatch(
          addNewSpecialMix({
            description,
            employee,
            spouse,
            child,
            oneParty,
            employeeSpouse,
            employeeChild,

            family,
            twoParty,
            threeParty,
            modifiedByName: user.profile.name,
            modifiedDate: new Date().toISOString(),
            effStartDate: dateToString(startDate),
            effEndDate: nullableDateToString(endDate),
          })
        );

        unwrapResult(resultAction);

        setDescription('');
        setEmployee(0);
        setSpouse(0);
        setChild(0);
        setOneParty(0);

        setEmployeeSpouse(0);
        setEmployeeChild(0);
        setFamily(0);
        setTwoParty(0);
        setThreeParty(0);

        setEffStartDate(new Date());
        setEffEndDate('');
      } catch (err) {
        console.error('Failed to save the specialMix factor record: ', err);
      } finally {
        setAddRequestStatus('idle');
      }
    }
  };

  return (
    <Box
      px={{
        base: '4',
        md: '10',
      }}
      pb="16"
      maxWidth="3xl"
      mx="auto"
    >
      <form>
        <Stack spacing="4" divider={<StackDivider />}>
          <FieldGroup title="Add SpecialMix Record">
            <VStack width="full" spacing="6">
              <FormControl id="description">
                <FormLabel>Description:</FormLabel>
                <Input
                  type="text"
                  value={description}
                  onChange={onDescriptionChanged}
                />
              </FormControl>
              <FormControl id="employee">
                <FormLabel>Employee:</FormLabel>
                <Input
                  type="number"
                  value={employee}
                  onChange={onEmployeeChanged}
                />
              </FormControl>
              <FormControl id="spouse">
                <FormLabel>Spouse:</FormLabel>
                <Input
                  type="number"
                  value={spouse}
                  onChange={onSpouseChanged}
                />
              </FormControl>
              <FormControl id="child">
                <FormLabel>Child:</FormLabel>
                <Input type="number" value={child} onChange={onChildChanged} />
              </FormControl>

              <FormControl id="oneParty">
                <FormLabel>OneParty:</FormLabel>
                <Input
                  type="number"
                  value={oneParty}
                  onChange={onOnePartyChanged}
                />
              </FormControl>

              <FormControl id="employeeSpouse">
                <FormLabel>EmployeeSpouse:</FormLabel>
                <Input
                  type="number"
                  value={employeeSpouse}
                  onChange={onEmployeeSpouseChanged}
                />
              </FormControl>
              <FormControl id="employeeChild">
                <FormLabel>EmployeeChild:</FormLabel>
                <Input
                  type="number"
                  value={employeeChild}
                  onChange={onEmployeeChildChanged}
                />
              </FormControl>
              <FormControl id="family">
                <FormLabel>Family:</FormLabel>
                <Input
                  type="number"
                  value={family}
                  onChange={onFamilyChanged}
                />
              </FormControl>
              <FormControl id="twoParty">
                <FormLabel>Two Party:</FormLabel>
                <Input
                  type="number"
                  value={twoParty}
                  onChange={onTwoPartyChanged}
                />
              </FormControl>

              <FormControl id="threeParty">
                <FormLabel>ThreeParty:</FormLabel>
                <Input
                  type="number"
                  value={threeParty}
                  onChange={onThreePartyChanged}
                />
              </FormControl>

              <FormControl>
                <FormLabel htmlFor="effStartDate">
                  Effective Start Date
                </FormLabel>
                <DatePicker
                  id="effStartDate"
                  selectedDate={startDate}
                  onChange={onEffStartDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective Start Date</FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="effEndDate">Effective End Date</FormLabel>
                <DatePicker
                  id="effEndDate"
                  selectedDate={endDate}
                  onChange={onEffEndDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective End Date</FormHelperText>
              </FormControl>
            </VStack>
          </FieldGroup>

          <Button
            onClick={onSaveSpecialMixClicked}
            disabled={!canSave}
            variant="brand-primary"
            size="xs"
          >
            Save SpecialMix Factor Record
          </Button>
          <ErrorDisplay
            title="The record dates overlap other records!"
            errorMessage={errorMessage}
          />
        </Stack>
      </form>
    </Box>
  );
};

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
  };
}

export const AddSpecialMixFormUser =
  connect(mapStateToProps)(AddSpecialMixForm);
