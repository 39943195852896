import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Select,
  Stack,
  StackDivider,
  VStack,
} from '@chakra-ui/react';

import {
  fetchEstimatedClaimPlan,
  updateEstimatedClaimPlan,
  selectEstimatedClaimPlanById,
  selectAllEstimatedClaimPlan,
} from './estimatedClaimPlanSlice';
import { FieldGroup } from '../../components/common/FieldGroup';
import DatePicker from '../../components/common/DatePicker';
import { ErrorDisplay } from '../common/ErrorDisplay';
import { overlapCheck } from '../../services/overlapCheck';
import {
  dateToString,
  nullableDateToString,
  stringToDateFormat,
} from '../../services/dateConverters';

export const EditEstimatedClaimPlanForm = ({ match, user }) => {
  const { estimatedClaimPlanId } = match.params;
  const estimatedClaimPlan = useSelector(state =>
    selectEstimatedClaimPlanById(state, estimatedClaimPlanId)
  );
  const estimatedClaimPlanList = useSelector(selectAllEstimatedClaimPlan);

  const stdt = stringToDateFormat(estimatedClaimPlan.effStartDate);
  console.log(stdt);
  const endt = stringToDateFormat(estimatedClaimPlan.effEndDate);
  console.log(endt);

  const [estClaimDesc, setEstClaimDesc] = useState(
    estimatedClaimPlan.estClaimDesc
  );
  const [employeeFactor, setEmployeeFactor] = useState(
    estimatedClaimPlan.employeeFactor
  );
  const [spouseFactor, setSpouseFactor] = useState(
    estimatedClaimPlan.spouseFactor
  );
  const [childFactor, setChildFactor] = useState(
    estimatedClaimPlan.childFactor
  );

  const [startDate, setEffStartDate] = useState(stdt);

  const [endDate, setEffEndDate] = useState(endt);
  const [updateRequestStatus, setUpdateRequestStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();
  const history = useHistory();

  const onEstClaimDescChanged = e => {
    setEstClaimDesc(e.target.value);
    setErrorMessage('');
  };

  const onEmployeeFactorChanged = e =>
    setEmployeeFactor(Number(e.target.value));
  const onSpouseFactorChanged = e => setSpouseFactor(Number(e.target.value));
  const onChildFactorChanged = e => setChildFactor(Number(e.target.value));

  const onEffStartDateChanged = e => {
    setEffStartDate(e);
    setErrorMessage('');
  };
  const onEffEndDateChanged = e => {
    setEffEndDate(e);
    setErrorMessage('');
  };

  const canSave =
    [
      estClaimDesc,
      !isNaN(employeeFactor),
      !isNaN(spouseFactor),
      !isNaN(childFactor),
      startDate,
    ].every(Boolean) && updateRequestStatus === 'idle';

  const onSaveEstimatedClaimPlanClicked = async () => {
    if (canSave) {
      try {
        setUpdateRequestStatus('pending');

        var estimatedClaimPlanOverlap = estimatedClaimPlanList.filter(
          overlapCheck,
          {
            id: estimatedClaimPlan.id,
            effStartDate: startDate,
            effEndDate: endDate,
            keys: [
              {
                name: 'estClaimDesc',
                value: estClaimDesc,
              },
            ],
          }
        );
        if (estimatedClaimPlanOverlap.length) {
          console.log(estimatedClaimPlanOverlap);
          setErrorMessage(
            'The record being entered overlaps an existing effective date range for this key value.'
          );
          return false;
        }

        const resultAction = await dispatch(
          updateEstimatedClaimPlan({
            id: Number(estimatedClaimPlanId),
            estClaimDesc,
            employeeFactor,
            spouseFactor,
            childFactor,
            modifiedByName: user.profile.name,
            modifiedDate: new Date().toISOString(),
            effStartDate: dateToString(startDate),
            effEndDate: nullableDateToString(endDate),
          })
        );
        console.log(resultAction);
        setUpdateRequestStatus('idle');
        history.push(`/estimatedClaimPlan`);
      } catch (err) {
        setUpdateRequestStatus('idle');
        console.error('Failed to save the estimatedClaimPlan factor: ', err);
      }
    }
  };
  return (
    <Box
      px={{
        base: '4',
        md: '10',
      }}
      pb="16"
      maxWidth="3xl"
      mx="auto"
    >
      <form>
        <Stack spacing="4" divider={<StackDivider />}>
          <FieldGroup title="Edit Administration Factors">
            <VStack width="full" spacing="6">
              <FormControl id="estClaimDesc">
                <FormLabel>EstClaimDesc:</FormLabel>
                <Input
                  type="text"
                  value={estClaimDesc}
                  onChange={onEstClaimDescChanged}
                />
              </FormControl>
              <FormControl id="employeeFactor">
                <FormLabel>EmployeeFactor:</FormLabel>
                <Input
                  type="number"
                  value={employeeFactor}
                  onChange={onEmployeeFactorChanged}
                />
              </FormControl>
              <FormControl id="spouseFactor">
                <FormLabel>SpouseFactor:</FormLabel>
                <Input
                  type="number"
                  value={spouseFactor}
                  onChange={onSpouseFactorChanged}
                />
              </FormControl>
              <FormControl id="childFactor">
                <FormLabel>ChildFactor:</FormLabel>
                <Input
                  type="number"
                  value={childFactor}
                  onChange={onChildFactorChanged}
                />
              </FormControl>

              <FormControl id="effStartDate">
                <FormLabel>Effective Start Date:</FormLabel>
                <DatePicker
                  id="effStartDate"
                  selectedDate={startDate}
                  onChange={onEffStartDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective Start Date</FormHelperText>
              </FormControl>
              <FormControl id="effEndDate">
                <FormLabel>Effective End Date:</FormLabel>
                <DatePicker
                  id="effEndDate"
                  selectedDate={endDate}
                  onChange={onEffEndDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective End Date</FormHelperText>
              </FormControl>
            </VStack>
          </FieldGroup>

          <Button
            onClick={onSaveEstimatedClaimPlanClicked}
            disabled={!canSave}
            variant="brand-primary"
            size="xs"
          >
            Save EstimatedClaimPlan Factor
          </Button>
          <ErrorDisplay
            title="The record dates overlap other records!"
            errorMessage={errorMessage}
          />
        </Stack>
      </form>
    </Box>
  );
};

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
  };
}

export const EditEstimatedClaimPlanFormUser = connect(mapStateToProps)(
  EditEstimatedClaimPlanForm
);
