import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import axios from 'axios';

const rolloverAdapter = createEntityAdapter();
const apiBaseUrl = window.ENV.api_base_url;

const initialState = rolloverAdapter.getInitialState({
  status: 'idle',
  error: null,
});

export const fetchRollover = createAsyncThunk(
  'rollover/fetchRollover',
  async (_, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };

    const response = await axios.get(`${apiBaseUrl}/api/Rollover`, config);
    return response.data;
  }
);

export const addNewRollover = createAsyncThunk(
  'rollover/addNewRollover',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.post(
      `${apiBaseUrl}/api/Rollover`,
      initialState,
      config
    );
    console.log('adding new rollover record');
    console.log(response);
    return { ...initialState, id: response.data };
  }
);

export const deleteRollover = createAsyncThunk(
  'rollover/deleteRollover',
  async (rolloverId, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.delete(
      `${apiBaseUrl}/api/Rollover/${rolloverId}`,
      config
    );
    return rolloverId;
  }
);

export const updateRollover = createAsyncThunk(
  'rollover/updateRollover',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.put(
      `${apiBaseUrl}/api/Rollover/${initialState.id}`,
      initialState,
      config
    );
    return { ...initialState, id: response.data };
  }
);

const rolloverSlice = createSlice({
  name: 'rollover',
  initialState,
  extraReducers: {
    [fetchRollover.pending]: state => {
      state.status = 'loading';
    },
    [fetchRollover.fulfilled]: (state, action) => {
      // Add any fetched posts to the array
      rolloverAdapter.upsertMany(state, action.payload);
      state.status = 'succeeded';
    },
    [fetchRollover.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [addNewRollover.fulfilled]: rolloverAdapter.addOne,
    [deleteRollover.fulfilled]: rolloverAdapter.removeOne,
    [updateRollover.fulfilled]: rolloverAdapter.upsertOne,
  },
});

export default rolloverSlice.reducer;

export const {
  selectAll: selectAllRollover,
  selectById: selectRolloverById,
  selectIds: selectRolloverIds,
} = rolloverAdapter.getSelectors(state => state.rollover);

