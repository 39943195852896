import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
  AccordionIcon,
  Heading,
} from '@chakra-ui/react';
import {
  fetchOrthodonticBaseRate,
  selectOrthodonticBaseRateIds,
  selectAllOrthodonticBaseRate,
} from './orthodonticBaseRateSlice';
import { AddOrthodonticBaseRateFormUser } from './AddOrthodonticBaseRateForm';
import { OrthodonticBaseRateList } from './OrthodonticBaseRateList';
import BrandLinkButton from '../../components/common/BrandLinkButton';

export const OrthodonticBaseRatePage = () => {
  const dispatch = useDispatch();

  const orderedOrthodonticBaseRateIds = useSelector(
    selectOrthodonticBaseRateIds
  );
  const orthodonticBaseRateList = useSelector(selectAllOrthodonticBaseRate);

  const error = useSelector(state => state.orthodonticBaseRate.error);
  if (error) {
    console.log('OrthodonticBaseRate List Error:' + error.toString());
  }

  const orthodonticBaseRateStatus = useSelector(
    state => state.orthodonticBaseRate.status
  );
  const [index, setIndex] = React.useState([]);

  useEffect(() => {
    if (orthodonticBaseRateStatus === 'idle') {
      dispatch(fetchOrthodonticBaseRate());
    }
  }, [orthodonticBaseRateStatus, dispatch]);

  function closeAccordion() {
    setIndex([]);
  }

  let content;

  if (orthodonticBaseRateStatus === 'loading') {
    content = <div className="loader">Loading...</div>;
  } else if (orthodonticBaseRateStatus === 'succeeded') {
    console.log(orderedOrthodonticBaseRateIds);
    content = (
      <div>
        <Heading size="xl" mb={8}>
          Orthodontic Base Rate Factors
        </Heading>
        <Accordion
          allowMultiple
          allowToggle="true"
          mb={8}
          index={index}
          onChange={setIndex}
        >
          <AccordionItem>
            <Box>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Text variant="brand-green-link" fontSize="lg">
                    Add New Factor
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </Box>
            <AccordionPanel pb={4}>
              <AddOrthodonticBaseRateFormUser
                orthodonticBaseRateList={orthodonticBaseRateList}
                closeAccordion={closeAccordion}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <OrthodonticBaseRateList
          orderedOrthodonticBaseRateIds={orderedOrthodonticBaseRateIds}
        />
      </div>
    );
  } else if (orthodonticBaseRateStatus === 'failed') {
    content = <div>{error}</div>;
  }

  return (
    <>
      {content}
      <Box mt={12}>
        <BrandLinkButton path="/settings">Back to Settings</BrandLinkButton>
      </Box>
    </>
  );
};

export default OrthodonticBaseRatePage;
