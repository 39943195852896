import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
  AccordionIcon,
  Heading,
} from '@chakra-ui/react';
import { fetchAPRC, selectAPRCIds, selectAllAPRC } from './aPRCSlice';
import { AddAPRCFormUser } from './AddAPRCForm';
import { APRCList } from './APRCList';
import BrandLinkButton from '../../components/common/BrandLinkButton';

export const APRCPage = () => {
  const dispatch = useDispatch();

  const orderedAPRCIds = useSelector(selectAPRCIds);
  const aPRCList = useSelector(selectAllAPRC);

  const error = useSelector(state => state.aPRC.error);
  if (error) {
    console.log('APRC List Error:' + error.toString());
  }

  const aPRCStatus = useSelector(state => state.aPRC.status);
  const [index, setIndex] = React.useState([]);

  useEffect(() => {
    if (aPRCStatus === 'idle') {
      dispatch(fetchAPRC());
    }
  }, [aPRCStatus, dispatch]);

  function closeAccordion() {
    setIndex([]);
  }

  let content;

  if (aPRCStatus === 'loading') {
    content = <div className="loader">Loading...</div>;
  } else if (aPRCStatus === 'succeeded') {
    console.log(orderedAPRCIds);
    content = (
      <div>
        <Heading size="xl" mb={8}>
          Affiliate Product Rate Change Factors
        </Heading>
        <Accordion
          allowMultiple
          allowToggle="true"
          mb={8}
          index={index}
          onChange={setIndex}
        >
          <AccordionItem>
            <Box>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Text variant="brand-green-link" fontSize="lg">
                    Add New Factor
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </Box>
            <AccordionPanel pb={4}>
              <AddAPRCFormUser
                aPRCList={aPRCList}
                closeAccordion={closeAccordion}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <APRCList orderedAPRCIds={orderedAPRCIds} />
      </div>
    );
  } else if (aPRCStatus === 'failed') {
    content = <div>{error}</div>;
  }

  return (
    <>
      {content}
      <Box mt={12}>
        <BrandLinkButton path="/settings">Back to Settings</BrandLinkButton>
      </Box>
    </>
  );
};

export default APRCPage;
