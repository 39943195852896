import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Select,
  Stack,
  StackDivider,
  VStack,
} from '@chakra-ui/react';

import {
  fetchConstantsAdmin,
  updateConstantsAdmin,
  selectConstantsAdminById,
  selectAllConstantsAdmin,
} from './constantsAdminSlice';
import { FieldGroup } from '../../components/common/FieldGroup';
import DatePicker from '../../components/common/DatePicker';
import { ErrorDisplay } from '../common/ErrorDisplay';
import { overlapCheck } from '../../services/overlapCheck';
import {
  dateToString,
  nullableDateToString,
  stringToDateFormat,
} from '../../services/dateConverters';

export const EditConstantsAdminForm = ({ match, user }) => {
  const { constantsAdminId } = match.params;
  const constantsAdmin = useSelector(state =>
    selectConstantsAdminById(state, constantsAdminId)
  );
  const constantsAdminList = useSelector(selectAllConstantsAdmin);

  const stdt = stringToDateFormat(constantsAdmin.effStartDate);
  console.log(stdt);
  const endt = stringToDateFormat(constantsAdmin.effEndDate);
  console.log(endt);

  const [constantsAdminNumber, setConstantsAdminNumber] = useState(
    constantsAdmin.constantsAdminNumber
  );

  const [generalAgent, setGeneralAgent] = useState(constantsAdmin.generalAgent);
  const [adminTrend, setAdminTrend] = useState(constantsAdmin.adminTrend);
  const [interestIncome, setInterestIncome] = useState(
    constantsAdmin.interestIncome
  );
  const [premiumPaymentLostInterest, setPremiumPaymentLostInterest] = useState(
    constantsAdmin.premiumPaymentLostInterest
  );
  const [twoYearWaitingPeriodUnder1000, setTwoYearWaitingPeriodUnder1000] =
    useState(constantsAdmin.twoYearWaitingPeriodUnder1000);

  const [twoYearWaitingPeriodOver1000, setTwoYearWaitingPeriodOver1000] =
    useState(constantsAdmin.twoYearWaitingPeriodOver1000);
  const [dpoPanelFeeUnder1000, setDpoPanelFeeUnder1000] = useState(
    constantsAdmin.dpoPanelFeeUnder1000
  );
  const [dpoPanelFeeOver1000, setDpoPanelFeeOver1000] = useState(
    constantsAdmin.dpoPanelFeeOver1000
  );
  const [perTransactionNoWithHold, setPerTransactionNoWithHold] = useState(
    constantsAdmin.perTransactionNoWithHold
  );
  const [perTransactionNpfUsage, setPerTransactionNpfUsage] = useState(
    constantsAdmin.perTransactionNpfUsage
  );
  const [allowedSubGroups1000, setAllowedSubGroups1000] = useState(
    constantsAdmin.allowedSubGroups1000
  );
  const [allowedSubGroups10001, setAllowedSubGroups10001] = useState(
    constantsAdmin.allowedSubGroups10001
  );
  const [lostInterest1Month, setLostInterest1Month] = useState(
    constantsAdmin.lostInterest1Month
  );
  const [lostInterest2Month, setLostInterest2Month] = useState(
    constantsAdmin.lostInterest2Month
  );
  const [lostInterestWeekly, setLostInterestWeekly] = useState(
    constantsAdmin.lostInterestWeekly
  );
  const [additionalRiskPremiumDelay, setAdditionalRiskPremiumDelay] = useState(
    constantsAdmin.additionalRiskPremiumDelay
  );
  const [additionalRiskRetention, setAdditionalRiskRetention] = useState(
    constantsAdmin.additionalRiskRetention
  );
  const [additionalRiskRetroRetention, setAdditionalRiskRetroRetention] =
    useState(constantsAdmin.additionalRiskRetroRetention);
  const [retentionDollarAmount, setRetentionDollarAmount] = useState(
    constantsAdmin.retentionDollarAmount
  );

  const [amount, setAmount] = useState(constantsAdmin.amount);
  const [maximum, setMaximum] = useState(constantsAdmin.maximum);
  const [factor, setFactor] = useState(constantsAdmin.factor);
  const [risk, setRisk] = useState(constantsAdmin.risk);
  const [aso2500, setAso2500] = useState(constantsAdmin.aso2500);
  const [aso25004999, setAso25004999] = useState(constantsAdmin.aso25004999);
  const [aso5000, setAso5000] = useState(constantsAdmin.aso5000);
  const [unknown0To999, setUnknown0To999] = useState(
    constantsAdmin.unknown0To999
  );
  const [unknownGT1000, setUnknownGT1000] = useState(
    constantsAdmin.unknownGT1000
  );
  const [additional, setAdditional] = useState(constantsAdmin.additional);
  const [perEligible, setPerEligible] = useState(constantsAdmin.perEligible);
  const [sublocation, setSublocation] = useState(constantsAdmin.sublocation);
  const [passThroughRateUnder800, setPassThroughRateUnder800] = useState(
    constantsAdmin.passThroughRateUnder800
  );
  const [passThroughRateOver800, setPassThroughRateOver800] = useState(
    constantsAdmin.passThroughRateOver800
  );
  const [perClaimDpo, setPerClaimDpo] = useState(constantsAdmin.perClaimDpo);
  const [perClaimNonDpo, setPerClaimNonDpo] = useState(
    constantsAdmin.perClaimNonDpo
  );

  const [perSubscriber, setPerSubscriber] = useState(
    constantsAdmin.perSubscriber
  );
  const [subGroupCharge, setSubGroupCharge] = useState(
    constantsAdmin.subGroupCharge
  );
  const [ofPaidClaims, setOfPaidClaims] = useState(constantsAdmin.ofPaidClaims);
  const [subsidy, setSubsidy] = useState(constantsAdmin.subsidy);
  const [asoProfitLoad, setAsoProfitLoad] = useState(
    constantsAdmin.asoProfitLoad
  );
  const [charge, setCharge] = useState(constantsAdmin.charge);
  const [lT10001, setLT10001] = useState(constantsAdmin.lT10001);
  const [range1000To4999, setRange1000To4999] = useState(
    constantsAdmin.range1000To4999
  );
  const [gT5000, setGT5000] = useState(constantsAdmin.gT5000);

  const [startDate, setEffStartDate] = useState(stdt);

  const [endDate, setEffEndDate] = useState(endt);
  const [updateRequestStatus, setUpdateRequestStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();
  const history = useHistory();

  const onGeneralAgentChanged = e => {
    setGeneralAgent(Number(e.target.value));
    setErrorMessage('');
  };

  const onAdminTrendChanged = e => setAdminTrend(Number(e.target.value));
  const onInterestIncomeChanged = e =>
    setInterestIncome(Number(e.target.value));
  const onPremiumPaymentLostInterestChanged = e =>
    setPremiumPaymentLostInterest(Number(e.target.value));
  const onTwoYearWaitingPeriodUnder1000Changed = e =>
    setTwoYearWaitingPeriodUnder1000(Number(e.target.value));

  const onTwoYearWaitingPeriodOver1000Changed = e =>
    setTwoYearWaitingPeriodOver1000(Number(e.target.value));
  const onDpoPanelFeeUnder1000Changed = e =>
    setDpoPanelFeeUnder1000(Number(Number(e.target.value)));
  const onDpoPanelFeeOver1000Changed = e =>
    setDpoPanelFeeOver1000(Number(e.target.value));
  const onPerTransactionNoWithHoldChanged = e =>
    setPerTransactionNoWithHold(Number(e.target.value));
  const onPerTransactionNpfUsageChanged = e =>
    setPerTransactionNpfUsage(Number(e.target.value));
  const onAllowedSubGroups1000Changed = e =>
    setAllowedSubGroups1000(Number(e.target.value));
  const onAllowedSubGroups10001Changed = e =>
    setAllowedSubGroups10001(Number(e.target.value));
  const onLostInterest1MonthChanged = e =>
    setLostInterest1Month(Number(e.target.value));
  const onLostInterest2MonthChanged = e =>
    setLostInterest2Month(Number(e.target.value));
  const onLostInterestWeeklyChanged = e =>
    setLostInterestWeekly(Number(e.target.value));
  const onAdditionalRiskPremiumDelayChanged = e =>
    setAdditionalRiskPremiumDelay(Number(e.target.value));
  const onAdditionalRiskRetentionChanged = e =>
    setAdditionalRiskRetention(Number(e.target.value));
  const onAdditionalRiskRetroRetentionChanged = e =>
    setAdditionalRiskRetroRetention(Number(e.target.value));
  const onRetentionDollarAmountChanged = e =>
    setRetentionDollarAmount(Number(e.target.value));

  const onAmountChanged = e => setAmount(Number(e.target.value));
  const onMaximumChanged = e => setMaximum(Number(e.target.value));
  const onFactorChanged = e => setFactor(Number(e.target.value));
  const onRiskChanged = e => setRisk(Number(e.target.value));
  const onAso2500Changed = e => setAso2500(Number(e.target.value));
  const onAso25004999Changed = e => setAso25004999(Number(e.target.value));
  const onAso5000Changed = e => setAso5000(Number(e.target.value));
  const onUnknown0To999Changed = e => setUnknown0To999(Number(e.target.value));
  const onUnknownGT1000Changed = e => setUnknownGT1000(Number(e.target.value));
  const onAdditionalChanged = e => setAdditional(Number(e.target.value));
  const onPerEligibleChanged = e => setPerEligible(Number(e.target.value));
  const onSublocationChanged = e => setSublocation(Number(e.target.value));
  const onPassThroughRateUnder800Changed = e =>
    setPassThroughRateUnder800(Number(e.target.value));
  const onPassThroughRateOver800Changed = e =>
    setPassThroughRateOver800(Number(e.target.value));
  const onPerClaimDpoChanged = e => setPerClaimDpo(Number(e.target.value));
  const onPerClaimNonDpoChanged = e =>
    setPerClaimNonDpo(Number(e.target.value));
  const onPerSubscriberChanged = e => setPerSubscriber(Number(e.target.value));
  const onSubGroupChargeChanged = e =>
    setSubGroupCharge(Number(e.target.value));
  const onOfPaidClaimsChanged = e => setOfPaidClaims(Number(e.target.value));
  const onSubsidyChanged = e => setSubsidy(Number(e.target.value));
  const onAsoProfitLoadChanged = e => setAsoProfitLoad(Number(e.target.value));
  const onChargeChanged = e => setCharge(Number(e.target.value));
  const onLT10001Changed = e => setLT10001(Number(e.target.value));
  const onRange1000To4999Changed = e =>
    setRange1000To4999(Number(e.target.value));
  const onGT5000Changed = e => setGT5000(Number(e.target.value));

  const onEffStartDateChanged = e => {
    setEffStartDate(e);
    setErrorMessage('');
  };
  const onEffEndDateChanged = e => {
    setEffEndDate(e);
    setErrorMessage('');
  };

  const canSave =
    [
      !isNaN(generalAgent),
      !isNaN(adminTrend),
      !isNaN(interestIncome),
      !isNaN(premiumPaymentLostInterest),
      !isNaN(twoYearWaitingPeriodUnder1000),
      !isNaN(twoYearWaitingPeriodOver1000),
      !isNaN(dpoPanelFeeUnder1000),
      !isNaN(dpoPanelFeeOver1000),
      !isNaN(perTransactionNoWithHold),
      !isNaN(perTransactionNpfUsage),
      !isNaN(allowedSubGroups1000),
      !isNaN(allowedSubGroups10001),
      !isNaN(lostInterest1Month),
      !isNaN(lostInterest2Month),
      !isNaN(lostInterestWeekly),
      !isNaN(additionalRiskPremiumDelay),
      !isNaN(additionalRiskRetention),
      !isNaN(additionalRiskRetroRetention),
      !isNaN(retentionDollarAmount),
      !isNaN(amount),
      !isNaN(maximum),
      !isNaN(factor),
      !isNaN(risk),
      !isNaN(aso2500),
      !isNaN(aso25004999),
      !isNaN(aso5000),
      !isNaN(unknown0To999),
      !isNaN(unknownGT1000),
      !isNaN(additional),
      !isNaN(perEligible),
      !isNaN(sublocation),
      !isNaN(passThroughRateUnder800),
      !isNaN(passThroughRateOver800),
      !isNaN(perClaimDpo),
      !isNaN(perClaimNonDpo),
      !isNaN(perSubscriber),
      !isNaN(subGroupCharge),
      !isNaN(ofPaidClaims),
      !isNaN(subsidy),
      !isNaN(asoProfitLoad),
      !isNaN(charge),
      !isNaN(lT10001),
      !isNaN(range1000To4999),
      !isNaN(gT5000),
      startDate,
    ].every(Boolean) && updateRequestStatus === 'idle';

  const onSaveConstantsAdminClicked = async () => {
    if (canSave) {
      try {
        setUpdateRequestStatus('pending');

        var constantsAdminOverlap = constantsAdminList.filter(overlapCheck, {
          id: constantsAdmin.id,
          effStartDate: startDate,
          effEndDate: endDate,
          keys: [
            {
              name: 'generalAgent',
              value: generalAgent,
            },
          ],
        });
        if (constantsAdminOverlap.length) {
          console.log(constantsAdminOverlap);
          setErrorMessage(
            'The record being entered overlaps an existing effective date range for this key value.'
          );
          return false;
        }

        const resultAction = await dispatch(
          updateConstantsAdmin({
            id: Number(constantsAdminId),
            generalAgent,
            adminTrend,
            interestIncome,
            premiumPaymentLostInterest,
            twoYearWaitingPeriodUnder1000,
            twoYearWaitingPeriodOver1000,
            dpoPanelFeeUnder1000,
            dpoPanelFeeOver1000,
            perTransactionNoWithHold,
            perTransactionNpfUsage,
            allowedSubGroups1000,
            allowedSubGroups10001,
            lostInterest1Month,
            lostInterest2Month,
            lostInterestWeekly,
            additionalRiskPremiumDelay,
            additionalRiskRetention,
            additionalRiskRetroRetention,
            retentionDollarAmount,
            amount,
            maximum,
            factor,
            risk,
            aso2500,
            aso25004999,
            aso5000,
            unknown0To999,
            unknownGT1000,
            additional,
            perEligible,
            sublocation,
            passThroughRateUnder800,
            passThroughRateOver800,
            perClaimDpo,
            perClaimNonDpo,
            perSubscriber,
            subGroupCharge,
            ofPaidClaims,
            subsidy,
            asoProfitLoad,
            charge,
            lT10001,
            range1000To4999,
            gT5000,
            modifiedByName: user.profile.name,
            modifiedDate: new Date().toISOString(),
            effStartDate: dateToString(startDate),
            effEndDate: nullableDateToString(endDate),
          })
        );
        console.log(resultAction);
        setUpdateRequestStatus('idle');
        history.push(`/constantsAdmin`);
      } catch (err) {
        setUpdateRequestStatus('idle');
        console.error('Failed to save the constantsAdmin factor: ', err);
      }
    }
  };
  return (
    <Box
      px={{
        base: '4',
        md: '10',
      }}
      pb="16"
      maxWidth="3xl"
      mx="auto"
    >
      <form>
        <Stack spacing="4" divider={<StackDivider />}>
          <FieldGroup title="Edit Administration Factors">
            <VStack width="full" spacing="6">
              <FormControl id="generalAgent">
                <FormLabel>GeneralAgent:</FormLabel>
                <Input
                  type="number"
                  value={generalAgent}
                  onChange={onGeneralAgentChanged}
                />
              </FormControl>
              <FormControl id="adminTrend">
                <FormLabel>AdminTrend:</FormLabel>
                <Input
                  type="number"
                  value={adminTrend}
                  onChange={onAdminTrendChanged}
                />
              </FormControl>
              <FormControl id="interestIncome">
                <FormLabel>InterestIncome:</FormLabel>
                <Input
                  type="number"
                  value={interestIncome}
                  onChange={onInterestIncomeChanged}
                />
              </FormControl>
              <FormControl id="premiumPaymentLostInterest">
                <FormLabel>PremiumPaymentLostInterest:</FormLabel>
                <Input
                  type="number"
                  value={premiumPaymentLostInterest}
                  onChange={onPremiumPaymentLostInterestChanged}
                />
              </FormControl>

              <FormControl id="twoYearWaitingPeriodUnder1000">
                <FormLabel>TwoYearWaitingPeriodUnder1000:</FormLabel>
                <Input
                  type="number"
                  value={twoYearWaitingPeriodUnder1000}
                  onChange={onTwoYearWaitingPeriodUnder1000Changed}
                />
              </FormControl>
              <FormControl id="twoYearWaitingPeriodOver1000">
                <FormLabel>TwoYearWaitingPeriodOver1000:</FormLabel>
                <Input
                  type="text"
                  value={twoYearWaitingPeriodOver1000}
                  onChange={onTwoYearWaitingPeriodOver1000Changed}
                />
              </FormControl>
              <FormControl id="dpoPanelFeeUnder1000">
                <FormLabel>DpoPanelFeeUnder1000:</FormLabel>
                <Input
                  type="number"
                  value={dpoPanelFeeUnder1000}
                  onChange={onDpoPanelFeeUnder1000Changed}
                />
              </FormControl>

              <FormControl id="dpoPanelFeeOver1000">
                <FormLabel>DpoPanelFeeOver1000:</FormLabel>
                <Input
                  type="number"
                  value={dpoPanelFeeOver1000}
                  onChange={onDpoPanelFeeOver1000Changed}
                />
              </FormControl>
              <FormControl id="perTransactionNoWithHold">
                <FormLabel>PerTransactionNoWithHold:</FormLabel>
                <Input
                  type="number"
                  value={perTransactionNoWithHold}
                  onChange={onPerTransactionNoWithHoldChanged}
                />
              </FormControl>
              <FormControl id="perTransactionNpfUsage">
                <FormLabel>PerTransactionNpfUsage:</FormLabel>
                <Input
                  type="number"
                  value={perTransactionNpfUsage}
                  onChange={onPerTransactionNpfUsageChanged}
                />
              </FormControl>
              <FormControl id="allowedSubGroups1000">
                <FormLabel>AllowedSubGroups1000:</FormLabel>
                <Input
                  type="number"
                  value={allowedSubGroups1000}
                  onChange={onAllowedSubGroups1000Changed}
                />
              </FormControl>

              <FormControl id="allowedSubGroups10001">
                <FormLabel>AllowedSubGroups10001:</FormLabel>
                <Input
                  type="number"
                  value={allowedSubGroups10001}
                  onChange={onAllowedSubGroups10001Changed}
                />
              </FormControl>
              <FormControl id="lostInterest1Month">
                <FormLabel>LostInterest1Month:</FormLabel>
                <Input
                  type="text"
                  value={lostInterest1Month}
                  onChange={onLostInterest1MonthChanged}
                />
              </FormControl>
              <FormControl id="lostInterest2Month">
                <FormLabel>LostInterest2Month:</FormLabel>
                <Input
                  type="number"
                  value={lostInterest2Month}
                  onChange={onLostInterest2MonthChanged}
                />
              </FormControl>

              <FormControl id="lostInterestWeekly">
                <FormLabel>LostInterestWeekly:</FormLabel>
                <Input
                  type="number"
                  value={lostInterestWeekly}
                  onChange={onLostInterestWeeklyChanged}
                />
              </FormControl>
              <FormControl id="additionalRiskPremiumDelay">
                <FormLabel>AdditionalRiskPremiumDelay:</FormLabel>
                <Input
                  type="number"
                  value={additionalRiskPremiumDelay}
                  onChange={onAdditionalRiskPremiumDelayChanged}
                />
              </FormControl>
              <FormControl id="additionalRiskRetention">
                <FormLabel>AdditionalRiskRetention:</FormLabel>
                <Input
                  type="number"
                  value={additionalRiskRetention}
                  onChange={onAdditionalRiskRetentionChanged}
                />
              </FormControl>
              <FormControl id="additionalRiskRetroRetention">
                <FormLabel>AdditionalRiskRetroRetention:</FormLabel>
                <Input
                  type="number"
                  value={additionalRiskRetroRetention}
                  onChange={onAdditionalRiskRetroRetentionChanged}
                />
              </FormControl>

              <FormControl id="retentionDollarAmount">
                <FormLabel>RetentionDollarAmount:</FormLabel>
                <Input
                  type="number"
                  value={retentionDollarAmount}
                  onChange={onRetentionDollarAmountChanged}
                />
              </FormControl>

              <FormControl id="amount">
                <FormLabel>Amount:</FormLabel>
                <Input
                  type="number"
                  value={amount}
                  onChange={onAmountChanged}
                />
              </FormControl>
              <FormControl id="maximum">
                <FormLabel>Maximum:</FormLabel>
                <Input
                  type="number"
                  value={maximum}
                  onChange={onMaximumChanged}
                />
              </FormControl>
              <FormControl id="factor">
                <FormLabel>Factor:</FormLabel>
                <Input
                  type="number"
                  value={factor}
                  onChange={onFactorChanged}
                />
              </FormControl>
              <FormControl id="risk">
                <FormLabel>Risk:</FormLabel>
                <Input type="number" value={risk} onChange={onRiskChanged} />
              </FormControl>

              <FormControl id="aso2500">
                <FormLabel>Aso2500:</FormLabel>
                <Input
                  type="number"
                  value={aso2500}
                  onChange={onAso2500Changed}
                />
              </FormControl>
              <FormControl id="aso25004999">
                <FormLabel>Aso25004999:</FormLabel>
                <Input
                  type="text"
                  value={aso25004999}
                  onChange={onAso25004999Changed}
                />
              </FormControl>
              <FormControl id="aso5000">
                <FormLabel>Aso5000:</FormLabel>
                <Input
                  type="number"
                  value={aso5000}
                  onChange={onAso5000Changed}
                />
              </FormControl>

              <FormControl id="unknown0To999">
                <FormLabel>Unknown0To999:</FormLabel>
                <Input
                  type="number"
                  value={unknown0To999}
                  onChange={onUnknown0To999Changed}
                />
              </FormControl>
              <FormControl id="unknownGT1000">
                <FormLabel>UnknownGT1000:</FormLabel>
                <Input
                  type="number"
                  value={unknownGT1000}
                  onChange={onUnknownGT1000Changed}
                />
              </FormControl>
              <FormControl id="additional">
                <FormLabel>Additional:</FormLabel>
                <Input
                  type="number"
                  value={additional}
                  onChange={onAdditionalChanged}
                />
              </FormControl>
              <FormControl id="perEligible">
                <FormLabel>PerEligible:</FormLabel>
                <Input
                  type="number"
                  value={perEligible}
                  onChange={onPerEligibleChanged}
                />
              </FormControl>

              <FormControl id="sublocation">
                <FormLabel>Sublocation:</FormLabel>
                <Input
                  type="number"
                  value={sublocation}
                  onChange={onSublocationChanged}
                />
              </FormControl>
              <FormControl id="passThroughRateUnder800">
                <FormLabel>PassThroughRateUnder800:</FormLabel>
                <Input
                  type="text"
                  value={passThroughRateUnder800}
                  onChange={onPassThroughRateUnder800Changed}
                />
              </FormControl>
              <FormControl id="passThroughRateOver800">
                <FormLabel>PassThroughRateOver800:</FormLabel>
                <Input
                  type="number"
                  value={passThroughRateOver800}
                  onChange={onPassThroughRateOver800Changed}
                />
              </FormControl>

              <FormControl id="perClaimDpo">
                <FormLabel>PerClaimDpo:</FormLabel>
                <Input
                  type="number"
                  value={perClaimDpo}
                  onChange={onPerClaimDpoChanged}
                />
              </FormControl>
              <FormControl id="perClaimNonDpo">
                <FormLabel>PerClaimNonDpo:</FormLabel>
                <Input
                  type="number"
                  value={perClaimNonDpo}
                  onChange={onPerClaimNonDpoChanged}
                />
              </FormControl>
              <FormControl id="perSubscriber">
                <FormLabel>PerSubscriber:</FormLabel>
                <Input
                  type="number"
                  value={perSubscriber}
                  onChange={onPerSubscriberChanged}
                />
              </FormControl>
              <FormControl id="subGroupCharge">
                <FormLabel>SubGroupCharge:</FormLabel>
                <Input
                  type="number"
                  value={subGroupCharge}
                  onChange={onSubGroupChargeChanged}
                />
              </FormControl>
              <FormControl id="ofPaidClaims">
                <FormLabel>OfPaidClaims:</FormLabel>
                <Input
                  type="number"
                  value={ofPaidClaims}
                  onChange={onOfPaidClaimsChanged}
                />
              </FormControl>
              <FormControl id="subsidy">
                <FormLabel>Subsidy</FormLabel>
                <Input
                  type="number"
                  value={subsidy}
                  onChange={onSubsidyChanged}
                />
              </FormControl>
              <FormControl id="asoProfitLoad">
                <FormLabel>AsoProfitLoad:</FormLabel>
                <Input
                  type="number"
                  value={asoProfitLoad}
                  onChange={onAsoProfitLoadChanged}
                />
              </FormControl>
              <FormControl id="charge">
                <FormLabel>Charge:</FormLabel>
                <Input
                  type="number"
                  value={charge}
                  onChange={onChargeChanged}
                />
              </FormControl>
              <FormControl id="lT10001">
                <FormLabel>LT10001:</FormLabel>
                <Input
                  type="number"
                  value={lT10001}
                  onChange={onLT10001Changed}
                />
              </FormControl>
              <FormControl id="range1000To4999">
                <FormLabel>Range1000To4999:</FormLabel>
                <Input
                  type="number"
                  value={range1000To4999}
                  onChange={onRange1000To4999Changed}
                />
              </FormControl>
              <FormControl id="gT5000">
                <FormLabel>GT5000:</FormLabel>
                <Input
                  type="number"
                  value={gT5000}
                  onChange={onGT5000Changed}
                />
              </FormControl>

              <FormControl id="effStartDate">
                <FormLabel>Effective Start Date:</FormLabel>
                <DatePicker
                  id="effStartDate"
                  selectedDate={startDate}
                  onChange={onEffStartDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective Start Date</FormHelperText>
              </FormControl>
              <FormControl id="effEndDate">
                <FormLabel>Effective End Date:</FormLabel>
                <DatePicker
                  id="effEndDate"
                  selectedDate={endDate}
                  onChange={onEffEndDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective End Date</FormHelperText>
              </FormControl>
            </VStack>
          </FieldGroup>

          <Button
            onClick={onSaveConstantsAdminClicked}
            disabled={!canSave}
            variant="brand-primary"
            size="xs"
          >
            Save Constants Admin Factor
          </Button>
          <ErrorDisplay
            title="The record dates overlap other records!"
            errorMessage={errorMessage}
          />
        </Stack>
      </form>
    </Box>
  );
};

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
  };
}

export const EditConstantsAdminFormUser = connect(mapStateToProps)(
  EditConstantsAdminForm
);
