import { Box, HStack } from '@chakra-ui/react';
import * as React from 'react';
import { BsCaretRightFill } from 'react-icons/bs';
import { NavLink } from 'react-router-dom';

export const NavItem = props => {
  const { active, subtle, icon, children, label, endElement, url, onClick } = props;
  return (
    <HStack
      w="full"
      px="3"
      py="2"
      cursor="pointer"
      userSelect="none"
      rounded="md"
      transition="all 0.2s"
      bg={active ? 'white' : undefined}
      color={active ? 'brand_green.600' : undefined}
      _hover={{
        bg: 'white',
        color: 'brand_green.600',
      }}
      _active={{
        bg: 'white',
        color: 'brand_green.600',
      }}
      onClick={onClick}
      as={NavLink}
      to={url}
    >
      <Box
        fontSize="lg"
        color={active ? 'brand_green.600' : 'currentcolor'}
        _hover={{ color: 'brand_green.600' }}
      >
        {icon}
      </Box>
      <Box
        flex="1"
        fontWeight="inherit"
        color={subtle ? 'gray.400' : undefined}
      >
        {label}
      </Box>
      {endElement && !children && <Box>{endElement}</Box>}
      {children && <Box fontSize="xs" flexShrink={0} as={BsCaretRightFill} />}
    </HStack>
  );
};
