import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import axios from 'axios';

const aPRCAdapter = createEntityAdapter();
const apiBaseUrl = window.ENV.api_base_url;

const initialState = aPRCAdapter.getInitialState({
  status: 'idle',
  error: null,
});

export const fetchAPRC = createAsyncThunk(
  'aPRC/fetchAPRC',
  async (_, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };

    const response = await axios.get(`${apiBaseUrl}/api/APRC`, config);
    return response.data;
  }
);

export const addNewAPRC = createAsyncThunk(
  'aPRC/addNewAPRC',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.post(
      `${apiBaseUrl}/api/APRC`,
      initialState,
      config
    );
    console.log('adding new aPRC record');
    console.log(response);
    return { ...initialState, id: response.data };
  }
);

export const deleteAPRC = createAsyncThunk(
  'aPRC/deleteAPRC',
  async (aPRCId, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.delete(
      `${apiBaseUrl}/api/APRC/${aPRCId}`,
      config
    );
    return aPRCId;
  }
);

export const updateAPRC = createAsyncThunk(
  'aPRC/updateAPRC',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.put(
      `${apiBaseUrl}/api/APRC/${initialState.id}`,
      initialState,
      config
    );
    return { ...initialState, id: response.data };
  }
);

const aPRCSlice = createSlice({
  name: 'aPRC',
  initialState,
  extraReducers: {
    [fetchAPRC.pending]: state => {
      state.status = 'loading';
    },
    [fetchAPRC.fulfilled]: (state, action) => {
      // Add any fetched posts to the array
      aPRCAdapter.upsertMany(state, action.payload);
      state.status = 'succeeded';
    },
    [fetchAPRC.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [addNewAPRC.fulfilled]: aPRCAdapter.addOne,
    [deleteAPRC.fulfilled]: aPRCAdapter.removeOne,
    [updateAPRC.fulfilled]: aPRCAdapter.upsertOne,
  },
});

export default aPRCSlice.reducer;

export const {
  selectAll: selectAllAPRC,
  selectById: selectAPRCById,
  selectIds: selectAPRCIds,
} = aPRCAdapter.getSelectors(state => state.aPRC);
