import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import axios from 'axios';

const areaAdapter = createEntityAdapter();
const apiBaseUrl = window.ENV.api_base_url;

const initialState = areaAdapter.getInitialState({
  status: 'idle',
  error: null,
});

export const fetchArea = createAsyncThunk(
  'area/fetchArea',
  async (_, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };

    const response = await axios.get(`${apiBaseUrl}/api/Area`, config);
    return response.data;
  }
);

export const addNewArea = createAsyncThunk(
  'area/addNewArea',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.post(
      `${apiBaseUrl}/api/Area`,
      initialState,
      config
    );
    console.log('adding new area record');
    console.log(response);
    return { ...initialState, id: response.data };
  }
);

export const deleteArea = createAsyncThunk(
  'area/deleteArea',
  async (areaId, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.delete(
      `${apiBaseUrl}/api/Area/${areaId}`,
      config
    );
    return areaId;
  }
);

export const updateArea = createAsyncThunk(
  'area/updateArea',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.put(
      `${apiBaseUrl}/api/Area/${initialState.id}`,
      initialState,
      config
    );
    return { ...initialState, id: response.data };
  }
);

const areaSlice = createSlice({
  name: 'area',
  initialState,
  extraReducers: {
    [fetchArea.pending]: state => {
      state.status = 'loading';
    },
    [fetchArea.fulfilled]: (state, action) => {
      // Add any fetched posts to the array
      areaAdapter.upsertMany(state, action.payload);
      state.status = 'succeeded';
    },
    [fetchArea.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [addNewArea.fulfilled]: areaAdapter.addOne,
    [deleteArea.fulfilled]: areaAdapter.removeOne,
    [updateArea.fulfilled]: areaAdapter.upsertOne,
  },
});

export default areaSlice.reducer;

export const {
  selectAll: selectAllArea,
  selectById: selectAreaById,
  selectIds: selectAreaIds,
} = areaAdapter.getSelectors(state => state.area);
