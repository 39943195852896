import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
  AccordionIcon,
  Heading,
} from '@chakra-ui/react';
import {
  fetchEstimatedClaim,
  selectEstimatedClaimIds,
  selectAllEstimatedClaim,
} from './estimatedClaimSlice';
import { AddEstimatedClaimFormUser } from './AddEstimatedClaimForm';
import { EstimatedClaimList } from './EstimatedClaimList';
import BrandLinkButton from '../../components/common/BrandLinkButton';

export const EstimatedClaimPage = () => {
  const dispatch = useDispatch();

  const orderedEstimatedClaimIds = useSelector(selectEstimatedClaimIds);
  const estimatedClaimList = useSelector(selectAllEstimatedClaim);

  const error = useSelector(state => state.estimatedClaim.error);
  if (error) {
    console.log('EstimatedClaim List Error:' + error.toString());
  }

  const estimatedClaimStatus = useSelector(
    state => state.estimatedClaim.status
  );
  const [index, setIndex] = React.useState([]);

  useEffect(() => {
    if (estimatedClaimStatus === 'idle') {
      dispatch(fetchEstimatedClaim());
    }
  }, [estimatedClaimStatus, dispatch]);

  function closeAccordion() {
    setIndex([]);
  }

  let content;

  if (estimatedClaimStatus === 'loading') {
    content = <div className="loader">Loading...</div>;
  } else if (estimatedClaimStatus === 'succeeded') {
    console.log(orderedEstimatedClaimIds);
    content = (
      <div>
        <Heading size="xl" mb={8}>
          Estimated Claim Factors
        </Heading>
        <Accordion
          allowMultiple
          allowToggle="true"
          mb={8}
          index={index}
          onChange={setIndex}
        >
          <AccordionItem>
            <Box>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Text variant="brand-green-link" fontSize="lg">
                    Add New Factor
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </Box>
            <AccordionPanel pb={4}>
              <AddEstimatedClaimFormUser
                estimatedClaimList={estimatedClaimList}
                closeAccordion={closeAccordion}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <EstimatedClaimList
          orderedEstimatedClaimIds={orderedEstimatedClaimIds}
        />
      </div>
    );
  } else if (estimatedClaimStatus === 'failed') {
    content = <div>{error}</div>;
  }

  return (
    <>
      {content}
      <Box mt={12}>
        <BrandLinkButton path="/settings">Back to Settings</BrandLinkButton>
      </Box>
    </>
  );
};

export default EstimatedClaimPage;
