import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Select,
  Stack,
  StackDivider,
  VStack,
} from '@chakra-ui/react';

import {
  fetchRollover,
  updateRollover,
  selectRolloverById,
  selectAllRollover,
} from './rolloverSlice';
import { FieldGroup } from '../../components/common/FieldGroup';
import DatePicker from '../../components/common/DatePicker';
import { ErrorDisplay } from '../common/ErrorDisplay';
import { overlapCheck } from '../../services/overlapCheck';
import {
  dateToString,
  nullableDateToString,
  stringToDateFormat,
} from '../../services/dateConverters';

export const EditRolloverForm = ({ match, user }) => {
  const { rolloverId } = match.params;
  const roller = useSelector(state => selectRolloverById(state, rolloverId));
  const rolloverList = useSelector(selectAllRollover);

  const stdt = stringToDateFormat(roller.effStartDate);
  console.log(stdt);
  const endt = stringToDateFormat(roller.effEndDate);
  console.log(endt);

  const [generalMax, setGeneralMax] = useState(roller.generalMax);
  const [threshold, setThreshold] = useState(roller.threshold);
  const [annualCarryover, setAnnualCarryover] = useState(
    roller.annualCarryover
  );
  const [maxCarryover, setMaxCarryover] = useState(roller.maxCarryover);
  const [max, setMax] = useState(roller.max);
  const [reqServices, setReqServices] = useState(roller.reqServices);
  const [factorAmt, setFactorAmt] = useState(roller.factorAmt);
  const [startDate, setEffStartDate] = useState(stdt);
  const [endDate, setEffEndDate] = useState(endt);
  const [updateRequestStatus, setUpdateRequestStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();
  const history = useHistory();

  const onGeneralMaxChanged = e => {
    setGeneralMax(Number(e.target.value));
    setErrorMessage('');
  };
  const onThresholdChanged = e => setThreshold(Number(e.target.value));
  const onAnnualCarryoverChanged = e =>
    setAnnualCarryover(Number(e.target.value));
  const onMaxCarryoverChanged = e => setMaxCarryover(Number(e.target.value));

  const onMaxChanged = e => setMax(Number(e.target.value));
  const onReqServicesChanged = e => setReqServices(e.target.value);
  const onFactorAmtChanged = e => setFactorAmt(Number(e.target.value));

  const onEffStartDateChanged = e => {
    setEffStartDate(e);
    setErrorMessage('');
  };
  const onEffEndDateChanged = e => {
    setEffEndDate(e);
    setErrorMessage('');
  };

  const canSave =
    [
      !isNaN(generalMax),
      !isNaN(threshold),
      !isNaN(annualCarryover),
      !isNaN(maxCarryover),
      !isNaN(max),
      reqServices,
      !isNaN(factorAmt),
      startDate,
    ].every(Boolean) && updateRequestStatus === 'idle';

  const onSaveRolloverClicked = async () => {
    if (canSave) {
      try {
        setUpdateRequestStatus('pending');

        var rolloverOverlap = rolloverList.filter(overlapCheck, {
          id: roller.id,
          effStartDate: startDate,
          effEndDate: endDate,
          keys: [
            {
              name: 'generalMax',
              value: generalMax,
            },
            {
              name: 'threshold',
              value: threshold,
            },
            {
              name: 'annualCarryover',
              value: annualCarryover,
            },
          ],
        });
        if (rolloverOverlap.length) {
          console.log(rolloverOverlap);
          setErrorMessage(
            'The record being entered overlaps an existing effective date range for this key value.'
          );
          return false;
        }

        const resultAction = await dispatch(
          updateRollover({
            id: Number(rolloverId),
            generalMax,
            threshold,
            annualCarryover,
            maxCarryover,
            max,
            reqServices,
            factorAmt,
            modifiedByName: user.profile.name,
            modifiedDate: new Date().toISOString(),
            effStartDate: dateToString(startDate),
            effEndDate: nullableDateToString(endDate),
          })
        );
        console.log(resultAction);
        setUpdateRequestStatus('idle');
        history.push(`/roller`);
      } catch (err) {
        setUpdateRequestStatus('idle');
        console.error('Failed to save the rollover: ', err);
      }
    }
  };

  return (
    <Box
      px={{
        base: '4',
        md: '10',
      }}
      pb="16"
      maxWidth="3xl"
      mx="auto"
    >
      <form>
        <Stack spacing="4" divider={<StackDivider />}>
          <FieldGroup title="Edit Rollover">
            <VStack width="full" spacing="6">
              <FormControl id="generalMax">
                <FormLabel>General Max:</FormLabel>
                <Input
                  type="number"
                  value={generalMax}
                  onChange={onGeneralMaxChanged}
                />
              </FormControl>
              <FormControl id="threshold">
                <FormLabel>Threshold:</FormLabel>
                <Input
                  type="number"
                  value={threshold}
                  onChange={onThresholdChanged}
                />
              </FormControl>
              <FormControl id="annualCarryover">
                <FormLabel>Annual Carryover:</FormLabel>
                <Input
                  type="number"
                  value={annualCarryover}
                  onChange={onAnnualCarryoverChanged}
                />
              </FormControl>
              <FormControl id="maxCarryover">
                <FormLabel>Max Carryover:</FormLabel>
                <Input
                  type="number"
                  value={maxCarryover}
                  onChange={onMaxCarryoverChanged}
                />
              </FormControl>

              <FormControl id="max">
                <FormLabel>Max:</FormLabel>
                <Input type="number" value={max} onChange={onMaxChanged} />
              </FormControl>
              <FormControl id="reqServices">
                <FormLabel>Req Services:</FormLabel>
                <Input
                  type="text"
                  value={reqServices}
                  onChange={onReqServicesChanged}
                />
              </FormControl>
              <FormControl id="factorAmt">
                <FormLabel>Factor Amt:</FormLabel>
                <Input
                  type="number"
                  value={factorAmt}
                  onChange={onFactorAmtChanged}
                />
              </FormControl>

              <FormControl id="effStartDate">
                <FormLabel>Effective Start Date:</FormLabel>
                <DatePicker
                  id="effStartDate"
                  selectedDate={startDate}
                  onChange={onEffStartDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective Start Date</FormHelperText>
              </FormControl>
              <FormControl id="effEndDate">
                <FormLabel>Effective End Date:</FormLabel>
                <DatePicker
                  id="effEndDate"
                  selectedDate={endDate}
                  onChange={onEffEndDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective End Date</FormHelperText>
              </FormControl>
            </VStack>
          </FieldGroup>

          <Button
            onClick={onSaveRolloverClicked}
            disabled={!canSave}
            variant="brand-primary"
            size="xs"
          >
            Save Rollover
          </Button>
          <ErrorDisplay
            title="The record dates overlap other records!"
            errorMessage={errorMessage}
          />
        </Stack>
      </form>
    </Box>
  );
};

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
  };
}

export const EditRolloverFormUser = connect(mapStateToProps)(EditRolloverForm);
