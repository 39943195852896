import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import axios from 'axios';

const aPCAsoAdapter = createEntityAdapter();
const apiBaseUrl = window.ENV.api_base_url;

const initialState = aPCAsoAdapter.getInitialState({
  status: 'idle',
  error: null,
});

export const fetchAPCAso = createAsyncThunk(
  'aPCAso/fetchAPCAso',
  async (_, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };

    const response = await axios.get(`${apiBaseUrl}/api/APCAso`, config);
    return response.data;
  }
);

export const addNewAPCAso = createAsyncThunk(
  'aPCAso/addNewAPCAso',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.post(
      `${apiBaseUrl}/api/APCAso`,
      initialState,
      config
    );
    console.log('adding new aPCAso record');
    console.log(response);
    return { ...initialState, id: response.data };
  }
);

export const deleteAPCAso = createAsyncThunk(
  'aPCAso/deleteAPCAso',
  async (aPCAsoId, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.delete(
      `${apiBaseUrl}/api/APCAso/${aPCAsoId}`,
      config
    );
    return aPCAsoId;
  }
);

export const updateAPCAso = createAsyncThunk(
  'aPCAso/updateAPCAso',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.put(
      `${apiBaseUrl}/api/APCAso/${initialState.id}`,
      initialState,
      config
    );
    return { ...initialState, id: response.data };
  }
);

const aPCAsoSlice = createSlice({
  name: 'aPCAso',
  initialState,
  extraReducers: {
    [fetchAPCAso.pending]: state => {
      state.status = 'loading';
    },
    [fetchAPCAso.fulfilled]: (state, action) => {
      // Add any fetched posts to the array
      aPCAsoAdapter.upsertMany(state, action.payload);
      state.status = 'succeeded';
    },
    [fetchAPCAso.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [addNewAPCAso.fulfilled]: aPCAsoAdapter.addOne,
    [deleteAPCAso.fulfilled]: aPCAsoAdapter.removeOne,
    [updateAPCAso.fulfilled]: aPCAsoAdapter.upsertOne,
  },
});

export default aPCAsoSlice.reducer;

export const {
  selectAll: selectAllAPCAso,
  selectById: selectAPCAsoById,
  selectIds: selectAPCAsoIds,
} = aPCAsoAdapter.getSelectors(state => state.aPCAso);
