import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
  AccordionIcon,
  Heading,
} from '@chakra-ui/react';
import {
  fetchAgeAdult,
  selectAgeAdultIds,
  selectAllAgeAdult,
} from './ageAdultSlice';
import { AddAgeAdultFormUser } from './AddAgeAdultForm';
import { AgeAdultList } from './AgeAdultList';
import BrandLinkButton from '../../components/common/BrandLinkButton';

export const AgeAdultPage = () => {
  const dispatch = useDispatch();

  const orderedAgeAdultIds = useSelector(selectAgeAdultIds);
  const ageAdultList = useSelector(selectAllAgeAdult);

  const error = useSelector(state => state.ageAdult.error);
  if (error) {
    console.log('AgeAdult List Error:' + error.toString());
  }

  const ageAdultStatus = useSelector(state => state.ageAdult.status);
  const [index, setIndex] = React.useState([]);

  useEffect(() => {
    if (ageAdultStatus === 'idle') {
      dispatch(fetchAgeAdult());
    }
  }, [ageAdultStatus, dispatch]);

  function closeAccordion() {
    setIndex([]);
  }

  let content;

  if (ageAdultStatus === 'loading') {
    content = <div className="loader">Loading...</div>;
  } else if (ageAdultStatus === 'succeeded') {
    console.log(orderedAgeAdultIds);
    content = (
      <div>
        <Heading size="xl" mb={8}>
          Age Adult Factors
        </Heading>
        <Accordion
          allowMultiple
          allowToggle="true"
          mb={8}
          index={index}
          onChange={setIndex}
        >
          <AccordionItem>
            <Box>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Text variant="brand-green-link" fontSize="lg">
                    Add New Factor
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </Box>
            <AccordionPanel pb={4}>
              <AddAgeAdultFormUser
                ageAdultList={ageAdultList}
                closeAccordion={closeAccordion}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <AgeAdultList orderedAgeAdultIds={orderedAgeAdultIds} />
      </div>
    );
  } else if (ageAdultStatus === 'failed') {
    content = <div>{error}</div>;
  }

  return (
    <>
      {content}
      <Box mt={12}>
        <BrandLinkButton path="/settings">Back to Settings</BrandLinkButton>
      </Box>
    </>
  );
};

export default AgeAdultPage;
