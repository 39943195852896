import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Select,
  Stack,
  StackDivider,
  VStack,
} from '@chakra-ui/react';

import {
  fetchOrthodonticBaseRate,
  updateOrthodonticBaseRate,
  selectOrthodonticBaseRateById,
  selectAllOrthodonticBaseRate,
} from './orthodonticBaseRateSlice';
import { FieldGroup } from '../../components/common/FieldGroup';
import DatePicker from '../../components/common/DatePicker';
import { ErrorDisplay } from '../common/ErrorDisplay';
import { overlapCheck } from '../../services/overlapCheck';
import {
  dateToString,
  nullableDateToString,
  stringToDateFormat,
} from '../../services/dateConverters';

export const EditOrthodonticBaseRateForm = ({ match, user }) => {
  const { orthodonticBaseRateId } = match.params;
  const orthodonticBaseRate = useSelector(state =>
    selectOrthodonticBaseRateById(state, orthodonticBaseRateId)
  );
  const orthodonticBaseRateList = useSelector(selectAllOrthodonticBaseRate);

  const stdt = stringToDateFormat(orthodonticBaseRate.effStartDate);
  console.log(stdt);
  const endt = stringToDateFormat(orthodonticBaseRate.effEndDate);
  console.log(endt);

  const [copay, setCopay] = useState(orthodonticBaseRate.copay);
  const [maximum, setMaximum] = useState(orthodonticBaseRate.maximum);
  const [oneYear, setOneYear] = useState(orthodonticBaseRate.oneYear);
  const [twoYear, setTwoYear] = useState(orthodonticBaseRate.twoYear);

  const [startDate, setEffStartDate] = useState(stdt);

  const [endDate, setEffEndDate] = useState(endt);
  const [updateRequestStatus, setUpdateRequestStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();
  const history = useHistory();

  const onCopayChanged = e => {
    setCopay(Number(e.target.value));
    setErrorMessage('');
  };
  const onMaximumChanged = e => setMaximum(Number(e.target.value));
  const onOneYearChanged = e => setOneYear(Number(e.target.value));
  const onTwoYearChanged = e => setTwoYear(Number(e.target.value));

  const onEffStartDateChanged = e => {
    setEffStartDate(e);
    setErrorMessage('');
  };
  const onEffEndDateChanged = e => {
    setEffEndDate(e);
    setErrorMessage('');
  };

  const canSave =
    [
      !isNaN(copay),
      !isNaN(maximum),
      !isNaN(oneYear),
      !isNaN(twoYear),
      startDate,
    ].every(Boolean) && updateRequestStatus === 'idle';

  const onSaveOrthodonticBaseRateClicked = async () => {
    if (canSave) {
      try {
        setUpdateRequestStatus('pending');

        var orthodonticBaseRateOverlap = orthodonticBaseRateList.filter(
          overlapCheck,
          {
            id: orthodonticBaseRate.id,
            effStartDate: startDate,
            effEndDate: endDate,
            keys: [
              {
                name: 'copay',
                value: copay,
              },
              {
                name: 'maximum',
                value: maximum,
              },
            ],
          }
        );
        if (orthodonticBaseRateOverlap.length) {
          console.log(orthodonticBaseRateOverlap);
          setErrorMessage(
            'The record being entered overlaps an existing effective date range for this key value.'
          );
          return false;
        }

        const resultAction = await dispatch(
          updateOrthodonticBaseRate({
            id: Number(orthodonticBaseRateId),
            copay,
            maximum,
            oneYear,
            twoYear,
            modifiedByName: user.profile.name,
            modifiedDate: new Date().toISOString(),
            effStartDate: dateToString(startDate),
            effEndDate: nullableDateToString(endDate),
          })
        );
        console.log(resultAction);
        setUpdateRequestStatus('idle');
        history.push(`/orthodonticBaseRate`);
      } catch (err) {
        setUpdateRequestStatus('idle');
        console.error('Failed to save the orthodontic Base Rate factor: ', err);
      }
    }
  };
  return (
    <Box
      px={{
        base: '4',
        md: '10',
      }}
      pb="16"
      maxWidth="3xl"
      mx="auto"
    >
      <form>
        <Stack spacing="4" divider={<StackDivider />}>
          <FieldGroup title="Edit Orthodontic Bae Rate Factors">
            <VStack width="full" spacing="6">
              <FormControl id="copay">
                <FormLabel>Copay:</FormLabel>
                <Input type="number" value={copay} onChange={onCopayChanged} />
              </FormControl>
              <FormControl id="maximum">
                <FormLabel>Maximum:</FormLabel>
                <Input
                  type="number"
                  value={maximum}
                  onChange={onMaximumChanged}
                />
              </FormControl>
              <FormControl id="oneYear">
                <FormLabel>OneYear:</FormLabel>
                <Input
                  type="number"
                  value={oneYear}
                  onChange={onOneYearChanged}
                />
              </FormControl>
              <FormControl id="twoYear">
                <FormLabel>TwoYear:</FormLabel>
                <Input
                  type="number"
                  value={twoYear}
                  onChange={onTwoYearChanged}
                />
              </FormControl>

              <FormControl id="effStartDate">
                <FormLabel>Effective Start Date:</FormLabel>
                <DatePicker
                  id="effStartDate"
                  selectedDate={startDate}
                  onChange={onEffStartDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective Start Date</FormHelperText>
              </FormControl>
              <FormControl id="effEndDate">
                <FormLabel>Effective End Date:</FormLabel>
                <DatePicker
                  id="effEndDate"
                  selectedDate={endDate}
                  onChange={onEffEndDateChanged}
                  showPopperArrow={true}
                />
                <FormHelperText>Effective End Date</FormHelperText>
              </FormControl>
            </VStack>
          </FieldGroup>

          <Button
            onClick={onSaveOrthodonticBaseRateClicked}
            disabled={!canSave}
            variant="brand-primary"
            size="xs"
          >
            Save OrthodonticBaseRate Factor
          </Button>
          <ErrorDisplay
            title="The record dates overlap other records!"
            errorMessage={errorMessage}
          />
        </Stack>
      </form>
    </Box>
  );
};

function mapStateToProps(state) {
  return {
    user: state.oidc.user,
  };
}

export const EditOrthodonticBaseRateFormUser = connect(mapStateToProps)(
  EditOrthodonticBaseRateForm
);
