import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  fonts: {
    heading: 'HCo Gotham',
    body: 'HCo Gotham',
  },
  colors: {
    brand_green: {
      600: '#43B02A',
      500: '#5FBC4A',
      300: '#8ED07F',
      200: '#B4DFAA',
      100: '#D0EBCA',
      50: '#D0EBCA',
    },
    brand_purple: {
      100: '#563D82',
      85: '#6F5A95',
      60: '#9A8BB4',
      40: '#BBB1CD',
      25: '#D5CFE0',
    },
    brand_blue: {
      100: '#00AEC7',
      85: '#26BACF',
      60: '#66CEDD',
      40: '#99DFE9',
      25: '#BFEBF1',
    },
    brand_orange: {
      100: '#C35500',
      85: '#E1714A',
      60: '#EA9B7F',
      40: '#F1BCAA',
      25: '#F6D5CA',
    },
    brand_black: {
      100: '#000000',
      85: '#262626',
      60: '#666666',
      25: '#BFBFBF',
      5: '#F2F2F2',
    },
  },
  shadows: {
    outline: '0 0 0 3px #D0EBCA',
  },
  components: {
    Text: {
      variants: {
        'brand-green-link': {
          color: 'brand_green.600',
          textDecoration: 'underline',
          fontWeight: 'bold',
        },
      },
    },
    Button: {
      // 1. We can update the base styles
      baseStyle: {
        fontWeight: 'bold', // Normally, it is "semibold"
        margin: '3px',
      },
      // 2. We can add a new button size or extend existing
      sizes: {
        xl: {
          h: '56px',
          fontSize: 'lg',
          px: '32px',
        },
      },
      // 3. We can add a new visual variant
      variants: {
        'brand-primary': {
          borderRadius: 0,
          color: 'white',
          bg: 'brand_green.600',
          fontSize: 'xl',
          p: 7,
          border: '3px solid',
          borderColor: 'brand_green.600',
          _hover: {
            color: 'brand_green.600',
            bg: 'white',
          },
        },
        'brand-primary-sm': {
          borderRadius: 0,
          color: 'white',
          bg: 'brand_green.600',
          fontSize: 'md',
          p: 4,
          border: '2px solid',
          borderColor: 'brand_green.600',
          _hover: {
            color: 'brand_green.600',
            bg: 'white',
          },
        },
        'brand-secondary': {
          borderRadius: 0,
          color: 'black',
          bg: 'brand_green.300',
          fontSize: 'xl',
          p: 7,
          border: '3px solid',
          borderColor: 'brand_green.300',
          _hover: {
            bg: 'white',
          },
        },
      },
    },
    BrandCenter: {
      // 1. We can update the base styles
      baseStyle: {
        fontWeight: 'bold', // Normally, it is "semibold"
        margin: '3px',
      },
      // 3. We can add a new visual variant
      variants: {
        'ctr-brand-primary': {
          borderRadius: 0,
          color: 'white',
          bg: 'brand_green.600',
          fontSize: 'xl',
          p: 6,
          border: '3px solid',
          borderColor: 'brand_green.600',
          _hover: {
            color: 'brand_green.600',
            bg: 'white',
            cursor: 'pointer',
          },
        },
      },
    },
    Checkbox: {
      parts: ['container', 'control', 'label', 'icon'],
      baseStyle: {
        control: {
          borderColor: 'brand_black.25',
          _checked: {
            borderColor: 'brand_green.600',
            bg: 'brand_green.600',
          },
        },
      },
      sizes: {},
      variants: {},
      defaultProps: {
        size: 'md',
      },
    },
    Radio: {
      parts: ['container', 'control', 'label', 'icon'],
      baseStyle: {
        control: {
          borderColor: 'brand_black.25',
          _checked: {
            borderColor: 'brand_green.600',
            bg: 'brand_green.600',
          },
        },
      },
      sizes: {},
      variants: {},
      defaultProps: {
        size: 'md',
      },
    },
  },
});

export default theme;
