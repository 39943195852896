import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
  AccordionIcon,
  Heading,
} from '@chakra-ui/react';
import {
  fetchCountyArea,
  selectCountyAreaIds,
  selectAllCountyArea,
} from './countyAreaSlice';
import { AddCountyAreaFormUser } from './AddCountyAreaForm';
import { CountyAreaList } from './CountyAreaList';
import BrandLinkButton from '../../components/common/BrandLinkButton';

export const CountyAreaPage = () => {
  const dispatch = useDispatch();

  const orderedCountyAreaIds = useSelector(selectCountyAreaIds);
  const countyAreaList = useSelector(selectAllCountyArea);

  const error = useSelector(state => state.countyArea.error);
  if (error) {
    console.log('CountyArea List Error:' + error.toString());
  }

  const countyAreaStatus = useSelector(state => state.countyArea.status);
  const [index, setIndex] = React.useState([]);

  useEffect(() => {
    if (countyAreaStatus === 'idle') {
      dispatch(fetchCountyArea());
    }
  }, [countyAreaStatus, dispatch]);

  function closeAccordion() {
    setIndex([]);
  }

  let content;

  if (countyAreaStatus === 'loading') {
    content = <div className="loader">Loading...</div>;
  } else if (countyAreaStatus === 'succeeded') {
    console.log(orderedCountyAreaIds);
    content = (
      <div>
        <Heading size="xl" mb={8}>
          County Area Factors
        </Heading>
        <Accordion
          allowMultiple
          allowToggle="true"
          mb={8}
          index={index}
          onChange={setIndex}
        >
          <AccordionItem>
            <Box>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Text variant="brand-green-link" fontSize="lg">
                    Add New Factor
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </Box>
            <AccordionPanel pb={4}>
              <AddCountyAreaFormUser
                countyAreaList={countyAreaList}
                closeAccordion={closeAccordion}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <CountyAreaList orderedCountyAreaIds={orderedCountyAreaIds} />
      </div>
    );
  } else if (countyAreaStatus === 'failed') {
    content = <div>{error}</div>;
  }

  return (
    <>
      {content}
      <Box mt={12}>
        <BrandLinkButton path="/settings">Back to Settings</BrandLinkButton>
      </Box>
    </>
  );
};

export default CountyAreaPage;
