import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import axios from 'axios';

const idcardChargeAdapter = createEntityAdapter();
const apiBaseUrl = window.ENV.api_base_url;

const initialState = idcardChargeAdapter.getInitialState({
  status: 'idle',
  error: null,
});

export const fetchIdcardCharge = createAsyncThunk(
  'idcardCharge/fetchIdcardCharge',
  async (_, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };

    const response = await axios.get(`${apiBaseUrl}/api/IdcardCharge`, config);
    return response.data;
  }
);

export const addNewIdcardCharge = createAsyncThunk(
  'idcardCharge/addNewIdcardCharge',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.post(
      `${apiBaseUrl}/api/IdcardCharge`,
      initialState,
      config
    );
    console.log('adding new idcardCharge record');
    console.log(response);
    return { ...initialState, id: response.data };
  }
);

export const deleteIdcardCharge = createAsyncThunk(
  'idcardCharge/deleteIdcardCharge',
  async (idcardChargeId, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.delete(
      `${apiBaseUrl}/api/IdcardCharge/${idcardChargeId}`,
      config
    );
    return idcardChargeId;
  }
);

export const updateIdcardCharge = createAsyncThunk(
  'idcardCharge/updateIdcardCharge',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.put(
      `${apiBaseUrl}/api/IdcardCharge/${initialState.id}`,
      initialState,
      config
    );
    return { ...initialState, id: response.data };
  }
);

const idcardChargeSlice = createSlice({
  name: 'idcardCharge',
  initialState,
  extraReducers: {
    [fetchIdcardCharge.pending]: state => {
      state.status = 'loading';
    },
    [fetchIdcardCharge.fulfilled]: (state, action) => {
      // Add any fetched posts to the array
      idcardChargeAdapter.upsertMany(state, action.payload);
      state.status = 'succeeded';
    },
    [fetchIdcardCharge.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [addNewIdcardCharge.fulfilled]: idcardChargeAdapter.addOne,
    [deleteIdcardCharge.fulfilled]: idcardChargeAdapter.removeOne,
    [updateIdcardCharge.fulfilled]: idcardChargeAdapter.upsertOne,
  },
});

export default idcardChargeSlice.reducer;

export const {
  selectAll: selectAllIdcardCharge,
  selectById: selectIdcardChargeById,
  selectIds: selectIdcardChargeIds,
} = idcardChargeAdapter.getSelectors(state => state.idcardCharge);
