import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import axios from 'axios';

const estimatedClaimAdapter = createEntityAdapter();
const apiBaseUrl = window.ENV.api_base_url;

const initialState = estimatedClaimAdapter.getInitialState({
  status: 'idle',
  error: null,
});

export const fetchEstimatedClaim = createAsyncThunk(
  'estimatedClaim/fetchEstimatedClaim',
  async (_, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };

    const response = await axios.get(
      `${apiBaseUrl}/api/EstimatedClaim`,
      config
    );
    return response.data;
  }
);

export const addNewEstimatedClaim = createAsyncThunk(
  'estimatedClaim/addNewEstimatedClaim',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.post(
      `${apiBaseUrl}/api/EstimatedClaim`,
      initialState,
      config
    );
    console.log('adding new estimatedClaim record');
    console.log(response);
    return { ...initialState, id: response.data };
  }
);

export const deleteEstimatedClaim = createAsyncThunk(
  'estimatedClaim/deleteEstimatedClaim',
  async (estimatedClaimId, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.delete(
      `${apiBaseUrl}/api/EstimatedClaim/${estimatedClaimId}`,
      config
    );
    return estimatedClaimId;
  }
);

export const updateEstimatedClaim = createAsyncThunk(
  'estimatedClaim/updateEstimatedClaim',
  async (initialState, { getState }) => {
    const user = getState().oidc.user;
    const config = {
      headers: { Authorization: `Bearer ${user.access_token}` },
    };
    console.log(config);
    const response = await axios.put(
      `${apiBaseUrl}/api/EstimatedClaim/${initialState.id}`,
      initialState,
      config
    );
    return { ...initialState, id: response.data };
  }
);

const estimatedClaimSlice = createSlice({
  name: 'estimatedClaim',
  initialState,
  extraReducers: {
    [fetchEstimatedClaim.pending]: state => {
      state.status = 'loading';
    },
    [fetchEstimatedClaim.fulfilled]: (state, action) => {
      // Add any fetched posts to the array
      estimatedClaimAdapter.upsertMany(state, action.payload);
      state.status = 'succeeded';
    },
    [fetchEstimatedClaim.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    [addNewEstimatedClaim.fulfilled]: estimatedClaimAdapter.addOne,
    [deleteEstimatedClaim.fulfilled]: estimatedClaimAdapter.removeOne,
    [updateEstimatedClaim.fulfilled]: estimatedClaimAdapter.upsertOne,
  },
});

export default estimatedClaimSlice.reducer;

export const {
  selectAll: selectAllEstimatedClaim,
  selectById: selectEstimatedClaimById,
  selectIds: selectEstimatedClaimIds,
} = estimatedClaimAdapter.getSelectors(state => state.estimatedClaim);
