import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
  AccordionIcon,
  Heading,
} from '@chakra-ui/react';
import {
  fetchWaitingPeriod,
  selectWaitingPeriodIds,
  selectAllWaitingPeriod,
} from './waitingPeriodSlice';
import { AddWaitingPeriodFormUser } from './AddWaitingPeriodForm';
import { WaitingPeriodList } from './WaitingPeriodList';
import BrandLinkButton from '../../components/common/BrandLinkButton';

export const WaitingPeriodPage = () => {
  const dispatch = useDispatch();
  console.log('attention:' + selectWaitingPeriodIds);
  const orderedWaitingPeriodIds = useSelector(selectWaitingPeriodIds);
  console.log('attention2:' + selectWaitingPeriodIds);
  const waitingPeriodList = useSelector(selectAllWaitingPeriod);

  const error = useSelector(state => state.waitingPeriod.error);
  if (error) {
    console.log('WaitingPeriod List Error:' + error.toString());
  }

  const waitingPeriodStatus = useSelector(state => state.waitingPeriod.status);
  const [index, setIndex] = React.useState([]);

  useEffect(() => {
    if (waitingPeriodStatus === 'idle') {
      dispatch(fetchWaitingPeriod());
    }
  }, [waitingPeriodStatus, dispatch]);

  function closeAccordion() {
    setIndex([]);
  }

  let content;

  if (waitingPeriodStatus === 'loading') {
    content = <div className="loader">Loading...</div>;
  } else if (waitingPeriodStatus === 'succeeded') {
    console.log(orderedWaitingPeriodIds);
    content = (
      <div>
        <Heading size="xl" mb={8}>
          Waiting Period Factors
        </Heading>
        <Accordion
          allowMultiple
          allowToggle="true"
          mb={8}
          index={index}
          onChange={setIndex}
        >
          <AccordionItem>
            <Box>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Text variant="brand-green-link" fontSize="lg">
                    Add New Factor
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </Box>
            <AccordionPanel pb={4}>
              <AddWaitingPeriodFormUser
                waitingPeriodList={waitingPeriodList}
                closeAccordion={closeAccordion}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <WaitingPeriodList orderedWaitingPeriodIds={orderedWaitingPeriodIds} />
      </div>
    );
  } else if (waitingPeriodStatus === 'failed') {
    content = <div>{error}</div>;
  }

  return (
    <>
      {content}
      <Box mt={12}>
        <BrandLinkButton path="/settings">Back to Settings</BrandLinkButton>
      </Box>
    </>
  );
};

export default WaitingPeriodPage;
