import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
  AccordionIcon,
  Heading,
} from '@chakra-ui/react';
import {
  fetchPandemic,
  selectPandemicIds,
  selectAllPandemic,
} from './pandemicSlice';
import { AddPandemicFormUser } from './AddPandemicForm';
import { PandemicList } from './PandemicList';
import BrandLinkButton from '../../components/common/BrandLinkButton';

export const PandemicPage = () => {
  const dispatch = useDispatch();

  const orderedPandemicIds = useSelector(selectPandemicIds);
  const pandemicList = useSelector(selectAllPandemic);

  const error = useSelector(state => state.pandemic.error);
  if (error) {
    console.log('Pandemic List Error:' + error.toString());
  }

  const pandemicStatus = useSelector(state => state.pandemic.status);
  const [index, setIndex] = React.useState([]);

  useEffect(() => {
    if (pandemicStatus === 'idle') {
      dispatch(fetchPandemic());
    }
  }, [pandemicStatus, dispatch]);

  function closeAccordion() {
    setIndex([]);
  }

  let content;

  if (pandemicStatus === 'loading') {
    content = <div className="loader">Loading...</div>;
  } else if (pandemicStatus === 'succeeded') {
    console.log(orderedPandemicIds);
    content = (
      <div>
        <Heading size="xl" mb={8}>
          Pandemic Factors
        </Heading>
        <Accordion
          allowMultiple
          allowToggle="true"
          mb={8}
          index={index}
          onChange={setIndex}
        >
          <AccordionItem>
            <Box>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Text variant="brand-green-link" fontSize="lg">
                    Add New Factor
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </Box>
            <AccordionPanel pb={4}>
              <AddPandemicFormUser
                pandemicList={pandemicList}
                closeAccordion={closeAccordion}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <PandemicList orderedPandemicIds={orderedPandemicIds} />
      </div>
    );
  } else if (pandemicStatus === 'failed') {
    content = <div>{error}</div>;
  }

  return (
    <>
      {content}
      <Box mt={12}>
        <BrandLinkButton path="/settings">Back to Settings</BrandLinkButton>
      </Box>
    </>
  );
};

export default PandemicPage;
