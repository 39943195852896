import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
  AccordionIcon,
  Heading,
} from '@chakra-ui/react';
import {
  fetchEhbTierDist,
  selectEhbTierDistIds,
  selectAllEhbTierDist,
} from './ehbTierDistSlice';
import { AddEhbTierDistFormUser } from './AddEhbTierDistForm';
import { EhbTierDistList } from './EhbTierDistList';
import BrandLinkButton from '../../components/common/BrandLinkButton';

export const EhbTierDistPage = () => {
  const dispatch = useDispatch();

  const orderedEhbTierDistIds = useSelector(selectEhbTierDistIds);
  const ehbTierDistList = useSelector(selectAllEhbTierDist);

  const error = useSelector(state => state.ehbTierDist.error);
  if (error) {
    console.log('EhbTierDist List Error:' + error.toString());
  }

  const ehbTierDistStatus = useSelector(state => state.ehbTierDist.status);
  const [index, setIndex] = React.useState([]);

  useEffect(() => {
    if (ehbTierDistStatus === 'idle') {
      dispatch(fetchEhbTierDist());
    }
  }, [ehbTierDistStatus, dispatch]);

  function closeAccordion() {
    setIndex([]);
  }

  let content;

  if (ehbTierDistStatus === 'loading') {
    content = <div className="loader">Loading...</div>;
  } else if (ehbTierDistStatus === 'succeeded') {
    console.log(orderedEhbTierDistIds);
    content = (
      <div>
        <Heading size="xl" mb={8}>
          EhbTierDist Factors
        </Heading>
        <Accordion
          allowMultiple
          allowToggle="true"
          mb={8}
          index={index}
          onChange={setIndex}
        >
          <AccordionItem>
            <Box>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Text variant="brand-green-link" fontSize="lg">
                    Add New Factor
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </Box>
            <AccordionPanel pb={4}>
              <AddEhbTierDistFormUser
                ehbTierDistList={ehbTierDistList}
                closeAccordion={closeAccordion}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <EhbTierDistList orderedEhbTierDistIds={orderedEhbTierDistIds} />
      </div>
    );
  } else if (ehbTierDistStatus === 'failed') {
    content = <div>{error}</div>;
  }

  return (
    <>
      {content}
      <Box mt={12}>
        <BrandLinkButton path="/settings">Back to Settings</BrandLinkButton>
      </Box>
    </>
  );
};

export default EhbTierDistPage;
